const styles = theme => ({
  avatar: Object.assign({}, theme.userChip, {
    border: "none",
    marginRight: 5,
    marginBottom: 5
  }),
  selectChip: {
    marginRight: 5
  },
  selectInputRoot: {
    width: "100%",

    "&:hover": {
      "& fieldset": {
        borderColor: [theme.greyBlue, "!important"],
      }
    },
  },

  selectInputFocused: {
    borderColor: theme.greyBlue
  },

  selectRoot: {
    paddingLeft: 20,
    paddingRight: 14
  },

  selectInput: {
    borderColor: theme.cloudyBlue
  },

  selectAdornment: {
    marginTop: 4,
  },

  selectMenu: {
    paddingBottom: 0
  },

  groupChip: Object.assign({}, theme.groupChip, {
    marginBottom: 5
  }),
  tooltipTitle: {
    textAlign: "center"
  },
  tooltipUser: {
    maxHeight: 400,
    overflow: "hidden auto",
    pointerEvents: "unset",
    opacity: 1
  },
  tooltip: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    lineHeight: 1.5,
    padding: 10,
    width: 250
  },
  tooltipContainer: {
    width: "100%",
    top: -13,
    position: "absolute",
    paddingRight: 16,
    textAlign: "center"
  },
  progress: {
    margin: "0 10px 0 0",
    color: "#fff"
  },
});

export default styles;