import defaultUserIcon from "../assets/images/default_profile_normal.png";
import Constants from './params'
import i18n from '../i18n'

const global = {

  getUserNameAndAvatar({ user }) {

    if (!user) {
      return {
        screenNameThatShouldBeDisplayed: null,
        profileImageThatShouldBeDisplayed: defaultUserIcon
      }
    }

    let u_twitter = user.user_twitter, u_linkedin = user.user_linkedin

    let screenNameThatShouldBeDisplayed =
      user.name ||
      (u_linkedin && u_linkedin.first_name && u_linkedin.last_name && `${u_linkedin.first_name} ${u_linkedin.last_name}`) ||
      (u_twitter && (u_twitter.display_name || u_twitter.screen_name)) ||
      'Utilisateur'

    let profileImageThatShouldBeDisplayed =
      (u_twitter && u_twitter.profile_image && u_twitter.profile_image.replace('_normal', '_bigger')) ||
      (u_linkedin && u_linkedin.profile_image) ||
      defaultUserIcon

    if (profileImageThatShouldBeDisplayed && profileImageThatShouldBeDisplayed.includes('ucarecdn.com')) profileImageThatShouldBeDisplayed = `${profileImageThatShouldBeDisplayed}-/resize/x83/`
    return { screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed }

  },

  isUrlFromTwitter(url) {
    return url && (url.includes("twitter.com") || url.includes("www.twitter.com"))
  },

  getNetworkMessagelength(message, params) {
    let length = {
      twitter: 0,
      linkedin: 0
    }

    if (!message) return length

    if (params.twitter) {
      // Retrieve url with or without protocol
      const regexUrl = /(\S+\.[^\s]+(\/\S+|\/|))/g
      let validUrl = []

      let urls = (typeof (message) === 'string') && message.match(regexUrl)

      if (urls) {
        for (let url of urls) {
          try {
            let urlObject = new URL(!(url.startsWith("http://") || url.startsWith("https://")) ? "https://" + url : url)

            let tld = urlObject.hostname.split(".").pop()
            if ((this.binarySearch(tld, Constants.TLD_LIST) !== -1)) validUrl.push(url)

          } catch (error) {
            console.error(error)
          }
        }

        length.twitter = !validUrl.length ? message.length : ((message.length - validUrl.join('').length) + (23 * validUrl.length))
      } else {
        length.twitter = message.length
      }
    }

    if (params.linkedin) length.linkedin = message.length

    return length
  },

  binarySearch(comparedValue, array) {

    let left = 0, right = array.length - 1;
    while (left <= right) {
      let middle = Math.round(left + (right - left) / 2);

      // Check if comparedValue is present at mid 
      if (array[middle] === comparedValue)
        return middle;

      // If comparedValue greater, ignore left half 
      if (array[middle] < comparedValue)
        left = middle + 1;

      // If comparedValue is smaller, ignore right half 
      else
        right = middle - 1;
    }

    // if we reach here, then element was 
    // not present 
    return -1;
  },

  manageHover(e, options = null) {
    const className = options && options.class ? options.class : 'background-color-hover'
    try {
      if (e.type === 'mouseenter') e.currentTarget.classList.add(className)

      else if (e.type === 'mouseleave') e.currentTarget.classList.remove(className)
    } catch (e) {
      console.error(e)
    }
  },

  giveTimeFromADate(date, reverse) {
    // Wording time
    const wording_time = i18n.t('manager', { returnObjects: true }).analytics.groupes

    // Get timestamp last saved.
    let timestampLastSaved = new Date(date).getTime() / 1000;

    // Get timestamp.
    const currentTimestamp = new Date().getTime() / 1000;

    // Create substract.
    let substract = !reverse ? Math.round(currentTimestamp - timestampLastSaved) : Math.round(timestampLastSaved - currentTimestamp);

    /* 4 possibilities to return date :
      - In secondes (substract is less than 60)
      - In minutes (substract is less than 60 * 60)
      - In hours (substract is less than 60 * 60 * 24)
      - In days (all others)
    */

    if (substract < 60) {

      // Get second.
      if (substract <= 0) {
        substract = 1;
      }

      // Return seconds.
      return `${substract} ${wording_time.day}`;

    } else if (substract < 60 * 60) {

      // Get minutes.
      const minutes = Math.round(substract / 60);

      // Return minutes.
      return `${minutes} ${wording_time.minute}`;

    } else if (substract < 60 * 60 * 24) {

      // Get hours.
      const hours = Math.round(substract / (60 * 60));

      // Return hours.
      return `${hours} ${wording_time.hour}`;

    } else {

      // Get days.
      const days = Math.round(substract / (60 * 60 * 24));

      // Return days.
      return `${days} ${wording_time.day}`;
    }
  },

  capitalize(value) {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value
  }
}

export default global;