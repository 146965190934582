import React from 'react';
import ReactSVG from "react-svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import diff from 'deep-diff';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { Translation } from 'react-i18next';
import Cookies from 'js-cookie'
import i18n from '../../../i18n'

// Material
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { withStyles, Tooltip } from "@material-ui/core";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { BasePicker, MuiPickersUtilsProvider, Calendar } from "material-ui-pickers";

// Assets
import ok from '../../../assets/images/ok.svg';
import wrong from '../../../assets/images/wrong.svg';
import sendIcon from '../../../assets/images/send.svg';
import AddImageIcon from '../../../assets/icons/add-image';
import AddVideoIcon from '../../../assets/icons/add-video';
import searchIcon from '../../../assets/images/search.svg';
import scheduleIcon from '../../../assets/images/schedule-dark-grey.svg'
import TwitterIcon from '../../../assets/icons/twitter-icon';
import LinkedinIcon from '../../../assets/icons/linkedin-icon';
// import IconFacebookBg from '../../../assets/icons/facebookBg'
import IconInstaBg from '../../../assets/icons/instagramBg'
import arrow from "../../../assets/images/arrow.svg";
import AlertInfoIcon from '../../../assets/icons/alert-info'
import WrongIcon from '../../../assets/icons/wrong';
import TrashIcon from '../../../assets/icons/trash';
import IconEmoji from '../../../assets/icons/emoji';
import IconArrowKeyboard from '../../../assets/icons/arrowKeyboard';
import defaultUserIcon from "../../../assets/images/default_profile_normal.png";
import InfoIcon from '../../../assets/icons/info'

// Components
import * as actionCreators from "../../../actions/actionCreators";
import TextEditor from '../textEditor/textEditor';
import LinkedinLogin from '../../misc/LinkedinLogin/LinkedinLogin'
import TwitterLogin from '../../misc/TwitterLogin/TwitterLogin';
import SelectDialog from "../selectDialog/select-dialog";
import ConfirmDialog from '../confirm-dialog';
import TweetitForm from '../tweetit-form-common';
import params from '../../../utils/params';
import TweetCard from "../tweetCard/tweet-card";
import GlobalUtils from '../../../utils/global'

// Material styles
import styles from './material-tweetit-form';
import EventEmitter from '../../../utils/EventEmitter';

function TransitionWrapper(props) {
  return <Grid container wrap={"nowrap"} style={{ position: "relative", display: "flex", flexWrap: "wrap" }} {...props} />;
}

function Transition(props) {
  return <CSSTransition {...props} />
}

class FormDialog extends TweetitForm {
  constructor(props) {
    super(props);

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.submitTweetit = this.submitTweetit.bind(this);
    this.setItems = this.setItems.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.selectContentType = this.selectContentType.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.setSchedule = this.setSchedule.bind(this);
    this.unsetSchedule = this.unsetSchedule.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);

    // Ref
    this.tooltipTwitterRef = React.createRef()
    this.tooltipLinkedinRef = React.createRef()

    /// Functions to manage the popin ///

    // Closing the popin by choosing the cancel button
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    // Closing the popin by choosing the validate button
    this.handleValidConfirm = this.handleValidConfirm.bind(this);
    // Open the popin
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this);

    // When clicking on the button to go back to the dashboard
    this.backToDashboard = this.backToDashboard.bind(this);

    // Choosing the post Facebook to share
    this.navFacebookPost = this.navFacebookPost.bind(this);

    this.togglePublishFor = this.togglePublishFor.bind(this)
    this.onSocialAuthSucceed = this.onSocialAuthSucceed.bind(this)
    this.onSocialAuthFailed = this.onSocialAuthFailed.bind(this)

    this.defaultState = {
      value: params.types.POST,
      message: "",
      formData: {
        id: null,
        users: [],
        groups: [],
        tag: [],
        type: params.types.POST,
        message: "",
        comment: "",
        media: [],
        mediaType: params.mediaTypes.IMAGE,
        share_url: null,
        share_ref: null,
        scheduled_at: null,
        submitted_at: null,
        email_notif: process.env.REACT_APP_FUNCTION_EMAIL_DEFAULT_ON === 'true',
        sms_notif: process.env.REACT_APP_FUNCTION_SMS_DEFAULT_ON === 'true',
        notify_users: true,
        hours: null,
        minutes: null,
        meridiem: null,
      },
      imgSrc: [],
      vidSrc: null,
      isScheduled: false,
      urlIsLoading: false,
      urlIsValid: undefined,
      messageIsValid: undefined,
      profileIsValid: undefined,
      imageError: null,
      videoError: null,
      isTweetContainGif: false,
      emojisIsOpen: false,
      fbPages: 2
    };

    this.state = Object.assign({
      selectAnchorTop: 0,
      selectAnchorLeft: 0,
      calendarAnchorTop: 0,
      calendarAnchorLeft: 0,
      usersWidth: 0,
      usersIsOpen: false,
      tagsIsOpen: false,
      confirmIsOpen: false,
      calendarIsOpen: false,
      calendarAnchor: null,
      loaded: false,
      avatarsIn: false,
      itemCreated: false,
      isModified: false,
      isTweetContainGif: false,

      willPublishForTwitter: false,
      willPublishForLinkedin: false,
      willPublishForFacebook: false,
      willPublishForInstagram: false,

      authenticatedOnTwitter: Cookies.get('twitter_key') ? true : false,
      authenticatedOnLinkedin: Cookies.get('linkedin') ? true : false,

      shouldOpenPopinNeedToAuthTwitter: null,
      shouldOpenPopinNeedToAuthLinkedin: null,

      [this.CONTENT_PUT_LINK_FORWARD]: false


    }, this.defaultState);

    // bind
    this.manageTooltipNetwork = this.manageTooltipNetwork.bind(this)
    this.displayUsersGroupsAssociated = this.displayUsersGroupsAssociated.bind(this)
    this.toggleEmojis = this.toggleEmojis.bind(this)
    this.handleCloseEmojis = this.handleCloseEmojis.bind(this)

  }

  async componentWillReceiveProps(nextProps, nextContext) {

    if (nextProps.open && diff(nextProps, this.props)) {
      if (nextProps.formData && Object.keys(nextProps.formData).length > 0 && nextProps.formData.constructor === Object) {
        let formData = nextProps.formData,
          wasScheduled = false;

        /* Rebuilding calendar and time values from schedule */
        if (formData.scheduled_at && formData.scheduled_at.isValid()) {
          formData.hours = formData.scheduled_at.hours() < 13 ? formData.scheduled_at.hours() : formData.scheduled_at.hours() - 12;
          formData.minutes = formData.scheduled_at.minutes();
          formData.meridiem = formData.scheduled_at.hours() < 13 ? 'AM' : 'PM';
          wasScheduled = true;
        }

        // Check If there is a gif
        // Get the list of id
        let ids = formData.media.map(m => m.uploadcare_id)

        // Init the list who will contain the id of gif media
        let listGifIds = []

        for (let id of ids) {
          let imageInfo = await this.props.getFileInfoUploadCare(id);

          if (imageInfo && imageInfo.format === "GIF") listGifIds.push(imageInfo.id)
        }

        if (listGifIds.length) {
          this.setState({
            isTweetContainGif: true
          })
        }

        /* For existing tweets retrieve users */
        if (nextProps.formData.id) {
          formData.users = formData.references
            .map(child => child.user)
            .filter(u => u && (u.status === 'enabled' || u.status === 'waiting'))
            .map(u => {

              const {
                screenNameThatShouldBeDisplayed,
                profileImageThatShouldBeDisplayed
              } = GlobalUtils.getUserNameAndAvatar({
                user: u
              })

              return Object.assign({
                label: screenNameThatShouldBeDisplayed,
                icon: profileImageThatShouldBeDisplayed
              }, u)
            });
        }

        // If the type of the post, is to follow a twitter profile
        if (formData.type === params.types.PROFILE) {
          formData.share_url = formData.share_url.replace('https://twitter.com/', '')
        }

        /*  Vérifie le type pour affiche la bonne fenêtre */
        this.setState({
          //message: formData.message.replace(/([^>])?([@#]\S+)/g, '$1<span>$2</span>').replace(/\s/g, '&nbsp;'),
          formData: Object.assign({}, this.defaultState.formData, formData),
          value: formData.type,
          usersIsOpen: false,
          tagsIsOpen: false,
          calendarIsOpen: false,
          isScheduled: wasScheduled,
          avatarsIn: false,
          authenticatedOnTwitter: Cookies.get('twitter_key') ? true : false,
          authenticatedOnLinkedin: Cookies.get('linkedin') ? true : false,
          willPublishForTwitter: formData.network === 'twitter',
          willPublishForLinkedin: formData.network === 'linkedin',
          willPublishForInstagram: formData.network === 'instagram',
          willPublishForFacebook: formData.network === 'facebook',
          [this.CONTENT_PUT_LINK_FORWARD]: (formData.network === 'linkedin') && formData.share_url ? true : false,
          urlIsValid: ['instagram', 'facebook'].includes(formData.network)
        }, () => {
          /* Form validation for existing tweets */

          if (nextProps.formData.id) {

            if (nextProps.formData.type === params.types.POST) {
              this.validateContent(nextProps.formData.message, this.CONTENT_MESSAGE, formData.type);
            } else if (nextProps.formData.type === params.types.REPOST) {
              this.validateContent(nextProps.formData.share_url, this.CONTENT_URL, formData.type);
            } else if (nextProps.formData.type === params.types.PROFILE) {
              this.validateContent(nextProps.formData.share_url, this.CONTENT_PROFILE, formData.type);
            }
          }
        });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    // If we hide the tweet form reset the boolean to detect a modification
    if (prevProps.open && !this.props.open) this.setState({ isModified: false })

    // If we display the tweet form to create a new tweet, reset the state to the default state
    if (!prevProps.open && this.props.open && this.props.formData && Object.keys(this.props.formData).length === 0) {
      await this.resetProperties()
    }
  }

  async resetProperties() {
    // Reset properties linked by reference (tempory solution)
    this.defaultState.formData = {
      id: null,
      users: [],
      groups: [],
      tag: [],
      type: params.types.POST,
      message: "",
      comment: "",
      media: [],
      mediaType:
      params.mediaTypes.IMAGE,
      share_url: null,
      share_ref: null,
      scheduled_at: null,
      submitted_at: null,
      email_notif: process.env.REACT_APP_FUNCTION_EMAIL_DEFAULT_ON === 'true',
      sms_notif: process.env.REACT_APP_FUNCTION_SMS_DEFAULT_ON === 'true',
      notify_users: true,
      hours: null,
      minutes: null,
      meridiem: null
    }

    const isAuthenticatedOnTwitter = Cookies.get('twitter_key') ? true : false
    const isAuthenticatedOnLinkedin = Cookies.get('linkedin') ? true : false

    this.setState({
      formData: Object.assign({}, this.defaultState.formData),
      imgSrc: [],
      imageError: null,
      videoError: null,
      vidSrc: null,
      isTweetContainGif: false,
      authenticatedOnTwitter: isAuthenticatedOnTwitter,
      authenticatedOnLinkedin: isAuthenticatedOnLinkedin,
      // if user is auth on both network or just twitter
      willPublishForTwitter: (isAuthenticatedOnTwitter && isAuthenticatedOnLinkedin) || isAuthenticatedOnTwitter,
      // if user is only auth on linkedin
      willPublishForLinkedin: isAuthenticatedOnLinkedin && !isAuthenticatedOnTwitter,
      willPublishForInstagram: false,
      willPublishForFacebook: false,
      [this.CONTENT_PUT_LINK_FORWARD]: isAuthenticatedOnLinkedin && !isAuthenticatedOnTwitter,
      value: params.types.POST,
      urlIsValid: undefined,
      fbPages: 2
    });
  }

  selectContentType = (event, value, options = null) => {
    // If the content type is the same that the one selected
    if ((this.state.value === value) && !(this.state.willPublishForInstagram || this.state.willPublishForFacebook)) return;

    // Reset the data
    let formData = this.state.formData;
    formData.message = '';
    formData.share_url = this.state.willPublishForInstagram || this.state.willPublishForFacebook ? formData.share_url : null;
    formData.share_ref = options ? formData.share_ref ? Object.assign(formData.share_ref, { ...options }) : { ...options } : null;
    formData.media = [];

    this.setState({
      value: value,
      formData: formData,
      isModified: true,
      messageIsValid: false,
      urlIsValid: this.state.willPublishForInstagram || this.state.willPublishForFacebook ? this.state.urlIsValid : undefined,
      profileIsValid: undefined,
      imgSrc: []
    });
  };

  async submitTweetit(status) {
    // Reset height 
    document.querySelector('.tweetit-form__preview-panel-scroll').style.height = '100%'

    let date = null;
    if (this.state.isScheduled && this.state.formData.scheduled_at) {
      date = this.state.formData.scheduled_at
        .hours(this.state.formData.hours)
        .minutes(this.state.formData.minutes);
      if (this.state.formData.meridiem === 'PM') {
        date.add({ hours: 12 });
      }
    }

    let tweetit = {
      message: this.state.formData.message,
      comment: this.state.formData.comment.length > 0 ? this.state.formData.comment : null,
      media: this.state.formData.media,
      mediaType: this.state.formData.mediaType,
      type: this.state.value,
      share_url: this.state.formData.share_url ? this.state.formData.share_url : null,
      share_ref: this.state.formData.share_ref ? this.state.formData.share_ref : null,
      status: status,
      isNotify: !((status === this.state.formData.status) && status === params.statuses.SUGGESTED),
      users: this.state.formData.users.map(user => user.id),
      groups: this.state.formData.groups.map(group => group.id),
      tag: this.state.formData.tag.length > 0 ? this.state.formData.tag.map(tag => tag.id) : null,
      scheduled_at: date,
      submitted_at: this.state.formData.submitted_at && (status === params.statuses.SUGGESTED) ? this.state.formData.submitted_at : !this.state.formData.submitted_at && (status === params.statuses.SUGGESTED) ? moment() : null,
      email_notif: this.state.formData.email_notif,
      sms_notif: this.state.formData.sms_notif,
      notify_users: this.state.formData.notify_users,
      updated_by: this.props.user.id,
      network: this.state.formData.network
    },
      stateLoading;

    // If the type of the post, is to follow a twitter profile
    if (tweetit.type === params.types.PROFILE) {
      tweetit.share_url = `https://twitter.com/${tweetit.share_url}`
    }

    // If the post is for Facebook, keep only one post
    if (this.state.willPublishForFacebook && tweetit.share_ref && tweetit.share_ref.posts && tweetit.share_ref.posts.length) {
      tweetit.share_ref = Object.assign(tweetit.share_ref, tweetit.share_ref.posts[tweetit.share_ref.index_selected])
      delete tweetit.share_ref.index_selected
      delete tweetit.share_ref.posts
    }

    tweetit.share_url = this.state.willPublishForFacebook && !tweetit.share_url.includes('facebook.com/') ? `https://www.facebook.com/${tweetit.share_url}` : tweetit.share_url

    if (status === params.statuses.DRAFT) {
      stateLoading = { saveLoading: true }
    } else {
      stateLoading = { submitLoading: true }
    }
    await this.setState(stateLoading);

    try {
      let res = null

      // Edit a post
      if (this.props.formData.id) {
        res = await this.props.editTweetitAction(this.state.formData.id, tweetit);

        // Create a post
      } else {

        tweetit.networks = []
        if (this.state.willPublishForLinkedin) tweetit.networks.push("linkedin")
        if (this.state.willPublishForTwitter) tweetit.networks.push("twitter")
        if (this.state.willPublishForInstagram) tweetit.networks.push("instagram")
        if (this.state.willPublishForFacebook) tweetit.networks.push("facebook")

        res = await this.props.createTweetitAction(tweetit);
      }

      if (res && res.error) {
        this.setState({
          isModified: true,
          saveLoading: false,
          submitLoading: false
        })
        EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
        return
      }

    } catch (err) {

    }

    await this.setState(Object.assign({
      saveLoading: false,
      submitLoading: false,
    }, this.defaultState));

    this.props.handleClose();
  }

  async deleteTweetit() {
    if (this.state.formData.id) {
      let res = await this.props.deleteTweetitAction(this.state.formData.id);

      if (res && res.error) {
        EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
        this.handleCloseConfirm();
        return
      }
    }
    await this.setState(Object.assign({}, this.defaultState));
    this.props.handleClose();
    this.handleCloseConfirm();
  }

  openSelectDialog = (e, field) => {
    let rect = e.currentTarget.getBoundingClientRect();
    this.setState({
      selectAnchorTop: rect.y,
      selectAnchorLeft: rect.x,
      usersWidth: rect.width,
      usersIsOpen: field === 'users',
      tagsIsOpen: field === 'tag'
    });
  };

  closeSelectDialog = () => {
    this.setState({
      usersIsOpen: false,
      tagsIsOpen: false
    });
  };

  async setItems(items, field) {
    if (items.length === 0) {
      this.closeSelectDialog();
      return;
    }

    let formData = Object.assign({}, this.state.formData);
    if (Array.isArray(field)) {
      field.forEach((f, index) => {

        formData[f] = items[index].filter(i => i.selected).map((item, index) => {
          if (f !== 'groups') return item
          // build array of object with user id (related to displayUsersGroupsAssociated() function)
          item.users = item.users.map((user_id) => { return { id: user_id } })
          return item
        });
      })
    } else {
      formData[field] = items.filter(i => i.selected);
    }
    await this.setState({
      formData: formData,
      isModified: true
    });
    await this.closeSelectDialog()
  };

  removeItem = (e, index, field) => {
    // Crash si on ne passe pas l'event en paramètre
    let formData = Object.assign({}, this.state.formData);

    // Remove the users include in the group
    if (field === 'groups') {
      let users = formData[field][index].users.map(user => user.id)
      formData['users'] = formData['users'].filter(user => !users.includes(user.id))
    }

    formData[field].splice(index, 1);

    this.setState({
      formData: formData,
      isModified: true
    });
  };

  /* Supprime défitivement via l'API */
  deleteItem = async (id, field) => {
    let res = await this.props.deleteTagAction(id)

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      return
    }

    let index = this.state.formData[field].findIndex(i => i.id === id);
    if (index >= 0) {
      this.removeItem(null, index, field);
    }
  };

  createItem = async (label, field) => {
    this.setState({
      itemCreated: true,
      isModified: true
    });

    let res = await this.props.createTagAction({ label: label });

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      this.setState({ tagsIsOpen: false })
      return
    }

    // Get formdata 
    let formData = Object.assign({}, this.state.formData);

    // Set the tag
    formData[field][0] = res;

    this.setState({
      itemCreated: false,
      formData: formData,
      tagsIsOpen: false
    });
  };

  openCalendar = (e) => {
    // Get formdata 
    let formData = Object.assign({}, this.state.formData);

    // If the user didn't select a date before, init the properties
    if (!this.state.isScheduled) {
      formData.scheduled_at = moment();
      formData.hours = formData.scheduled_at.hours() < 13 ? formData.scheduled_at.hours() : formData.scheduled_at.hours() - 12;
      formData.minutes = formData.scheduled_at.minutes();
      formData.meridiem = formData.scheduled_at.hours() < 13 ? 'AM' : 'PM';
    }

    let rect = e.currentTarget.getBoundingClientRect();

    this.setState({
      calendarAnchorLeft: rect.x + rect.width / 2,
      calendarAnchorTop: rect.y - 20,
      calendarIsOpen: true,
      formData: formData
    });
  };

  closeCalendar(e) {
    if (e.target.nodeName.match(/li/i)) { // Emppêche la fermeture lors d'un click sur les Select
      return;
    }

    // Get formdata 
    let formData = Object.assign({}, this.state.formData);

    // If the user didn't select a date reset the schedule property
    if (!this.state.isScheduled) {
      formData.scheduled_at = null;
      formData.hours = null;
      formData.minutes = null;
      formData.meridiem = null;
    }

    this.setState({
      calendarIsOpen: false,
      formData: formData
    });
  }

  setSchedule() {
    // If the moment object is set
    if (this.state.formData.scheduled_at) {
      this.state.formData.scheduled_at
        .hours(this.state.formData.hours)
        .minutes(this.state.formData.minutes);
    }
    if (this.state.formData.scheduled_at && this.state.formData.meridiem === 'PM') {
      this.state.formData.scheduled_at.add({ hours: 12 });
    }

    if (this.state.formData.scheduled_at && this.state.formData.scheduled_at < new Date()) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).home.window_post_create.popin_message.time_passed, style: "error" })
    } else {
      this.setState({
        calendarIsOpen: false,
        isScheduled: true
      });
    }

    this.setState({
      isModified: true
    })
  }

  unsetSchedule(e) {
    e.stopPropagation(); // Empêche le clic sur le bouton de programmation

    // Retrieve the formData
    let formData = this.state.formData;

    // Reset the properties
    formData.scheduled_at = null;
    formData.hours = null;
    formData.minutes = null;
    formData.meridiem = null;

    this.setState({
      isScheduled: false,
      isModified: true,
      formData: formData
    });
  }

  toggleNotifications(type) {
    // Retrieve the formData
    let formData = this.state.formData;

    // Invert the value
    formData[type] = !formData[type]

    // Update notify_users
    formData.notify_users = formData.email_notif || formData.sms_notif

    // Save in the state
    this.setState({
      formData: formData,
      isModified: true
    })
  }

  handleCloseConfirm(e = null) {

    // If the popin open is for deleting a tweet
    if (this.state.confirmIsOpen === 'delete') this.setState({ confirmIsOpen: false });

    // If the popin open is for saving the modifications of a tweet
    else if (this.state.confirmIsOpen === 'edit') {
      // Close the popin
      this.setState({
        confirmIsOpen: false
      }, () => {
        // Closing the window containing the tweetit form
        if (e === null) this.props.handleClose()
      });
    }
  }

  handleOpenConfirm(e) {
    this.setState({ confirmIsOpen: e.currentTarget.getAttribute('popin-type') });
  }

  handleValidConfirm() {
    // If the popin open is for deleting a tweet
    if (this.state.confirmIsOpen === 'delete') this.deleteTweetit();

    // If the popin open is for saving the modifications of a tweet
    else if (this.state.confirmIsOpen === 'edit') {
      this.setState({
        confirmIsOpen: false
      }, () => {
        // Submit the tweetit If the content is valid
        if (this.state.urlIsValid || (this.state.messageIsValid || this.state.formData.media.length !== 0) || this.state.profileIsValid) {
          this.submitTweetit((this.state.formData.status === params.statuses.DRAFT) || !this.state.formData.status ? params.statuses.DRAFT : params.statuses.SUGGESTED)

          // If there is an error display the message corresponding to the content type
        } else {
          let message = 'Contenu du post invalide';

          if (this.state.value === params.types.REPOST) message = 'Url du retweet invalide'

          else if (this.state.value === params.types.PROFILE) message = 'Profil à suivre invalide'

          // Display the snackbar in the bottom of the screen
          EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: message, style: "error" })
        }
      })
    }
  }

  backToDashboard() {
    // If the data has been modfied
    if (this.state.isModified) {

      /* 
      Open the popin to ask :
          - If the user wants to go back to the dashboard without saving the modifications 
      OR 
           - Save the modifications
      */
      this.setState({
        confirmIsOpen: 'edit',
        isTweetContainGif: false
      })
    } else {
      this.setState({
        isTweetContainGif: false
      })
      this.props.handleClose()
    }
  }

  navFacebookPost(direction) {
    return (e) => {
      let formData = this.state.formData
      let index = Object.assign({}, formData.share_ref).index_selected

      // Increase or decrease index
      if (direction === 'up') {
        index = formData.share_ref.index_selected - 1 >= 0 ? formData.share_ref.index_selected - 1 : 0
      } else {
        index = formData.share_ref.index_selected + 1 < formData.share_ref.posts.length ? formData.share_ref.index_selected + 1 : formData.share_ref.posts.length - 1
      }

      if (index === formData.share_ref.index_selected) return

      formData.share_ref.index_selected = index

      this.setState({ formData }, () => {
        this.moveFacebookPosts(index)
      })
    }
  }

  handleDateChange(moment) {
    let formData = Object.assign({}, this.state.formData);
    formData.scheduled_at = moment;

    this.setState({
      formData: formData,
      isModified: true
    })
  }

  handleTimeChange(e) {
    let formData = Object.assign({}, this.state.formData);
    formData[e.target.name] = e.target.value;

    this.setState({
      formData: formData,
      isModified: true
    })
  }

  /**
   * IF AUTHENTICATED
   *      Set button to green (allow publishing for linkedin or twitter)
   */
  togglePublishFor(social) {
    // Do not edit the network If the post is already create
    if (this.state.formData.id) return;

    return async (event) => {

      const stateKey = social === "twitter" ? "willPublishForTwitter" : social === "linkedin" ? "willPublishForLinkedin" : social === "instagram" ? "willPublishForInstagram" : social === "facebook" ? "willPublishForFacebook" : null

      // If Insta or Facebook reset properties
      if (['instagram', 'facebook'].includes(social)) {
        let willpublish = {
          willPublishForTwitter: false,
          willPublishForLinkedin: false,
          willPublishForFacebook: false,
          willPublishForInstagram: false,
          value: params.types.REPOST
        }

        willpublish[stateKey] = !this.state[stateKey]

        await this.resetProperties()

        let formData = this.state.formData

        formData.share_ref = { type: 'share' }

        this.setState({
          ...willpublish,
          formData
        })

        return
      }

      // Reset height
      try {
        document.querySelector('.tweetit-form__preview-panel-scroll').style.height = '100%'
      } catch (e) {
        console.error(e)
      }

      // setState according to social
      if (stateKey && typeof this.state[stateKey] !== "undefined") {
        let willpublish = {
          willPublishForTwitter: false,
          willPublishForLinkedin: false,
          willPublishForFacebook: false,
          willPublishForInstagram: false,
        }

        willpublish[stateKey] = !this.state[stateKey]

        await this.setState({
          ...willpublish,
          formData: {
            ...this.state.formData,
            share_url: null,
            share_ref: null
          },
          isModified: true,
          [this.CONTENT_PUT_LINK_FORWARD]: false
        })

        // Display the avatar and screen name depending the choosen network
        let formdata = this.state.formData,
          users_id = this.props.users.filter(u => u.status !== params.userStatuses.DISABLED)

        if (this.state.willPublishForTwitter && !this.state.willPublishForLinkedin) {
          formdata.users = formdata.users.filter(u => !!u.user_twitter)
          users_id = users_id.filter(u => !!u.user_twitter).map(u => u.id)

          formdata.groups = formdata.groups.filter(g => {
            for (let u of g.users) {
              if (users_id.includes(u.id)) return true
            }
            return false
          })

        } else if (!this.state.willPublishForTwitter && this.state.willPublishForLinkedin) {
          formdata.users = formdata.users.filter(u => !!u.user_linkedin)

          users_id = users_id.filter(u => !!u.user_linkedin).map(u => u.id)

          formdata.groups = formdata.groups.filter(g => {
            for (let u of g.users) {
              if (users_id.includes(u.id)) return true
            }
            return false
          })

        }

        await this.setState({ formData: formdata })

        // Only Linkedin
        if (this.state.willPublishForLinkedin && !this.state.willPublishForTwitter) {
          await this.setState({
            [this.CONTENT_PUT_LINK_FORWARD]: true
          })

          if (this.state.value !== params.types.POST)
            this.selectContentType(null, params.types.POST)
        }

        // Reset video state properties if needed 
        if (this.state.vidSrc || this.state.videoError || this.state.videoIsLoading) {
          await this.setState({
            formData: {
              ...this.state.formData,
              media: [],
            },
            vidSrc: null,
            videoError: null,
            videoIsLoading: false
          })
        }

        // If all the networks are select, modify content type to POST
        if (((this.state.value !== params.types.POST) && this.state.willPublishForLinkedin && this.state.willPublishForTwitter)) {
          this.selectContentType(null, params.types.POST)
        }

        // Update the preview
        if (this.state.value === params.types.POST) {
          this.validateContent(this.state.formData.message, this.CONTENT_MESSAGE, this.state.formData.type)
        }

      }
    }
  }

  onSocialAuthSucceed = social => async response => {
    try {
      // Twitter
      if (social === "twitter" && response.ok) {
        const token = response.headers.get('x-auth-token');

        response.json().then(user => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur.`)

          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { expires: 90, secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { expires: 90, secure: true });
          Cookies.set('twitter_key', user['twitter_key'], { expires: 90, secure: true });
          this.setState({ authenticatedOnTwitter: true, shouldOpenPopinNeedToAuthTwitter: null, willPublishForTwitter: true }, () => {
            // Close the popin
            this.manageTooltipNetwork(null, { network: social })
          })
        })
      } else if (social === "twitter" && !response.ok) {
        response.json().then((err) => {
          this.onSocialAuthFailed()(err.error)
        })
        return
      }

      // Linkedin
      if (social === "linkedin") {
        let res = await this.props.getLinkedinAccessToken({
          code: response.code,
          redirect_uri: `${window.location.origin}/linkedin`,
        })

        if (!res.ok) {
          res.json().then((err) => {
            this.onSocialAuthFailed()(err.error)
          })
          return;
        }

        res.json().then(user => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur`)

          const token = res.headers.get('x-auth-token');
          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { expires: 90, secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { expires: 90, secure: true });

            Cookies.set(
              'linkedin', 
              user['linkedin_key'], 
              { 
                expires: Math.ceil((user['linkedin_token_expires_in'] - new Date().getTime()) / (1000 * 3600 * 24)), 
                secure: true 
              }
            )
          this.setState({ authenticatedOnLinkedin: true, shouldOpenPopinNeedToAuthLinkedin: null, willPublishForLinkedin: true }, () => {
            // Close the popin
            this.manageTooltipNetwork(null, { network: social })
          })
        })
      }

    } catch (e) {
      this.onSocialAuthFailed(social)(e.message)
    }
  }

  onSocialAuthFailed = social => response => {
    if (response && response.message === 'Popup has been closed by user') return;

    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, {
      message: response && typeof (response) === 'string' ? response : `Une erreur s'est produite.`,
      style: "error"
    })
  }

  isPostNotValid(options) {
    let isPostNotValid = (
      this.state.submitLoading || this.state.saveLoading || this.state.imageIsLoading || this.state.videoIsLoading
      || (!this.state.messageIsValid && this.state.formData.media.length === 0 && this.state.value === params.types.POST)
      || (!this.state.urlIsValid && this.state.value === params.types.REPOST)
      || (!this.state.profileIsValid && this.state.value === params.types.PROFILE)
      || (!this.state.willPublishForTwitter && !this.state.willPublishForLinkedin && !this.state.willPublishForFacebook && !this.state.willPublishForInstagram)
      || ((this.state.willPublishForTwitter || this.state.willPublishForLinkedin) && (this.state.value !== params.types.REPOST) && (this.state.willPublishForTwitter ? (options.length.twitter > 280) : (options.length.linkedin > 3000)))
    )

    if (!options || !options.exclude || (options && options.exclude && !options.exclude.includes('audience'))) {
      isPostNotValid = isPostNotValid || this.state.formData.users.length + this.state.formData.groups.length === 0
    }

    return isPostNotValid
  }

  // Hide or show the toolitp network
  manageTooltipNetwork(e, params) {
    try {
      let element = null,
        ref = null,
        network = (e && e.currentTarget.getAttribute('data-network')) || params.network

      ref = network === 'twitter' ? this.tooltipTwitterRef : this.tooltipLinkedinRef

      // Retrieve the tooltip
      element = ref.current.querySelector('.tweetit-form__customTooltip-tooltip')

      // If the tooltip is displayed
      if (element.classList.value.includes('show')) {
        element.classList.remove('show')

        // Do no interact with the popin If the post is not create, and the user is already authentified with the network
      } else if (!(!this.state.formData.id && (((network === 'linkedin') && this.state.authenticatedOnLinkedin) || ((network === 'twitter') && this.state.authenticatedOnTwitter)))) {
        element.classList.add('show')
      }
    } catch (err) {
      console.error(err)
      return;
    }
  }

  displayUsersGroupsAssociated() {
    return (
      <div className="tweetit-form__select-value">
        {
          [
            ...this.state.formData.groups.map((group, index) =>
              <Chip key={index} label={group.label}
                className={classNames(this.props.classes.selectChip, this.props.classes.groupChip)}
                onDelete={e => this.removeItem(e, index, 'groups')}
                deleteIcon={<CloseIcon />}
              />
            ),
            // if state.users belongs to some groups hide user
            ...this.state.formData.users.filter(usersItem => {

              // user belongs to some group
              let someGroupIncludeUser = this.state.formData.groups.some((groupsItem, i) => {

                // match usersItem in groups.user
                let matchUserId = groupsItem.users.find(groupsItemUsersItem => groupsItemUsersItem.id === usersItem.id)

                return matchUserId
              })
              return !someGroupIncludeUser

            }).map((user, index) => {

              const {
                screenNameThatShouldBeDisplayed,
                profileImageThatShouldBeDisplayed
              } = GlobalUtils.getUserNameAndAvatar({
                user: user
              })

              return (
                <Avatar alt={screenNameThatShouldBeDisplayed} key={`u-${index}`}
                  className={this.props.classes.avatar}
                  src={profileImageThatShouldBeDisplayed}
                  onError={(e) => { e.target.src = defaultUserIcon }}
                />
              )
            })
          ]
        }
      </div>
    )
  }

  // Retrieve users, groups selected. And retrieve all users from selected groups
  getData(options) {
    let groups = null, users = null;
    users = this.props.users
      .filter(u => u.status !== params.userStatuses.DISABLED)
      .filter(u => u.user_twitter || u.user_linkedin)
      .filter(u =>
        (options.currentNetwork === 'linkedin' && u.user_linkedin) ||
        (options.currentNetwork === 'twitter' && u.user_twitter) ||
        (['instagram', 'facebook'].includes(options.currentNetwork) && (u.user_twitter || u.user_linkedin)) ||
        !options.currentNetwork
      )
      .map(u => {
        const {
          screenNameThatShouldBeDisplayed,
          profileImageThatShouldBeDisplayed
        } = GlobalUtils.getUserNameAndAvatar({
          user: u
        })

        return Object.assign({
          label: screenNameThatShouldBeDisplayed,
          icon: profileImageThatShouldBeDisplayed,
          selected: !!this.state.formData.users.find(f => f.id === u.id)
        }, u)
      })
    if (options.data === 'users_selected') return users

    let users_id = users.map(u => u.id)

    groups = this.props.groups.filter(g => {
      for (let u of g.users) {
        if (users_id.includes(u)) return true
      }
      return false
    })

    groups = groups
      .filter(g => g.users.length)
      .map(g => Object.assign({
        selected: !!this.state.formData.groups.find(f => f.id === g.id)
      }, g))

    if (options.data === 'groups_selected') return groups
  }

  toggleEmojis() {
    this.setState({
      emojisIsOpen: !this.state.emojisIsOpen
    })
  }

  handleCloseEmojis() {
    this.setState({
      emojisIsOpen: false
    })
  }

  render() {
    const length = GlobalUtils.getNetworkMessagelength(this.state.formData.message, { twitter: this.state.willPublishForTwitter, linkedin: this.state.willPublishForLinkedin })

    const { classes } = this.props;
    const { value } = this.state;
    const currentNetwork = (this.state.willPublishForLinkedin && this.state.willPublishForTwitter) ? null :
      this.state.willPublishForTwitter ? "twitter" : this.state.willPublishForLinkedin ? "linkedin" :
        this.state.willPublishForInstagram ? "instagram" : this.state.willPublishForFacebook ? "facebook" : null

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).home.window_post_create

            return (
              <div className="tweetit-form__no-css-rule">
                <ConfirmDialog title={"Confirmation"}
                  message={t.popin_delete.message.join('\n')}
                  handleClose={this.handleCloseConfirm}
                  handleValid={this.handleValidConfirm}
                  label={{ buttonClose: t.popin_delete.btn_cancel, buttonValid: t.popin_delete.btn_delete }}
                  open={this.state.confirmIsOpen === 'delete'}
                />
                <ConfirmDialog title={"Confirmation"}
                  message={"Vous allez retourner au dashboard.\nSouhaitez-vous sauvegarder vos modifications ?"}
                  handleClose={this.handleCloseConfirm}
                  handleValid={this.handleValidConfirm}
                  label={{ buttonClose: 'Ne pas sauvegarder', buttonValid: 'Sauvegarder', styleValid: 'primary' }}
                  open={this.state.confirmIsOpen === 'edit'}
                />

                {/*
                ********************************************************************
                *************************** CALENDAR *******************************
                ********************************************************************
                */}
                <Popover
                  open={this.state.calendarIsOpen}
                  anchorEl={this.state.calendarAnchor}
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: this.state.calendarAnchorTop, left: this.state.calendarAnchorLeft }}
                  onClose={this.handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <ClickAwayListener onClickAway={this.closeCalendar}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <BasePicker value={this.state.formData.scheduled_at} onChange={() => {/* Jamais invoqué */ }}>
                        {({ date }) =>
                          <Paper className={this.props.classes.picker}>
                            <Calendar
                              date={date}
                              onChange={this.handleDateChange}
                              disablePast={true}
                            />
                            <Grid container justify={"space-evenly"}>
                              <Select
                                name={"hours"}
                                value={this.state.formData.hours}
                                onChange={this.handleTimeChange}
                                input={<OutlinedInput labelWidth={0} />}
                                IconComponent={ArrowDropDownRoundedIcon}
                              >
                                {[...Array(13).keys()].map(hour =>
                                  <MenuItem key={hour}
                                    value={hour}>
                                    {hour.toLocaleString('fr-FR', { minimumIntegerDigits: 2 })}
                                  </MenuItem>
                                )}
                              </Select>
                              <Select
                                name={"minutes"}
                                value={this.state.formData.minutes}
                                onChange={this.handleTimeChange}
                                input={<OutlinedInput labelWidth={0} />}
                                IconComponent={ArrowDropDownRoundedIcon}
                              >
                                {[...Array(60).keys()].map(minute =>
                                  <MenuItem key={minute}
                                    value={minute}>
                                    {minute.toLocaleString('fr-FR', { minimumIntegerDigits: 2 })}
                                  </MenuItem>
                                )}
                              </Select>
                              <Select
                                name={"meridiem"}
                                value={this.state.formData.meridiem}
                                onChange={this.handleTimeChange}
                                input={<OutlinedInput labelWidth={0} />}
                                IconComponent={ArrowDropDownRoundedIcon}
                              >
                                <MenuItem value={"AM"}> AM </MenuItem>
                                <MenuItem value={"PM"}> PM </MenuItem>
                              </Select>
                            </Grid>
                            <Button variant="contained" color="primary" className={classes.pickerSubmit}
                              onClick={this.setSchedule}>
                              {t.btn_date}
                            </Button>
                          </Paper>
                        }
                      </BasePicker>
                    </MuiPickersUtilsProvider>
                  </ClickAwayListener>
                </Popover>
                <Dialog
                  fullScreen
                  classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
                  open={this.props.open}
                  TransitionComponent={Transition}
                  TransitionProps={{
                    appear: true,
                    enter: false,
                    exit: true,
                    classNames: {
                      appear: classes.dialogAppear,
                      appearActive: classes.dialogAppearActive,
                      exit: classes.dialogExit,
                      exitActive: classes.dialogExitActive
                    }
                  }}
                  transitionDuration={{ enter: 300, exit: 200, }}
                  onClose={this.props.handleClose}
                  aria-labelledby="form-dialog-title"
                >
                  <div className="tweetit-form__content">
                    <div className="tweetit-form__root">
                      <div className="tweetit-form__form-panel background-color-secondary">

                        {/*
                        ********************************************************************
                        ********************* BUTON BACK TO DASHBOARD *********************
                        ********************************************************************
                        */}
                        <div className="tweetit-form__dialog-top">
                          <IconButton className={classes.dialogClose} onClick={this.backToDashboard}>
                            <ArrowLeftIcon />
                          </IconButton>
                          {t.back_to}
                        </div>
                        <div className="tweetit-form__column">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <FormLabel>
                              {t.publish_on} :
                            </FormLabel>

                            {/*
                            ********************************************************************
                            ********************* BUTON NETWORKS *******************************
                            ********************************************************************
                            */}
                            <div className="tweetit-form__column-inline">

                              {/* BUTTON TWITTER */}
                              {process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true' ?
                                <div className="tweetit-form__customTooltip" ref={this.tooltipTwitterRef} data-network="twitter" onMouseEnter={this.manageTooltipNetwork} onMouseLeave={this.manageTooltipNetwork}>
                                  <button
                                    onClick={this.state.authenticatedOnTwitter ? this.togglePublishFor('twitter') : undefined}
                                    className={`tweetit-form__customTooltip-opener tweetit-form__column-btnChoice tweetit-form__column-btnChoice__twitter ${this.state.willPublishForTwitter ? " checked" : ""}
                                                                ${this.state.authenticatedOnTwitter || this.state.willPublishForTwitter ? "" : " disabled"}`}
                                  >
                                    <TwitterIcon />
                                    <ReactSVG className="icon__ok" src={ok} />
                                  </button>
                                  <div className="tweetit-form__customTooltip-tooltip">
                                    <div className={this.props.classes.tooltipContainer}>
                                      <img alt="arrow" src={arrow} />
                                    </div>

                                    {!this.state.formData.id && !this.state.authenticatedOnTwitter ?
                                      <>
                                        <p>{t.tooltip_network.text_not_connect.replace('[[network]]', 'Twitter')}</p>
                                        <TwitterLogin loginUrl={params.DOMAIN + "/auth/twitter"}
                                          onFailure={this.onSocialAuthFailed("twitter")}
                                          onSuccess={this.onSocialAuthSucceed('twitter')} // twitterAuthSucceed = key in this.state
                                          showIcon={true}
                                          requestTokenUrl={params.DOMAIN + `/auth/twitter/reverse?callback=${window.location.origin}`}>
                                          <TwitterIcon />
                                          <Typography>
                                            {t.tooltip_network.button.replace('[[network]]', 'Twitter')}
                                          </Typography>
                                        </TwitterLogin>
                                      </>
                                      : 'Ce post est déjà crée, vous ne pouvez modifier le réseau sélectionné.'}
                                  </div>
                                </div>
                                : ''}

                              {/* BUTTON LINKEDIN */}
                              {process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true' ?
                                <div className="tweetit-form__customTooltip" ref={this.tooltipLinkedinRef} data-network="linkedin" onMouseEnter={this.manageTooltipNetwork} onMouseLeave={this.manageTooltipNetwork}>
                                  <button
                                    onClick={this.state.authenticatedOnLinkedin ? this.togglePublishFor('linkedin') : undefined}
                                    className={`tweetit-form__customTooltip-opener tweetit-form__column-btnChoice tweetit-form__column-btnChoice__linkedin ${this.state.willPublishForLinkedin ? " checked" : ""}
                                                                ${this.state.authenticatedOnLinkedin || this.state.willPublishForLinkedin ? "" : " disabled"}`}
                                  >
                                    <LinkedinIcon />
                                    <ReactSVG className="icon__ok" src={ok} />
                                  </button>

                                  <div className="tweetit-form__customTooltip-tooltip">
                                    <div className={this.props.classes.tooltipContainer}>
                                      <img alt="arrow" src={arrow} />
                                    </div>
                                    {!this.state.formData.id && !this.state.authenticatedOnLinkedin ?
                                      <>
                                        <p>{t.tooltip_network.text_not_connect.replace('[[network]]', 'Linkedin')}</p>
                                        <LinkedinLogin
                                          onSuccess={this.onSocialAuthSucceed("linkedin")}
                                          onFailure={this.onSocialAuthFailed("linkedin")}
                                          redirectUri={`${window.location.origin}/linkedin`}
                                          clientId={process.env.REACT_APP_LINKEDIN_ID}
                                          scope="r_emailaddress r_liteprofile w_member_social r_basicprofile rw_organization_admin r_organization_social"
                                          state={uuid()}
                                        >
                                          <LinkedinIcon />
                                          <Typography>
                                            {t.tooltip_network.button.replace('[[network]]', 'Linkedin')}
                                          </Typography>
                                        </LinkedinLogin>
                                      </>
                                      : t.tooltip_network.text_already_create}
                                  </div>
                                </div>
                                : ''}

                              {/* BUTTON INSTAGRAM */}
                              {process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true' ?
                                <button
                                  onClick={this.togglePublishFor('instagram')}
                                  className={`tweetit-form__customTooltip-opener tweetit-form__column-btnChoice margin  ${this.state.willPublishForInstagram ? " checked" : ""}`}
                                >
                                  <IconInstaBg />
                                  <ReactSVG className="icon__ok" src={ok} />
                                </button>
                                : ''}

                              {/* BUTTON FACEBOOK */}
                              {/* { process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true' ?
                                <button 
                                    onClick={this.togglePublishFor('facebook')}
                                    className={`tweetit-form__customTooltip-opener tweetit-form__column-btnChoice  ${this.state.willPublishForFacebook ? " checked" : ""}`}
                                >
                                    <IconFacebookBg />
                                    <ReactSVG className="icon__ok" src={ok}/>
                                </button>
                                : ''}
                              */}
                            </div>
                          </FormControl>
                        </div>

                        {/*
                        ********************************************************************
                        ************************** FORM START ******************************
                        ********************************************************************
                        */}
                        <div className="tweetit-form__column">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <FormLabel htmlFor="users">
                              {t.post_to}
                            </FormLabel>
                            <SelectDialog
                              positionTop={this.state.selectAnchorTop}
                              positionLeft={this.state.selectAnchorLeft}
                              width={this.state.usersWidth}
                              isMultiple={true}
                              items={[this.getData({ data: 'groups_selected', currentNetwork: currentNetwork }), this.getData({ data: 'users_selected', currentNetwork: currentNetwork })]}
                              field={['groups', 'users']}
                              labels={[`${t.groups}`, `${t.members}`]}
                              itemClass={[classes.groupChip, null]}
                              setItems={this.setItems}
                              open={this.state.usersIsOpen}
                              closeSelectDialog={this.closeSelectDialog}
                              params={{ parent: "tweetit-form-audience" }}
                            />
                            <Select
                              classes={{ select: classes.select }}
                              multiple
                              value={[0, 1]} // Pour passer dans renderValue mais sans réévaluer à chaque fois
                              input={
                                <OutlinedInput
                                  labelWidth={0}
                                  name="users"
                                  id="users"
                                  startAdornment={
                                    <InputAdornment position="start" className={classes.selectAdornment}>
                                      <ReactSVG src={searchIcon} />
                                    </InputAdornment>
                                  }
                                  classes={{
                                    notchedOutline: classes.selectInput,
                                    adornedStart: classes.selectRoot,
                                    focused: classes.selectInputFocused,
                                    root: classes.selectInputRoot
                                  }}
                                />
                              }
                              IconComponent={
                                ArrowDropDownRoundedIcon
                              }
                              renderValue={() => this.displayUsersGroupsAssociated(currentNetwork)}

                              open={false}
                              onOpen={() => { }}
                              onClose={() => { }}
                              onClick={e => this.openSelectDialog(e, 'users')}
                            />
                          </FormControl>

                        </div>
                        <div className="tweetit-form__column">
                          <FormControl variant="outlined" className={classes.formControl}>
                            <FormLabel>
                              {t.thematic} :
                            </FormLabel>
                            <SelectDialog
                              positionTop={this.state.selectAnchorTop}
                              positionLeft={this.state.selectAnchorLeft}
                              width={this.state.usersWidth}
                              isMultiple={false}
                              allowAdd={true}
                              allowDelete={true}
                              items={this.props.tags.map(t => Object.assign({
                                selected: !!this.state.formData.tag.find(f => f.id === t.id)
                              }, t))}
                              field={'tag'}
                              itemClass={classes.tagChip}
                              setItems={this.setItems}
                              open={this.state.tagsIsOpen}
                              closeSelectDialog={this.closeSelectDialog}
                              handleDelete={this.deleteItem}
                              handleAdd={this.createItem}
                              deleteIcon={<TrashIcon />}
                            />
                            <Select
                              multiple
                              value={[0]} // Pour passer dans renderValue mais sans réévaluer à chaque fois
                              input={
                                <OutlinedInput
                                  labelWidth={0}
                                  name="tag"
                                  id="tag"
                                  startAdornment={
                                    <InputAdornment position="start" className={classes.selectAdornment}>
                                      <ReactSVG src={searchIcon} />
                                    </InputAdornment>
                                  }
                                  classes={{
                                    notchedOutline: classes.selectInput,
                                    adornedStart: classes.selectRoot,
                                    root: classes.selectInputRoot,
                                    focused: classes.selectInputFocused,
                                  }}
                                />
                              }
                              IconComponent={
                                ArrowDropDownRoundedIcon
                              }
                              renderValue={() => (
                                <div className="tweetit-form__select-value">
                                  {
                                    this.state.formData.tag.map((item, index) => (
                                      <Chip key={index} label={item.label}
                                        className={classNames(classes.selectChip, classes.tagChip)}
                                        onDelete={e => this.removeItem(e, index, 'tag')}
                                        deleteIcon={<CloseIcon />}
                                      />
                                    ))
                                  }
                                </div>
                              )}

                              open={false}
                              onOpen={() => { }}
                              onClose={() => { }}
                              onClick={e => this.openSelectDialog(e, 'tag')}
                            >
                            </Select>
                          </FormControl>
                        </div>

                        <div className="tweetit-form__column">
                          <FormControl variant="outlined" className={classes.formControl}>

                            {/*
                            ********************************************************************
                            ********************* CONTENT TYPE LABEL ***************************
                            ********************************************************************
                            */}
                            <FormLabel>
                              {t.content} :
                            </FormLabel>
                            <div className="tweetit-form__no-css-rule">

                              {/* LABEL TWITTER & LINKEDIN */}
                              {!this.state.willPublishForFacebook && !this.state.willPublishForInstagram ?
                                <>
                                  <Chip
                                    label={t.new_post}
                                    clickable
                                    className={value === params.types.POST ? classes.chip : classNames(classes.chip, classes.inactiveChip)}
                                    color="primary"
                                    onClick={e => this.selectContentType(e, params.types.POST)}
                                  />
                                  {!this.state.willPublishForLinkedin ?
                                    <>
                                      <Chip
                                        label={t.retweet}
                                        clickable
                                        className={value === params.types.REPOST ? classes.chip : classNames(classes.chip, classes.inactiveChip)}
                                        color="primary"
                                        onClick={e => this.selectContentType(e, params.types.REPOST)}
                                      />
                                      <Chip
                                        label={t.follow}
                                        clickable
                                        className={value === params.types.PROFILE ? classes.chip : classNames(classes.chip, classes.inactiveChip)}
                                        color="primary"
                                        onClick={e => this.selectContentType(e, params.types.PROFILE)}
                                      />
                                    </>
                                    : ''}
                                </>
                                : ''}

                              {/* LABEL Instagram & Facebook */}
                              {this.state.willPublishForFacebook || this.state.willPublishForInstagram ?
                                <>
                                  <Chip
                                    label={t.share}
                                    clickable
                                    className={this.state.formData.share_ref && this.state.formData.share_ref.type === 'share' ? classes.chip : classNames(classes.chip, classes.inactiveChip)}
                                    color="primary"
                                    onClick={e => this.selectContentType(e, params.types.REPOST, { type: 'share' })}
                                  />
                                  <Chip
                                    label={t.like}
                                    clickable
                                    className={this.state.formData.share_ref && this.state.formData.share_ref.type === 'like' ? classes.chip : classNames(classes.chip, classes.inactiveChip)}
                                    color="primary"
                                    onClick={e => this.selectContentType(e, params.types.REPOST, { type: 'like' })}
                                  />
                                  <Chip
                                    label={t.post_comment}
                                    clickable
                                    className={this.state.formData.share_ref && this.state.formData.share_ref.type === 'comment' ? classes.chip : classNames(classes.chip, classes.inactiveChip)}
                                    color="primary"
                                    onClick={e => this.selectContentType(e, params.types.REPOST, { type: 'comment' })}
                                  />
                                </>
                                : ''}
                            </div>

                            {/*
                            ********************************************************************
                            ******************************* POST *******************************
                            ********************************************************************
                            */}
                            {value === params.types.POST && (
                              <div className="tweetit-form__tweet-container">
                                <TextEditor
                                  value={this.state.formData.message}
                                  onChange={this.handleChange(this.CONTENT_MESSAGE)}
                                  isNewPost={this.state.formData.id}
                                  placeholder={t.placeholder_content}
                                  warning={t.linkedin_warning}
                                  limit={`${currentNetwork === 'twitter' ? 280 : currentNetwork === 'linkedin' ? 3000 : null}`}
                                  willPublishForTwitter={this.state.willPublishForTwitter}
                                  willPublishForLinkedin={this.state.willPublishForLinkedin}
                                  willPublishForInstagram={this.state.willPublishForInstagram}
                                  willPublishForFacebook={this.state.willPublishForFacebook}
                                  handleCloseEmojis={this.handleCloseEmojis}
                                  emojisIsOpen={this.state.emojisIsOpen}
                                  searchAccountAction={this.props.searchAccountAction}
                                />
                                <div className="tweetit-form__textarea-post-content__footer">

                                  {/** Show twitter character counter when twitter is selected */}
                                  {this.state.willPublishForTwitter && <span className="tweetit-form__textarea-post-content__footer-col tweetit-form__textarea-post-content__footer-col__twitter">
                                    <TwitterIcon />
                                    <span style={{ color: length.twitter <= 280 ? "#1DA1F2" : "#f2615e" }} className="tweetit-form__textarea-post-content__footer-count">{280 - length.twitter}</span>
                                  </span>}

                                  {/** Show linkedin character counter linkedin is selected */}
                                  {this.state.willPublishForLinkedin && <span className="tweetit-form__textarea-post-content__footer-col tweetit-form__textarea-post-content__footer-col__linkedin">
                                    <LinkedinIcon />
                                    <span style={{ color: length.linkedin <= 3000 ? "#1171A1" : "#f2615e" }} className="tweetit-form__textarea-post-content__footer-count">{3000 - length.linkedin}</span>
                                  </span>}

                                  {/** Show checkbox(put link forward) when only linkedin is selected */}
                                  {(this.state.willPublishForLinkedin && !this.state.willPublishForTwitter) && <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="primary"
                                        classes={{ root: classes.shareUrlCheckbox }}
                                        onChange={this.handleChange(this.CONTENT_PUT_LINK_FORWARD)}
                                        checked={this.state[this.CONTENT_PUT_LINK_FORWARD]}
                                      />
                                    }
                                    label={"Mettre en avant le lien"}
                                    classes={{ label: classes.shareUrlLabel }}
                                  />}
                                  <button onClick={this.toggleEmojis} className="tweetit-form__textarea-post-content__footer-emojis">
                                    <IconEmoji />
                                  </button>
                                </div>
                              </div>
                            )}


                            {/*
                            ********************************************************************
                            ******************************* REPOST *****************************
                            ********************************************************************
                            */}
                            {(value === params.types.REPOST) || (this.state.willPublishForInstagram || this.state.willPublishForFacebook) ? (
                              <div className="tweetit-form__tweet-container">
                                <TextField
                                  id="outlined-flexible"
                                  label=""
                                  placeholder={`${this.state.willPublishForTwitter ? t.placeholder_retweet : this.state.willPublishForInstagram ? t.placeholder_post_link : t.placeholder_post_link_fb}`}
                                  value={this.state.formData.share_url || ''}
                                  onChange={this.handleChange(this.CONTENT_URL)}
                                  className={classes.textField}
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                />
                                {this.state.urlIsValid !== undefined && (
                                  <div className="tweetit-form__tweet-check-icon">
                                    {(!this.state.urlIsLoading && this.state.urlIsValid) && (
                                      <ReactSVG src={ok} />
                                    )}
                                    {(!this.state.urlIsLoading && !this.state.urlIsValid) && (
                                      <ReactSVG src={wrong} />
                                    )}
                                    {this.state.urlIsLoading && (
                                      <CircularProgress className={classes.progress} size={20} />
                                    )}
                                  </div>
                                )}
                              </div>
                            )
                              : ''}

                            {/*
                            ********************************************************************
                            *******************************PROFILE *****************************
                            ********************************************************************
                            */}

                            {value === params.types.PROFILE && (
                              <div className="tweetit-form__tweet-container">
                                <TextField
                                  id="outlined-flexible"
                                  label=""
                                  placeholder={t.placeholder_follow}
                                  value={this.state.formData.share_url || ""}
                                  onChange={this.handleChange(this.CONTENT_PROFILE)}
                                  className={classes.textField}
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                />
                                {this.state.profileIsValid !== undefined && (
                                  <div className="tweetit-form__tweet-check-icon">
                                    {(!this.state.urlIsLoading && this.state.profileIsValid) && (
                                      <ReactSVG src={ok} />
                                    )}
                                    {(!this.state.urlIsLoading && !this.state.profileIsValid) && (
                                      <ReactSVG src={wrong} />
                                    )}
                                    {this.state.urlIsLoading && (
                                      <CircularProgress className={classes.progress} size={20} />
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </FormControl>
                        </div>

                        {/*
                        ********************************************************************
                        ********************** Upload an image or a video ******************
                        ********************************************************************
                        */}

                        {(this.state.value === params.types.POST) && !this.state.willPublishForInstagram && !this.state.willPublishForFacebook ?
                          <div className="tweetit-form__upload-container">
                            {this.state.formData.media.length > 0 ?
                              <TransitionGroup component={TransitionWrapper}>
                                {/** Show uploaded media (video or images) */}
                                {
                                  ////////////////////////////////////
                                  //////////// DISPLAY VIDEO /////////
                                  ////////////////////////////////////
                                  this.state.formData.media[0].type === params.mediaTypes.VIDEO || this.state.formData.mediaType === params.mediaTypes.VIDEO ?
                                    <CSSTransition
                                      appear={true} enter={false} exit={false} timeout={150}
                                      classNames={{ appear: classes.mediaAppear, appearActive: classes.mediaAppearActive }}
                                    >
                                      <div className="tweetit-form__upload-container-media-item">
                                        <CSSTransition
                                          appear={true} enter={false} exit={false} timeout={150}
                                          classNames={{ appear: classes.mediaAppear, appearActive: classes.mediaAppearActive }}
                                        >
                                          <IconButton
                                            onClick={() => this.handleFileRemove(0)}
                                            className={classes.buttonRemoveMedia + " noOver"}
                                            disableRipple>
                                            <WrongIcon />
                                          </IconButton>
                                        </CSSTransition>
                                        <video
                                          className={this.props.classes.media}
                                          src={this.state.vidSrc || this.props.getMediaUrl(this.state.formData.media[0])}
                                        />
                                      </div>
                                    </CSSTransition>
                                    :
                                    ////////////////////////////////////
                                    /////////// DISPLAY IMAGES /////////
                                    ////////////////////////////////////
                                    this.state.formData.media.map((image, index) =>
                                      <CSSTransition
                                        appear={true} enter={false} exit={false} timeout={150}
                                        classNames={{ appear: classes.mediaAppear, appearActive: classes.mediaAppearActive }}
                                      >
                                        <div className="tweetit-form__upload-container-media-item">
                                          <CSSTransition
                                            appear={true} enter={false} exit={false} timeout={150}
                                            classNames={{ appear: classes.mediaAppear, appearActive: classes.mediaAppearActive }}
                                          >
                                            <IconButton
                                              onClick={() => this.handleFileRemove(index)}
                                              className={classes.buttonRemoveMedia + " noOver"}
                                              disableRipple>
                                              <WrongIcon />
                                            </IconButton>
                                          </CSSTransition>
                                          <img alt={image.alt}
                                            className={this.props.classes.media}
                                            src={this.state.imgSrc[index] || this.props.getMediaUrl(image)}
                                          />
                                        </div>

                                      </CSSTransition>
                                    )
                                }
                                {/* 
                                  ////////////////////////////////////
                                  //////////// INPUT MEDIA ///////////
                                  ////////////////////////////////////
                              */}
                                {(this.state.formData.media[0].type === params.mediaTypes.IMAGE || this.state.formData.mediaType === params.mediaTypes.IMAGE)
                                  && this.state.formData.media.length < 4
                                  && this.state.formData.media.filter(m => m.type === 'image/gif').length < 1
                                  && !this.state.isTweetContainGif
                                  && <CSSTransition
                                    appear={true} enter={false} exit={false} timeout={150}
                                    classNames={{ appear: classes.mediaAppear, appearActive: classes.mediaAppearActive }}
                                  >
                                    <div className="tweetit-form__media-add">
                                      <input
                                        accept="image/*"
                                        className={this.props.classes.input}
                                        onChange={this.handleFileChange}
                                        id={this.MEDIA_IMAGE}
                                        name={this.MEDIA_IMAGE}
                                        type="file"
                                      />
                                      {this.state.imageIsLoading ?
                                        <CircularProgress size={26} color={"primary"} />
                                        :
                                        <label htmlFor={this.MEDIA_IMAGE}>
                                          <IconButton component="span" disableRipple>
                                            <AddIcon />
                                          </IconButton>
                                        </label>
                                      }
                                    </div>
                                  </CSSTransition>
                                }
                              </TransitionGroup>
                              : <div className="tweetit-form__media-container">
                                {
                                  this.state.imageIsLoading
                                    ? <CircularProgress size={26} color={"primary"} className={classes.mediaLoader} />
                                    : <div style={{ position: "relative" }}>
                                      <input
                                        accept="image/*"
                                        className={this.props.classes.input}
                                        onChange={this.handleFileChange}
                                        id={this.MEDIA_IMAGE}
                                        name={this.MEDIA_IMAGE}
                                        type="file"
                                      />
                                      <label htmlFor={this.MEDIA_IMAGE}>
                                        <Tooltip classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                                          title={
                                            <div className={this.props.classes.tooltipTitle}>
                                              <div className={this.props.classes.tooltipContainer}>
                                                <img alt="arrow" src={arrow} />
                                              </div>
                                              Ajouter une image ou un gif
                                            </div>
                                          }
                                        >
                                          <IconButton variant="contained" component="span"
                                            className={classes.buttonAddImage}
                                            disableRipple>
                                            <AddImageIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </label>
                                    </div>
                                } {
                                  this.state.videoIsLoading
                                    ? <CircularProgress size={26} color={"primary"} className={classes.mediaLoader} />
                                    : <div style={{ position: "relative" }}>
                                      <input
                                        accept=".mp4,.mov"
                                        className={this.props.classes.input}
                                        onClick={event => event.target.value = null}
                                        onChange={this.handleFileChange}
                                        id={this.MEDIA_VIDEO}
                                        name={this.MEDIA_VIDEO}
                                        type="file"
                                      />
                                      <label htmlFor={this.MEDIA_VIDEO}>
                                        <Tooltip classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                                          title={
                                            <div className={this.props.classes.tooltipTitle}>
                                              <div className={this.props.classes.tooltipContainer}>
                                                <img alt="arrow" src={arrow} />
                                              </div>
                                              Ajouter une vidéo (au format .mp4)
                                            </div>
                                          }
                                        >
                                          <IconButton
                                            variant="contained" component="span"
                                            className={classes.buttonAddImage}
                                            disableRipple>
                                            <AddVideoIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </label>
                                    </div>
                                }
                              </div>
                            }
                            {this.state.imageError && (
                              <Typography className={classes.errorText}>{this.state.imageError}</Typography>
                            )}
                            {this.state.videoError && (
                              <Typography className={classes.errorText}>{this.state.videoError}</Typography>
                            )}
                          </div>
                          : ''}

                        {/*
                        ********************************************************************
                        ******************** NOTIFICATION SELECTOR *************************
                        ********************************************************************
                        */}
                        <div className="tweetit-form__row tweetit-form__row--margin">
                          <FormLabel>{t.notifications} :</FormLabel>
                          <div className="tweetit-form__row__container">
                            {process.env.REACT_APP_FUNCTION_EMAIL === 'true' && (
                              <FormControlLabel
                                control={
                                  <Checkbox color={"primary"}
                                    checked={this.state.formData.email_notif}
                                    onChange={(e) => this.toggleNotifications('email_notif', e)}
                                  />
                                }
                                label={t.notify_email}
                              />
                            )}
                            {process.env.REACT_APP_FUNCTION_SMS === 'true' && (
                              <FormControlLabel
                                control={
                                  <Checkbox color={"primary"}
                                    checked={this.state.formData.sms_notif}
                                    onChange={(e) => this.toggleNotifications('sms_notif', e)}
                                  />
                                }
                                label={t.notify_sms}
                              />
                            )}
                          </div>
                        </div>
                        {/* TODO: */}
                        <div className="tweetit-form__infobox">
                          <InfoIcon color={'#1A7FC8'} />
                          <p className="tweetit-form__infobox__text">
                            {t.notification_info}
                          </p>
                        </div>

                        {/*
                        ********************************************************************
                        *********************** MANAGER COMMENT ****************************
                        ********************************************************************
                        */}
                        <div className="tweetit-form__column">
                          <FormControl className={classes.formControl}>
                            <FormLabel>{t.comment} :</FormLabel>
                            <TextField
                              id="outlined-multiline-flexible"
                              label=""
                              multiline
                              rows="7"
                              rowsMax="7"
                              value={this.state.formData.comment}
                              onChange={this.handleChange(this.CONTENT_COMMENT)}
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                            />
                          </FormControl>
                        </div>
                      </div>

                      {/*
                      ********************************************************************
                      ************************** POST PREVIEW ****************************
                      ********************************************************************
                      */}   

                      <div className={`tweetit-form__preview-panel ${this.state.willPublishForLinkedin && this.state.willPublishForTwitter ? 'is-alert' : ''} ${this.state.willPublishForFacebook && this.state.formData.share_ref && this.state.formData.share_ref.posts && this.state.formData.share_ref.posts.length ? 'preview-fb' : ''}`}>
                        {/* Number of Facebook posts loaded */}
                        {this.state.willPublishForFacebook ?
                          <div className={`tweetit-form__preview-facebook-info background-color-primary ${this.state.formData.share_ref && this.state.formData.share_ref.posts && this.state.formData.share_ref.posts.length ? 'show' : ''}`}>
                            {this.state.formData.share_ref && this.state.formData.share_ref.posts && this.state.formData.share_ref.posts.length} derniers posts de la page : {this.state.formData.share_ref && this.state.formData.share_ref.screen_name}
                          </div>
                          : ''}

                        <div className="tweetit-form__preview-panel-scroll">
                          {/* Facebook scroll preview */}
                          {this.state.willPublishForFacebook && this.state.formData.share_ref && this.state.formData.share_ref.posts && this.state.formData.share_ref.posts.length ?
                            <>
                              <div className="tweetit-form__preview-panel-container">
                                {this.state.formData.share_ref.posts.map((p, index) => {
                                  let isSelected = this.state.formData.share_ref.index_selected === index
                                  return (
                                    <div className={`tweetit-form__preview-post ${isSelected ? 'selected' : ''} border-color-primary-i`} key={index}>

                                      {/* Label suggested post and nav buttons */}
                                      {isSelected ?
                                        <>
                                          <span className="tweetit-form__preview-post-label background-color-primary">Post suggéré</span>
                                        </>
                                        :
                                        ''}

                                      <TweetCard
                                        hideAvatars={true}
                                        hideSocialIcon={true}
                                        groups={this.state.formData.groups}
                                        members={this.state.formData.users}
                                        tag={this.state.formData.tag}
                                        message={this.state.formData.message}
                                        comment={this.state.formData.comment}
                                        share_ref={p}
                                        share_url={this.state.formData.share_url}
                                        media={this.state.formData.media}
                                        mediaType={this.state.formData.mediaType}
                                        network={currentNetwork}
                                        orphans={this.props.orphans}
                                        imgSrc={this.state.imgSrc}
                                        vidSrc={this.state.vidSrc}
                                        status={params.statuses.DRAFT}
                                        type={value}
                                        scheduled_at={this.state.formData.scheduled_at ? this.state.formData.scheduled_at.format() : null}
                                        updated_at={this.state.formData.updated_at}
                                        isPreview={true}
                                        isSubmitable={false}
                                        showHeader={true}
                                        isEditable={false}
                                        isRemovable={false}
                                        isScheduled={this.state.isScheduled}
                                        getMediaUrl={this.props.getMediaUrl}
                                        enlargeMedia={this.props.enlargeMedia}
                                        getFileInfoUploadCare={this.props.getFileInfoUploadCare}
                                        isUser={false}
                                        isTweetContainGif={this.state.isTweetContainGif}
                                      />
                                    </div>
                                  )
                                })}
                                {/* Load more posts*/}
                                <button className="tweetit-form__preview-loadmore background-color-primary" onClick={this.loadMoreFacebookPosts}>
                                  Charger plus de posts  {this.state.urlIsLoading ? <CircularProgress style={{ color: 'white', marginLeft: '15px' }} size={20} /> : ''}
                                </button>
                              </div>

                              {/* Facebook nav button preview */}
                              {this.state.willPublishForFacebook && this.state.formData.share_ref && this.state.formData.share_ref.posts && this.state.formData.share_ref.posts.length ?
                                <>
                                  <div className="tweetit-form__preview-post-nav left" onClick={this.navFacebookPost('up')}>
                                    <IconArrowKeyboard />
                                  </div>
                                  <div className="tweetit-form__preview-post-nav right" onClick={this.navFacebookPost('down')}>
                                    <IconArrowKeyboard />
                                  </div>
                                </>
                                : ''}
                            </>

                            :
                            <TweetCard
                              authenticatedOnTwitter={true}
                              authenticatedOnLinkedin={true}
                              hideAvatars={true}
                              hideSocialIcon={false}
                              groups={this.state.formData.groups}
                              members={this.state.formData.users}
                              tag={this.state.formData.tag}
                              message={this.state.formData.message}
                              comment={this.state.formData.comment}
                              share_ref={this.state.formData.share_ref}
                              share_url={this.state.formData.share_url}
                              media={this.state.formData.media}
                              mediaType={this.state.formData.mediaType}
                              network={currentNetwork}
                              orphans={this.props.orphans}
                              imgSrc={this.state.imgSrc}
                              vidSrc={this.state.vidSrc}
                              status={params.statuses.DRAFT}
                              type={value}
                              scheduled_at={this.state.formData.scheduled_at ? this.state.formData.scheduled_at.format() : null}
                              updated_at={this.state.formData.updated_at}
                              isPreview={true}
                              isSubmitable={false}
                              showHeader={true}
                              isEditable={false}
                              isRemovable={false}
                              isScheduled={this.state.isScheduled}
                              getMediaUrl={this.props.getMediaUrl}
                              enlargeMedia={this.props.enlargeMedia}
                              getFileInfoUploadCare={this.props.getFileInfoUploadCare}
                              isUser={true}
                              isTweetContainGif={this.state.isTweetContainGif}
                            />
                          }
                        </div>

                        {/** Show alert if post will be published at least 2 networks */}
                        <div className={`tweetit-form__preview-panel-alert-container ${this.state.willPublishForLinkedin && this.state.willPublishForTwitter ? 'show' : ''}`}>
                          <CSSTransition
                            in={this.state.willPublishForLinkedin && this.state.willPublishForTwitter}
                            appear={true}
                            enter={true}
                            exit={true}
                            unmountOnExit
                            timeout={300}
                            classNames={"preview-alert"}
                          >
                            <div className="tweetit-form__preview-panel-alert">
                              <AlertInfoIcon />
                              <p className="tweetit-form__preview-panel-alert-text">
                                {t.multi_network.map(v => {
                                  return (<>{v} <br /> </>)
                                })}
                              </p>
                            </div>
                          </CSSTransition>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*
                  ********************************************************************
                  ****************** BUTTONS AT THE BOTTOM OF THE PAGE ***************
                  ********************************************************************
                  */}

                  <div className="tweetit-form__dialog-actions">
                    {/* Buttons delete and save at the bottom left */}
                    <div className="tweetit-form__dialog-actions-container">
                      <div className="tweetit-form__no-css-rule">
                        <Button className={classNames(this.props.classes.action, classes.navLinkCancel)}
                          popin-type='delete' onClick={this.handleOpenConfirm}>
                          {t.btn_delete}
                        </Button>
                        {(this.props.formData.status === params.statuses.DRAFT || !this.props.formData.id) && (
                          <Button variant="contained"
                            className={classNames(this.props.classes.action, this.props.classes.buttonEdit)}
                            onClick={() => this.submitTweetit(params.statuses.DRAFT)}
                            disabled={this.isPostNotValid({ exclude: ['audience'], length })}
                          >
                            {this.state.saveLoading
                              ? <CircularProgress className={this.props.classes.progressBtn} size={20} />
                              : <SaveIcon className={this.props.classes.leftIcon} />
                            }
                            {t.btn_save}
                          </Button>
                        )}
                      </div>

                      {/* Button schedule at the bottom right */}
                      <div className="tweetit-form__no-css-rule">
                        {
                          (this.props.formData.status === params.statuses.DRAFT || !this.props.formData.id || this.state.isScheduled) &&
                          <Button variant="contained" color={"primary"}
                            className={!this.state.isScheduled
                              ? classNames(this.props.classes.action, this.props.classes.buttonSchedule)
                              : classNames(this.props.classes.action, this.props.classes.buttonScheduleActive)}
                            onClick={e => this.openCalendar(e)}
                          >
                            {this.state.submitLoading ? (
                              <CircularProgress className={this.props.classes.progressBtn} size={20} />
                            ) : (
                              <ReactSVG src={scheduleIcon} svgClassName={'leftIcon'} />
                            )}
                            {
                              !this.state.isScheduled
                                ? t.btn_schedule
                                : `${this.state.formData.scheduled_at ? this.state.formData.scheduled_at.format('D MMM, HH:mm') : ''} ${this.state.formData.meridiem}`
                            }
                            {this.state.isScheduled &&
                              <ReactSVG src={wrong} svgClassName={'rightIcon'}
                                onClick={this.unsetSchedule}
                              />
                            }
                          </Button>
                        }
                        <Button variant="contained" color="primary" className={classNames(this.props.classes.action, this.props.classes.buttonSubmit)}
                          onClick={() => this.submitTweetit(params.statuses.SUGGESTED)}
                          disabled={this.isPostNotValid({ length })}
                        >
                          {this.state.submitLoading ? (
                            <CircularProgress className={this.props.classes.progressBtn} size={20} />
                          ) : (
                            // If the tweetit is published, display the saveIcon else the send icon
                            this.state.formData.status === params.statuses.SUGGESTED ?
                              <SaveIcon className={this.props.classes.leftIcon} />
                              :
                              <ReactSVG src={sendIcon} svgClassName={'leftIcon'} />
                          )}
                          {
                            this.state.formData.status === params.statuses.SUGGESTED ? 'Sauvegarder' : !this.state.isScheduled ? t.btn_submit : t.btn_submit_schedule
                          }
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>

            )
          }
        }
      </Translation>

    );
  }
}

const mapStateToProps = (state) => {
  return state
};

FormDialog.defaultProps = {
  groups: [],
  tags: [],
};

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTweetitAction: PropTypes.func.isRequired,
  editTweetitAction: PropTypes.func.isRequired,
  deleteTweetitAction: PropTypes.func.isRequired,
  getTweetAction: PropTypes.func.isRequired,
  fetchOpenGraphAction: PropTypes.func.isRequired,
  getProfileAction: PropTypes.func.isRequired,
  createTagAction: PropTypes.func.isRequired,
  deleteTagAction: PropTypes.func.isRequired,
  getMediaUrl: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators, null, { pure: false })(withStyles(styles)(FormDialog));
