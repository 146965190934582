import Constants from '../utils/params';
import hash from "object-hash";
import Cookies from 'js-cookie'
import { handleErrors, catchErrors, checkToken } from './utils';
import params from '../utils/params.js'

/*
  Envoi d'un tweet
  data = {message(string), media(base64), media_alt(string)}
 */
export function sendPostAction(post_id, network) {
  return (dispatch) => {
    return checkToken().then((t) => {
      dispatch({
        type: params.UPDATE_LOADING,
        loading: true,
      });
      let url = "", headers = {
        'Content-Type': 'application/json',
        'x-auth-token': t.token,
      };

      if (network === 'twitter') {
        url = `${Constants.DOMAIN}/tweet/send?post_id=${post_id}`
        headers['twitter_key'] = Cookies.get('twitter_key')

      } else if (network === 'linkedin') {
        url = `${Constants.DOMAIN}/linkedin?post_id=${post_id}`
        headers['linkedin_key'] = Cookies.get('linkedin')

      }

      return fetch(url, {
        method: 'post',
        headers: headers
      })
        .then(handleErrors)
        .then(res => {
          dispatch({
            type: params.UPDATE_LOADING,
            loading: false,
          });
          dispatch({
            type: params.REMOVE_TWEETIT,
            post_id: post_id,
          });
          return (res)
        })
        .catch(catchErrors)
    });
  }
}

/*
    Verif the access of the different networks (twitter , linkedin)
 */
export function verifyNetworkAuth() {
  return (dispatch) => {

    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/verify_network_auth`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key'),
          'linkedin_key': Cookies.get('linkedin')
        }
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    })
  }
}

/*
  GET SPECIFIC INVITE LINK
*/
export function getInviteAction(invite_token) {
  return (dispatch) => {
    return fetch(`${Constants.DOMAIN}/verify?invite_token=${invite_token}`, {
      method: 'get'
    }).then(handleErrors)
      .then(res => {
        return res;
      })
      .catch(catchErrors)
  }
}

/*
  GET user info from email token (If the mail has not been confirm)
*/
export function getUserFromEmailTokenAction(email_token) {
  return (dispatch) => {
    return fetch(`${Constants.DOMAIN}/verify?email_token=${email_token}`, {
      method: 'get'
    }).then(handleErrors)
      .then(res => {
        return res;
      })
      .catch(catchErrors)
  }
}

/*
   Send the confirmation mail to the user
 */
export function sendConfirmMailAction(data) {
  return () => {
    let formData = new FormData();
    for (let key in data) {
      data.hasOwnProperty(key) && formData.append(key, data[key]);
    }

    return fetch(`${Constants.DOMAIN}/verify`, {
      method: 'post',
      body: formData
    }).then(handleErrors)
      .then(res => {
        return res;
      })
      .catch(catchErrors)
  }
}

/*
   confirm the email of the user
 */

export function editConfirmMailUserAction() {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/verify?confirm_email=1`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
        },
      }).then(handleErrors)
        .then(res => {
          return (res)
        })
        .catch(catchErrors)
    });
  }
}

/*
  Création d'un retweet
 */
export function createReTweetAction(tweet_id, post_id) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/retweet`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key')
        },
        body: JSON.stringify({ tweet_id: tweet_id, post_id: post_id })
      }).then(handleErrors)
        .then(res => {
          return (res)
        })
        .catch(catchErrors)
    });
  }
}

/*
  Récupère la liste des tweetits (les tweets suggéré)
 */
export function getTweetitsUserAction(limit, skip) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/tweetits/user?limit=` + (limit ? limit : 0) + '&skip=' + (skip ? skip : 0), {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {

          dispatch({
            type: params.UPDATE_SUGGESTED_TWEETITS,
            tweetits: res
          });
          return (res)
        })
        .catch(catchErrors)
    });
  }
}

/*
  Récupère les nouveau tweetits excluant ceux en paramètre
 */
export function getNewUserTweetitsAction(after, tweetits) {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/tweetits/user/except?tweetits=${JSON.stringify(tweetits)}&after=${after}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {

          dispatch({
            type: params.ADD_TWEETITS,
            tweetits: res,
            status: 'suggested',
          });
        })
        .catch(catchErrors)
    });
  }
}



/*
  Met à jour la liste des tweetits si hash différent
 */
export function refreshUserTweetitsAction(tweetits, len, lastHash, currentFormId, status) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/tweetits/user?limit=` + (len ? len : 0) + (status ? ('&status=' + status.join()) : "") + "&tweetits=" + JSON.stringify(tweetits), {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }

      }).then(handleErrors)
        .then(res => {

          if (lastHash !== hash(res.filter(t => t.id !== currentFormId)/* Ignorer le tweetit en cours d'édition */)) {
            dispatch({
              type: params.UPDATE_SUGGESTED_TWEETITS,
              tweetits: res
            });
          }
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Edition d'un tweetit
  data = {message(string), media_alt(string), media(base64)}
 */
export function editTweetitUserAction(post_id, data) {
  return dispatch => {
    return checkToken().then(t => {
      /* Supprime les champs vides */
      Object.keys(data).forEach(key => (data[key] === null && key !== "media") && delete data[key]);

      if (data.share_ref)
        data.share_ref = JSON.stringify(data.share_ref);
      if (data.media)
        data.media = JSON.stringify(data.media);

      let formData = new FormData();
      for (let key in data) {
        data.hasOwnProperty(key) && formData.append(key, data[key]);
      }
      formData.append('post_id', post_id);

      return fetch(`${Constants.DOMAIN}/tweetit/user`, {
        method: 'put',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  Devient follower d'un autre utilisateur
 */
export function followProfileAction(post_id, screen_name) {
  return dispatch => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/follow`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key')
        },
        body: JSON.stringify({
          post_id: post_id,
          screen_name: screen_name
        })
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.REMOVE_TWEETIT,
            post_id: post_id,
          });

          return res;
        })
        .catch(catchErrors)
    });
  }
}

export function submitMedia(media, size) {
  return dispatch => {
    return checkToken().then(t => {
      let formData = new FormData();
      formData.append('media', media);
      formData.append('mediaSize', size);

      return fetch(`${Constants.DOMAIN}/media`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  Update a user (For role User)
 */
export function editProfilUserAction(user) {
  return (dispatch) => {
    return checkToken().then(t => {

      return fetch(`${Constants.DOMAIN}/user`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        },
        body: JSON.stringify({
          name: user.name,
          email: user.email,
          phone: user.phone,
          lang: user.lang,
          email_notification: user.email_notification,
          sms_notification: user.sms_notification,
          newsletter_notification: user.newsletter_notification,
          role: params.roles.USER,
          status: user.status
        })
      })
        .then(handleErrors)
        .then((res) => {
          if (res && !res.error) {
            dispatch({
              type: Constants.UPDATE_USER,
              user: res,
              currentUser: true
            })
          }
          return res
        })
        .catch(catchErrors)
    });
  }
}

/*
  GET ALL FLUX USER
*/
export function getAllFluxUserAction(params = {}) {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/user/flux${params.network ? `?network=${params.network}` : ''}${params.offset ? `&offset=${params.offset}` : ''}`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key')
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  POST A CONTENT FROM A FLUX
*/
export function postContentFluxAction(flux_post_id, post_id) {
  return dispatch => {
    return checkToken().then(t => {
      let formData = new FormData();
      formData.append('flux_post_id', flux_post_id);
      formData.append('post_id', post_id);

      return fetch(`${Constants.DOMAIN}/user/flux`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key')
        },
        body: formData
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

export function shareFluxLinkedinAction(flux_post_id, post_id) {
  return dispatch => {
    return checkToken().then(t => {
      let formData = new FormData();

      formData.append('flux_post_id', flux_post_id);
      formData.append('linkedin_post_id', post_id);

      return fetch(`${Constants.DOMAIN}/linkedin/reshare`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token,
          'linkedin_key': Cookies.get('linkedin')
        },
        body: formData
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  CREATE FLUX POST ACTIVITY
*/
export function createFluxPostActivityAction(flux_post_id, label, message) {
  return dispatch => {
    return checkToken().then(t => {
      let formData = new FormData();
      formData.append('flux_post_id', flux_post_id);
      formData.append('label', label);
      formData.append('message', message);

      return fetch(`${Constants.DOMAIN}/flux_post/activity`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token,
        },
        body: formData
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  CREATE USER ACTIVITY
*/
export function createUserActivityAction(label, message) {
  return dispatch => {
    return checkToken().then(t => {
      let formData = new FormData();
      formData.append('label', label);
      formData.append('message', message);

      return fetch(`${Constants.DOMAIN}/user/activity`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token,
        },
        body: formData
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  GET USERS RANK
*/
export function getUsersRankAction(params = {}) {
  return (dispatch) => {
    return checkToken().then(t => {

      let query = null
      // Filters
      if (params && params.filters) {
        for (let s in params.filters) {
          if (Array.isArray(params.filters[s])) {
            query = !query ? `?${s}=${params.filters[s].join('-')}` : `${query}&${s}=${params.filters[s].join('-')}`
          } else {
            query = !query ? `?${s}=${params.filters[s]}` : `${query}&${s}=${params.filters[s]}`
          }
        }
      }

      // Date
      if (params && params.date) {
        query = !query ? `?from=${params.date.from}&to=${params.date.to}` : `${query}&from=${params.date.from}&to=${params.date.to}`
      }

      return fetch(`${Constants.DOMAIN}/users/rank${query || ''}`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
    Get the uploadcare url image
 */
export function getUploadCareUrlAction(options) {
  return (dispatch) => {

    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/flux_posts/uploadcare/status/?id=${options.id}&token=${options.token}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
        }
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    })
  }
}

/*
  CREATE NEWSLETTER ACTIVITY
*/
export function createNewsletterActivityAction(params) {
  return dispatch => {
    let formData = new FormData();
    formData.append('label', params.label);
    formData.append('parent_id', params.parent_id);
    formData.append('flux_post_id', params.flux_post_id);
    formData.append('user_id', params.user_id);

    return fetch(`${Constants.DOMAIN}/newsletter_activity`, {
      method: 'post',
      body: formData
    }).then(handleErrors)
      .then(res => res)
      .catch(catchErrors)
  }
}