import React from 'react';

function IconCheckboxChecked() {
  return (
    <svg className="icon icon__checkbox-checked fill-primary" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="15" height="15" rx="3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1887 4.10645C11.0446 3.96452 10.8077 3.96452 10.6637 4.10645L6.00787 8.69238C5.86359 8.83467 5.62706 8.83467 5.48296 8.69238L4.33648 7.56312C4.19193 7.42118 3.95567 7.42118 3.81103 7.56312L3.10794 8.25656C2.96402 8.39814 2.96402 8.63106 3.10794 8.77308L5.48296 11.1123C5.62706 11.2543 5.86359 11.2543 6.00787 11.1123L11.8919 5.31596C12.036 5.17385 12.036 4.94102 11.8919 4.79881L11.1887 4.10645Z" fill="white" />
    </svg>
  )
}

export default IconCheckboxChecked