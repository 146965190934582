import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSVG from "react-svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import diff from 'deep-diff';
import { Translation } from 'react-i18next';

// Material
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";

// Assets
import DeleteIcon from "../../../assets/icons/delete-icon";
import searchIcon from "../../../assets/images/search.svg";
import defaultUserIcon from "../../../assets/images/default_profile_normal.png";

// Material styles
import styles from './material-select-dialog';

class SelectDialog extends React.Component {
  state = {
    field: null,
    searchValue: '',
    filtered: [],
    scroll: 'paper',
  };

  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContent) {
    if (nextProps.items && (this.state.filtered.length === 0 || diff(this.props.items, nextProps.items))) {
      let filtered = [];

      nextProps.items.forEach(item => {
        if (Array.isArray(item)) {
          filtered.push(Array(item.length).fill(true));
        } else {
          filtered.push(true);
        }
      });

      this.setState({
        filtered: filtered,
        searchValue: ''
      });
    }

    if (nextProps.field && this.state.field === null) {
      this.setState({
        field: nextProps.field
      })
    }
  }

  static ignoreToggle(event) {
    event.persist();
    event.preventDefault();
  }

  async handleToggle(event, item) {
    if (!this.props.isMultiple) {
      item.selected = true;
      this.props.items.forEach(i => {
        i.id !== item.id && (i.selected = false);
      });
      /* Le state sera mis à jour par l'appel de méthode */
      this.handleClose();
      this.forceUpdate();
    } else {
      item.selected = !item.selected;

      /* On modifie directement les props au lieu du state donc il faut forcer un nouveau rendu */
      this.forceUpdate();
    }
  };

  handleSearch = event => {
    let value = event ? event.target.value : this.state.searchValue,
      isNested = false,
      regex = null;

    try {
      regex = event ? new RegExp(value, 'i') : new RegExp(value, 'i');
    } catch (e) {
      return;
    }

    let filtered = this.props.items.map(item => {
      if (Array.isArray(item)) {
        isNested = true;
        return item;
      } else {
        return item.label.search(regex) !== -1;
      }
    });
    if (isNested) {
      filtered = filtered.map(items => {
        return items.map(item => item.label.search(regex) !== -1);
      })
    }
    this.setState({
      filtered: filtered,
      searchValue: value
    });
  };

  async handleClose() {
    await this.props.setItems(this.props.items, this.state.field);
    this.setState({
      filtered: [],
      searchValue: ''
    });
  };

  async handleAdd() {

    // add new item
    await this.props.handleAdd(this.state.searchValue, this.props.field)

    // then reset filter
    this.setState({
      filtered: [],
      searchValue: ''
    })
  }

  // If the parent component is the form to create a post and items containing users and groups
  getUsersFromSelectedGroups() {
    let listUsersInGroups = []
    if (this.props.params && (['tweetit-form-audience', 'settings-flux-modal'].includes(this.props.params.parent))) {
      if (this.props.items[0] && this.props.items[0].length) {
        this.props.items[0].filter(g => g.selected === true).map((group) => {
          let users = group.users.map((user) => { return user })
          listUsersInGroups = listUsersInGroups.concat(users)
          return true
        })

        listUsersInGroups = [...new Set(listUsersInGroups)]
      }
    }

    return listUsersInGroups
  }

  render() {
    const { classes } = this.props;

    const listUsersInGroups = this.getUsersFromSelectedGroups()

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).home

            return (
              this.props.open &&
              <Popover
                open={this.props.open}
                anchorReference="anchorPosition"
                anchorPosition={{ top: this.props.positionTop, left: this.props.positionLeft }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflow: "visible"
                  }
                }}
                transitionDuration={0}
              >
                <Paper className={classes.content}
                  style={{ width: this.props.width }}
                >
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <div className="select-dialog__container flex column">
                      <OutlinedInput
                        labelWidth={0}
                        autoFocus={true}
                        type={'search'}
                        onChange={this.handleSearch}
                        startAdornment={
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <ReactSVG src={searchIcon} />
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: classes.selectDialogInput
                        }}
                      />
                      <PerfectScrollbar
                        className={this.props.containerClass
                          ? classNames(classes.container, this.props.containerClass)
                          : classes.container
                        }
                        option={{
                          wheelSpeed: 1,
                          wheelPropagation: true,
                          swipeEasing: false,
                          minScrollbarLength: 5
                        }}
                      >
                        <div className="select-dialog__no-css-rule">
                          {this.state.filtered.filter(visible => visible).length === 0 &&
                            <div className="select-dialog__container flex center">
                              <Typography className={classes.emptyText}>
                                {t.window_post_create.no_match}
                              </Typography>
                            </div>
                          }
                          {this.props.items.map((item, index) => {
                            if (Array.isArray(item)) {
                              return (
                                <div key={index} className={classes.subList}>
                                  <ListSubheader className={classes.listSubheader} disableSticky={true}>
                                    {this.props.labels[index]}
                                  </ListSubheader>
                                  {this.state.filtered && this.state.filtered[index] && this.state.filtered[index].filter(visible => visible).length === 0 &&
                                    <div className="select-dialog__container flex center">
                                      <Typography className={classes.emptyText}>
                                        {t.window_post_create.no_match}
                                      </Typography>
                                    </div>
                                  }
                                  {item.map((subItem, subIndex) => {
                                    subItem.groupSelected = (index === 1) && listUsersInGroups.includes(subItem.id)
                                    subItem.selected = subItem.groupSelected ? false : subItem.selected

                                    return (
                                      this.state.filtered && this.state.filtered[index] && this.state.filtered[index][subIndex] &&
                                      <ListItem key={subItem.id} dense button
                                        classes={{
                                          container: classes.listItemContainer,
                                          root: this.props.isMultiple
                                            ? classes.listItemMultiple
                                            : classes.listItem
                                        }}
                                        className={subItem.groupSelected ? 'select-dialog__item disable' : ''}
                                        onClick={e => this.handleToggle(e, subItem)} >
                                        {this.props.isMultiple &&
                                          <Checkbox
                                            checked={!!subItem.selected || subItem.groupSelected}
                                            className={classes.checkbox}
                                            color="primary"
                                            onMouseDown={SelectDialog.ignoreToggle}
                                          />
                                        }
                                        {subItem.icon &&
                                          <Avatar alt={subItem.label} src={subItem.icon} className={classes.avatar} onError={(e) => { e.target.src = defaultUserIcon }} />
                                        }
                                        <ListItemText
                                          primary={subItem.label}
                                          className={this.props.itemClass[index]
                                            ? this.props.isMultiple
                                              ? this.props.itemClass[index]
                                              : classNames(this.props.itemClass[index], classes.listItemTextSingle)
                                            : (subItem.selected || subItem.groupSelected)
                                              ? classes.listItemTextSelected
                                              : classes.listItemText
                                          }
                                        />
                                        {this.props.allowDelete &&
                                          <ListItemSecondaryAction classes={{
                                            root: classes.deleteAction
                                          }}>
                                            <IconButton aria-label="Delete"
                                              onClick={() => this.props.handleDelete(subItem.id, this.props.field[index])}
                                            >
                                              {this.props.deleteIcon ? this.props.deleteIcon : <DeleteIcon />}
                                            </IconButton>
                                            {this.props.deleteIcon ? this.props.deleteIcon : <DeleteIcon />}
                                          </ListItemSecondaryAction>
                                        }
                                      </ListItem>
                                    )
                                  })}
                                </div>
                              )
                            } else {

                              return (
                                this.state.filtered[index] &&
                                <ListItem key={item.id} dense button
                                  classes={{
                                    container: classes.listItemContainer,
                                    root: classes.listItem
                                  }}
                                  onClick={e => this.handleToggle(e, item)} >
                                  {this.props.isMultiple &&
                                    <Checkbox
                                      checked={!!item.selected}
                                      className={classes.checkbox}
                                      color="primary"
                                      onMouseDown={SelectDialog.ignoreToggle}
                                    />
                                  }
                                  {item.icon && <Avatar alt={item.label} src={item.icon} className={classes.avatar} />}
                                  <ListItemText
                                    primary={item.label}
                                    className={this.props.itemClass
                                      ? this.props.isMultiple
                                        ? this.props.itemClass
                                        : classNames(this.props.itemClass, classes.listItemTextSingle)
                                      : item.selected
                                        ? classes.listItemTextSelected
                                        : classes.listItemText
                                    }
                                  />
                                  {this.props.allowDelete &&
                                    <ListItemSecondaryAction
                                      classes={{
                                        root: classes.deleteAction
                                      }}
                                      onClick={() => this.props.handleDelete(item.id, this.props.field)}
                                    >
                                      {this.props.deleteIcon ? this.props.deleteIcon : <DeleteIcon />}
                                    </ListItemSecondaryAction>
                                  }
                                </ListItem>
                              )
                            }
                          })}
                          {
                            this.props.allowAdd && (
                              this.state.searchValue.length < 1
                                ? <Grid container justify={"center"} alignItems={"center"} wrap={"nowrap"}
                                  className={classes.addAction}>
                                  <Typography className={classNames(classes.addText, classes.addTextDisabled)}>
                                    {t.window_post_create.thematic_search}
                                  </Typography>
                                </Grid>
                                : <Grid container justify={"center"} alignItems={"center"} wrap={"nowrap"}
                                  className={classNames(classes.addAction, classes.addActionActive)}
                                  onClick={this.handleAdd}
                                >
                                  <AddCircleIcon className={classes.addIcon} />
                                  <Typography className={classes.addText}>
                                    {t.window_post_create.thematic_create} « {this.state.searchValue} »
                                  </Typography>
                                </Grid>
                            )
                          }
                        </div>
                      </PerfectScrollbar>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Popover>

            )
          }
        }
      </Translation>
    );
  }
}

SelectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  positionTop: PropTypes.number.isRequired,
  positionLeft: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  allowAdd: PropTypes.bool,
  allowDelete: PropTypes.bool,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  field: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  labels: PropTypes.arrayOf(PropTypes.string),
  itemClass: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  setItems: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  deleteIcon: PropTypes.element
};

export default withStyles(styles)(SelectDialog);