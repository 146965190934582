// Libs
import React from "react"
import { uuid } from 'uuidv4';
import i18n from '../../i18n'

// Material
import Typography from '@material-ui/core/Typography';

// Assets
import TwitterIcon from '../../assets/icons/twitter-icon'
import IconInstagram from '../../assets/icons/instagram'
import IconFacebook from '../../assets/icons/facebook'
import IconLinkedin from '../../assets/icons/linkedin-icon'
import emptyFace from "../../assets/images/empty-face.svg"
import LinkedinIcon from '../../assets/icons/linkedin-icon';

// Components
import Slider from "../misc/slider/Slider"
import MediaDialog from "../misc/mediaDialog/media-dialog";
import FluxCard from "../misc/fluxCard/fluxCard";
import LinkedinLogin from '../misc/LinkedinLogin/LinkedinLogin';
import TwitterLogin from '../misc/TwitterLogin/TwitterLogin';
import Constants from '../../utils/params';

const wording = i18n.t('user', { returnObjects: true })

class UserFlux extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: null,
      flux_list: null,
      fluxNetworkSkipped: {
        twitter: [],
        linkedin: [],
        facebook: [],
        instagram: [],
      },
      fluxNetworkOffsets: {
        twitter: 0,
        linkedin: 0,
        facebook: 0,
        instagram: 0,
      }
    };

    this.loadMore = this.loadMore.bind(this)
    this.saveFlux = this.saveFlux.bind(this)
    this.onSkip = this.onSkip.bind(this)

  }

  componentDidMount() {

    // retrieve flux_list if exists
    if (this.props.flux_list && this.props.flux_list) this.saveFlux(this.props.flux_list)

    localStorage.removeItem("flux_network")
    // retrieve flux_network's storage
    /* let fluxNetworkStorage = localStorage.getItem("flux_network")
     if (fluxNetworkStorage) {
         this.setState({
             fluxNetworkSkipped: JSON.parse(fluxNetworkStorage)
         })
     }*/

  }

  componentDidUpdate(prevProps) {

    // retrieve flux_list if exists
    if ((this.props.flux_list && this.props.flux_list) && (!this.state.flux_list)) {
      this.saveFlux(this.props.flux_list)
    }
  }

  async loadMore(network) {

    let offsets = { ...this.state.fluxNetworkOffsets }

    // increment flux offset
    offsets[network] += 10

    let new_flux_list = await this.props.getAllFluxUserAction({ network, offset: offsets[network] })

    // if there is more flux list - we save new offset and save flux
    if (new_flux_list && new_flux_list.length) {
      this.setState({
        fluxNetworkOffsets: offsets
      })

      this.saveFlux(new_flux_list)
    }

  }

  saveFlux(flux_list, new_flux_list = null) {
    let posts = this.state.posts
    if (!posts) posts = []
    // add new posts
    posts = [...posts, ...(new_flux_list ? new_flux_list : flux_list)]

    this.setState({ flux_list, posts })
  }

  buildCard(posts, options) {

    return posts.map((post) => {

      // Disable linkedin share post without content
      if (post.flux.network === 'linkedin' && post.post.shared_post && !post.post.shared_post.text) return (null);

      return (
        <FluxCard
          key={`${post.id}`}
          id={post.id}
          account={post.flux}
          post={post.post}
          enlargeMedia={this.props.enlargeMedia}
          fetchOpenGraphAction={this.props.fetchOpenGraphAction}
          postContentFluxAction={this.props.postContentFluxAction}
          shareFluxLinkedinAction={this.props.shareFluxLinkedinAction}
          getUploadCareUrlAction={this.props.getUploadCareUrlAction}
          createFluxPostActivityAction={this.props.createFluxPostActivityAction}
          onSkip={this.onSkip}
          options={options}
        />
      )
    })
  }

  renderFluxList(network, options) {


    let networkTitle = ""
    let networkIcon = null

    switch (network) {
      case "twitter":
        networkTitle = "Twitter"
        networkIcon = <TwitterIcon />
        break
      case "instagram":
        networkTitle = "Instagram"
        networkIcon = <IconInstagram />
        break
      case "facebook":
        networkTitle = "Facebook"
        networkIcon = <IconFacebook />
        break
      case "linkedin":
        networkTitle = "Linkedin"
        networkIcon = <IconLinkedin />
        break
      default:
        networkTitle = ""
        networkIcon = null
    }

    let posts = this.state.posts.filter(post => post.flux.network === network)
    let skippedPosts = this.state.fluxNetworkSkipped[network]
    posts = posts.filter(p => !skippedPosts.includes(p.id))

    // don't render if there is no flux
    if (posts && !posts.length) {
      return ""
    } else {

      return (
        <div className="user-flux__section">

          {/*posts */}
          <div className="user-flux__section-title">
            {networkIcon} {networkTitle}
          </div>
          {(
            <div className="user-flux__section-body">
              <Slider loadMore={() => this.loadMore(network)}>
                {this.buildCard(posts, options)}
              </Slider>
            </div>
          )}
        </div>
      )
    }

  }

  onSkip(postId, postNetwork) {

    let fluxNetworkStorage = localStorage.getItem("flux_network")

    // we will create the storage
    if (!fluxNetworkStorage) {

      let defaultFluxNetworkStorage = {
        twitter: [],
        linkedin: [],
        facebook: [],
        instagram: [],
      }

      // push this post's id in the storage
      defaultFluxNetworkStorage[postNetwork].push(postId)


      // create storage
      this.setState({ fluxNetworkSkipped: defaultFluxNetworkStorage })
      localStorage.setItem("flux_network", JSON.stringify(defaultFluxNetworkStorage))
    }
    // we retrieved it
    else {
      fluxNetworkStorage = JSON.parse(fluxNetworkStorage)

      // if post is not skipped yet
      if (!fluxNetworkStorage[postNetwork].includes(postId)) {
        // push this post's id in the storage
        fluxNetworkStorage[postNetwork].push(postId)
      }

      // update storage
      this.setState({ fluxNetworkSkipped: fluxNetworkStorage })
      localStorage.setItem("flux_network", JSON.stringify(fluxNetworkStorage))
    }


  }

  render() {
    const isTwitter = this.state.posts && this.state.posts.some(s => s.flux.network === "twitter") ? this.props.user.user_twitter && !this.props.authenticatedOnTwitter : false
    const isLinkedin = this.state.posts && this.state.posts.some(s => s.flux.network === "linkedin") ? this.props.user.user_linkedin && !this.props.authenticatedOnLinkedin : false

    const totalSkipped = this.state.fluxNetworkSkipped.linkedin.length + this.state.fluxNetworkSkipped.instagram.length + this.state.fluxNetworkSkipped.facebook.length + this.state.fluxNetworkSkipped.twitter.length

    return (
      <div className="user-flux">

        <MediaDialog
          fullWidth={true}
          open={this.props.mediaIsOpen}
          currentIndex={this.props.mediaIndex}
          media={this.props.media}
          getMediaUrl={this.props.getMediaUrl}
          handleClose={this.props.minimizeMedia}
        />

        {this.state.flux_list && this.state.flux_list.length ?
          <h1 className="user-flux__heading">{wording.news.title}</h1>
          : ''}

        {/* Render flux list, if there is no flux render empty face */}
        {
          (this.state.posts && this.state.posts.length && (this.state.posts.length !== totalSkipped)) ? (
            <>
              {/* Button TWITTER // TO-REVIEW */}
              {isTwitter && (process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true') ?
                <TwitterLogin
                  loginUrl={Constants.DOMAIN + "/auth/twitter"}
                  onFailure={this.props.onSocialAuthFailed("twitter")}
                  onSuccess={this.props.onSocialAuthSucceed('twitter')} // twitterAuthSucceed = key in this.state
                  showIcon={true}
                  register={this.props.user.user_twitter ? false : true}
                  forceLogin={this.props.user.user_twitter ? false : true}
                  className="signin__twitter-button"
                  requestTokenUrl={Constants.DOMAIN + `/auth/twitter/reverse?callback=${window.location.origin}`}>

                  {/* Twitter icon */}
                  <TwitterIcon />
                  <Typography>
                    {wording.button_twitter}
                  </Typography>
                </TwitterLogin>
                : ''}

              {(process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true') && this.renderFluxList("twitter", { isTwitter })}

              {/* Button LINKEDIN // TO-REVIEW */}
              {isLinkedin && (process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true') ?
                <LinkedinLogin
                  className="signin__linkedin-button"
                  onSuccess={this.props.onSocialAuthSucceed("linkedin")}
                  onFailure={this.props.onSocialAuthFailed("linkedin")}
                  clientId={process.env.REACT_APP_LINKEDIN_ID}
                  redirectUri={`${window.location.origin}/linkedin`}
                  scope="r_liteprofile w_member_social r_basicprofile"
                  state={uuid()}
                >
                  <LinkedinIcon />
                  <Typography>
                    {wording.button_linkedin}
                  </Typography>
                </LinkedinLogin>
                : ''}

              {(process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true') && this.renderFluxList("linkedin", { isLinkedin })}

              {(process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true') && this.renderFluxList("instagram")}

              {(process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true') && this.renderFluxList("facebook")}
            </>
          ) :
            this.state.posts !== null ?
              (
                <div className="user-flux__empty">
                  <img alt="empty" src={emptyFace} />
                  <p>
                    {wording.news.text_no_content}
                  </p>
                </div>
              )
              : ''
        }

        {this.state.posts === null ?
          <div className="user-flux__loading">
            {wording.news.text_waiting}
          </div>
          : ''}
      </div>
    )
  }
}

export default UserFlux