import React from 'react';

// Init component.
function IconSchedule(props) {
  return (
    <svg width="4" height="8" viewBox="0 0 4 8" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6 4.49185V0.802115C1.6 0.320846 1.28 0 0.8 0C0.32 0 0 0.320846 0 0.802115V4.81269C0 5.05333 0.08 5.21375 0.24 5.37417L2.64 7.78052C3.12 8.18158 3.6 7.94094 3.76 7.78052C4.08 7.45967 4.08 6.9784 3.76 6.65756L1.6 4.49185Z" />
    </svg>
  );
}

export default IconSchedule;