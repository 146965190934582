// Libs
import React from "react"
import i18n from '../../../i18n'

// Assets
import CardCancelIcon from '../../../assets/icons/card-cancel'
import IconRetweet from '../../../assets/icons/retweet'
import IconShare from '../../../assets/icons/share'
import IconThumbLike from '../../../assets/icons/thumb-like'
import validateIcon from "../../../assets/images/validate-icon.svg";
import CircularProgress from '@material-ui/core/CircularProgress';

// Utils
import params from '../../../utils/params'

const wording = i18n.t('user', { returnObjects: true })

class FluxCardFooter extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      labelValid: null,
      loading: false
    }
  }

  postContent = async () => {
    await this.setState({ loading: true })
    let res = await this.props.postContentFluxAction(this.props.id, this.props.post.id_str)

    if (res && (!res.error || (res.error === 'You have already retweeted this Tweet.'))) await this.setState({ labelValid: res.error ? wording.news.retweeted_already : wording.news.retweeted })

    await this.setState({ loading: false })
  }

  shareLinkedinFlux = async () => {

    await this.setState({ loading: true })
    let res = await this.props.shareFluxLinkedinAction(this.props.id, this.props.post.id)
    await this.setState({ loading: false })

    // handle error
    if (res.error) {
      if (res.error === "Un post Linkedin identique a récemment déjà été partagé.") {
        this.setState({ labelValid: 'Déjà partagé' })
      }
    } else {
      this.setState({ labelValid: 'Partagé' })
    }
  }

  onSkip = async () => {
    this.props.onSkip(this.props.id, this.props.account.network)

    if ((this.props.total_cards - this.props.currentIndex) <= 3) this.props.loadMore()

    await this.props.createFluxPostActivityAction(this.props.id, params.label_activity.FLUX_POST_PASS, 'user passed the post')
      .catch((err) => console.error(err))
  }

  onLike = async () => {
    await this.props.createFluxPostActivityAction(this.props.id, params.label_activity.FLUX_POST_LIKE, 'user clicked on the like button of the post')
      .catch((err) => console.error(err))
  }

  renderActionButton() {

    const network = this.props.account.network


    if (network === "twitter") {
      return (
        <button className="flux-card__footer-btn-action color-primary" onClick={this.postContent}>
          {this.state.loading ? <CircularProgress size={14} /> : <IconRetweet />}
          <span className="flux-card__footer-btn-label">{wording.news.btn_retweet}</span>
        </button>
      )
    }
    else if (network === "linkedin") {
      return (
        <button className="flux-card__footer-btn-action color-primary" onClick={this.shareLinkedinFlux}>
          {this.state.loading ? <CircularProgress size={14} /> : <IconShare />}
          <span className="flux-card__footer-btn-label">{wording.news.btn_share}</span>
        </button>
      )
    }
    else if (network === "facebook") {
      return (
        <a
          href={`https://www.facebook.com/${this.props.account.screen_name}/posts/${this.props.post.post_id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flux-card__footer-btn-action color-primary"
          onClick={this.onLike}
        >
          {this.state.loading ? <CircularProgress size={14} /> : <IconThumbLike />}
          <span className="flux-card__footer-btn-label">{wording.news.btn_like}</span>
        </a>
      )
    }
    else if (network === "instagram") {
      return (
        <a
          href={this.props.post.link}
          target="_blank"
          rel="noopener noreferrer"
          className="flux-card__footer-btn-action color-primary"
          onClick={this.onLike}
        >
          {this.state.loading ? <CircularProgress size={14} /> : <IconThumbLike />}
          <span className="flux-card__footer-btn-label">{wording.news.btn_like}</span>
        </a>
      )
    }
  }

  render() {

    return (
      <div className="flux-card__footer">
        <div className="flux-card__footer-container">
          <div className={`flux-card__footer-switch ${this.state.labelValid ? 'sent' : ''}`}>
            {/* FOOTER BTN */}
            <div className="flux-card__footer-btn">
              <button onClick={this.onSkip} className="flux-card__footer-btn-cancel">
                <CardCancelIcon />
                <span className="flux-card__footer-btn-label">{wording.news.btn_pass}</span>
              </button>

              {this.renderActionButton()}
            </div>

            {/* FOOTER VALID */}
            <div className="flux-card__footer-sent">
              <img alt="valid" src={validateIcon} />
              {this.state.labelValid}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FluxCardFooter