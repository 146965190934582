import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'react-perfect-scrollbar/dist/css/styles.css';

// Material
import { withStyles } from '@material-ui/core/styles';

// Assets

// Components
import List from './list'
import Edit from './edit'
import MembersInvitation from "../members/membersInvitation/membersInvitation";

// Material styles
import styles from './material-members'

class Members extends React.Component {

  formatUser(user, groups, isMe) {
    return {
      id: user.id,
      phone: user.phone,
      email: user.email,
      account: "twitter",
      role: user.role,
      lang: user.lang,
      status: user.status,
      emailNotifications: user.email_notification,
      smsNotification: !!user.sms_notification,
      is_user_test: user.is_user_test,
      name: user.name,
      user_twitter: user.user_twitter,
      user_linkedin: user.user_linkedin,
      // get groups for sortingFunctions
      groups: groups.filter(g => g.users.includes(user.id)),
      is_me: isMe
    }
  }

  render() {
    const props = this.props;
    return (
      <div className="members__no-css-rule">
        <div className="members__root-grid">
          {/* Popin members invitation */}
          {props.isPopinInvitationOpen ?
            <MembersInvitation {...props} />
            : ''}

          <Switch>
            <Route exact path="/members" render={() => <List {...props} formatUser={this.formatUser} />} />
            <Route exact path="/members/edit" render={() => <Edit {...props} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

Members.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  editAccountAction: PropTypes.func.isRequired,
  handleOpenConfirm: PropTypes.func.isRequired,
  handleCancelConfirm: PropTypes.func.isRequired,
  handleValidConfirm: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  isVisible: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Members);
