import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from "react-svg";
import diff from 'deep-diff';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import i18n from '../../../i18n'

// Material
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import Slide from '@material-ui/core/Slide';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import IconButton from "@material-ui/core/IconButton/IconButton";
// import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

// Assets
import sendIcon from "../../../assets/images/send.svg";
import wrong from "../../../assets/images/wrong.svg";
import AddImageIcon from "../../../assets/icons/add-image";
import AddVideoIcon from '../../../assets/icons/add-video';
import commentIcon from "../../../assets/images/comment.svg";
import TwitterIcon from '../../../assets/icons/twitter-icon';
import LinkedinIcon from '../../../assets/icons/linkedin-icon';

// Components
import TweetitForm from '../tweetit-form-common';
import params from '../../../utils/params.js';
import GlobalUtils from '../../../utils/global'
import TextEditor from '../textEditor/textEditor'

// Material styles
import styles from './material-user-tweetit-form';
import EventEmitter from '../../../utils/EventEmitter';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const wording = i18n.t('user', { returnObjects: true })

class FullScreenDialog extends TweetitForm {
  constructor(props) {
    super(props);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileRemoveUser = this.handleFileRemoveUser.bind(this);
    this.submitTweetit = this.submitTweetit.bind(this);
    this.editTweetit = this.editTweetit.bind(this);

    this.USER_FORM = true;

    this.defaultState = {
      value: 0,
      formDataHash: null,
      formData: {
        id: null,
        users: null,
        message: "",
        media: [],
        mediaType: params.mediaTypes.IMAGE,
        status: "",
        type: "",
        share_ref: null,
        share_url: null
      },
      imgSrc: [],
      vidSrc: null,
      urlIsLoading: false,
      urlIsValid: undefined,
      messageIsValid: undefined,
      imageIsLoading: false,
      videoIsLoading: false,
      imageError: null,
      videoError: null,
      [this.CONTENT_PUT_LINK_FORWARD]: false
    };

    this.state = Object.assign({
      messageIsValid: undefined,
      posting: false,
      loaded: false
    }, this.defaultState);

  }

  componentWillReceiveProps(nextProps, nextContext) {

    if (!nextProps.open) {
      this.setState({ loaded: false });
    }
    if (nextProps.formData && Object.keys(nextProps.formData).length > 0 && nextProps.formData.constructor === Object) {
      if (!this.state.loaded) {
        let formData = nextProps.formData;
        /* Form validation */
        if (nextProps.formData.type === params.types.POST) {
          this.validateContent(nextProps.formData.message, this.CONTENT_MESSAGE, formData.type);
        } else if (nextProps.formData.type === params.types.REPOST) {
          this.validateContent(nextProps.formData.share_url, this.CONTENT_URL, formData.type);
        } else if (nextProps.formData.type === params.types.PROFILE) {
          this.validateContent(nextProps.formData.share_url, this.CONTENT_PROFILE, formData.type);
        }

        /*  Vérifie si le type pour affiche la bonne fenètre */
        this.setState({ loaded: true, formDataHash: formData, formData: formData, value: formData.type, [this.CONTENT_PUT_LINK_FORWARD]: (formData.network === 'linkedin') && formData.share_url ? true : false });
      }
    } else {
      this.setState(Object.assign({
        posting: false,
      }, this.defaultState));
    }
  }

  handleFileRemoveUser(e) {
    let index = e.currentTarget.getAttribute('index');

    let formData = Object.assign({}, this.state.formData);

    if (formData.mediaType === params.mediaTypes.IMAGE) {
      formData.media.splice(index, 1);

      let imgSrc = [...this.state.imgSrc];
      imgSrc.splice(index, 1);

      this.setState({ imgSrc: imgSrc })
    } else if (formData.mediaType === params.mediaTypes.VIDEO) {
      formData.media = [];
      this.setState({ vidSrc: null });
    }

    this.setState({
      formData: formData,
      imageError: null,
      videoError: null,
      isModified: true
    }, async () => {
      let res = await this.props.editTweetitUserAction(this.state.formData.id, {
        message: this.state.formData.message,
        media: this.state.formData.media,
        mediaType: this.state.formData.mediaType,
        url: this.state.formData.share_url ? this.state.formData.share_url : null,
        status: params.statuses.SUGGESTED,
        type: this.state.formData.type
      });

      if (res && res.error) EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" });
    })
  }

  async submitTweetit() {
    // Init value who will receive the result of the request
    let res = null

    // Manager the component CircularProgress on the post card
    await this.setState({ posting: true });

    // Update the content of the post
    res = await this.editTweetit();

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      this.setState({ posting: false })
      return;
    }

    // Send the post to the right network
    res = await this.props.sendPostAction(this.state.formData.id, this.state.formData.network);

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      this.setState({ posting: false })
      return;
    }

    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.post.popin_message.post_sent, style: "success" })
    await this.setState(this.defaultState);
    this.props.getTweetitsUserAction(this.props.tweetits.filter(t => t.status === params.statuses.SUGGESTED).length);

    // Close the form
    this.props.handleClose();
    this.setState({ posting: false })
  }

  editTweetit() {
    return this.props.editTweetitUserAction(this.state.formData.id, {
      message: this.state.formData.message,
      media: this.state.formData.media,
      mediaType: this.state.formData.mediaType,
      url: this.state.formData.share_url ? this.state.formData.share_url : null,
      status: (diff(this.state.formDataHash, this.state.formData) ? params.statuses.EDITED : params.statuses.PUBLISHED),
      type: this.state.formData.type,
      share_url: this.state.formData.share_url ? this.state.formData.share_url : null,
      share_ref: this.state.formData.share_ref ? this.state.formData.share_ref : null,
    });
  }

  render() {
    const isPostTwitter = this.state.formData.network === 'twitter'
    const length = GlobalUtils.getNetworkMessagelength(this.state.formData.message, { twitter: isPostTwitter, linkedin: !isPostTwitter })

    const characters_count_color = (isPostTwitter && (length.twitter <= 280 ? "#1DA1F2" : "#f2615e")) || (!isPostTwitter && (length.linkedin <= 3000 ? "#1171A1" : "#f2615e"))

    const { classes } = this.props;
    return (
      <div className="user-tweetit-form__no-css-rule">
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.handleClose}
          TransitionComponent={Transition}
        >
          <div className="user-tweetit-form__toolbar">
            <IconButton className={classes.dialogClose} onClick={this.props.handleClose}>
              <ArrowLeftRoundedIcon />
            </IconButton>
            <Button variant="contained" color="primary" className={classes.buttonSubmit}
              onClick={(e) => this.submitTweetit(e, params.statuses.PUBLISHED)}
              disabled={
                (this.state.imageIsLoading !== false || this.state.videoIsLoading ||
                  (!this.state.messageIsValid && this.state.formData.media.length === 0 && !this.state.formData.message && this.state.formData.type === params.types.POST) ||
                  (!this.state.urlIsValid && this.state.formData.type === params.types.REPOST) ||
                  (this.state.posting) ||
                  characters_count_color === '#f2615e'
                )
              }
            >
              {this.state.posting ? (
                <CircularProgress className={this.props.classes.progress} size={20} />
              ) : (
                <ReactSVG src={sendIcon} className={classes.sendIcon} />
              )}
              {wording.post.publish}
            </Button>
          </div>
          <div className="user-tweetit-form__root-grid">
            <Typography className={classes.title}>
              {wording.post.edit}
            </Typography>
            <Typography
              className={classes.headerInfo}
            >
              {/** Show network icon */}
              {this.state.formData.network === "linkedin" && <LinkedinIcon />}
              {this.state.formData.network === "twitter" && <TwitterIcon />}

              <span>{this.state.formData.network === "twitter" ? wording.post.publish_warning_twitter : this.state.formData.network === "linkedin" ? wording.post.publish_warning_linkedin : ""}</span>
            </Typography>
            <div className="user-tweetit-form__container">
              <div className="user-tweetit-form__container__input">
                <TextEditor
                  value={this.state.formData.message}
                  onChange={this.handleChange(this.CONTENT_MESSAGE)}
                  isNewPost={false}
                  placeholder={''}
                  warning={wording.post.linkedin_warning}
                  willPublishForTwitter={this.state.formData.network === 'twitter'}
                  willPublishForLinkedin={this.state.formData.network === 'linkedin'}
                  willPublishForInstagram={this.state.formData.network === 'instagram'}
                  willPublishForFacebook={this.state.formData.network === 'facebook'}
                  handleCloseEmojis={() => {}}
                  emojisIsOpen={false}
                  searchAccountAction={this.props.searchAccountAction}
                />
              </div>

              {this.state.formData.type === params.types.POST && (
                <Typography component={"p"} style={
                  { color: characters_count_color }
                } className={classes.tweetCount}>
                  {isPostTwitter ? length.twitter : length.linkedin}
                </Typography>
              )}
            </div>

            {/** Show checkbox(put link forward) when only linkedin is selected */}
            {this.state.formData.network === "linkedin" && (
              <div className="user-tweetit-form__container">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      classes={{ root: classes.shareUrlCheckbox }}
                      onChange={this.handleChange(this.CONTENT_PUT_LINK_FORWARD)}
                      checked={this.state[this.CONTENT_PUT_LINK_FORWARD]}
                    />
                  }
                  label={wording.post.display_thumbnail_link}
                  classes={{ label: classes.shareUrlLabel }}
                />
              </div>
            )}


            {this.state.formData.share_ref && (this.state.formData.network === "linkedin") ? (
              <div className="user-tweetit-form__container">
                <div className="user-tweetit-form__ogPreview">
                  {this.state.formData.share_ref.image && <div className="user-tweetit-form__ogPreview-image">
                    <img src={this.state.formData.share_ref.image} alt="" />
                  </div>}
                  {this.state.formData.share_ref.title && <p className="user-tweetit-form__ogPreview-title">{this.state.formData.share_ref.title}</p>}
                  {this.state.formData.share_ref.url && <p className="user-tweetit-form__ogPreview-url">{this.state.formData.share_ref.url}</p>}
                </div>
              </div>
            ) : ""
            }


            {this.state.formData.type === params.types.POST && (
              <div className="user-tweetit-form__container">
                {this.state.formData.media.length > 0 ? (
                  <TransitionGroup>
                    <CSSTransition
                      appear={true}
                      enter={false}
                      exit={false}
                      timeout={150}
                      classNames={{
                        appear: classes.mediaAppear,
                        appearActive: classes.mediaAppearActive
                      }}
                    >
                      {
                        this.state.formData.media[0].type === params.mediaTypes.VIDEO || this.state.formData.mediaType === params.mediaTypes.VIDEO ?
                          this.state.videoIsLoading ?
                            <div className="user-tweetit-form__container column align">
                              <CircularProgress size={200} color={"primary"} className={classes.mediaLoader} />
                            </div>
                            :
                            <div className="user-tweetit-form__container column">
                              <video
                                className={this.props.classes.media}
                                src={this.state.vidSrc || this.props.getMediaUrl(this.state.formData.media[0])}
                              />
                              <input
                                accept=".mp4,.mov"
                                className={this.props.classes.mediaInput}
                                onChange={e => this.handleFileChange(e, true, 0)}
                                id={this.MEDIA_VIDEO}
                                name={this.MEDIA_VIDEO}
                                type="file"
                              />
                              <IconButton
                                onClick={this.handleFileRemoveUser}
                                index={0}
                                className={classes.buttonRemoveMedia + " noOver"}
                                disableRipple>
                                <ReactSVG src={wrong} />
                              </IconButton>
                              <Typography color={this.state.videoError ? "red" : "textSecondary"}
                                className={classes.mediaHint}>
                                {this.state.videoError
                                  ? this.state.videoError
                                  : wording.post.edit_video
                                }
                              </Typography>
                            </div>
                          :
                          <div className="user-tweetit-form__container column">
                            {
                              this.state.formData.media.map((image, index) =>
                                this.state.imageIsLoading === index ?
                                  <div className="user-tweetit-form__container column align" key={index}>
                                    <CircularProgress size={200} color={"primary"} className={classes.mediaLoader} />
                                  </div>
                                  :
                                  <div className="user-tweetit-form__container column" key={index}>

                                    <img alt={image.alt} className={this.props.classes.media}
                                      src={this.state.imgSrc[index] || this.props.getMediaUrl(image)}
                                    />
                                    <input
                                      accept="image/*"
                                      className={this.props.classes.mediaInput}
                                      onChange={e => this.handleFileChange(e, true, index)}
                                      id={this.MEDIA_IMAGE}
                                      name={this.MEDIA_IMAGE}
                                      type="file"
                                    />
                                    <IconButton
                                      onClick={this.handleFileRemoveUser}
                                      index={index}
                                      className={classes.buttonRemoveMedia + " noOver"}
                                      disableRipple>
                                      <ReactSVG src={wrong} />
                                    </IconButton>
                                    <Typography color={this.state.imageError ? "error" : "textSecondary"}
                                      className={classes.mediaHint}>
                                      {this.state.imageError
                                        ? this.state.imageError
                                        : wording.post.edit_image
                                      }
                                    </Typography>
                                  </div>
                              )
                            }
                          </div>
                      }
                    </CSSTransition>
                  </TransitionGroup>
                ) : (
                  <div className="user-tweetit-form__container user-tweetit-form__container-add-media-container" >
                    {
                      typeof this.state.imageIsLoading === 'number'
                        ? <CircularProgress size={26} color={"primary"} className={classes.mediaLoader} />
                        : <div className="user-tweetit-form_no-css-rule">
                          <input
                            accept="image/*"
                            className={this.props.classes.input}
                            onChange={this.handleFileChange}
                            id={this.MEDIA_IMAGE}
                            name={this.MEDIA_IMAGE}
                            type="file"
                          />
                          <label htmlFor={this.MEDIA_IMAGE}>
                            <IconButton
                              variant="contained" component="span"
                              className={classes.buttonAddImage}
                              disableRipple>
                              <AddImageIcon />
                            </IconButton>
                          </label>
                          {this.state.imageError && (
                            <Typography style={{ color: "#ff0000", position: "absolute" }}>
                              {this.state.imageError}
                            </Typography>
                          )}
                        </div>
                    } {
                      this.state.videoIsLoading ?
                        <CircularProgress size={26} color={"primary"} className={classes.mediaLoader} />
                        :
                        this.state.formData.network !== 'linkedin' ?
                          <div className="user-tweetit-form__no-css-rule">
                            <input
                              accept=".mp4,.mov"
                              className={this.props.classes.input}
                              onChange={this.handleFileChange}
                              id={this.MEDIA_VIDEO}
                              name={this.MEDIA_VIDEO}
                              type="file"
                            />
                            <label htmlFor={this.MEDIA_VIDEO}>
                              <IconButton
                                variant="contained" component="span"
                                className={classes.buttonAddImage}
                                disableRipple>
                                <AddVideoIcon />
                              </IconButton>
                            </label>
                            {this.state.videoError && (
                              <Typography style={{ color: "#ff0000", position: "absolute" }}>
                                {this.state.videoError}
                              </Typography>
                            )}
                          </div>
                          : ''
                    }
                  </div>
                )}
              </div>
            )}
            {
              this.state.formData.comment &&
              <div className="user-tweetit-form__container column comment-block">
                <Typography component={"div"} color={"textSecondary"}
                  className={this.props.classes.commentIcon}>
                  <ReactSVG src={commentIcon} />
                  {wording.post.comment}
                </Typography>
                <div className="user-tweetit-form__comment">
                  <Typography color={"textSecondary"}>
                    {this.state.formData.comment}
                  </Typography>
                </div>
              </div>
            }
          </div>
        </Dialog>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  getMediaUrl: PropTypes.func.isRequired,
  fetchOpenGraphAction: PropTypes.func.isRequired,
};

export default withStyles(styles)(FullScreenDialog);