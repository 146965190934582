import React from 'react';
import { connect } from "react-redux";
import * as EmailValidator from 'email-validator';

// Import components
import * as actionCreators from "../actions/actionCreators"
import Onboarding from "../components/onboarding/onboarding"
import history from '../history';

class OnboardinCon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: "loading",
      invite_token: null,
      email_token: null,
      invite: null,
      user: null,
      error: null,
      name: '',
      phone: '',
      email: '',
      isBtn: true,
      checkbox: {
        terms: false
      }
    }

    this.stepContinue = this.stepContinue.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  async componentDidMount() {
    // get token from URL
    let params = new URLSearchParams(history.location.search);
    let invite_token = params.get('t');
    let email_token = params.get('m');

    if (!invite_token && !email_token) {
      history.push('/')
      return;
    }

    // Invite token
    if (invite_token) {
      let res = await this.props.getInviteAction(invite_token)

      if (res && res.error) {
        this.setState({
          step: res.not_valid ? 'not_valid' : 'error',
          error: this.getError(res.error)
        })
        return;

      }

      this.setState({ invite: res, step: 'start', invite_token: invite_token })

      // Email token (to confirm the mail)
    } else if (email_token) {
      let res = await this.props.getUserFromEmailTokenAction(email_token)

      if (res && res.error) {
        this.setState({
          step: res.not_valid ? 'not_valid' : 'error',
          error: this.getError(res.error)
        })
        return;

      }

      this.setState({ email_token: email_token, step: 'email_confirm_valid' })
    }
  }

  async stepContinue(options) {
    let step = this.state.step, isBtn = false

    if (step === 'start') {
      step = this.state.invite.type === 'link' ? 'name' : 'email'
    } else if (step === 'name') {
      step = 'phone'
      isBtn = false
    } else if (step === 'phone') {
      step = process.env.REACT_APP_NAME === 'miciv' ? 'email' : 'rgpd'
      isBtn = false
    } else if (step === 'rgpd') {
      step = 'email'
      isBtn = false
    } else if (step === 'email') {
      step = 'email_confirm'
    } else if (step === 'email_confirm_valid') {
      history.push(`/${history.location.search}`)
    }

    // Send the user info to confirm mail
    if (step === 'email_confirm') {
      await this.setState({ step: 'loading' })

      let res = await this.props.sendConfirmMailAction({
        name: this.state.name,
        phone: this.state.phone,
        email: `${this.state.email}${(this.state.invite && this.state.invite.email_domain) || ''}`,
        invite_token: this.state.invite_token,
        user_id: (this.state.user && this.state.user.id) || null
      })

      this.setState({
        step: res.not_valid ? 'not_valid' : step,
        error: this.getError(res.error),
        user: (res.user_id && { id: res.user_id }) || null
      })
      return
    }

    this.setState({ step, isBtn: isBtn })
  }

  handleChange(type) {
    return (e) => {
      if (type === 'name') {
        this.setState({
          name: e.currentTarget.value,
          isBtn: e.currentTarget.value && (e.currentTarget.value.trim().split(/\s+/).length > 1)
        })

      } else if (type === 'phone') {
        this.setState({
          phone: e,
          isBtn: !!e && (/^[0-9]{5,}$/g.test(e.replace(/\s/g, '')))
        })
      } else if (type === 'email') {

        let email = e.currentTarget.value
        this.setState({
          email: email,
          isBtn: this.isEmailValid(email) && (process.env.REACT_APP_NAME === 'miciv' ? Object.values(this.state.checkbox).every(parameter => parameter === true) : true)
        })
      }
    }
  }

  handleCheck(value) {
    let checkbox = this.state.checkbox
    checkbox[`${value}`] = !checkbox[`${value}`]
    this.setState({ checkbox: checkbox, isBtn: Object.values(checkbox).every(parameter => parameter === true) && (process.env.REACT_APP_NAME === 'miciv' ? this.isEmailValid(this.state.email) : true) })
  }

  getError(err) {
    if (!err) return null

    return typeof (err) === 'string' ? err : JSON.stringify(err)
  }

  isEmailValid(email) {
    return !!email && EmailValidator.validate(`${email}${(this.state.invite && this.state.invite.email_domain) || ''}`)
  }
  render() {

    return (
      <Onboarding
        step={this.state.step}
        error={this.state.error}
        isBtn={this.state.isBtn}
        stepContinue={this.stepContinue}
        handleChange={this.handleChange}
        handleCheck={this.handleCheck}
        name={this.state.name}
        phone={this.state.phone}
        email={this.state.email}
        invite={this.state.invite}
        checkbox={this.state.checkbox}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return state
};

export default connect(mapStateToProps, actionCreators)(OnboardinCon);
