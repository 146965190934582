import React from "react";
import ReactSVG from "react-svg";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { Translation } from 'react-i18next';

// Material
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

// Components
import SelectDialog from "../misc/selectDialog/select-dialog";
import GlobalUtils from "../../utils/global"

// Assets
import searchIcon from "../../assets/images/search.svg";
import deleteIcon from "../../assets/images/wrong.svg";
import defaultUserIcon from "../../assets/images/default_profile_normal.png";

// Material styles
import styles from './material-groups';

class Groups extends React.Component {
  constructor(props) {
    super(props);

    this.addUser = this.addUser.bind(this);
    this.setGroup = this.setGroup.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showCreateForm = this.showCreateForm.bind(this);


    this.state = {
      selectAnchorTop: 0,
      selectAnchorLeft: 0,
      selectWidth: 0,
      selectIsOpen: false,
      users: props.users.map(u => {

        const {
          screenNameThatShouldBeDisplayed,
          profileImageThatShouldBeDisplayed
        } = GlobalUtils.getUserNameAndAvatar({
          user: u
        })

        return Object.assign({
          label: screenNameThatShouldBeDisplayed,
          icon: profileImageThatShouldBeDisplayed
        }, u)
      }),
      user: null,
      group: null,
      groupCopy: null,
      create: false,
      loading: false,
    };

    this.textInput = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.users.length === 0 && this.props.users && this.props.users.length) {
      this.setState({
        users: this.props.users.map(u => {

          // get appropriate screenName and profileImage (twitter > linkedin > default)
          const {
            screenNameThatShouldBeDisplayed,
            profileImageThatShouldBeDisplayed
          } = GlobalUtils.getUserNameAndAvatar({
            user: u
          })

          return Object.assign({
            label: screenNameThatShouldBeDisplayed,
            icon: profileImageThatShouldBeDisplayed
          }, u)
        }),
      })
    }
  }

  setGroup(group, replace = true) {
    let usersCopy = [...group.users];

    this.setState({
      create: false,
      group: group,
    });

    if (replace) {
      this.setState({
        groupCopy: Object.assign({}, group, { label: "", users: usersCopy }),
      });
    }
  }

  openSelectDialog = e => {
    e.preventDefault();
    if (!this.state.groupCopy) {
      return;
    }
    let rect = e.currentTarget.getBoundingClientRect();
    this.setState({
      selectAnchorTop: rect.y,
      selectAnchorLeft: rect.x,
      selectWidth: rect.width,
      selectIsOpen: true,
    });
  };

  closeSelectDialog = () => {
    this.setState({
      selectIsOpen: false,
    });
  };

  async addUser(user) {
    let user_id = user.find(u => u.selected);

    if (!user_id || this.state.groupCopy.users.includes(user_id.id)) {
      return await this.closeSelectDialog();
    }

    let newUsers = [...this.state.groupCopy.users, user_id.id];

    await this.setState({
      groupCopy: Object.assign({}, this.state.groupCopy, { users: newUsers })
    });
    await this.closeSelectDialog();
  }

  async handleSubmit() {
    let group;

    if (!this.state.groupCopy) {
      return;
    }
    if (this.state.groupCopy.label.trim().length === 0) {
      if (this.create) {
        return;
      }
      group = Object.assign({}, this.state.groupCopy, {
        label: this.state.group ? this.state.group.label : ""
      });
    } else {
      group = this.state.groupCopy;
    }

    this.setState({ loading: true });

    await this.props.submitGroup(group);

    this.setState({
      create: false,
      group: null,
      groupCopy: null,
      loading: false,
    });
  }

  async handleDelete() {
    if (this.state.create) {
      this.setState({
        create: false,
        group: null,
        groupCopy: null,
      })
    } else if (this.state.group) {
      this.setState({
        group: null,
        groupCopy: null,
        loading: true,
      });

      await this.props.deleteGroup(this.state.group.id);

      this.setState({ loading: false });
    }
  }

  handleRemove(user_id) {
    this.state.groupCopy.users.splice(this.state.groupCopy.users.indexOf(user_id), 1);
    this.forceUpdate();
  }

  handleChange(e) {
    this.setState({
      groupCopy: Object.assign({}, this.state.groupCopy, { label: e.currentTarget.value })
    })
  }

  async showCreateForm() {
    await this.setState({
      create: true,
      group: null,
      groupCopy: {
        label: "",
        users: [],
      },
    });

    this.textInput.current.focus();
  }

  render() {
    const props = this.props;
    const { classes } = props;

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).settings.groups

            return (
              <div className="groups__root">
                <div className="groups__left">
                  <Fab size={"small"} color="primary" aria-label="Add" className={classes.groupAdd}
                    onClick={this.showCreateForm}
                  >
                    <AddIcon />
                  </Fab>
                  <List component="nav" className={classes.groupList}>
                    {props.groups.map(group =>
                      <ListItem button dense key={group.id} className={classes.groupItem}
                        onClick={() => this.setGroup(group)}
                      >
                        <ListItemText primary={group.label}
                          className={group === this.state.group
                            ? classNames(classes.groupItemText, classes.groupItemTextSelected)
                            : classes.groupItemText
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </div>
                {
                  this.state.groupCopy &&
                  <div className="groups__right">
                    <div className="groups__container">
                      <div className="groups__container align">
                        <Typography color={this.state.group ? "primary" : "textSecondary"}
                          className={classes.groupName}>
                          {
                            this.state.group
                              ? this.state.group.label
                              : this.state.create
                                ? t.new_group
                                : "Aucun groupe sélectionné"
                          }
                        </Typography>
                      </div>
                      <div className="groups__container actions">
                        <Button variant={"contained"} className={classes.action}
                          disabled={this.state.loading}
                          onClick={this.handleDelete}>
                          {
                            this.state.loading
                              ? <CircularProgress size={24} className={classNames(classes.action, classes.buttonProgress)} />
                              : <CloseIcon className={classes.buttonIcon} />
                          }
                          {this.state.create ? t.btn_cancel : t.btn_delete}
                        </Button>
                        <Button variant={"contained"} color={"primary"} className={classes.action}
                          disabled={this.state.loading}
                          onClick={this.handleSubmit}
                        >
                          {this.state.loading
                            ? <CircularProgress size={24} className={classNames(classes.action, classes.buttonProgress)} />
                            : <SaveIcon className={classes.buttonIcon} />
                          }
                          {t.btn_save}
                        </Button>
                      </div>
                    </div>
                    <div className="groups__input-container">
                      <OutlinedInput
                        inputRef={this.textInput}
                        disabled={!this.state.groupCopy}
                        value={this.state.groupCopy ? this.state.groupCopy.label : ""}
                        labelWidth={0}
                        placeholder={t.placeholder_group}
                        name="label"
                        id="label"
                        classes={{
                          notchedOutline: classes.inputOutline,
                          input: this.state.groupCopy
                            ? classes.input
                            : classNames(classes.inputDisabled, classes.input)
                        }}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="groups__input-container">
                      <SelectDialog
                        positionTop={this.state.selectAnchorTop}
                        positionLeft={this.state.selectAnchorLeft}
                        width={this.state.selectWidth}
                        isMultiple={false}
                        items={this.state.users
                          .filter(u => (u.status === "enabled") && u.role === "User")
                          .map(u => Object.assign({
                            selected: !!this.state.groupCopy && !!this.state.groupCopy.users.find(f => f === u.id)
                          }, u))
                        }
                        field={'user'}
                        itemClass={null}
                        containerClass={classes.userList}
                        setItems={this.addUser}
                        open={this.state.selectIsOpen}
                        closeSelectDialog={this.closeSelectDialog}
                      />
                      <OutlinedInput
                        disabled={!this.state.groupCopy}
                        labelWidth={0}
                        placeholder={t.placeholder_member}
                        name="users"
                        id="users"
                        startAdornment={
                          <InputAdornment position="start" className={classes.selectAdornment}>
                            <ReactSVG src={searchIcon} />
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: classes.inputOutline,
                          adornedStart: classes.searchRoot,
                          input: this.state.groupCopy
                            ? classNames(classes.searchInput, classes.input)
                            : classNames(classes.inputDisabled, classes.searchInput, classes.input)
                        }}
                        onClick={this.openSelectDialog}
                      />
                    </div>
                    <div className="groups__no-css-rule">
                      <List component="nav">
                        {
                          this.state.groupCopy
                          && this.state.users.map(user => {

                            const {
                              screenNameThatShouldBeDisplayed,
                              profileImageThatShouldBeDisplayed
                            } = GlobalUtils.getUserNameAndAvatar({ user: user })

                            return (
                              this.state.groupCopy.users.includes(user.id)
                              && <ListItem key={user.id} className={classes.userItem} classes={{
                                container: classes.userItemContainer
                              }}
                              >
                                <Avatar alt={screenNameThatShouldBeDisplayed} src={profileImageThatShouldBeDisplayed} onError={(e) => { e.target.src = defaultUserIcon }} className={classes.avatar} />
                                <ListItemText primary={screenNameThatShouldBeDisplayed} className={classes.userItemText} />
                                <ListItemSecondaryAction classes={{
                                  root: classes.deleteAction
                                }}>
                                  <IconButton aria-label="Delete" className={classes.userDeleteAction}
                                    onClick={() => this.handleRemove(user.id)}
                                  >
                                    <ReactSVG src={deleteIcon} />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </div>
                  </div>
                }
              </div>
            )
          }
        }
      </Translation>
    )
  }
}

Groups.defaultProps = {
  groups: [],
};

Groups.propTypes = {
  classes: PropTypes.object.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object),
  submitGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
};

export default withStyles(styles)(Groups);