import React from 'react'
import PropTypes from "prop-types"

const LinkedinWarning = ({ active, text, x, y}) => (
  <>
    <div
      className={active ? 'linkedin-warn linkedin-warn--active' : 'linkedin-warn'}
      style={{top: y + 'px', left: x + 'px', '--x': x + 'px'}}
    >
      {text.map(paragraph => (
        <p className="linkedin-warn__text">
          { paragraph }
        </p>
      ))}
    </div>
  </>
)

LinkedinWarning.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.array.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
}

export default LinkedinWarning
