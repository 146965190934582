const styles = theme => ({
  // Snackbar success
  snackbar_success: {
    backgroundColor: theme.mintyGreen
  },
  // Snackbar error
  snackbar_error: {
    backgroundColor: theme.grapefruit
  },
})

export default styles