// Libs
import React from 'react'
import { NavLink } from 'react-router-dom'
import i18n from '../../i18n'

// Icons
import IconProfile from '../../assets/icons/profile'
import IconHome from '../../assets/icons/home'
import IconFlux from '../../assets/icons/flux'
import IconLeaderboard from "../../assets/icons/leaderboard"

const wording = i18n.t('user', { returnObjects: true })
class MobileNav extends React.Component {
  render() {
    return (
      <div className="mobile-nav">
        <NavLink exact activeClassName="active color-primary" to="/" className="mobile-nav__link">
          <div className="mobile-nav__link-icon">
            <IconHome />
          </div>
          <span className="mobile-nav__link-text">{wording.nav.home}</span>
        </NavLink>

        {process.env.REACT_APP_FUNCTION_FLUX === 'true' ?
          <NavLink activeClassName="active color-primary" to="/flux" className="mobile-nav__link">
            <div className="mobile-nav__link-icon">
              <IconFlux />
            </div>
            <span className="mobile-nav__link-text">{wording.nav.news}</span>
          </NavLink>
          : ''}

        {process.env.REACT_APP_FUNCTION_LEADERBOARD === 'true' ?
          <NavLink activeClassName="active color-primary" to="/leaderboard" className="mobile-nav__link">
            <div className="mobile-nav__link-icon">
              <IconLeaderboard />
            </div>
            <span className="mobile-nav__link-text">{wording.nav.rank}</span>
          </NavLink>
          : ''}

        <NavLink activeClassName="active color-primary" to="/profil" className="mobile-nav__link">
          <div className="mobile-nav__link-icon">
            <IconProfile />
          </div>
          <span className="mobile-nav__link-text">{wording.nav.profil}</span>
        </NavLink>
      </div>
    )
  }
}

export default MobileNav