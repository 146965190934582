import React from 'react';
import moment from 'moment'
import { CircularProgress } from '@material-ui/core';
import i18n from '../../i18n'

// Components
import IconTwitter from "../../assets/icons/twitter-icon"
import IconLinkedin from "../../assets/icons/linkedin-icon"
import IconCheck from '../../assets/icons/check'
import IconSchedule from "../../assets/icons/schedule.js";
import IconClose from "../../assets/icons/close.js";
import IconSearch from "../../assets/icons/search.js";

// Import utils
import global from '../../utils/global'
import EventEmitter from '../../utils/EventEmitter';
import params from '../../utils/params';

const wording = i18n.t('manager', { returnObjects: true }).analytics.groupes

class analyticsGroups extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      search: ''
    }
  }

  async componentDidMount() {
    let data = await this.props.getAnalyticsGroupsAction()

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = null
    }

    this.setState({ data })
  }

  buildRow() {
    let rows = []
    const class_group = 'background-color-secondary color-primary'

    if (!this.state.data) return rows

    rows = this.state.data.groups.filter(g => (g.label.search(new RegExp(this.state.search, 'i')) !== -1) || !this.state.search).map((g, i) => {
      let posts = g.posts.sort(function (a, b) {
        return moment(b.created_at) - moment(a.created_at)
      });

      return (
        <div className={`analytics-groups__row ${(i % 2) !== 1 ? 'odd' : ''}`} key={i}>
          <div className="analytics-groups__col">
            <span className="analytics-groups__rank">
              {i + 1}
            </span>
            <div className={`analytics-groups__group ${class_group}`}>{g.label}</div>
          </div>
          <div className="analytics-groups__col">
            <span className="analytics-groups__total-user">{g.total_user}</span>
          </div>
          <div className="analytics-groups__col">
            <div className="analytics-groups__network">
              <IconTwitter /> {((g.total_twitter / (g.total_twitter + g.total_linkedin)) * 100).toFixed(0)}%
            </div>
            <div className="analytics-groups__network">
              <IconLinkedin /> {((g.total_linkedin / (g.total_twitter + g.total_linkedin)) * 100).toFixed(0)}%
            </div>
          </div>
          <div className="analytics-groups__col">
            <div className="analytics-groups__status">
              <div className="analytics-groups__status-bloc">
                <div className="analytics-groups__status-icon wait"><IconSchedule /></div>
                {g.total_suggested ? ((g.total_suggested / (g.total_published + g.total_removed + g.total_suggested)) * 100).toFixed(0) : 0}%
              </div>
              <div className="analytics-groups__status-bloc">
                <div className="analytics-groups__status-icon accept"><IconCheck /></div>
                {g.total_published ? ((g.total_published / (g.total_published + g.total_removed + g.total_suggested)) * 100).toFixed(0) : 0}%
              </div>
              <div className="analytics-groups__status-bloc">
                <div className="analytics-groups__status-icon refuse"><IconClose /></div>
                {g.total_removed ? ((g.total_removed / (g.total_published + g.total_removed + g.total_suggested)) * 100).toFixed(0) : 0}%
              </div>
            </div>
          </div>
          <div className="analytics-groups__col">
            <span className="analytics-groups__suggest">{global.giveTimeFromADate(posts[0].created_at)}</span>
          </div>
        </div>
      )
    })
    return rows
  }

  render() {
    if (!this.state.data) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.loading_data}</p> <CircularProgress /></div>

    if (this.state.data && this.state.data.groups && !this.state.data.groups.length) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.empty_data}</p></div>

    return (
      <div className="analytics-groups">
        <div className="analytics-groups__container">
          {/* HEAD */}
          <div className="analytics-groups__head">
            {wording.title} <span className="analytics-groups__head-info">{this.state.data ? this.state.data.groups.length : ''}  {wording.total_groups}</span>
          </div>

          {/* SEARCH BAR */}
          <div className="analytics-groups__search">
            <IconSearch />
            <input className="analytics-groups__search-input" type="text" value={this.state.search} onChange={(e) => this.setState({ search: e.currentTarget.value })} />
          </div>

          {/* TABLE */}
          <div className="analytics-groups__table">
            <div className="analytics-groups__row head">
              <div className="analytics-groups__col">
                {wording.label_group}
              </div>
              <div className="analytics-groups__col">
                {wording.label_users}
              </div>
              <div className="analytics-groups__col">
                {wording.label_network}
              </div>
              <div className="analytics-groups__col">
                {wording.label_accept}
              </div>
              <div className="analytics-groups__col">
                {wording.label_suggest}
              </div>
            </div>
            {this.buildRow()}
          </div>
        </div>
      </div>
    )
  }
}

export default analyticsGroups
