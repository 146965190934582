// Libs
import React from "react"
import i18n from '../../i18n'

// Assets
import IconStar from "../../assets/icons/star"
import confettis from "../../assets/images/confettis.svg"
import emptyFace from "../../assets/images/empty-face.svg"
import defaultUserIcon from "../../assets/images/default_profile_normal.png";

// utils
import global from "../../utils/global";

const wording = i18n.t('user', { returnObjects: true })

let img = "https://www.coe.int/documents/9253022/14570967/arbuste-jaune-forsythia.jpg/9c2ca731-b45e-3638-db96-9c652530031f?t=1585645002000&width=942"

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      period: this.props.users_rank && this.props.users_rank['week'].length ? 'week' : 'all'
    };

    this.modifyPeriod = this.modifyPeriod.bind(this)
  }

  componentDidMount() {
    //this.scrollIntoItemUser()
  }

  componentDidUpdate() {
    //this.scrollIntoItemUser()
  }

  /* scrollIntoItemUser() {
        let el = document.querySelector('.leaderboard__list-item.is-me')

        if (el) el.scrollIntoView()
    }*/

  modifyPeriod(value) {
    return (e) => {
      this.setState({ period: value })
      return
    }
  }

  render() {
    let users_rank = this.props.users_rank ? Object.assign({}, this.props.users_rank) : this.props.users_rank

    if (users_rank && users_rank[`${this.state.period}`].length) {
      let index_user = users_rank[`${this.state.period}`].map((v, i) => { return v.user.id }).indexOf(this.props.user.id)

      if (index_user > 10) users_rank[`${this.state.period}`] = users_rank[`${this.state.period}`].slice(index_user - 10)

      index_user = users_rank[`${this.state.period}`].map((v, i) => { return v.user.id }).indexOf(this.props.user.id)

      if (users_rank[`${this.state.period}`].length > 21) users_rank[`${this.state.period}`] = users_rank[`${this.state.period}`].slice(0, index_user + 10)
    }

    return (
      <div className="leaderboard" style={{ background: `url('${confettis}')` }}>

        {/* Nav */}
        <nav className="leaderboard__nav">
          <button className={`color-primary leaderboard__nav-link ${this.state.period === "week" ? "active" : ""}`} onClick={this.modifyPeriod("week")}>{wording.rank.tab_week}</button>
          <button className={`color-primary leaderboard__nav-link ${this.state.period === "all" ? "active" : ""}`} onClick={this.modifyPeriod("all")}>{wording.rank.tab_total}</button>
        </nav>

        {/* Top 3 */}
        <div className="leaderboard__ranking">
          {this.props.users_rank && this.props.users_rank[`${this.state.period}`].length ?
            this.props.users_rank[`${this.state.period}`].slice(0, 3).map((rank, index) => {
              index = index + 1

              let rankingPosition = "rank-" + index
              const { screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed } = global.getUserNameAndAvatar({ user: rank.user })

              return (
                <div key={index} className={`leaderboard__ranking-item ${rankingPosition}`}>
                  <div className="leaderboard__ranking-item-avatar-container">
                    <img src={profileImageThatShouldBeDisplayed} alt="" className="leaderboard__ranking-item-avatar" />
                    <div className={`leaderboard__ranking-item-position ${rankingPosition}`}>{index}</div>
                  </div>
                  <div className="leaderboard__ranking-item-fullname">{screenNameThatShouldBeDisplayed}</div>
                  <div className="leaderboard__ranking-item-score">
                    {rank.total}
                    <IconStar />
                  </div>
                </div>
              )
            })
            : ''}
        </div>


        {/* List */}
        <div className="leaderboard__list">
          {/* Empty state */}
          {users_rank && !users_rank[`${this.state.period}`].length ?
            <div className="leaderboard__empty">
              <img alt="empty" src={emptyFace} />
              <p className="leaderboard__empty-title">
                Pas encore de classement
              </p>
            </div>
            : ''}
          <div className="leaderboard__list-scroll">
            {
              users_rank && users_rank[`${this.state.period}`].length ?
                users_rank[`${this.state.period}`].map((rank, index) => {

                  let isMe = rank.user.id === this.props.user.id
                  const { screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed } = global.getUserNameAndAvatar({ user: rank.user })

                  return (
                    <div key={index} className={`leaderboard__list-item ${isMe ? "is-me" : ""}`}>
                      <div className="leaderboard__list-item-position">{this.props.users_rank[`${this.state.period}`].map((v, i) => { return v.user.id }).indexOf(rank.user.id) + 1}</div>
                      <img className="leaderboard__list-item-avatar" src={profileImageThatShouldBeDisplayed} onError={(e) => { e.target.src = defaultUserIcon }} alt="" />
                      <div className="leaderboard__list-row">
                        <div className={`leaderboard__list-item-fullname ${isMe ? "color-primary" : ""}`}>
                          {`${screenNameThatShouldBeDisplayed} ${isMe ? "(" + wording.rank.me + ")" : ""}`}
                        </div>
                      </div>
                      <div className={`leaderboard__list-item-score ${isMe ? "color-primary" : ""}`}>
                        {rank.total}
                        <IconStar />
                      </div>
                    </div>
                  )
                })
                : ''}
          </div>
        </div>
      </div>
    )
  }
}

export default Leaderboard