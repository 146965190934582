const styles = theme => ({
  rootGrid: {
    flexGrow: 1,
    margin: 0,
    top: 0,
    width: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    overflowScrolling: "touch",
    WebkitOverflowScrolling: "touch",
    height: "100%",
    padding: "0 20px",
    [theme.breakpoints.up('md')]: {
      padding: "0 83px 0 83px",
    },
    "& > div:first-child": {
      maxWidth: "100%",
      width: "100%",
    }
  },
  title: {
    margin: "20px 0",
    fontSize: 15,
    fontWeight: 700,
    color: "rgb(134, 161, 178)",
  },
  scrollbarContent: {
    margin: 0,
    width: "100%",
    "& > div:first-child": {
      width: "100%",
    }
  },
  scrollbarItem: {
    padding: "10px 0px !important",
    //maxWidth: 350,
    width: "100%",
  },
  buttonAddTweetit: {
    padding: "12px 0",
    margin: "11px 2px 5px 2px",
    fontSize: 16,
    fontWeight: 700,
    boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.2)",
  },
  AddCircleIcon: {
    color: "white",
    padding: "0 20px 0 0",
    height: 16,
    width: 16,
  },
  loadingScroll: {
    height: 100,
    paddingTop: "20px !important",
    margin: "0 20px",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  }
});

export default styles;