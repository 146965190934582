import React from 'react';

function IconCheckbox() {
  return (
    <svg className="icon icon__checkbox" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="14" height="14" rx="2.5" fill="white" stroke="#C3D0D7" />
    </svg>
  )
}

export default IconCheckbox