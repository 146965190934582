import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  content: {
    // padding: 10,
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    border: `solid 1px ${theme.cloudyBlue}`
  },
  inputAdornment: {
    marginTop: 4,
    marginLeft: 6,
  },
  selectDialogInput: {
    borderColor: [theme.greyishBrown, "!important"],
  },
  emptyText: {
    color: theme.cloudyBlue,
    fontSize: 15,
    fontWeight: 600,
  },
  container: {
    maxHeight: 250,
    overflowY: "auto",
    padding: "10px 0",
    position: "relative",
    top: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    borderRadius: 5,
  },
  subList: {
    margin: "5px 20px 15px",
  },
  listSubheader: {
    padding: "0 !important",
    marginBottom: 5,
    lineHeight: "18px",
    textTransform: "uppercase",
    fontSize: 13,
    fontWeight: 600,
    color: "#86a1b2"
  },
  listItemContainer: {
    "&:hover": {
      "& $deleteAction": {
        display: "initial",
      },
    },
  },
  listItemMultiple: {
    padding: "5px 0 !important"
  },
  listItem: {
    padding: "5px 20px !important"
  },
  listItemText: {
    padding: "0 0 0 10px",
    fontSize: 14,
    color: "#697e8b"
  },
  listItemTextSelected: {
    padding: "0 0 0 10px",
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  listItemTextSingle: {
    paddingLeft: [16, "!important"],
  },
  checkbox: {
    padding: "0 10px 0 0",
    color: "#c3d0d7"
  },
  avatar: {
    width: 30,
    height: 30
  },
  deleteAction: {
    display: "none",
    marginTop: 2,
    marginRight: 29,
    cursor: "pointer",
  },
  addAction: {
    borderTop: "solid 1px #d1dbe1",
    marginTop: 10,
    padding: "15px 20px 5px",
    cursor: "default",
  },
  addActionActive: {
    cursor: "pointer",
  },
  addIcon: {
    fontSize: 18,
    marginRight: 8,
    color: theme.tagChip.color,
  },
  addIconDisabled: {
    color: theme.greyBlue,
  },
  addText: {
    color: theme.tagChip.color,
  },
  addTextDisabled: {
    color: '#86a1b2',
  }
});

export default styles