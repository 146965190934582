const styles = theme => ({
  tooltipTitle: {
    textAlign: "center",
    display: "flex",
    alignItems: "center"
  },
  tooltipUser: {
    maxHeight: 400,
    overflow: "hidden auto",
    pointerEvents: "unset",
    opacity: 1
  },
  tooltip: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    lineHeight: 1.5,
    padding: 10,
    width: 250
  },
  tooltipContainer: {
    right: "-8px",
    position: "absolute",
    transform: "rotate(90deg)"
  },
  progress: {
    margin: "0 10px 0 0",
    color: "#fff"
  },
});

export default styles;