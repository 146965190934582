import React from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment'
import styles from "./material-analytics"
import i18n from '../../i18n'

// Components
import Filter from './../misc/filter/filter'
import DatePicker from './../misc/datePicker/datePicker'
import TweetCard from './../misc/tweetCard/tweet-card';

// Assets
import IconTwitter from "../../assets/icons/twitter-icon"
import IconLinkedin from "../../assets/icons/linkedin-icon"
import IconFacebook from "../../assets/icons/facebook"
import IconInstagram from "../../assets/icons/instagram"
import IconCheck from '../../assets/icons/check'
import IconSchedule from "../../assets/icons/schedule.js";
import IconClose from "../../assets/icons/close.js";
import arrow from "../../assets/images/arrow.svg"

// Import utils
import global from '../../utils/global'
import params from '../../utils/params'
import EventEmitter from '../../utils/EventEmitter';

const wording = i18n.t('manager', { returnObjects: true }).analytics.content
moment.locale(i18n.language)

class analyticsContents extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      filters: null,
      date: null,
      index_row_popin: null,
      loading: false
    }

    this.applyFilter = this.applyFilter.bind(this)
    this.applyDate = this.applyDate.bind(this)
    this.getData = this.getData.bind(this)
    this.rowOver = this.rowOver.bind(this)
    this.rowLeave = this.rowLeave.bind(this)
  }

  async componentDidMount() {
    let data = { posts: [] }, new_data = null, offset = 0
    await this.setState({ loading: true })

    while (true) {
      new_data = await this.props.getAnalyticsContentsAction({ offset: offset })
        .catch((err) => { return { error: err } })

      if (new_data && new_data.error) {
        EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: new_data.error, style: "error" })
        break
      }

      if (new_data && !new_data.error && new_data.posts && !new_data.posts.length) break

      data.posts = data.posts.concat(new_data.posts)

      await this.setState({ data })

      offset = offset + 25

    }
    this.setState({ loading: false, data })

  }

  rowLeave(e) {
    this.setState({ index_row_popin: null })
  }

  async applyFilter(filters) {
    await this.setState({ filters })
  }

  async applyDate(date) {
    await this.setState({ date })
  }

  getData() {
    let data = Object.assign({}, this.state.data)
    let filters = this.state.filters
    let date = this.state.date

    if (filters && filters.group) {
      data.posts = data.posts.filter(p => p.groups.some(g => filters.group.includes(g.id)))
    }

    if (filters && filters.network) {
      data.posts = data.posts.filter(p => filters.network.includes(p.network))
    }

    if (filters && filters.tag) {
      data.posts = data.posts.filter(p => filters.tag.includes(p.tag_id))
    }

    if (date && date.from && date.to) {
      data.posts = data.posts.filter(p => moment(p.submitted_at).isBetween(date.from, date.to))
    }

    if (filters && filters.name) {
      data.posts = data.posts.filter(p =>
        (p.target.users && p.target.users.length && p.target.users.some(u => global.getUserNameAndAvatar({ user: u }).screenNameThatShouldBeDisplayed && global.getUserNameAndAvatar({ user: u }).screenNameThatShouldBeDisplayed.search(new RegExp(filters.name, 'i')) !== -1)) ||
        (p.target.groups && p.target.groups.length && p.target.groups.some(g => g.users.some(u => global.getUserNameAndAvatar({ user: u }).screenNameThatShouldBeDisplayed && global.getUserNameAndAvatar({ user: u }).screenNameThatShouldBeDisplayed.search(new RegExp(filters.name, 'i')) !== -1)))
      )
    }

    return data.posts
  }

  rowOver(e) {
    this.setState({ index_row_popin: e.currentTarget.getAttribute('data-index') })
  }

  rowOut() {
    this.setState({ index_row_popin: null })
  }

  formatUploadUrl(media, originalSize) {
    if (!media || !media.uploadcare_id) {
      return null;
    }

    if (media.type === params.mediaTypes.VIDEO || media.type.includes('video')) {
      return `https://ucarecdn.com/${media.uploadcare_id}/video.mp4`;
    }

    return `https://ucarecdn.com/${media.uploadcare_id}/${originalSize ? '' : '-/resize/x200/'}`;
  }

  buildRow() {
    let rows = [], post_card = null
    const class_group = 'background-color-secondary color-primary'

    if (!this.state.data) return rows

    rows = this.getData().map((p, i) => {

      if (`${this.state.index_row_popin}` === `${i}`) {
        post_card = (
          <div className="analytics-contents__post" >
            <TweetCard
              showHeader={true}
              id={p.id}
              user={this.props.user}
              users={this.props.users}
              user_id={p.user_id}
              getMediaUrl={this.formatUploadUrl}
              media={p.media}
              mediaType={p.media && p.media.length > 0 ? p.media[0].type : params.mediaTypes.IMAGE}
              message={p.message ? p.message : ""}
              comment={p.comment ? p.comment : ""}
              created_at={p.created_at}
              updated_at={p.updated_at}
              scheduled_at={p.scheduled_at}
              submitted_at={p.submitted_at}
              notify_users={p.notify_users}
              groups={p.groups ? p.groups : []}
              references={p.children ? p.children : []}
              orphans={p.orphans}
              share_url={p.share_url ? p.share_url : null}
              share_ref={p.share_ref ? p.share_ref : null}
              tag={p.tag ? [p.tag] : []}
              type={p.type}
              status={p.status}
              getFileInfoUploadCare={this.props.getFileInfoUploadCare}
              isSubmitable={false}
              isEditable={false}
              isRemovable={false}
              isScheduled={false}
              isSelected={this.state.selectedTweetit ? this.state.selectedTweetit.id === p.id : false}
              updated_by={p.updated_by}
              isUser={false}
              network={p.network}
              enlargeMedia={this.props.enlargeMedia}
            />
          </div>
        )
      } else {
        post_card = null
      }
      return (
        <div className={`analytics-contents__row ${(i % 2) !== 1 ? 'odd' : ''}`} key={i}>

          {/* popin post card */}
          {post_card ? post_card : ''}

          <div className="analytics-contents__col cursor" data-index={i} onMouseOver={this.rowOver}>
            <span className="analytics-contents__rank">
              {i + 1}
            </span>
            <div className="analytics-contents__message">
              {p.message || p.share_url}
            </div>
          </div>
          <div className="analytics-contents__col">
            <div className="analytics-contents__submit">
              {moment(p.submitted_at || p.created_at).lang(i18n.language).format('Do MMMM YYYY')}
            </div>
          </div>
          <div className="analytics-contents__col">
            {p.network === 'twitter' ? <IconTwitter /> : p.network === 'linkedin' ? <IconLinkedin /> : p.network === 'instagram' ? <IconInstagram /> : p.network === 'facebook' ? <IconFacebook /> : ''}
          </div>
          <div className="analytics-contents__col">
            {p.target.groups.length > 1 || p.target.users.length > 1 ?
              <Tooltip
                interactive
                classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                placement="left"
                title={
                  <div className={this.props.classes.tooltipTitle}>
                    <div className={this.props.classes.tooltipContainer}>
                      <img alt="arrow" src={arrow} />
                    </div>
                    <div className="analytics-contents__tooltip-outer">
                      {p.target.groups.length ?
                        <p>
                          Groupes : {p.target.groups.map(g => g.label).join(', ')}
                        </p>
                        : ''}

                      {p.target.users.length ?
                        <p>
                          Utilisateurs : {p.target.users.map(u => global.getUserNameAndAvatar({ user: u }).screenNameThatShouldBeDisplayed).join(', ')}
                        </p>
                        : ''}
                    </div>
                  </div>
                }
              >
                <div className="analytics-contents__tooltip-inner">
                  {p.target.groups[0] ? <div className={`analytics-contents__group ${class_group}`}>{p.target.groups[0].label}</div> : ''}
                  {p.target.groups[1] ? <div className={`analytics-contents__group ${class_group}`}>+{p.target.groups.length - 1}</div> : ''}
                  <br />
                  <div className="analytics-contents__people">
                    {p.target.users[0] ? global.getUserNameAndAvatar({ user: p.target.users[0] }).screenNameThatShouldBeDisplayed : ''}
                    {p.target.users[1] ? ` et ${p.target.users.length - 1} membre${p.target.users.length - 1 > 1 ? 's' : ''}` : ''}
                  </div>
                </div>
              </Tooltip>
              :
              <>
                {p.target.groups[0] ? <div className={`analytics-contents__group ${class_group}`}>{p.target.groups[0].label}</div> : ''}
                <br />
                <div className="analytics-contents__people">
                  {p.target.users[0] ? global.getUserNameAndAvatar({ user: p.target.users[0] }).screenNameThatShouldBeDisplayed : ''}

                  {/* If there is no groups and no users*/}
                  {!p.target.groups.length && !p.target.users.length ? 'Utilisateur / Groupe supprimé' : ''}
                </div>
              </>
            }

          </div>
          <div className="analytics-contents__col">
            <div className="analytics-contents__status">
              <div className="analytics-contents__status-bloc">
                <div className="analytics-contents__status-icon wait"><IconSchedule /></div>
                {((p.total_suggested / (p.total_published + p.total_removed + p.total_suggested)) * 100).toFixed(0)}%
              </div>
              <div className="analytics-contents__status-bloc">
                <div className="analytics-contents__status-icon accept"><IconCheck /></div>
                {((p.total_published / (p.total_published + p.total_removed + p.total_suggested)) * 100).toFixed(0)}%
              </div>
              <div className="analytics-contents__status-bloc">
                <div className="analytics-contents__status-icon refuse"><IconClose /></div>
                {((p.total_removed / (p.total_published + p.total_removed + p.total_suggested)) * 100).toFixed(0)}%
              </div>
            </div>
          </div>

        </div>
      )
    })

    return rows
  }

  render() {
    if (!this.state.data) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.loading_data}</p> <CircularProgress /></div>

    if (this.state.data && this.state.data.posts && !this.state.data.posts.length) return <div className="analytics__loading"> <p>Pas de donnée à afficher</p></div>

    return (
      <div className="analytics-contents">
        <div className="analytics-contents__container">
          {/* FILTER + DAYPICKER */}
          <div className="analytics-contents__head">
            <Filter filters={['group', 'tag', 'network', 'name']} groups={this.props.groups} tags={this.props.tags} applyFilter={this.applyFilter} />
            <DatePicker applyDate={this.applyDate} />
          </div>

          {/* TABLE */}
          <div className="analytics-contents__table" onMouseLeave={this.rowLeave}>
            <div className="analytics-contents__row head">
              <div className="analytics-contents__col">
                {wording.label_content}
              </div>
              <div className="analytics-contents__col">
                {wording.label_submit_date}
              </div>
              <div className="analytics-contents__col">
                {wording.label_network}
              </div>
              <div className="analytics-contents__col">
                {wording.label_audience}
              </div>
              <div className="analytics-contents__col">
                {wording.label_accept}
              </div>
            </div>
            {this.buildRow()}
            {this.state.loading ? <div className="analytics-contents__loading"> <CircularProgress /> </div> : ''}
          </div>

        </div>
      </div>
    )
  }
}

export default withStyles(styles)(analyticsContents)
