import React from 'react';

class Mentions extends React.Component {
  render() {
    return (
      <div className="mentions">
        <div className="grid">
          <h1 className="mentions__title">Mentions légales</h1>
          <h2 className="mentions__subtitle">1. Éditeur</h2>
          <p className="mentions__text">Conformément aux dispositions de l’article 6-I 1° de la loi n°2004-575 du 21 juin 2004 relative à la confiance dans l’économie numérique, l’éditeur du site tweetit.io est : <span className="bold"> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France</span>, société par actions simplifiée, au capital de 1 994 899 667,21 euros, immatriculée au registre du commerce et des sociétés de Caen, sous le numéro 672 050 085, dont le siège social est situé ZI Route de Paris 14120 MONDEVILLE.</p>
          <p className="mentions__text">Numéro de téléphone : 02 31 70 60 88  (prix d'un appel local depuis un poste fixe)</p>
          <p className="mentions__text">Adresse électronique : reseaux_sociaux@{process.env.REACT_APP_CLIENT_NAME ? process.env.REACT_APP_CLIENT_NAME.replace(/\s/g, '') : 'societe'}.com</p>
          <h2 className="mentions__subtitle">2. Directeur de la publication</h2>
          <p className="mentions__text">Le directeur de la publication du site tweetit.io  est Florence Lepany Duval en qualité de Directrice adjointe de la communication de la société  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span></p>
          <h2 className="mentions__subtitle">3. Hébergement</h2>
          <p className="mentions__text">Le prestataire assurant le stockage direct et permanent du site tweetit.io est la société AWS,  Legal Department 410 Terry Avenue North P.O. Box 81226 Seattle, WA 98108-1226 - USA http://aws.amazon.com</p>
        </div>
      </div>
    )
  }
}


export default Mentions;
