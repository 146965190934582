import React from 'react';

// Init component.
function IconFacebookBg(props) {
  return (
    <svg className="icon__facebook-bg" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#4267B2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.7067 21.6667V15.5924H11.6667V13.2142H13.7067V11.4688C13.7067 9.43964 14.946 8.33345 16.7613 8.33345C17.372 8.33166 17.9823 8.36298 18.5897 8.42727V10.5393H17.3373C16.3533 10.5393 16.1613 11.0084 16.1613 11.6957V13.2099H18.5155L18.21 15.5881H16.1613V21.6667H13.7067Z" fill="white" />
    </svg>
  );
}

export default IconFacebookBg;