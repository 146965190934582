import React from 'react';
import { CircularProgress } from '@material-ui/core';
import i18n from '../../i18n'

// Assets
import IconTwitter from "../../assets/icons/twitter-icon"
import IconLinkedin from "../../assets/icons/linkedin-icon"
import IconFacebook from "../../assets/icons/facebook"
import IconInstagram from "../../assets/icons/instagram"
import IconCheck from '../../assets/icons/check'
import IconSchedule from "../../assets/icons/schedule.js";
import IconClose from "../../assets/icons/close.js";
import IconArrow from "../../assets/icons/arrow.js";

// Utils 
import EventEmitter from '../../utils/EventEmitter';
import params from '../../utils/params';

const wording = i18n.t('manager', { returnObjects: true }).analytics.networks

class analyticsNetworks extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      label_dropdown: [
        wording.newsletter.dropdown.last_week,
        wording.newsletter.dropdown.last_month,
        wording.newsletter.dropdown.last_year
      ],
      label_index: {
        [wording.newsletter.dropdown.last_week]: "week",
        [wording.newsletter.dropdown.last_month]: "month",
        [wording.newsletter.dropdown.last_year]: "year"
      },
      isOpen: false,
      select: wording.newsletter.dropdown.last_week
    }

    this.dropdownRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.selectPeriod = this.selectPeriod.bind(this)
  }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    let data = await this.props.getAnalyticsNetworksAction({ period: this.state.label_index[this.state.select] })

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = null
    }

    this.setState({ data })
  }


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (this.state.isOpen && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      this.setState({ isOpen: false })
    }
  }

  selectPeriod(period) {
    return async (e) => {
      await this.setState({ select: period, isOpen: false })
      let data = await this.props.getAnalyticsNetworksAction({ period: this.state.label_index[this.state.select] })

      if (data && data.error) {
        EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
        data = this.state.data
      }

      this.setState({ data })
    }
  }

  getPercent(n, d) {
    let value = 0
    if (d) value = n / d
    return Math.round(value * 100)
  }

  buildRowSuggest() {
    let rows = []

    if (!this.state.data) return rows

    let i = 0, net = this.state.data.suggests

    for (let n in net) {
      let icon = {
        'twitter': <IconTwitter />,
        'linkedin': <IconLinkedin />,
        'instagram': <IconInstagram />,
        'facebook': <IconFacebook />
      }

      rows.push(
        <div className={`analytics-networks__row ${(i % 2) !== 1 ? 'odd' : ''} suggest`} key={i}>
          <div className="analytics-networks__col stat">
            <span className="analytics-networks__rank">
              {i + 1}
            </span>
            {icon[n]} {n}
          </div>
          <div className="analytics-networks__col">
            {['twitter', 'linkedin'].includes(n) ?
              <div className="analytics-networks__status">
                <div className="analytics-networks__status-bloc">
                  <div className="analytics-networks__status-icon wait"><IconSchedule /></div>
                  {this.getPercent(net[n].suggested, (net[n].suggested + net[n].removed + net[n].published))}%
                </div>
                <div className="analytics-networks__status-bloc">
                  <div className="analytics-networks__status-icon accept"><IconCheck /></div>
                  {this.getPercent(net[n].published, (net[n].suggested + net[n].removed + net[n].published))}%
                </div>
                <div className="analytics-networks__status-bloc">
                  <div className="analytics-networks__status-icon refuse"><IconClose /></div>
                  {this.getPercent(net[n].removed, (net[n].suggested + net[n].removed + net[n].published))}%
                </div>
              </div>
              : ''}
          </div>
          <div className="analytics-networks__col stat">
            {net[n].total_users}
          </div>
          <div className="analytics-networks__col stat">
            {net[n].suggest_from_manager}
          </div>
        </div>
      )
      i++
    }

    return rows
  }

  buildRowFlux() {
    let icon = {
      'twitter': <IconTwitter />,
      'linkedin': <IconLinkedin />,
      'instagram': <IconInstagram />,
      'facebook': <IconFacebook />
    }


    if (!this.state.data) return []

    return this.state.data.flux.map((f, i) => {
      return (
        <div className={`analytics-networks__row ${(i % 2) !== 1 ? 'odd' : ''} flux`} key={i}>
          <div className="analytics-networks__col stat">
            <span className="analytics-networks__rank">
              {i + 1}
            </span>
            {icon[f.network]} {f.display_name}
          </div>
          <div className="analytics-networks__col stat">
            {f.total_users}
          </div>
          <div className="analytics-networks__col stat">
            {(f.percent_click * 100).toFixed(2)}%
          </div>
        </div>
      )
    })

  }

  buildRowNewsletter() {
    let rows = []

    for (let i = 0; i < 1; i++) {
      rows.push(
        <div className={`analytics-networks__row ${(i % 2) !== 1 ? 'odd' : ''} Newsletter`}>
          <div className="analytics-networks__col">
            <span className="analytics-networks__rank">
              1
            </span>
            Weekly digest
          </div>
          <div className="analytics-networks__col">
            230
          </div>
          <div className="analytics-networks__col">
            4%
          </div>
          <div className="analytics-networks__col">
            20%
          </div>
        </div>
      )
    }
    return rows
  }

  render() {

    if (!this.state.data) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.loading_data}</p> <CircularProgress /></div>

    return (
      <div className="analytics-networks">
        <div className="analytics-networks__container">

          {/* TABLE SUGGEST */}
          <div className="analytics-networks__table">
            <div className="analytics-networks__table-title">
              {wording.suggest.title}
            </div>
            <div className="analytics-networks__row head suggest">
              <div className="analytics-networks__col">
                {wording.suggest.label_network}
              </div>
              <div className="analytics-networks__col">
                {wording.suggest.label_accept}
              </div>
              <div className="analytics-networks__col">
                {wording.suggest.label_users}
              </div>
              <div className="analytics-networks__col">
                {wording.suggest.label_suggest}
              </div>
            </div>
            {this.buildRowSuggest()}
          </div>

          {/* TABLE FLUX */}
          <div className="analytics-networks__table">
            <div className="analytics-networks__table-title">
              {wording.flux.title}
            </div>
            <div className="analytics-networks__row head flux">
              <div className="analytics-networks__col">
                {wording.flux.label_account}
              </div>
              <div className="analytics-networks__col">
                {wording.flux.label_sub}
              </div>
              <div className="analytics-networks__col">
                {wording.flux.label_clicks}
              </div>
            </div>
            {this.buildRowFlux()}
          </div>

          {/* TABLE NEWSLETTER */}
          <div className="analytics-networks__table">
            <div className="analytics-networks__table-header">
              <div className="analytics-networks__table-title">
                {wording.newsletter.title}
              </div>

              <div className="analytics-networks__dropdown" ref={this.dropdownRef}>
                <div className={`analytics-networks__dropdown-item ${this.state.isOpen ? 'first' : ''}`} onClick={() => this.setState({ isOpen: true })}>
                  {this.state.select} <IconArrow />
                </div>

                <div className="analytics-networks__dropdown-choice">
                  {this.state.isOpen ?
                    this.state.label_dropdown.filter(l => l !== this.state.select).map((v, i) => {
                      return (
                        <div className={`analytics-networks__dropdown-item ${i === 1 ? 'last' : 'middle'}`} key={i} onClick={this.selectPeriod(v)}>
                          {v}
                        </div>
                      )
                    })
                    : ''}
                </div>
              </div>
            </div>

            <div className="analytics-networks__row head suggest">
              <div className="analytics-networks__col">
                {wording.newsletter.label_nl}
              </div>
              <div className="analytics-networks__col">
                {wording.newsletter.label_sub}
              </div>
              <div className="analytics-networks__col">
                {wording.newsletter.label_open}
              </div>
              <div className="analytics-networks__col">
                {wording.newsletter.label_clicks}
              </div>
            </div>
            <div className="analytics-networks__row odd suggest">
              <div className="analytics-networks__col stat">
                Dernières actualités
              </div>
              <div className="analytics-networks__col stat">
                {this.state.data && this.state.data.total_users_flux ? this.state.data.total_users_flux : 0}
              </div>
              <div className="analytics-networks__col stat weight">
                {this.state.data && this.state.data.total_nl_open ? this.state.data.total_nl_open : 0}%
              </div>
              <div className="analytics-networks__col stat weight color-primary-i">
                {this.state.data && this.state.data.total_nl_post_click ? this.state.data.total_nl_post_click : 0}%
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default analyticsNetworks
