// Libs
import React from "react"
import PerfectScrollbar from 'react-perfect-scrollbar';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import i18n from '../../../i18n'

// Assets
import TwitterBgIcon from '../../../assets/icons/twitterBg';
import LinkedinBgIcon from '../../../assets/icons/linkedinBg';
import IconCheckbox from '../../../assets/icons/checkbox';
import IconCheckboxChecked from '../../../assets/icons/checkbox-checked';
import IconCrossDeleteIcon from '../../../assets/icons/cross-delete-icon';
import global from "../../../utils/global";

const wording = i18n.t('manager', { returnObjects: true }).analytics.filter

class Filter extends React.Component {

  constructor(props) {
    super()
    this.state = {
      dropdown: { group: false, tag: false, filter: false },
      selected: { group: [], tag: [], network: [], name: '' },
      filters_selected: [],
      filter_active: null,
      filter_label: { group: wording.group, tag: wording.thematic, network: wording.network, name: wording.name }
    }

    this.openDropdown = this.openDropdown.bind(this)
    this.openFilterChoice = this.openFilterChoice.bind(this)
    this.selectName = this.selectName.bind(this)
    this.applyFilter = this.applyFilter.bind(this)
  }

  applyFilterDebounced = AwesomeDebouncePromise(this.applyFilter.bind(this), 700);

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    let isOutside = false
    try {
      isOutside = !event.target.classList[0].includes('filter__category') && !event.target.classList[0].includes('choice-filter')

    } catch (e) {
      isOutside = false
    }
    // If we click outside.
    if (!Object.values(this.state.dropdown).every(key => key === false) && isOutside) {
      let dropdown = this.state.dropdown
      // Reset dropdown
      Object.keys(dropdown).forEach(v => dropdown[v] = false)
      this.setState({ dropdown })
    }
  }

  openDropdown(e) {
    e.stopPropagation()
    const category = e.currentTarget.getAttribute('data-category')

    let dropdown = this.state.dropdown
    // Reset dropdown
    Object.keys(dropdown).forEach(v => dropdown[v] = false)
    dropdown[category] = true
    this.setState({ dropdown })
  }

  openFilterChoice() {
    if (this.state.filters_selected.length === this.props.filters.length) return
    let dropdown = this.state.dropdown
    dropdown.filter = true
    this.setState({ dropdown })
  }

  addFilter(filter) {
    return (e) => {
      e.stopPropagation()
      let filters_selected = this.state.filters_selected
      let dropdown = this.state.dropdown

      if (['group', 'tag'].includes(filter)) dropdown[`${filter}`] = true

      dropdown.filter = false
      filters_selected.push(filter)
      this.setState({ filters_selected, dropdown })
    }
  }

  removeFilter(filter) {
    return (e) => {
      e.stopPropagation()
      let filters_selected = this.state.filters_selected
      let selected = this.state.selected
      selected[filter] = filter === 'name' ? '' : []

      filters_selected = filters_selected.filter(f => f !== filter)

      this.setState(
        { filters_selected }, () => {
          if (this.props.disableDelay) this.applyFilter()
          else this.applyFilterDebounced()
        }
      )
    }
  }

  applyFilter() {
    let selected = Object.assign({}, this.state.selected)
    selected.group = selected.group ? selected.group.map(g => g.id) : selected.group
    selected.tag = selected.tag ? selected.tag.map(g => g.id) : selected.tag

    for (let s in selected) {
      if ((Array.isArray(selected[s]) && !selected[s].length) || !selected[s]) delete selected[s]
    }
    this.props.applyFilter(Object.keys(selected).length ? selected : null)
  }

  selectGroup(group) {
    return (e) => {
      let selected = this.state.selected

      if (selected.group.some(g => g.id === group.id)) {
        selected.group = selected.group.filter(g => g.id !== group.id)

      } else {
        selected.group.push(group)
      }
      this.setState(
        { selected }, () => {
          if (this.props.disableDelay) this.applyFilter()
          else this.applyFilterDebounced()
        }
      )
    }
  }

  selectTag(tag) {
    return (e) => {
      let selected = this.state.selected

      if (selected.tag.some(t => t.id === tag.id)) {
        selected.tag = selected.tag.filter(t => t.id !== tag.id)

      } else {
        selected.tag.push(tag)
      }
      this.setState(
        { selected }, () => {
          if (this.props.disableDelay) this.applyFilter()
          else this.applyFilterDebounced()
        }
      )
    }
  }

  selectNetwork(network) {
    return (e) => {
      e.stopPropagation()
      let selected = this.state.selected
      if (selected.network.includes(network)) selected.network = selected.network.filter(n => n !== network)
      else selected.network.push(network)

      this.setState(
        { selected }, () => {
          if (this.props.disableDelay) this.applyFilter()
          else this.applyFilterDebounced()
        }
      )
    }
  }

  selectName(e) {
    let selected = this.state.selected
    selected.name = e.currentTarget.value
    this.setState(
      { selected }, () => {
        if (this.props.disableDelay) this.applyFilter()
        else this.applyFilterDebounced()
      }
    )
  }

  buildGroups(groups) {
    const class_group = 'background-color-secondary color-primary'

    let ids_selected = this.state.selected.group.map(g => g.id)

    let groups_list = groups.map(g => {
      return (
        <div className="filter__category-group" onClick={this.selectGroup(g)}>
          {!ids_selected.includes(g.id) ? <IconCheckbox /> : <IconCheckboxChecked />}

          <div className={`filter__category-group-item ${class_group}`} >
            {g.label}
          </div>
        </div>
      )
    })

    let groups_selected = this.state.selected.group[0] ? [<div className={`filter__category-group-item ${class_group}`}>{this.state.selected.group[0].label} <span>{this.state.selected.group[0].users.length}</span></div>] : []

    if (this.state.selected.group[1]) groups_selected.push(<div className={`filter__category-group-item ${class_group}`}>+{this.state.selected.group.length - 1}</div>)

    return { groups_list, groups_selected }
  }

  buildTags(tags) {
    let ids_selected = this.state.selected.tag.map(t => t.id)

    let tags_list = tags.map(t => {
      return (
        <div className="filter__category-tag" onClick={this.selectTag(t)}>
          {!ids_selected.includes(t.id) ? <IconCheckbox /> : <IconCheckboxChecked />}

          <div className="filter__category-tag-item" >
            {t.label}
          </div>
        </div>
      )
    })

    let tags_selected = this.state.selected.tag[0] ? [<div className="filter__category-tag-item">{this.state.selected.tag[0].label}</div>] : []

    if (this.state.selected.tag[1]) tags_selected.push(<div className="filter__category-tag-item">+{this.state.selected.tag.length - 1}</div>)

    return { tags_list, tags_selected }
  }

  render() {
    const groups = this.buildGroups(this.props.groups && this.props.groups.length ? this.props.groups.filter(g => g.users && g.users.length) : [])
    const tags = this.buildTags(this.props.tags || [])

    let filter = {}
    // Filter group
    filter.group = (
      <div className="filter__category" data-category="group" onClick={this.openDropdown}>
        {/* button to remove */}
        <button className="filter__category-btn-delete" onClick={this.removeFilter('group')}>
          <IconCrossDeleteIcon />
        </button>
        <span className="filter__category-title">{wording.group} : </span>
        {/* Groups selected*/}
        {groups.groups_selected}

        {/* Dropdown group */}
        {this.state.dropdown.group ?
          <div className="filter__category-group-dropdown">
            <PerfectScrollbar
              option={{
                wheelSpeed: 1,
                wheelPropagation: true,
                swipeEasing: false,
                minScrollbarLength: 5
              }}
            >
              {groups.groups_list}
            </PerfectScrollbar>
          </div>
          : ''}
      </div>
    )

    // Filter tag
    filter.tag = (
      <div className="filter__category" data-category="tag" onClick={this.openDropdown}>
        {/* button to remove */}
        <button className="filter__category-btn-delete" onClick={this.removeFilter('tag')}>
          <IconCrossDeleteIcon />
        </button>
        <span className="filter__category-title">{wording.thematic} : </span>

        {/* tags selected */}
        {tags.tags_selected}

        {/* Dropdown tag */}
        {this.state.dropdown.tag ?
          <div className="filter__category-tag-dropdown">
            <PerfectScrollbar
              option={{
                wheelSpeed: 1,
                wheelPropagation: true,
                swipeEasing: false,
                minScrollbarLength: 5
              }}
            >
              {tags.tags_list}
            </PerfectScrollbar>
          </div>
          : ''}
      </div>
    )

    // Filter network
    filter.network = (
      <div className="filter__category">
        {/* button to remove */}
        <button className="filter__category-btn-delete" onClick={this.removeFilter('network')}>
          <IconCrossDeleteIcon />
        </button>
        <span className="filter__category-title">{wording.network} :</span>
        <div className={`filter__category-network ${this.state.selected.network.includes('twitter') ? 'select' : ''}`} onClick={this.selectNetwork('twitter')}>
          <TwitterBgIcon />
        </div>
        <div className={`filter__category-network ${this.state.selected.network.includes('linkedin') ? 'select' : ''}`} onClick={this.selectNetwork('linkedin')}>
          <LinkedinBgIcon />
        </div>
      </div>
    )

    // Filter name
    filter.name = (
      <div className="filter__category">
        {/* button to remove */}
        <button className="filter__category-btn-delete" onClick={this.removeFilter('name')}>
          <IconCrossDeleteIcon />
        </button>

        <span className="filter__category-title">{wording.name} :</span>
        <input type="text" className="filter__category-name" value={this.state.selected.name} placeholder="Entrez un nom" onChange={this.selectName} onClick={(e) => e.stopPropagation()} />
      </div>
    )

    return (
      <div className="filter" onClick={this.openFilterChoice}>
        <div className="filter__title">
          {wording.label}
        </div>

        {this.state.filters_selected.map(f => {
          return filter[`${f}`]
        })}

        {this.state.dropdown.filter ?
          <div className="filter__choice-filter-container">
            <div className="filter__choice-filter-bloc">
              {this.props.filters && this.props.filters.filter(f => !this.state.filters_selected.includes(f)).map((f, i) => {
                return (
                  <div
                    className="filter__choice-filter-item"
                    key={i}
                    onMouseEnter={(e) => global.manageHover(e, { class: 'color-primary' })}
                    onMouseLeave={(e) => global.manageHover(e, { class: 'color-primary' })}
                    onClick={this.addFilter(f)}
                  >
                    {this.state.filter_label[`${f}`]}
                  </div>
                )
              })}
            </div>
          </div>
          : ''}
      </div>
    )
  }
}

export default Filter