const styles = theme => ({
  avatar: {
    width: 46,
    height: 46,
    marginLeft: 15,
  },
  avatarButton: {
    padding: 0,

    justifyContent: "flex-end",
    width: "100%"
  },
  menuItemText: {
    textAlign: "center",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    fontSize: "15px !important",
    color: "#697e8b !important"
  },
  // Main tabs  (Switch between dashboard and members)
  tabsRoot: {
    fontSize: 15,
  },
  tabsIndicator: {
    width: 78,
    height: 5,
    borderRadius: 4,
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY ? process.env.REACT_APP_COLOR_PRIMARY : '#1757A6',
  },
  wrapper: {
    height: 79,
    width: 90,
  },
  tabsLabel: {
    color: '#525252',
    fontSize: 15,
  },
  flexContainer: {
    height: "100%",
    margin: "0 auto",
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 90,
    width: 90,
    maxWidth: 90,
    fontSize: 15,
    marginRight: theme.spacing.unit * 4,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  tabSelected: {
    '& p': {
      color: process.env.REACT_APP_COLOR_PRIMARY ? process.env.REACT_APP_COLOR_PRIMARY : '#1757A6',
    },
  },
  tabLabel: {
    width: "auto",
  },
  // Secondary tabs  (Switch between members and groups)
  secondaryTabs_tabsRoot: {
    width: "100%",
    margin: "0 auto",
    height: 79,
    fontSize: 15,
    backgroundColor: theme.palette.background.paper,
    "& > div:first-child": {
      maxWidth: 1366,
    }
  },
  secondaryTabs_tabsIndicator: {
    width: 78,
    height: 5,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
  secondaryTabs_flexContainer: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
  },
  secondaryTabs_tabRoot: {
    textTransform: 'initial',
    minWidth: 180,
    width: 180,
    maxWidth: 180,
    fontSize: 15,
    marginRight: theme.spacing.unit * 4,
  },
  secondaryTabs_wrapper: {
    height: 79,
    width: 180,
  },
  secondaryTabs_tabSelected: {
    '& p': {
      color: [theme.palette.primary.main, '!important'],
    },
  },
  secondaryTabs_tabsLabel: {
    width: "auto",
    color: '#525252',
    fontSize: 15,
  },
});

export default styles