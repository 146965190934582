import React, { Component } from "react";

class LinkedinLogin extends Component {
  constructor(props) {
    super(props);

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onButtonClick(e) {
    if (this.props.onClick) this.props.onClick({ network: 'linkedin' })
    var popup = this.openPopup();
    const { redirectUri, clientId, state, scope } = this.props;
    popup.location = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
    this.polling(popup);
  }

  openPopup() {
    const w = this.props.dialogWidth;
    const h = this.props.dialogHeight;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;

    return window.open(
      "",
      "",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
    );
  }

  polling(popup) {
    const polling = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        this.props.onFailure(new Error("Popup has been closed by user"));
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };
      try {

        if (!popup.location.hostname.includes("linkedin.com") && popup.location.pathname === '/linkedin') {
          if (popup.location.search) {

            const query = new URLSearchParams(popup.location.search);

            const code = query.get("code");
            /*const state = query.get("state");

            // Prevent CSRF ATTACK
            if (state !== this.props.state)  {
              closeDialog();
              this.props.onFailure(`Une erreur de type CSRF est survenue.`)
              return;
            }*/

            if (!code) {
              closeDialog();
              this.props.onFailure(`Une erreur est survenue lors de l'authentification.`)
              return;
            }

            closeDialog();
            this.props.onSuccess({ code: code });
          } else {
            closeDialog();
            this.props.onFailure(
              new Error(
                "OAuth redirect has occurred but no query or hash parameters were found. " +
                "They were either not set during the redirect, or were removed—typically by a " +
                "routing library—before Twitter react component could read it."
              )
            );
          }
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in IE.
      }
    }, 500);
  }

  render() {
    const { children } = this.props;
    return (
      <button
        type="button"
        onClick={this.onButtonClick}
        className={this.props.className}
      >
        {children}
      </button>

    );
  }
}

LinkedinLogin.defaultProps = {
  dialogWidth: 800,
  dialogHeight: 800
};

export default LinkedinLogin;