import React from 'react';

// Init component.
function IconTwitterBg(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#1DA1F2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.279 21.6666C17.8136 21.6666 20.8408 17.0495 20.8408 13.0456C20.8408 12.9144 20.8408 12.7839 20.832 12.6539C21.421 12.225 21.9293 11.6939 22.3333 11.0855C21.7841 11.3306 21.2015 11.4913 20.605 11.5622C21.2332 11.1836 21.7033 10.588 21.9279 9.88636C21.3373 10.2393 20.691 10.488 20.0172 10.6218C19.0844 9.62313 17.6023 9.3787 16.4019 10.0256C15.2014 10.6725 14.5813 12.0498 14.8891 13.3852C12.4696 13.2631 10.2154 12.1124 8.68746 10.2195C7.88878 11.604 8.29673 13.3751 9.61908 14.2642C9.14021 14.2499 8.67178 14.1199 8.25332 13.885C8.25332 13.8974 8.25332 13.9104 8.25332 13.9234C8.25372 15.3657 9.26342 16.608 10.6675 16.8936C10.2244 17.0152 9.75964 17.033 9.30874 16.9455C9.70295 18.1798 10.8326 19.0254 12.12 19.0497C11.0545 19.8929 9.73821 20.3507 8.38298 20.3493C8.14356 20.3488 7.90438 20.3342 7.66666 20.3056C9.04276 21.1948 10.6439 21.6665 12.279 21.6643" fill="white" />
    </svg>
  );
}

export default IconTwitterBg;