import { createMuiTheme } from '@material-ui/core/styles';

const blueGrey = '#86a1b2',
  cloudyBlue = '#c3d0d7',
  greyishBrown = '#525252',
  azure = '#1ca1f2',
  waterBlue = '#1990d9',
  greyBlue = '#697e8b',
  mintyGreen = '#23e27f',
  algaeGreen = '#1fc971',
  grapefruit = '#f2615e',
  pastelRed = '#d95754'
  ;
/////////////
const THEME = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY ? process.env.REACT_APP_COLOR_PRIMARY : '#1757A6',
      hover: process.env.REACT_APP_COLOR_HOVER ? process.env.REACT_APP_COLOR_HOVER : '#0B3569'// TO-REVIEW feature/129
    }
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: "'Open Sans'",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal"
  },
  // Permet de modifier sur certains boutons un effet d'ondulation au moment du clique
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: "rgb(35, 226, 127)",
        height: 75,
        padding: "15px",
        textAlign: "center",
        fontSize: 16,
        fontFamily: "'Open Sans'",
        fontWeight: 600,
      },
      message: {
        width: "100%",
      }
    },
    MuiButton: {
      root: {
        textTransform: "none",
        transition: "none",
        borderRadius: 5,
      },
      contained: {
        boxShadow: `0 0 0 1px ${cloudyBlue}`,
        color: greyBlue,
        backgroundColor: "white",
        "&:hover": {
          boxShadow: "0 0 0 1px #abb6bd",
          backgroundColor: "white",
        },
        "&:active": {
          boxShadow: `0 0 0 1px ${cloudyBlue}`,
        },
        '&:disabled': {
          color: "#ffffff",
          backgroundColor: "#dbdbdb",
        },
      },
      containedPrimary: {
        boxShadow: "none",
        color: "white",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: process.env.REACT_APP_COLOR_HOVER ? process.env.REACT_APP_COLOR_HOVER : '#0B3569',
        },
        "&:active": {
          boxShadow: "none",
        }
      },
      containedSecondary: {
        boxShadow: "none",
        color: "white",
        backgroundColor: grapefruit,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: pastelRed,
        },
        "&:active": {
          boxShadow: "none",
        }
      },
    },
    MuiListItem: {
      button: {
        "&:hover, &:focus": {
          background: "none"
        }
      }
    },
    MuiListItemText: {
      primary: {
        color: "inherit",
        fontSize: 15,
        fontWeight: 600,
        lineHeight: "30px",
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 15,
        fontWeight: 600,
        height: 20,
        marginBottom: 10,
        color: greyishBrown,
        '&$focused': {
          color: greyishBrown
        }
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
          borderRadius: 5
        }
      },
      selectMenu: {
        fontSize: 15,
        color: blueGrey
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        '& $notchedOutline': {
          borderColor: cloudyBlue
        },
        '&$focused $notchedOutline': {
          borderColor: blueGrey,
          borderWidth: 1
        },
        minHeight: 40
      },
      input: {
        paddingTop: 5,
        paddingBottom: 5
      }
    },
    MuiCardActionArea: {
      focusHighlight: {
        opacity: "0 !important"
      }
    },
    MuiPickersDay: {
      day: {
        fontSize: 15,
        fontWeight: 600,
        color: greyishBrown,
        borderRadius: 5
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        "& > *": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiTableCell: {
      head: {
        fontSize: 13,
        fontWeight: 600,
        color: greyishBrown
      },
      body: {
        fontSize: 15,
        fontWeight: 600,
      }
    }
  },
  /* CONSTANTS */
  blueGrey: blueGrey,
  cloudyBlue: cloudyBlue,
  greyishBrown: greyishBrown,
  azure: azure,
  waterBlue: waterBlue,
  greyBlue: greyBlue,
  mintyGreen: mintyGreen,
  algaeGreen: algaeGreen,
  grapefruit: grapefruit,
  pastelRed: pastelRed,
  /* GLOBAL CLASSES */

  groupChip: {
    height: 30,
    borderRadius: 15,
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY ? process.env.REACT_APP_COLOR_SECONDARY : '#EBF2FF',
    fontSize: 14,
    fontWeight: 600,
    color: process.env.REACT_APP_COLOR_PRIMARY ? process.env.REACT_APP_COLOR_PRIMARY : '#1757A6',
    "& > span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
  },
  tagChip: {
    backgroundColor: '#f2ebf7',
    color: '#9258bd',
    fontWeight: 900,
    textTransform: "uppercase"
  },
  userChip: {
    height: 30,
    width: 30,
    border: "solid 3px white"
  },
});

export default THEME;