
import PropTypes from "prop-types";
import { uuid } from 'uuidv4';
import { Translation } from 'react-i18next';

// Material 
import Typography from '@material-ui/core/Typography';

// Assets
import TwitterIcon from '../../assets/icons/twitter-icon'
import LinkedinIcon from '../../assets/icons/linkedin-icon'
import OkIcon from '../../assets/icons/ok'
import ArrowIcon from '../../assets/icons/arrow'

// Components
import LinkedinLogin from '../../components/misc/LinkedinLogin/LinkedinLogin';
import TwitterLogin from '../../components/misc/TwitterLogin/TwitterLogin';
import Constants from '../../utils/params';
import React from 'react';

class SignIn extends React.Component {
  render() {
    const email_token = this.props.email_token ? JSON.stringify({ email_token: this.props.email_token }) : null

    const TwitterAuth = (props) => {

      // no data
      if (!this.props.userNetworks || (this.props.userNetworks && !this.props.userNetworks.twitter)) {
        return ""
      }

      // Has succeed
      if (this.props.needSecondAuth && this.props.twitterAuthSucceed) {
        return (
          <div className="signin__authSucceed signin__authSucceed-twitter">
            <TwitterIcon />
            <Typography>
              {props.label}
            </Typography>
            <OkIcon />
          </div>
        )
      }

      // Button
      return (
        <TwitterLogin
          loginUrl={Constants.DOMAIN + "/auth/twitter"}
          onFailure={this.props.onFailed}
          onSuccess={this.props.onAuthSucceed('twitterAuthSucceed')} // twitterAuthSucceed = key in this.state
          requestTokenUrl={Constants.DOMAIN + `/auth/twitter/reverse?callback=${window.location.origin}`}
          body={email_token}
          register={email_token ? true : false}
          showIcon={true}
          className="signin__twitter-button"

        >

          {/* Twitter icon */}
          <TwitterIcon />
          <Typography>
            {props.label}
          </Typography>
        </TwitterLogin>
      )
    }

    const LinkedinAuth = (props) => {

      // no data
      if (!this.props.userNetworks || (this.props.userNetworks && !this.props.userNetworks.linkedin)) {
        return ""
      }

      // Has succeed
      if (this.props.needSecondAuth && this.props.linkedinAuthSucceed) {
        return (
          <div className="signin__authSucceed signin__authSucceed-linkedin">
            <LinkedinIcon className="signin__left-icon" />
            <Typography>
              {props.label}
            </Typography>
            <OkIcon />
          </div>
        )
      }

      // Button
      return (
        <LinkedinLogin
          className={"signin__linkedin-button"}
          onSuccess={this.props.handleAuthentificationLinkedin} // linkedinAuthSucceed = key in this.state
          onFailure={this.props.onFailed}
          clientId={process.env.REACT_APP_LINKEDIN_ID}
          redirectUri={`${window.location.origin}/linkedin`}
          scope={`r_liteprofile w_member_social r_basicprofile ${!this.props.member ? 'rw_organization_admin r_organization_social' : ''}`}
          state={uuid()}
        >
          <LinkedinIcon />

          <Typography>
            {props.label}
          </Typography>
        </LinkedinLogin>
      )
    }

    return (
      <div className="signin__root">
        <div className="signin__layout">
          <Translation>
            {
              (t, { i18n }) => {
                t = t('login', { returnObjects: true })

                return (
                  <div className="signin__container">
                    {/* Logo */}
                    <img className="signin__logo" alt="logo" src={process.env.REACT_APP_LOGO} />

                    <p className="signin__heading">{t.title}</p>
                    <p className="signin__sub-heading">{t.txt}</p>

                    {/* Authentication twitter login */}
                    {process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true' ?
                      <TwitterAuth label={t.btn_twitter} />
                      : ''}

                    {/* Authentication linkedin login */}
                    {process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true' ?
                      <LinkedinAuth label={t.btn_linkedin} />
                      : ''}

                    {(this.props.needSecondAuth && (this.props.linkedinAuthSucceed || this.props.twitterAuthSucceed)) && (
                      <button onClick={this.props.goToDashboard} className="signin__gotodashboard">
                        {t.btn_dash}
                        <ArrowIcon rotation="90deg" />
                      </button>
                    )}

                    {/* Support mail */}
                    {process.env.REACT_APP_SUPPORT_MAIL ?
                      <>
                        <p className="signin__need-help-text">{t.legend}</p>
                        <a className="signin__need-help-link" href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>{t.contact}</a>
                      </>
                      : ''}

                    {/* Error message */}
                    {this.props.error && (
                      <p className="signin__error">
                        {this.props.error}
                      </p>
                    )}
                  </div>
                )
              }
            }
          </Translation>
        </div>
      </div>
    );

  };
}

SignIn.propTypes = {
  handleChange: PropTypes.func.isRequired,
  onAuthSucceed: PropTypes.func.isRequired,
  linkedinAuthSucceed: PropTypes.any.isRequired,
  twitterAuthSucceed: PropTypes.any.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  userNetworks: PropTypes.shape({
    twitter: PropTypes.bool.isRequired,
    linkedin: PropTypes.bool.isRequired,
    first_auth: PropTypes.bool,
  }),
  needSecondAuth: PropTypes.bool.isRequired
}

export default SignIn;