import React from 'react';
import { connect } from "react-redux";
import Cookies from 'js-cookie'

// Import components
import * as actionCreators from "../actions/actionCreators"
import Header from "../components/header/header.js";
import history from '../history';

class HeaderCon extends React.Component {

  logout = async () => {
    await this.props.logUserOut();
    await this.props.updateUserAction({});
    await this.props.updateUsersAction([]);
    this.props.updateIsAuthenticated(false);
    Cookies.remove('token');
    Cookies.remove('refresh_token');
    Cookies.remove('twitter_key')
    Cookies.remove('linkedin')
    history.push('/')
  };

  render() {
    return (
      <Header history={this.props.history} logout={this.logout} user={this.props.user} scrollToTop={this.props.scrollToTop} role={this.props.role} />
    )
  }
}

const mapStateToProps = (state) => {
  return state
};

export default connect(mapStateToProps, actionCreators)(HeaderCon);
