import Constants from '../utils/params';
import { handleErrors, catchErrors, checkToken } from './utils';

/*
  Update a user (For role Manager)
 */
export function editAccountAction(user_id, name, email, phone, status, groups, role, emailNotifications, smsNotification, is_user_test) {
  return (dispatch) => {
    return checkToken().then(t => {
      groups = JSON.stringify(groups);

      return fetch(`${Constants.DOMAIN}/user`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        },
        body: JSON.stringify({
          user_id: user_id,
          name: name,
          email: email,
          phone: phone,
          status: status,
          groups: groups,
          role: role,
          emailNotifications: emailNotifications | 0,
          smsNotification: smsNotification | 0,
          is_user_test: is_user_test
        })
      })
        .then(handleErrors)
        .then((res) => {
          if (res && !res.error) {
            dispatch({
              type: Constants.UPDATE_USER,
              user: res
            })
          }
          return res
        })
        .catch(catchErrors)
    });
  }
}

/*
  Suppression d'un compte utilisateur
 */
export function deleteUserAction(user_id) {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/user`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        },
        body: JSON.stringify({ user: user_id })
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  Création d'un groupe
 */
export function createGroupAction(data) {
  return dispatch => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/group`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        },
        body: JSON.stringify(data)
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: Constants.ADD_GROUP,
            group: res,
          });
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Modification d'un groupe
 */
export function editGroupAction(data) {
  return dispatch => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/group`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        },
        body: JSON.stringify(data)
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: Constants.UPDATE_GROUP,
            group: res
          });

          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Suppression d'un groupe
 */
export function deleteGroupAction(id) {
  return dispatch => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/group/${id}`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: Constants.REMOVE_GROUP,
            group_id: id,
          });
          return res;
        })
        .catch(catchErrors)
    });
  }
}
