import React from 'react';

// Init component.
function IconUser(props) {
  return (
    <svg className="icon__user" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={props.className ? props.className : ''} fillRule="evenodd" clipRule="evenodd" d="M3.34835 12.0984C4.05161 11.3951 5.00544 11 6 11H12C12.9946 11 13.9484 11.3951 14.6517 12.0984C15.3549 12.8016 15.75 13.7554 15.75 14.75V16.25C15.75 16.6642 15.4142 17 15 17C14.5858 17 14.25 16.6642 14.25 16.25V14.75C14.25 14.1533 14.0129 13.581 13.591 13.159C13.169 12.7371 12.5967 12.5 12 12.5H6C5.40326 12.5 4.83097 12.7371 4.40901 13.159C3.98705 13.581 3.75 14.1533 3.75 14.75V16.25C3.75 16.6642 3.41421 17 3 17C2.58579 17 2.25 16.6642 2.25 16.25V14.75C2.25 13.7554 2.64509 12.8016 3.34835 12.0984Z" />
      <path className={props.className ? props.className : ''} fillRule="evenodd" clipRule="evenodd" d="M9 3.5C7.75736 3.5 6.75 4.50736 6.75 5.75C6.75 6.99264 7.75736 8 9 8C10.2426 8 11.25 6.99264 11.25 5.75C11.25 4.50736 10.2426 3.5 9 3.5ZM5.25 5.75C5.25 3.67893 6.92893 2 9 2C11.0711 2 12.75 3.67893 12.75 5.75C12.75 7.82107 11.0711 9.5 9 9.5C6.92893 9.5 5.25 7.82107 5.25 5.75Z" />
    </svg>
  );
}

export default IconUser;