import React from 'react';

function IconClose(props) {
  return (
    <svg className="icon__card-cancel" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.376577 0.376577C-0.125526 0.87868 -0.125526 1.69275 0.376577 2.19485L4.18173 6L0.376577 9.80515C-0.125526 10.3073 -0.125526 11.1213 0.376577 11.6234C0.87868 12.1255 1.69275 12.1255 2.19485 11.6234L6 7.81827L9.80515 11.6234C10.3073 12.1255 11.1213 12.1255 11.6234 11.6234C12.1255 11.1213 12.1255 10.3073 11.6234 9.80515L7.81827 6L11.6234 2.19485C12.1255 1.69275 12.1255 0.87868 11.6234 0.376577C11.1213 -0.125526 10.3073 -0.125526 9.80515 0.376577L6 4.18173L2.19485 0.376577C1.69275 -0.125526 0.87868 -0.125526 0.376577 0.376577Z" />
    </svg>
  );
}

export default IconClose;