import React from 'react';

function IconSend() {
  return (
    <svg className="icon__send" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path fillRule="nonzero" d="M14.166 6.553L2.871 1.12c-.476-.229-1.014-.128-1.403.263-.39.39-.553.993-.425 1.571l1.005 4.57v.95l-1.005 4.57c-.128.578.035 1.18.425 1.571.39.392.928.492 1.403.263l11.295-5.432C14.681 9.2 15 8.645 15 8s-.32-1.2-.834-1.447z" />
    </svg>
  )
}

export default IconSend;