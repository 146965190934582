// Lib
import React from 'react';
import { Picker } from 'emoji-mart';
import PropTypes from "prop-types"
import 'emoji-mart/css/emoji-mart.css';

class EmojiPicker extends React.Component {
  constructor(props) {
    super(props);

    // Rebind ES6.
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.emojiRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {

    // If we click outside.
    if (this.props.isOpen && this.emojiRef && this.emojiRef.current && !this.emojiRef.current.contains(event.target)) {
      this.props.handleClose();
    }
  }

  handleClick(emoji) {
    this.props.onSelect(emoji.native)
  }

  render() {
    return (
      <div className={`emoji__picker${!this.props.isOpen ? '-hidden' : ''}`} ref={this.emojiRef}>
        <Picker
          onClick={this.handleClick}
          set="twitter"
        />
      </div>
    );
  }
}

EmojiPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default EmojiPicker;
