import React from 'react'

const PlayShadowIcon = ({ className = "" }) => (
  <svg className={"icon__play-shadow "} width="49" height="53" viewBox="0 0 49 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M37.3028 19.88C39.3543 21.0243 39.3543 23.9757 37.3028 25.12L14.6324 37.7651C12.6328 38.8804 10.1711 37.4348 10.1711 35.1451L10.1711 9.85493C10.1711 7.56523 12.6328 6.11957 14.6324 7.23494L37.3028 19.88Z" fill="white" />
    </g>
    <defs>
      <filter id="filter0_d" x="0.170898" y="0.850616" width="48.6704" height="51.2988" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default PlayShadowIcon