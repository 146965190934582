import Constants from '../utils/params';
import hash from 'object-hash';
import Cookies from 'js-cookie'
import { handleErrors, catchErrors, checkToken } from './utils';
import params from '../utils/params.js'

/*
  Création d'un tweetit
  data = {message(string), media_alt(string), media(base64), users(array)}
 */
export function createTweetitAction(data) {
  return (dispatch) => {
    return checkToken().then(t => {
      /* Supprime les champs vides */
      Object.keys(data).forEach(key => (data[key] === null || data[key] === "") && delete data[key]);

      /* Sérialise les champs object en JSON */
      if (data.users)
        data.users = JSON.stringify(data.users);
      if (data.groups)
        data.groups = JSON.stringify(data.groups);
      if (data.share_ref)
        data.share_ref = JSON.stringify(data.share_ref);
      if (data.scheduled_at)
        data.scheduled_at = data.scheduled_at.format();
      if (data.media)
        data.media = JSON.stringify(data.media);
      if (data.networks)
        data.networks = JSON.stringify(data.networks);

      /* Convertit les champs boolean en tinyint */
      data.notify_users = data.notify_users | 0;
      data.email_notif = data.email_notif | 0;
      data.sms_notif = data.sms_notif | 0;

      let formData = new FormData();
      for (let key in data) {
        data.hasOwnProperty(key) && formData.append(key, data[key]);
      }

      return fetch(`${Constants.DOMAIN}/tweetit`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.ADD_TWEETIT,
            tweetit: res,
          });
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Edition d'un tweetit
  data = {message(string), media_alt(string), media(base64)}
 */
export function editTweetitAction(post_id, data) {
  return (dispatch) => {
    return checkToken().then(t => {
      /* Supprime les champs vides */
      Object.keys(data).forEach(key => ((data[key] === null && key !== 'media') || data[key] === "") && delete data[key]);

      /* Sérialise les champs object en JSON */
      if (data.users)
        data.users = JSON.stringify(data.users);
      if (data.groups)
        data.groups = JSON.stringify(data.groups);
      if (data.share_ref)
        data.share_ref = JSON.stringify(data.share_ref);
      if (data.media)
        data.media = JSON.stringify(data.media);

      /* Convertit les champs boolean en tinyint */
      data.notify_users = data.notify_users | 0;
      data.email_notif = data.email_notif | 0;
      data.sms_notif = data.sms_notif | 0;

      let formData = new FormData();
      for (let key in data) {
        data.hasOwnProperty(key) && formData.append(key, data[key]);
      }
      formData.append('post_id', post_id);

      return fetch(`${Constants.DOMAIN}/tweetit`, {
        method: 'put',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => {

          dispatch({
            type: params.REPLACE_TWEETIT,
            post_id: post_id,
            tweetit: res,
          });
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Edition  de l'activité d'un tweet d'un tweetit
 */
export function editPostActivityAction(post_id, manager_id) {
  return (dispatch) => {
    return checkToken().then(t => {

      let formData = new FormData();

      formData.append('post_id', post_id);
      formData.append('manager_id', manager_id);

      return fetch(`${Constants.DOMAIN}/post/activity`, {
        method: 'put',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  Suppression d'un tweetit/retweetit
 */
export function deleteTweetitAction(post_id) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/tweetit`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        },
        body: JSON.stringify({ post_id: post_id })
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.REMOVE_TWEETIT,
            post_id: post_id,
          });
          return (res)
        })
        .catch(catchErrors)
    });
  }
}

/*
  Récupère la liste des tweetits par status
 */
export function getTweetitsAction(limit, offset, status) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/tweetits?limit=${limit ? limit : 0}&offset=${offset ? offset : 0}${status ? '&status=' + status.join() : ""}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }

      }).then(handleErrors)
        .then(res => {
          switch (status[0]) {
            case "draft":
              dispatch({
                type: params.UPDATE_DRAFT_TWEETITS,
                tweetits: res,
                concat: true
              });
              break;
            case "suggested":
              dispatch({
                type: params.UPDATE_SUGGESTED_TWEETITS,
                tweetits: res,
                concat: true
              });
              break;
            default:
              break;
          }

          return (res)
        })
        .catch(catchErrors)
    });
  }
}


/*
  Met à jour la liste des tweetits si hash différent
 */
export function refreshTweetitsAction(limit, offset, lastHash, currentFormId, status) {
  return (dispatch) => {
    return checkToken().then((t) => {

      return fetch(`${Constants.DOMAIN}/tweetits?limit=${limit ? limit : 0}&offset=${offset ? offset : 0}${status ? '&status=' + status : null}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {
          if (lastHash !== hash(res.filter(t => t.id !== currentFormId)/* Ignorer le tweetit en cours d'édition */)) {
            switch (status) {
              case params.statuses.DRAFT:
                dispatch({
                  type: params.UPDATE_DRAFT_TWEETITS,
                  tweetits: res
                });
                break;
              case params.statuses.SUGGESTED:
                dispatch({
                  type: params.UPDATE_SUGGESTED_TWEETITS,
                  tweetits: res
                });
                break;
              default:
                break;
            }
          }
        })
        .catch(catchErrors)
    });
  }
}


/*
  Récupère la liste des utilisateurs
 */
export function getUsersAction() {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/users`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.UPDATE_USERS,
            users: res
          });
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Récupère la liste des groupes
 */
export function getGroupsAction() {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/groups`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.UPDATE_GROUPS,
            groups: res
          });
          return res;
        })
        .catch(catchErrors)
    })
  }
}

/*
  Récupère la liste des thématiques
 */
export function getTagsAction() {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/tags`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.UPDATE_TAGS,
            tags: res
          });
          return res;
        })
        .catch(catchErrors)
    })
  }
}

/*
  Création d'une thématique
 */
export function createTagAction(data) {
  return dispatch => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/tag`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        },
        body: JSON.stringify(data)
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.ADD_TAG,
            tag: res
          })
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Suppression d'une thématique
 */
export function deleteTagAction(id) {
  return dispatch => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/tag/${id}`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token
        }
      }).then(handleErrors)
        .then(res => {
          dispatch({
            type: params.REMOVE_TAG,
            tag_id: id,
          });
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  Récupère un tweet par id
 */
export function getTweetAction(tweet_id) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/tweet/?tweet_id=` + tweet_id, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key')
        }

      }).then(handleErrors)
        .then(res => {
          return res
        })
        .catch(catchErrors)
    });
  }
}

/*
  Récupère un profil twitter par screen name
 */
export function getProfileAction(screen_name) {
  return () => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/profile/?screen_name=${screen_name}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key')
        }

      }).then(handleErrors)
        .then(res => res)
        .catch(catchErrors)
    });
  }
}

/*
  Retrieve instagram post
 */
export function getInstagramPostAction(url) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/post/instagram?url=` + url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
        }

      }).then(handleErrors)
        .then(res => {
          return res
        })
        .catch(catchErrors)
    });
  }
}

/*
  Retrieve facebook post
 */
export function getFacebookPostsAction(account, fbPages) {
  return (dispatch) => {
    return checkToken().then((t) => {
      return fetch(`${Constants.DOMAIN}/post/facebook?account=${account}&pages=${fbPages}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': t.token,
        }

      }).then(handleErrors)
        .then(res => {
          return res
        })
        .catch(catchErrors)
    });
  }
}

/*
  Récupère les données d'invitation d'un nouveau membre
 */
export function getUserNetworksAction(network_token) {
  return (dispatch) => {
    return fetch(`${Constants.DOMAIN}/user/network?network_token=` + network_token, {
      method: 'get'
    })
      .then(handleErrors)
      .then(res => {
        return (res)
      })
      .catch(catchErrors)
  }
}

/*
  CREATE INVITE LINK
*/

export function createInviteAction(data) {
  return (dispatch) => {
    return checkToken().then(t => {
      /* Supprime les champs vides */
      Object.keys(data).forEach(key => (data[key] === null || data[key] === "") && delete data[key]);

      /* Sérialise les champs object en JSON */
      if (data.users)
        data.users = JSON.stringify(data.users);
      if (data.groups)
        data.groups = JSON.stringify(data.groups);

      let formData = new FormData();
      for (let key in data) {
        data.hasOwnProperty(key) && formData.append(key, data[key]);
      }

      return fetch(`${Constants.DOMAIN}/invite_links`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  EDIT INVITE LINK
*/

export function editInviteAction(data) {
  return (dispatch) => {
    return checkToken().then(t => {
      let formData = new FormData();
      for (let key in data) {
        data.hasOwnProperty(key) && formData.append(key, data[key]);
      }

      return fetch(`${Constants.DOMAIN}/invite_links`, {
        method: 'put',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  GET ALL INVITE LINK
*/
export function getAllInviteAction() {
  return (dispatch) => {
    return checkToken().then(t => {

      return fetch(`${Constants.DOMAIN}/invite_links`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  CREATE FLUX
*/

export function createFluxAction(data) {
  return (dispatch) => {
    return checkToken().then(t => {
      /* Supprime les champs vides */
      Object.keys(data).forEach(key => (data[key] === null || data[key] === "") && delete data[key]);

      /* Sérialise les champs object en JSON */
      if (data.users)
        data.users = JSON.stringify(data.users);
      if (data.groups)
        data.groups = JSON.stringify(data.groups);
      if (data.options)
        data.options = JSON.stringify(data.options);
      if (data.account_info)
        data.account_info = JSON.stringify(data.account_info);

      let formData = new FormData();
      for (let key in data) {
        data.hasOwnProperty(key) && formData.append(key, data[key]);
      }

      return fetch(`${Constants.DOMAIN}/flux`, {
        method: 'post',
        headers: {
          'x-auth-token': t.token,
          'linkedin_key': Cookies.get('linkedin'),
          'twitter_key': Cookies.get('twitter_key')
        },
        body: formData
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  EDIT FLUX
*/

export function editFluxAction(data) {
  return (dispatch) => {
    return checkToken().then(t => {
      /* Supprime les champs vides */
      Object.keys(data).forEach(key => (data[key] === null || data[key] === "") && delete data[key]);

      /* Sérialise les champs object en JSON */
      if (data.users)
        data.users = JSON.stringify(data.users);
      if (data.groups)
        data.groups = JSON.stringify(data.groups);
      if (data.options)
        data.options = JSON.stringify(data.options);

      let formData = new FormData();
      for (let key in data) {
        data.hasOwnProperty(key) && formData.append(key, data[key]);
      }

      return fetch(`${Constants.DOMAIN}/flux`, {
        method: 'put',
        headers: {
          'x-auth-token': t.token
        },
        body: formData
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  DELETE FLUX
*/

export function deleteFluxAction(data) {
  return (dispatch) => {
    return checkToken().then(t => {

      return fetch(`${Constants.DOMAIN}/flux/${data.id}`, {
        method: 'delete',
        headers: {
          'x-auth-token': t.token
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}
/*
  GET ALL FLUX
*/
export function getAllFluxAction() {
  return (dispatch) => {
    return checkToken().then(t => {

      return fetch(`${Constants.DOMAIN}/flux`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/*
  SEARCH ACCOUNT
*/
export function searchAccountAction(data) {
  return (dispatch) => {
    return checkToken().then(t => {

      return fetch(`${Constants.DOMAIN}/accounts/search?flux_network=${data.network}${data.name ? `&name=${data.name}` : ''}${data.page ? `&page=${data.page}` : ''}`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
          'twitter_key': Cookies.get('twitter_key'),
          'linkedin_key': Cookies.get('linkedin')
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}


/* ANALYTICS global*/
export function getAnalyticsGlobalAction(params = {}) {
  return (dispatch) => {
    return checkToken().then(t => {

      let query = null

      // Filters
      if (params && params.filters) {
        for (let s in params.filters) {
          if (Array.isArray(params.filters[s])) {
            query = !query ? `?${s}=${params.filters[s].join('-')}` : `${query}&${s}=${params.filters[s].join('-')}`
          } else {
            query = !query ? `?${s}=${params.filters[s]}` : `${query}&${s}=${params.filters[s]}`
          }
        }
      }

      // Date
      if (params && params.date) {
        query = !query ? `?from=${params.date.from}&to=${params.date.to}` : `${query}&from=${params.date.from}&to=${params.date.to}`
      }

      return fetch(`${Constants.DOMAIN}/analytics/global${query || ''}`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/* ANALYTICS groups */
export function getAnalyticsGroupsAction() {
  return (dispatch) => {
    return checkToken().then(t => {

      return fetch(`${Constants.DOMAIN}/analytics/groups`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/* ANALYTICS tags */
export function getAnalyticsTagsAction(params = {}) {
  return (dispatch) => {
    return checkToken().then(t => {
      let query = null

      // Filters
      if (params && params.filters) {
        for (let s in params.filters) {
          if (Array.isArray(params.filters[s])) {
            query = !query ? `?${s}=${params.filters[s].join('-')}` : `${query}&${s}=${params.filters[s].join('-')}`
          } else {
            query = !query ? `?${s}=${params.filters[s]}` : `${query}&${s}=${params.filters[s]}`
          }
        }
      }

      return fetch(`${Constants.DOMAIN}/analytics/tags${query || ''}`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/* ANALYTICS contents */
export function getAnalyticsContentsAction(params = {}) {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(`${Constants.DOMAIN}/analytics/contents?offset=${params.offset}`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}

/* ANALYTICS networks */
export function getAnalyticsNetworksAction(params) {
  return (dispatch) => {
    return checkToken().then(t => {

      return fetch(`${Constants.DOMAIN}/analytics/networks?period=${params && params.period}`, {
        method: 'get',
        headers: {
          'x-auth-token': t.token,
        },
      }).then(handleErrors)
        .then(res => {
          return res;
        })
        .catch(catchErrors)
    });
  }
}