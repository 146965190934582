
// Libs
import React from "react"
import ReactSVG from "react-svg";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';
import Cookies from 'js-cookie'
import i18n from '../../i18n'

// Assets
import TwitterVerified from "../../assets/icons/twitter-verified"
import IconTwitter from "../../assets/icons/twitter-icon"
import IconLinkedin from "../../assets/icons/linkedin-icon"
import IconFacebook from "../../assets/icons/facebook"
import IconInstagram from "../../assets/icons/instagram"
import IconSearch from "../../assets/icons/search";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import searchIcon from '../../assets/images/search.svg';
import IconRadioChecked from "../../assets/icons/radio-checked";
import IconRadio from "../../assets/icons/radio";
import IconCheckbox from "../../assets/icons/checkbox";
import IconCheckboxChecked from "../../assets/icons/checkbox-checked";
import defaultUserIcon from "../../assets/images/default_profile_normal.png";
import arrow from "../../assets/images/arrow.svg"
import TwitterIcon from "../../assets/icons/twitter-icon";
import LinkedinIcon from "../../assets/icons/linkedin-icon";

// Components
import SelectDialog from "../misc/selectDialog/select-dialog";
import { withStyles } from "@material-ui/core/styles";
import ConfirmDialog from '../misc/confirm-dialog';

// Utils
import GlobalUtils from '../../utils/global'
import params from '../../utils/params';
import EventEmitter from '../../utils/EventEmitter';

// Material
import { Select, OutlinedInput, InputAdornment, Tooltip, CircularProgress } from "@material-ui/core";
import styles from "./material-settingsFluxModal"
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";

class SettingsFluxModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenConfirm: false,
      network: '',
      bar_left: 0,
      bar_width: 0,

      // Select dialog
      selectAnchorTop: 0,
      selectAnchorLeft: 0,
      dialogWidth: 0,
      isDialogOpen: false,

      // Account result search
      accountSearchResult: null,
      accountSearchName: '',
      accountSearchPage: 0,
      accountLoading: false,

      // Flux data
      id: null,
      screen_name: '',
      groups: [],
      users: [],
      account_info: null,

      // options
      frequency: 0,
      options: {
        display: ['tweets']
      },

      authenticatedOnTwitter: Cookies.get('twitter_key') ? true : false,
      authenticatedOnLinkedin: Cookies.get('linkedin') ? true : false,

      fetchingResults: false,
    }
    this.modalRef = React.createRef();

    this.createFlux = this.createFlux.bind(this)
    this.editFlux = this.editFlux.bind(this)
    this.selectAccount = this.selectAccount.bind(this)
    this.handleChangeSearchAccount = this.handleChangeSearchAccount.bind(this)
    this.loadMoreAccount = this.loadMoreAccount.bind(this)

    // Select dialog
    this.openSelectDialog = this.openSelectDialog.bind(this)
    this.closeSelectDialog = this.closeSelectDialog.bind(this)
    this.setItems = this.setItems.bind(this)

    this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this)
    this.setNetwork = this.setNetwork.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this);

    // Options event
    this.setOptions = this.setOptions.bind(this)
  }

  searchAccountDebounced = AwesomeDebouncePromise(this.searchAccount.bind(this), 200);
  searchAccountInstaFbDebounced = AwesomeDebouncePromise(this.searchAccount.bind(this), 700);

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    // Init data If we edit a flux
    if (this.props.fluxToEdit) {
      let state = this.state
      state = Object.assign(state, this.props.fluxToEdit)
      state.account_info = JSON.parse(this.props.fluxToEdit.account_info)
      state.options = JSON.parse(this.props.fluxToEdit.options)
      this.setState(state)

    } else {
      const { network, bar_left, bar_width } = this.initNetwork()
      await this.setState({ network, bar_width, bar_left })

      if (network === 'linkedin') {
        let accountSearchResult = await this.props.searchAccountAction({ network: 'linkedin' })
        if (accountSearchResult && !accountSearchResult.error && this.state.network === 'linkedin') this.setState({ accountSearchResult })
      }

    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  initNetwork() {
    let network = '', bar_left = 0, bar_width = 0, el = null
    if ((process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true') && Cookies.get('twitter_key')) {
      network = 'twitter'
    } else if ((process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true') && Cookies.get('linkedin')) {
      network = 'linkedin'
    } else if (process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true') {
      network = 'facebook'
    }

    try {
      el = document.getElementById(network)
      bar_left = el.offsetLeft
      bar_width = el.offsetWidth
    } catch (e) { console.error(e) }

    return { network, bar_left, bar_width }
  }

  createFlux() {
    if (!this.isFluxValid()) return

    let data = {
      display_name: this.state.account_info.name, screen_name: this.state.screen_name,
      frequency: this.state.frequency, options: this.state.network === 'twitter' ? this.state.options : {},
      users: this.state.users.map(u => u.id), groups: this.state.groups.map(g => g.id),
      network: this.state.network, account_info: this.state.account_info
    }
    this.props.createFlux(data)
  }

  editFlux() {
    if (!this.isFluxValid()) return

    let data = {
      id: this.state.id, frequency: this.state.frequency,
      options: this.state.options, users: this.state.users.map(u => u.id),
      groups: this.state.groups.map(g => g.id),
    }
    this.props.editFlux(data)
  }

  isFluxValid() {
    let isValid = true

    if (this.state.network === 'twitter' && !this.state.options.display.length) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.flux.popin_message.content_display, style: "error" })
      isValid = false
    } else if (!this.state.screen_name) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.flux.popin_message.select_account, style: "error" })
      isValid = false
    } else if (!this.state.groups.length && !this.state.users.length) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.flux.popin_message.select_audience, style: "error" })
      isValid = false
    }

    return isValid
  }

  selectAccount(account) {
    return (e) => {
      this.setState({
        account_info: account,
        screen_name: account.screen_name,
        accountSearchResult: null,
        accountSearchName: '',
        accountSearchPage: 0,
      })
    }
  }

  openSelectDialog(e) {
    let rect = e.currentTarget.getBoundingClientRect();
    this.setState({
      selectAnchorTop: rect.y,
      selectAnchorLeft: rect.x,
      dialogWidth: rect.width,
      isDialogOpen: true
    });
  };

  closeSelectDialog() {
    this.setState({ isDialogOpen: false })
  }

  async setItems(items, field) {

    if (items.length === 0) {
      this.closeSelectDialog();
      return;
    }

    let data = {}
    if (Array.isArray(field)) {
      field.forEach((f, index) => {
        data[f] = items[index].filter(i => i.selected).map((item, index) => {
          if (f !== 'groups') return item
          // build array of object with user id (related to displayUsersGroupsAssociated() function)
          item.users = item.users.map((user_id) => { return { id: user_id } })
          return item
        });
      })
    } else {
      data[field] = items.filter(i => i.selected);
    }
    const { groups, users } = data

    await this.setState({
      groups,
      users,
      isModified: true
    });
    await this.closeSelectDialog()
  };

  getData(options) {
    let groups = null, users = null;
    users = this.props.users
      .filter(user => user.role === "User")
      .filter(u => u.status !== params.userStatuses.DISABLED)
      .filter(u => u.user_twitter || u.user_linkedin)
      .map(u => {
        const {
          screenNameThatShouldBeDisplayed,
          profileImageThatShouldBeDisplayed
        } = GlobalUtils.getUserNameAndAvatar({
          user: u
        })

        return Object.assign({
          label: screenNameThatShouldBeDisplayed,
          icon: profileImageThatShouldBeDisplayed,
          selected: !!this.state.users.find(f => f.id === u.id)
        }, u)
      })

    if (options.data === 'users_selected') return users

    let users_id = users.map(u => u.id)

    groups = this.props.groups.filter(g => {
      for (let u of g.users) {
        if (users_id.includes(u)) return true
      }
      return false
    })

    groups = groups
      .filter(g => g.users.length)
      .map(g => Object.assign({
        selected: !!this.state.groups.find(f => f.id === g.id)
      }, g))

    if (options.data === 'groups_selected') return groups
  }

  displayUsersGroupsAssociated() {
    return (
      <div className="tweetit-form__select-value">
        {
          [
            ...this.state.groups.map((group, index) =>
              <Chip key={index} label={group.label}
                className={`${this.props.classes.selectChip} ${this.props.classes.groupChip}`}
              />
            ),
            // if state.users belongs to some groups hide user
            ...this.state.users.filter(usersItem => {

              // user belongs to some group
              let someGroupIncludeUser = this.state.groups.some((groupsItem, i) => {

                // match usersItem in groups.user
                let matchUserId = groupsItem.users && groupsItem.users.find(groupsItemUsersItem => groupsItemUsersItem.id === usersItem.id)

                return matchUserId
              })
              return !someGroupIncludeUser

            }).map((user, index) => {
              const { screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed } = GlobalUtils.getUserNameAndAvatar({ user: user })
              return <Avatar alt={screenNameThatShouldBeDisplayed} key={`u-${index}`} className={this.props.classes.avatar} src={profileImageThatShouldBeDisplayed} onError={(e) => { e.target.src = defaultUserIcon }} />
            })
          ]
        }
      </div>
    )
  }

  toggleConfirmDialog() {
    this.setState({
      isOpenConfirm: !this.state.isOpenConfirm,
    })
  }


  setNetwork(network) {
    return async (e) => {
      this.setState({ network, bar_left: e.currentTarget.offsetLeft, bar_width: e.currentTarget.offsetWidth, account_info: null, accountSearchResult: null, accountSearchName: '' })

      // Retrieve the page where the manager have an administrator role on the Linkedin page
      if (network === 'linkedin') {
        let accountSearchResult = await this.props.searchAccountAction({ network: 'linkedin' })

        if (accountSearchResult && !accountSearchResult.error) this.setState({ accountSearchResult })
      }
    }
  }

  handleClickOutside(e) {
    if (this.modalRef.current && !this.modalRef.current.contains(e.target) && (e.target.className === 'settings-flux__modal')) {
      this.props.setModal(null);
    }
  }

  setOptions(type, data) {
    return (e) => {
      let options = this.state.options
      if (type === 'display') {
        if (options.display.includes(data)) options.display = options.display.filter(d => d !== data)
        else options.display.push(data)

        this.setState({ options })
      } else {
        this.setState({ [`${type}`]: data })
      }
    }
  }

  handleChangeSearchAccount(e) {

    const value = e.currentTarget.value

    this.setState({
      accountSearchName: value,
      accountSearchPage: 0,
      fetchingResults: value ? true : false
    }, () => {
      if (['instagram', 'facebook'].includes(this.state.network)) {
        this.searchAccountInstaFbDebounced()
      } else {
        this.searchAccountDebounced()
      }
    })
  }

  loadMoreAccount(e) {
    if (this.state.network !== 'twitter') return

    if (e.scrollTop > 0 && !this.state.accountLoading) {
      this.setState({
        accountSearchPage: this.state.accountSearchPage + 1,
        accountLoading: true
      },
        () => {
          this.searchAccountDebounced()
        }
      )
    }

  }

  async searchAccount() {
    if (!this.state.accountSearchName) return

    let res = await this.props.searchAccountAction({ name: this.state.accountSearchName, page: this.state.accountSearchPage, network: this.state.network })

    if (res && res.error) {
      console.error(res.error)
      this.setState({ accountLoading: false, fetchingResults: false })
    } else {
      this.setState({
        accountSearchResult: this.state.accountSearchPage ? this.state.accountSearchResult.concat(res) : res,
        accountLoading: false,
        fetchingResults: false,
      })
    }
  }

  render() {
    // add OR edit
    const modal = this.props.modal
    const account_info = this.state.account_info
    const accountSearchResult = this.state.accountSearchResult
    const classes = this.props.classes
    const net = this.state.network

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).settings.flux.popin

            return (
              <div className="settings-flux__modal">
                <div className="settings-flux__modal-container" ref={this.modalRef}>
                  {(modal === "add") && (

                    <React.Fragment>
                      {/* NETWORKS */}
                      <div className="settings-flux__modal-nav">
                        {process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true' ?
                          <Tooltip
                            open={this.state.authenticatedOnTwitter ? false : undefined}
                            interactive
                            classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                            title={
                              <div className={this.props.classes.tooltipTitle}>
                                <div className={this.props.classes.tooltipContainer}>
                                  <img alt="arrow" src={arrow} />
                                </div>
                                Si vous souhaitez créer un flux Twitter, vous devez vous y connecter
                                <Link to={`/members/edit?user=${this.props.user.id}`} className="settings-flux__modal-auth-link">
                                  <TwitterIcon />
                                  Se connecter à Twitter
                                </Link>
                              </div>
                            }
                          >
                            <div className={`settings-flux__modal-nav-item ${net === 'twitter' ? "color-primary" : ""}`} onClick={this.state.authenticatedOnTwitter ? this.setNetwork('twitter') : null} id="twitter">
                              <IconTwitter />
                              Twitter
                            </div>
                          </Tooltip>
                          : ''}

                        {process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true' ?
                          <Tooltip
                            open={this.state.authenticatedOnLinkedin ? false : undefined}
                            interactive
                            classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip, root: this.props.classes.tooltipRoot }}
                            title={
                              <div className={this.props.classes.tooltipTitle}>
                                <div className={this.props.classes.tooltipContainer}>
                                  <img alt="arrow" src={arrow} />
                                </div>
                                Si vous souhaitez créer un flux Linkedin, vous devez vous y connecter
                                <Link to={`/members/edit?user=${this.props.user.id}`} className="settings-flux__modal-auth-link">
                                  <LinkedinIcon />
                                  Se connecter à Linkedin
                                </Link>
                              </div>
                            }
                          >
                            <div className={`settings-flux__modal-nav-item ${net === 'linkedin' ? "color-primary" : ""}`} onClick={this.state.authenticatedOnLinkedin ? this.setNetwork('linkedin') : null} id="linkedin">
                              <IconLinkedin />
                              Linkedin
                            </div>
                          </Tooltip>
                          : ''}

                        {process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true' ?
                          <div className={`settings-flux__modal-nav-item ${net === 'facebook' ? "color-primary" : ""}`} onClick={this.setNetwork('facebook')} id="facebook">
                            <IconFacebook />
                            Facebook
                          </div>
                          : ''}

                        {process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true' ?
                          <div className={`settings-flux__modal-nav-item ${net === 'instagram' ? "color-primary" : ""}`} onClick={this.setNetwork('instagram')} id="instagram">
                            <IconInstagram />
                            Instagram
                          </div>
                          : ''}

                        <span className="settings-flux__modal-nav-bar background-color-primary" style={{ transform: `translateX(${this.state.bar_left - 5}px)`, width: `${this.state.bar_width + 10}px` }}></span>
                      </div>

                      {/* ACCOUNT SEARCH */}
                      <div className="settings-flux__modal-search">
                        {/* SEARCH INPUT */}
                        {net !== 'linkedin' &&
                          <>
                            <IconSearch />
                            <input type="text" className="settings-flux__modal-search-input" onChange={this.handleChangeSearchAccount} value={this.state.accountSearchName} placeholder={!account_info ? t.input.replace('[[network]]', net.charAt(0).toUpperCase() + net.slice(1)) : "Rechercher un autre compte"} />
                            {this.state.fetchingResults ? <CircularProgress className="settings-flux__modal-circular" size={20} /> : ""}
                          </>
                        }

                        {/* SEARCH RESULT */}
                        {accountSearchResult && accountSearchResult.length && (this.state.accountSearchName || net !== 'twitter') ?
                          <div className={`settings-flux__modal-results ${!['twitter', 'instagram'].includes(net) ? 'no-height' : ''}`}>
                            <PerfectScrollbar
                              option={{
                                wheelSpeed: 1,
                                wheelPropagation: true,
                                swipeEasing: false,
                                minScrollbarLength: 20
                              }}
                              onYReachEnd={this.loadMoreAccount}
                            >
                              {
                                accountSearchResult.map((t, index) => (
                                  <button key={index} className="settings-flux__modal-results-item" onClick={this.selectAccount(t)}>
                                    <img src={t.profile_image_url || defaultUserIcon} alt={t.name} className="settings-flux__modal-results-avatar" />
                                    <div>
                                      <div className="settings-flux__modal-results-display">
                                        {t.name}
                                        {t.verified && <TwitterVerified />}
                                      </div>

                                      {/* display HANDLE FOR TWITTER */}
                                      {['twitter', 'instagram'].includes(net) &&
                                        <div className="settings-flux__modal-results-account">@{t.screen_name}</div>
                                      }
                                    </div>
                                  </button>
                                ))
                              }
                            </PerfectScrollbar>
                          </div>
                          : ''
                        }

                        {/* MESSAGE IN CASE THERE IS NO LINKEDIN PAGES */}
                        {accountSearchResult && !accountSearchResult.length && net === 'linkedin' &&
                          <div className="settings-flux__modal-info"> No Linkedin pages found</div>
                        }

                        {/* Linkedin : loading pages */}
                        {!accountSearchResult && !this.state.account_info && net === 'linkedin' &&
                          <CircularProgress size={20} />
                        }

                      </div>
                    </React.Fragment>
                  )}

                  <div className="settings-flux__modal-details">
                    {/* FLUX SELECTED */}
                    <div className="settings-flux__modal-details-header">
                      {account_info &&
                        <div className="settings-flux__modal-details-info">
                          <img src={account_info.profile_image_url || defaultUserIcon} alt="" className="settings-flux__modal-details-info-img" />
                          <div>
                            <div className="settings-flux__modal-details-info-display">
                              {account_info.name}
                              {account_info.verified && <TwitterVerified />}
                            </div>
                            {['twitter', 'instagram'].includes(net) &&
                              <div className="settings-flux__modal-details-info-account">@{this.state.screen_name}</div>
                            }
                          </div>
                        </div>
                      }
                      <div className="settings-flux__modal-details-info-actions">
                        <button className="settings-flux__btn settings-flux__btn-primary background-color-primary" onClick={modal === "add" ? this.createFlux : this.editFlux}>

                          {this.props.submitting && <CircularProgress className={this.props.classes.progress} size={20} />}
                          {modal === "add" ? t.btn_add : t.btn_update}
                        </button>
                        {modal === "edit" && <button className="settings-flux__btn settings-flux__btn-secondary" onClick={this.toggleConfirmDialog}>{t.btn_delete} </button>}
                      </div>
                    </div>

                    {/* FLUX AUDIENCE */}
                    <p className="settings-flux__modal-details-label" >{t.audience}</p>
                    <SelectDialog
                      positionTop={this.state.selectAnchorTop}
                      positionLeft={this.state.selectAnchorLeft}
                      width={this.state.dialogWidth}
                      isMultiple={true}
                      items={[this.getData({ data: 'groups_selected' }), this.getData({ data: 'users_selected' })]}
                      field={['groups', 'users']}
                      labels={['Groupes', 'Membres']}
                      itemClass={[classes.groupChip, null]}
                      setItems={this.setItems}
                      open={this.state.isDialogOpen}
                      closeSelectDialog={this.closeSelectDialog}
                      params={{ parent: "settings-flux-modal" }}
                    />

                    <Select
                      multiple
                      value={[0, 1]} // Pour passer dans renderValue mais sans réévaluer à chaque fois
                      input={
                        <OutlinedInput
                          labelWidth={0}
                          name="users"
                          id="users"
                          startAdornment={
                            <InputAdornment
                              position="start"
                              className={classes.selectAdornment}
                            >
                              <ReactSVG src={searchIcon} />
                            </InputAdornment>
                          }
                          classes={{
                            notchedOutline: classes.selectInput,
                            adornedStart: classes.selectRoot,
                            focused: classes.selectInputFocused,
                            root: classes.selectInputRoot
                          }}
                        />
                      }
                      IconComponent={
                        ArrowDropDownRoundedIcon
                      }
                      renderValue={() => this.displayUsersGroupsAssociated()}
                      open={false}
                      onOpen={() => { }}
                      onClose={() => { }}
                      onClick={this.openSelectDialog}
                      classes={{ selectMenu: classes.selectMenu }}
                    />

                    {/* FLUX PARAMS */}
                    <div className="settings-flux__modal-details-parameters">

                      {/* FLUX PARAMS DISPLAY */}
                      {net === 'twitter' &&
                        <div className={`settings-flux__modal-details-parameters-item ${this.props.fluxToEdit && this.props.fluxToEdit.id ? 'disable' : ' '}`}>
                          <p className="settings-flux__modal-details-label">{t.label_display}</p>
                          <div className="settings-flux__modal-details-checkbox" onClick={this.setOptions('display', 'tweets')}>
                            <input name="showTweets" id="showTweets" type="checkbox" className="settings-flux__modal-details-checkbox-input" value="showTweets" />
                            {this.state.options.display.includes('tweets') ? <IconCheckboxChecked /> : <IconCheckbox />}
                            {t.tweet}
                          </div>
                          <div className="settings-flux__modal-details-checkbox" onClick={this.setOptions('display', 'retweets')}>
                            <input name="showRts" id="showRts" type="checkbox" className="settings-flux__modal-details-checkbox-input" value="showRts" />
                            {this.state.options.display.includes('retweets') ? <IconCheckboxChecked /> : <IconCheckbox />}
                            {t.retweet}
                          </div>
                        </div>
                      }

                      {/* FLUX PARAMS NOTIFICATION FREQUENCY */}
                      <div className="settings-flux__modal-details-parameters-item">
                        <p className="settings-flux__modal-details-label">{t.label_notif}</p>
                        <div className="settings-flux__modal-details-checkbox" onClick={this.setOptions('frequency', 1)}>
                          <input name="onePerDay" id="onePerDay" type="radio" className="settings-flux__modal-details-checkbox-input" value="onePerDay" />
                          {this.state.frequency === 1 ? <IconRadioChecked /> : <IconRadio />}
                          {t.one_per_day}
                        </div>
                        <div className="settings-flux__modal-details-checkbox" onClick={this.setOptions('frequency', 7)}>
                          <input name="onePerWeek" id="onePerWeek" type="radio" className="settings-flux__modal-details-checkbox-input" value="onePerWeek" />
                          {this.state.frequency === 7 ? <IconRadioChecked /> : <IconRadio />}
                          {t.one_per_week}
                        </div>
                        <div className="settings-flux__modal-details-checkbox" onClick={this.setOptions('frequency', 30)}>
                          <input name="onePerMonth" id="onePerMonth" type="radio" className="settings-flux__modal-details-checkbox-input" value="onePerMonth" />
                          {this.state.frequency === 30 ? <IconRadioChecked /> : <IconRadio />}
                          {t.one_per_month}
                        </div>
                        <div className="settings-flux__modal-details-checkbox" onClick={this.setOptions('frequency', 0)}>
                          <input name="never" id="never" type="radio" className="settings-flux__modal-details-checkbox-input" value="never" />
                          {this.state.frequency === 0 ? <IconRadioChecked /> : <IconRadio />}
                          {t.never}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* POPIN CONFIRMATION FLUX DELETE */}
                <ConfirmDialog
                  title={"Confirmation"}
                  message={"Êtes-vous sûr de vouloir supprimer ce flux ?"}
                  handleCancel={this.toggleConfirmDialog}
                  handleClose={this.toggleConfirmDialog}
                  handleValid={() => { this.props.deleteFlux({ id: this.state.id }) }}
                  open={this.state.isOpenConfirm}
                />

              </div>
            )
          }
        }
      </Translation>

    )
  }
}

export default withStyles(styles)(SettingsFluxModal)