import React from 'react';

// Init component.
function IconMail(props) {
  return (
    <svg className="icon__mail" width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5 2C15.5 1.175 14.825 0.5 14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V11C0.5 11.825 1.175 12.5 2 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2ZM14 2L8 5.7425L2 2H14ZM8 7.25L2 3.5V11H14V3.5L8 7.25Z" fill="#86A1B2" />
    </svg>
  );
}

export default IconMail;
