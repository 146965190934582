import React from 'react'

const AddVideoIcon = () => (
  <svg className="icon__add-video" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM10.5 8.86602C11.1667 8.48112 11.1667 7.51888 10.5 7.13398L7.5 5.40192C6.83333 5.01702 6 5.49815 6 6.26795L6 9.73205C6 10.5019 6.83333 10.983 7.5 10.5981L10.5 8.86602Z"
    />
  </svg>
)

export default AddVideoIcon