import React from "react";

class Mentions extends React.Component {
  render() {
    const app_name = process.env.REACT_APP_NAME === 'tweetit' ? 'Tweet-It' : process.env.REACT_APP_NAME

    return (
      <div className="politics">
        <div className="grid">
          <h1 className="politics__title">
            Politique générale de protection des données
          </h1>

          <h2 className="politics__subtitle">1. Introduction</h2>
          <p className="politics__text">
            Les informations suivantes vous sont communiquées afin que vous
            puissiez prendre connaissance des engagements en matière de
            protection des données à caractère personnel de la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France - zone industrielle Route de Paris 14120 Mondeville - qui
            agit en tant que responsable du traitement pour les traitements de
            données à caractère personnel évoqués dans le présent document.
            <br />
            <br />
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France s’est engagée dans une politique de
            respect et de maintien de normes élevées en matière de déontologie et
            notamment de protection des données à caractère personnel.
            <br />
            <br />
            Sur l’Application <span className="capitalize">{app_name}</span>, la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France met en œuvre
            des traitements de données à caractère personnel en tant que
            responsable du traitement. L’identité et les coordonnées de la
            société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France sont précisées à la rubrique « mentions
            légales» de l’Application.
            <br />
            <br />
            La présente politique de protection des données personnelles a pour
            objectif de vous informer sur la manière dont ces données sont
            traitées par la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France et sur l’étendue de vos
            droits.
            <br />
            <br />
            Elle pourra être amenée à évoluer en fonction du contexte légal et
            réglementaire applicable.

            <br />
            <br />
            Il est rappelé que l’inscription, l’utilisation du site relèvent du libre choix del’utilisateur.
          </p>
          <h2 className="politics__subtitle">
            2. Les principes applicables aux données personnelles
          </h2>
          <p className="politics__text">
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France s’attache au respect des principes
            suivants dans le cadre de la collecte et l’exploitation des données
            personnelles.
          </p>
          <h3 className="politics__subsubtitle">
            2.1 Une utilisation légitime et proportionnée de vos données
          </h3>
          <p className="politics__text">
            Les données personnelles sont collectées, en permanence, par la
            société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France pour des finalités déterminées, explicites
            et légitimes.
            <br />
            <br />
            Les données personnelles collectées par la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France
            ne peuvent être utilisées ultérieurement de manière incompatible avec
            les finalités initiales pour lesquelles elles ont été collectées.
            <br />
            <br />
            Pour chaque traitement, la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France s’engage à ne
            collecter et traiter que des données strictement nécessaires à
            l’objectif poursuivi.
          </p>
          <h3 className="politics__subsubtitle">
            2.2 Une collecte loyale et transparente
          </h3>
          <p className="politics__text">
            Dans un souci de loyauté et de transparence vis-à-vis des
            utilisateurs de l’application la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France prend soin
            d’informer les personnes concernées de chaque traitement qu’elle met
            en œuvre par des mentions d’information.
            <br />
            <br />
            Ces données sont collectées loyalement ; aucune collecte n’est
            effectuée à l’insu des personnes concernées et sans qu’elles en
            soient informées.
          </p>
          <h3 className="politics__subsubtitle">
            2.3 Pertinence adéquation et minimisation des données collectées
          </h3>
          <p className="politics__text">
            Les données personnelles collectées sont strictement nécessaires à
            l’objectif poursuivi par la collecte. La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France
            s’attache à minimiser les données collectées et à les tenir exactes.

            <br />
            <br />
            Les données personnelles collectées sont mises à jour régulièrement et stockéespar la société SAASTORY prestataire de Carrefour France dans ses bases dedonnées sécurisées.
            <br />
            <br />
            Les données personnelles collectées sont mises à jour régulièrement
            et stockées par la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France dans ses bases de
            données sécurisées.
          </p>

          <h3 className="politics__subsubtitle">
            2.4 Une protection des données personnelles dès la conception et par
            défaut
          </h3>
          <p className="politics__text">
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France a adopté des politiques et process
            internes et met en œuvre des mesures qui respectent les principes de
            protection des données personnelles dès la conception et de
            protection des données personnelles par défaut.
            <br />
            <br />
            Ainsi, lors de l'élaboration, de la conception, de la sélection et
            de l'utilisation d'applications,de services et de produits qui
            reposent sur le traitement de données personnelles la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France prend en compte le droit à la protection des
            données personnelles ou s’assure auprès de leurs éditeurs qu’elles
            répondent aux prescriptions légales et permettent d’assurer la
            protection des données qui y seront traitées.
            <br />
            <br />A ce titre, par exemple la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France procède à
            de la pseudonymisation et àdes techniques de chiffrement des données
            personnelles dès que cela est possible ou nécessaire.
          </p>

          <h2 className="politics__subtitle">
            3. Le traitement de vos données
          </h2>
          <h3 className="politics__subsubtitle">
            3.1 Bases légales et finalités des traitements
          </h3>
          <p className="politics__text">
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France met en œuvre sur le présent site web, différents traitements dont les bases légales sont les suivantes : l’exécution d’un contrat, votre consentement, et son intérêt légitime.
          </p>
          <br />
          <p className="politics__text">
            Les finalités associées à chaque base légale sont listées ci-dessous
            :
          </p>
          <br />
          <ul className="politics__list">
            <li className="politics__list-item no-style">
              En se fondant sur le respect de nos obligations légales et
              réglementaires, nous mettons en œuvre des traitements poursuivant
              les finalités suivantes :
            </li>
            <br />
            <li className="politics__list-item no-style">
              La gestion de notre relation avec vous, en tant qu’utilisateurs du site Internet en ce incluant notamment :
            </li>
            <br />
            <li className="politics__list-item no-style">
              <ul className="politics__list sublist">
                <li className="politics__list-item number">
                  la création d’un compte personnel sur le site.
                </li>
                <li className="politics__list-item number">
                  l’utilisation du site et des services qu’il propose.
                </li>
                <li className="politics__list-item number">
                  la   gestion   des   communications   et   le   suivi   des   échanges   avec   les utilisateurs.
                </li>
              </ul>
            </li>
            <br />
            <li className="politics__list-item no-style">
              En se fondant sur nos intérêts légitimes, nous mettons en œuvre
              des traitements poursuivant les finalités sont les suivantes :
            </li>

            <br />
            <li className="politics__list-item no-style">
              <ul className="politics__list sublist">
                <li className="politics__list-item number">
                  la gestion de vos demandes d’information et réclamations ;
                </li>
                <li className="politics__list-item number">
                  l’établissement de tout moyen de preuve nécessaire à la défense de nos droits ;
                </li>
                <li className="politics__list-item number">
                  la   conservation   des   traces   informatiques   pour   détecter   de   manière préventive les incidents de sécurité.
                </li>

              </ul>
            </li>
          </ul>
          <br />
          <p className="politics__text">
            Les finalités poursuivies sur le fondement de nos intérêts légitimes
            le sont dans le respect devos droits et libertés.
            <br />
            Si la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France est amenée à traiter vos données pour
            des finalités autres que celles listées dans le paragraphe ci-avant,
            nous vous en informerons, et procéderons à toutes démarches
            complémentaires éventuellement nécessaires.
          </p>

          <h3 className="politics__subsubtitle">3.2 Collecte de données</h3>
          <p className="politics__text">
            La société  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France s’engage vis-à-vis des utilisateurs
            du site à ne collecter et à ne traiter que les données nécessaires à ses activités. En conséquence, la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France collecte différentes données à caractère personnel vous concernant.
            <br /><br />
            Nous recueillons le prénom, le nom, l’adresse de courrier électronique professionnelle,
            le numéro de téléphone, le lien vers la photo de profil du compte Twitter et/ou LinkedIn
            synchronisé (le cas échéant), le token du compte Twitter et/ou LinkedIn synchronisé (le cas échéant),
            l’ID du compte LinkedIn synchronisé (le cas échéant), l’ID du post proposé à la publication et son statut (le cas échéant).

          </p>

          <h3 className="politics__subsubtitle">3.3 Personnes concernées</h3>
          <p className="politics__text">
            Les personnes concernées par les traitements que réalise la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France sur le présent site sont tous utilisateur du site sans distinction.
            <br />
            <br />
          </p>

          <h3 className="politics__subsubtitle"> 3.4 Destinataires des données</h3>
          <p className="politics__text">

            Pour atteindre les finalités décrites ci-dessus et dans les limites
            nécessaires à la poursuite de ces finalités, les données collectées
            par la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France peuvent être transmises à tout ou
            partie des destinataires suivants :
          </p>
          <br />
          <ul className="politics__list">
            <li className="politics__list-item">
              l’entité juridique du groupe <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> qui gère de manière
              centralisée la base client ;
            </li>
            <li className="politics__list-item">
              nos prestataires, partenaires comme les réseaux sociaux sur
              lesquels vous dispose d’un compte personnel et avec lesquels vous
              acceptez de partager des données personnelles en publiant via
              l’Application <span className="capitalize">{app_name}</span> et sous-traitants (notamment
              les prestataires informatiques, prestataires intervenant comme
              tiers sécurisé dans le secteur de la publicité) ;
            </li>
            <li className="politics__list-item">
              les avocats, auxiliaires de justice et officiers ministériels, les
              autorités administratives ou judiciaires saisies d’un litige le cas
              échéant, dans le cadre du respect des obligations légales
              incombant à la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France ou pour permettre à la
              société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France d’assurer la défense de ses droits et
              intérêts ;
            </li>
            <li className="politics__list-item">
              les services chargés du contrôle tels que les commissaires aux
              comptes et les auditeurs ainsi que les services chargés du contrôle
              interne.
            </li>
          </ul>

          <h3 className="politics__subsubtitle">
            3.5 Durées de conservation des données
          </h3>
          <p className="politics__text">
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France conserve les données à caractère personnel qu’elle collecte uniquement pendant la
            durée nécessaire aux finalités du traitement et en accord avec la législation applicable. Les données collectées
            par la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France seront, ainsi, conservées pour la durée nécessaire à l’accomplissement
            des finalités décrites ci-dessus, augmentée du délai de la prescription légale. Plus précisément,
            nous organisons notre politique de conservation des données de la manière suivante :
          </p>


          <div className="politics__table politics__text">
            <div className="politics__table-row">
              <div className="politics__table-cell">
                Finalités
              </div>
              <div className="politics__table-cell">
                Durée de conservation
              </div>
            </div>
            <div className="politics__table-row">
              <div className="politics__table-cell">
                La gestion de notre relation avec vous, en tant qu’utilisateurs du site Interne
              </div>
              <div className="politics__table-cell">
                Les données sont conservées pendant un (1) an à partir du dernier contact entrant c’est-à-dire si au bout d’une période de un (1) an il n’y a : aucune connexion ou modification à l'espace personnel  depuis 1 an.
              </div>
            </div>
            <div className="politics__table-row">
              <div className="politics__table-cell">
                La gestion des données liées à l’utilisation du site et des services qu’il propose
              </div>
              <div className="politics__table-cell">
                Les données sont conservées pendant un (1) an.
              </div>
            </div>
            <div className="politics__table-row">
              <div className="politics__table-cell">
                La gestion de vos demandes d’information et réclamations
              </div>
              <div className="politics__table-cell">
                Toute la durée de la relation contractuelle.
              </div>
            </div>
            <div className="politics__table-row">
              <div className="politics__table-cell">
                L’établissement de tout moyen de preuve nécessaire à la défense de nos droits.
              </div>
              <div className="politics__table-cell">
                Toute la durée de la relation contractuelle en cas de résiliation ou pendant une période de 3 ans à compter du dernier contact entrant.
              </div>
            </div>
            <div className="politics__table-row">
              <div className="politics__table-cell">
                La conservation des traces informatiques pour détecter de manière préventive les incidents de sécurité
              </div>
              <div className="politics__table-cell">
                Les traces informatiques sont conservées pendant treize (13) mois.
              </div>
            </div>
            <div className="politics__table-row">
              <div className="politics__table-cell">
                La gestion des demandes d’exercice de droits
              </div>
              <div className="politics__table-cell">
                Les données sont conservées pendant un 1 an
              </div>
            </div>
          </div>

          <h3 className="politics__subsubtitle">3.6 Sécurité de vos données</h3>
          <p className="politics__text">
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France accorde une importance particulière à la
            sécurité de vos données personnelles.
            <br />
            <br />
            Elle a mis en place des mesures techniques et organisationnelles
            adaptées au degré de sensibilité des données personnelles, en vue
            d’assurer l’intégrité et à la confidentialité les données et de les
            protéger contre toute intrusion malveillante, toute perte,
            altération ou divulgation à des tiers non autorisés.
            <br />
            <br />
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France effectue régulièrement des audits afin
            de vérifier la bonne application opérationnelle des règles relatives
            à la sécurité des données.
            <br />
            <br />
            Ainsi, elle s’engage à prendre les mesures de sécurité physiques,
            techniques et organisationnelles nécessaires pour :
          </p>
          <br />
          <ul className="politics__list">
            <li className="politics__list-item">protéger ses activités ;</li>
            <li className="politics__list-item">
              préserver la sécurité des données personnelles des utilisateurs de
              l’Application <span className="capitalize">{app_name}</span> contre tout accès, modification,
              déformation, divulgation, destruction ou accès non autorisés des
              données personnelles qu’elle détient.
            </li>
          </ul>
          <br />
          <p className="politics__text">
            Néanmoins, la sécurité et la confidentialité de vos données
            personnelles reposent sur les bonnes pratiques de chacun, ainsi vous
            êtes invité à rester vigilant sur la question.
            <br />
            <br />
            Conformément à ses engagements, la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France choisit
            ses sous-traitants et prestataires avec soin et leur impose :
          </p>
          <br />
          <ul className="politics__list">
            <li className="politics__list-item">
              un niveau de protection des données personnelles équivalent aux
              siens ;
            </li>
            <li className="politics__list-item">
              une utilisation des données personnelles ou des informations
              uniquement pour assurer la gestion des services qu’ils doivent
              fournir ;
            </li>
            <li className="politics__list-item">
              un respect strict de la législation et de la réglementation
              applicable en matière de confidentialité et de données personnelles
              ;
            </li>
            <li className="politics__list-item">
              la mise en œuvre de toutes les mesures adéquates pour assurer la
              protection des données personnelles qu’ils peuvent être amenés à
              traiter ;
            </li>
            <li className="politics__list-item">
              la définition des mesures techniques, organisationnelles
              nécessaires pour assurer la sécurité.
            </li>
          </ul>
          <br />
          <p className="politics__text">
            La société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France conclut avec ses sous-traitants,
            conformément aux obligations légales, des contrats définissant
            précisément les conditions et modalités de traitement des données
            personnelles.
          </p>

          <h3 className="politics__subsubtitle">
            3.7  Utilisation des données dans des applications tierces
          </h3>
          <p className="politics__text">
            En connectant votre compte Tweet-It à l’application tierce LinkedIn ou Twitter, vous acceptez de
            partager avec ces applications tierces vos données Tweet-It. Ce partage sera soumis à la politique de confidentialité
            de l’application tierce concernée. Nous vous invitons à consultez le site web de l’application ou contactez son propriétaire pour obtenir un exemplaire.
          </p>

          <h2 className="politics__subtitle">
            4. Vos droits et leurs modalités d’exercice
          </h2>
          <p className="politics__text">
            Les droits visés ci-dessous ne concernent que les données que <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> disposent à votre égard et non celles qui vous avez partagées avec l’application
            tierce LinkedIn ou Twitter. Pour toute demande concernant ces données, ce sont ces sociétés qu’il conviendra de solliciter directement.
          </p>
          <h3 className="politics__subsubtitle">
            4.1 Le contenu de vos droits
          </h3>
          <p className="politics__text">
            Vous bénéficiez des droits suivants liés aux données vous concernant
            :
          </p>
          <br />
          <ul className="politics__list">
            <li className="politics__list-item">droit d’accès ;</li>
            <li className="politics__list-item">droit de rectification ;</li>
            <li className="politics__list-item">
              droit d’effacement (sauf si elles sont nécessaires à l’exécution
              des services, ou qu’elles sont nécessaires à la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>
              France pour respecter ses obligations légales ou constater ou
              exercer ses droits) ;
            </li>
            <li className="politics__list-item">
              droit de définir des directives relatives au sort de vos données
              après votre décès, etce auprès d’un tiers de confiance, certifié
              et chargé de faire respecter votre volonté,conformément aux
              exigences du cadre juridique applicable.
            </li>
          </ul>
          <br />
          <p className="politics__text">
            Au titre du droit d’accès, la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France pourra vous
            demander le paiement de frais raisonnables basés sur les coûts
            administratifs pour toute copie supplémentaire des données à celle
            qui sera communiquée.
            <br />
            <br />
            Vous disposez également :
          </p>
          <br />
          <ul className="politics__list">
            <li className="politics__list-item">
              d’un droit d’obtenir la limitation d’un traitement ;
            </li>
            <li className="politics__list-item">
              d’un droit à la portabilité des données fournies ;
            </li>
            <li className="politics__list-item">d’un droit d’opposition.</li>
          </ul>
          <h3 className="politics__subsubtitle">4.2 Le droit d’opposition</h3>
          <p className="politics__text">
            Vous pouvez demander à exercer votre droit d’opposition à un
            traitement de données personnelles vous concernant pour des raisons
            tenant à votre situation particulière lorsque le traitement est
            fondé sur l’intérêt légitime de la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France.
            <br />
            <br />
            En cas d’exercice d’un tel droit d’opposition, la société <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France cessera le traitement sauf lorsqu’il existe des motifs
            légitimes et impérieux pour le traitement qui prévalent sur vos
            intérêts, droits et libertés ou pour la constatation, l’exercice ou
            la défense d’un droit en justice.
            <br />
            <br />
            Vous pouvez également vous opposer à tout traitement lié à la
            prospection sans qu’il soit nécessaire d’invoquer des raisons tenant
            à votre situation particulière.
          </p>

          <h3 className="politics__subsubtitle">
            4.3 L’exercice de vos droits
          </h3>
          <p className="politics__text">
            La communication de directives spécifiques post-mortem et l’exercice
            de vos droits s’effectuent par courrier électronique à
            l’adresse reseaux_sociaux@{process.env.REACT_APP_CLIENT_NAME ? process.env.REACT_APP_CLIENT_NAME.replace(/\s/g, '') : 'societe'}.com ou par
            courrier postal au Délégué à la protection des données personnelles,
            àl’adresse 35 rue Pierre et Dominique Ponchardier, espace Fauriel,
            CS 60337, 42015 Saint Etienne cedex 2 , précisant les informations
            permettant d’établir votre identité.
          </p>

          <h3 className="politics__subsubtitle">
            4.4 L’introduction d’une réclamation
          </h3>
          <p className="politics__text">
            Si vous estimez, après nous avoir contactés, que vos droits sur vos
            données ne sont pas respectés, vous pouvez adresser une réclamation à
            la Commission nationale de l’informatique et des libertés (3 place
            de Fontenoy - TSA 80715 – 75334 Paris cedex 07 ; tél.: 01 53 73 22
            22).
          </p>
        </div>
      </div>
    );
  }
}

export default Mentions;
