import React from "react";
import PropTypes from "prop-types";
import xss from "xss";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import i18n from '../../i18n'

import params from '../../utils/params';
import utils from '../../utils/global';
import EventEmitter from '../../utils/EventEmitter';
class TweetitForm extends React.Component {
  constructor(props) {
    super(props);

    this.CONTENT_URL = 'url';
    this.CONTENT_MESSAGE = 'message';
    this.CONTENT_PROFILE = 'screen_name';
    this.CONTENT_COMMENT = 'comment';
    this.CONTENT_PUT_LINK_FORWARD = "put_link_forward"

    this.MEDIA_IMAGE = 'media';
    this.MEDIA_VIDEO = 'bigMedia';

    this.handleFileChange = this.handleFileChange.bind(this);
    this.moveFacebookPosts = this.moveFacebookPosts.bind(this);
    this.loadMoreFacebookPosts = this.loadMoreFacebookPosts.bind(this)
  }

  validateContentDebounced = AwesomeDebouncePromise(this.validateContent.bind(this), 500);

  /* Vérifie le contenu du message et de url */
  validateContent(content, type, postType) {
    // wording error
    const t = i18n.t('manager', { returnObjects: true }).home.window_post_create

    const publishForLinkedin = this.state.formData.network === "linkedin" || (this.state.willPublishForLinkedin && !this.state.willPublishForTwitter)

    let formData = Object.assign({}, this.state.formData);

    /* Si c'est un tweet existant, la validation a lieu avant que le type ne soit récupéré */
    if (!postType) {
      postType = this.state.value; // Si c'est un nouveau tweet, le type est déjà défini par l'UI
    }

    // Retweet
    if (type === this.CONTENT_URL) {
      if (postType !== params.types.REPOST) {
        return;
      }
      if (this.USER_FORM) {
        this.setState({
          urlIsValid: true,
        });
        return;
      }

      // Retrieve tweet info
      if (this.state.willPublishForTwitter) {
        this.fetchTweet(content, type);

        // Retrieve instagram info
      } else if (this.state.willPublishForInstagram) {

        if (content && content.startsWith("https://www.instagram.com/") && (formData.share_ref.link !== content)) {
          this.setState({ urlIsLoading: true, urlIsValid: true })
          this.fetchInstagramPost(content);

        } else if (content && !content.startsWith("https://www.instagram.com/")) {
          EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: t.popin_message.insta_error, style: "error" })
          this.setState({ urlIsValid: false })

        } else if (!content) {
          let formData = this.state.formData
          formData.share_url = ""
          formData.share_ref = { type: formData.share_ref.type }
          this.setState({ formData, urlIsValid: undefined })
        }


        // Retrieve facebook info
      } else if (this.state.willPublishForFacebook) {
        // Reset height
        try {
          document.querySelector('.tweetit-form__preview-panel-scroll').style.height = '100%'
        } catch (e) {

        }

        if (content) {
          let account = content

          if (content.includes('facebook.com/')) {
            account = content.split('facebook.com/')
            account = account && account.length && account[1] ? account[1] : null
            account = account ? account.split('/')[0] : null
          }
          if (account) {
            if (account !== formData.share_ref.screen_name) {

              formData.share_ref.posts = []
              formData.share_ref.index_selected = 0

              this.setState({ urlIsLoading: true, urlIsValid: true, fbPages: 2 }, () => { this.fetchFacebookPosts(account) })

            }
          } else {
            EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: t.popin_message.fb_error, style: "error" })
            this.setState({ urlIsValid: false })
          }
        } else if (!content) {
          let formData = this.state.formData
          formData.share_url = ""
          formData.share_ref = { type: formData.share_ref.type }
          this.setState({ formData, urlIsValid: undefined })
        }
      }

      // Tweet
    } else if (type === this.CONTENT_MESSAGE) {
      if (postType !== params.types.POST) {
        return;
      }

      // there is no message
      if (content.length === 0) {
        // we need to reset preview
        this.setState({
          messageIsValid: false,
          formData: {
            ...this.state.formData, share_ref: null, share_url: null
          }
        });
      } else {
        this.setState({ messageIsValid: true });
        let urls = this.retrieveUrls(content)

        // if there is link in message handle preview
        if (urls && urls.length) {

          // Retrieve the first link
          let firstLinkFound = urls[0]

          // Remove dot, comma and two points at the end of url
          if (firstLinkFound.endsWith(',') || firstLinkFound.endsWith('.') || firstLinkFound.endsWith(':')) firstLinkFound = firstLinkFound.slice(0, firstLinkFound.length - 1)

          // only publishing for linkedin
          if (publishForLinkedin) {
            if (this.state[this.CONTENT_PUT_LINK_FORWARD]) {
              this.fetchExternalLinkPreview(firstLinkFound)
            }
          }

          // only publishing for twitter
          else if (this.state.willPublishForTwitter && !this.state.willPublishForLinkedin) {

            let firstInternalLink = urls.find(urlsItem => utils.isUrlFromTwitter(urlsItem))

            // link is internal
            if (firstInternalLink) {
              this.fetchTweet(firstInternalLink, type)
            }

            // link is external
            else {

              // prevent external preview with media in post
              if (this.state.formData.media.length || this.state.vidSrc || (this.state.imgSrc && this.state.imgSrc.length)) {
                this.resetLinkPreview()
              } else {
                this.fetchExternalLinkPreview(firstLinkFound)
              }
            }
          }

        } else {
          // we need to reset preview if there is no more url
          this.resetLinkPreview()
        }
      }
    } else if (type === this.CONTENT_PROFILE) {
      if (postType !== params.types.PROFILE || this.USER_FORM) {
        return;
      }
      let profileIdRegex = /^@?\w+$/ig,
        profileId = profileIdRegex.exec(content);
      if (!profileId) {
        this.setState({ profileIsValid: !content ? undefined : false });
      } else {
        if (profileId[0]) {
          this.setState({ profileIsValid: true }, async () => {
            // Retrieve the profile information of the user
            let res = await this.props.getProfileAction(profileId[0])

            if (res && res.error) {
              this.setState({ profileIsValid: false, urlIsLoading: false })

            } else if (res) {
              formData.share_ref = {
                profile_image: res.profile_image_url_https,
                screen_name: res.screen_name,
                name: res.name
              };

              this.setState({
                profileIsValid: true,
                urlIsLoading: false,
                formData: formData
              });
            }
          })
        } else {
          this.setState({ profileIsValid: false });
        }
      }
    }
  }

  retrieveUrls = content => {
    const urlRegex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig
    return content.match(urlRegex) || []
  }

  resetLinkPreview = () => {
    let formData = { ...this.state.formData }

    if (!formData.share_ref && !formData.share_url) return

    formData.share_ref = null
    formData.share_url = null
    this.setState({ formData })
  }

  fetchExternalLinkPreview = async urlPreview => {
    let formData = { ...this.state.formData }

    // IF first time fetching preview or prev share_url is different from new urlPreview we fetch again
    // prevent from fetching same website url
    if (!formData.share_url || (formData.share_url && formData.share_url !== urlPreview)) {

      // Add the protocal If it does not match the regex
      const url = !urlPreview.match(/(https?:\/\/[^\s]+)/g) ? `https://${urlPreview}` : urlPreview

      let res = await this.props.fetchOpenGraphAction(url)


      if (res && res.error) {
        // we need to reset link preview if url parsing has failed
        this.resetLinkPreview()
        return;
      }

      // If receive not enough information, do not display the preview
      formData.share_ref = res && Object.keys(res).length > 1 ? res : null
      formData.share_url = urlPreview
      this.setState({
        formData: formData
      })

    }
  }

  handleChange = name => event => {

    let formData = Object.assign({}, this.state.formData);

    let publishForLinkedin = formData.network === "linkedin" || (this.state.willPublishForLinkedin && !this.state.willPublishForTwitter)
    let mustResetMedia = false

    /* Form validation */
    if (name === this.CONTENT_URL) {
      let url = xss(event.target.value.trim());
      this.validateContentDebounced(url, name);

      formData.share_url = url;
    } else if (name === this.CONTENT_MESSAGE) {
      // For a message, event is equal to the value of the message (Only for manager)
      // let message = xss(!this.USER_FORM ? event : event.target.value);
      let message = xss(event)
      this.validateContentDebounced(message, name);

      formData[name] = message;
    } else if (name === this.CONTENT_PROFILE) {
      let profile = xss(event.target.value.trim());
      this.validateContentDebounced(profile, name);

      formData.share_url = profile;
    } else if (name === this.CONTENT_COMMENT) {
      let comment = xss(event.target.value);
      formData[name] = comment;
    }

    // toggle CONTENT_PUT_LINK_FORWARD
    else if (name === this.CONTENT_PUT_LINK_FORWARD) {
      this.setState({
        [this.CONTENT_PUT_LINK_FORWARD]: event.target.checked
      })

      // if disabled -> remove preview
      if (!event.target.checked) {
        formData.share_url = null
        formData.share_ref = null
      }
      // ELSE we need validate message and fetch preview if needed
      else {

        // remove media when put link forward on linkedin
        if (publishForLinkedin) {
          formData.media = []
          mustResetMedia = true
        }
        this.validateContentDebounced(formData[this.CONTENT_MESSAGE], this.CONTENT_MESSAGE);
      }
    }

    this.setState({
      //message: formData.message.replace(/([^>])?([@#]\S+)/g, '$1<span>$2</span>').replace(/\s/g, '&nbsp;'),
      formData: formData,
      isModified: true,
      // remove media when put link forward on linkedin
      imgSrc: mustResetMedia ? [] : this.state.imgSrc
    }, () => {
      // Check the height of the post for Facebook post
      if ((name === this.CONTENT_COMMENT) && this.state.willPublishForFacebook) {
        let currentIndex = (this.state.formData && this.state.formData.share_ref && this.state.formData.share_ref.index_selected) || 0
        this.moveFacebookPosts(currentIndex)
      }
    });
  };

  handleFileChange(event, replace, index) {
    // Persist the event to acces to the property of It in the async functions (onload then onloadedmetadata below)
    event.persist()

    // If the media is video, we check the duration
    if (event.target.id === this.MEDIA_VIDEO) {
      // Init file reader object
      let reader = new FileReader()

      // Function who will be execute when the video has been loaded
      reader.onload = function (e) {

        // Create a video element and set It the video loaded
        let video = document.createElement("video");
        video.setAttribute("src", e.target.result);

        // Function who will be execute when meta data for the video has been loaded
        video.onloadedmetadata = function () {

          // Compare the duration to the maximum (30s pour twitter, 30min pour Linkedin)
          if (this.state.willPublishForTwitter && video.duration <= 30) this.submitMedia(event, replace, index)
          else if (this.state.willPublishForLinkedin && video.duration <= (30 * 60)) this.submitMedia(event, replace, index)

          // Display an error message If the duration is greater
          else this.setState({
            videoError: `La durée maximum de votre vidéo doit être de ${this.state.willPublishForTwitter ? '30s' : '30min'}.`
          });
        }.bind(this);
      }.bind(this)
      // Permit to read the media, at the end It will trigger the onload event
      reader.readAsDataURL(event.target.files[0]);

      // If the media in an image
    } else {
      this.submitMedia(event, replace, index)
    }

  }

  submitMedia(event, replace, index) {
    let target = event.target,
      type,
      size,
      totalSize,
      error = null,
      isVideo = target.id === this.MEDIA_VIDEO;

    if (!target.files || target.files.length === 0) {
      return;
    }

    this.setState({
      imageError: null,
      videoError: null,
      isModified: true
    });

    type = target.files[0].type;
    size = target.files[0].size;

    if (!replace) {
      totalSize = this.state.formData.media.reduce((acc, curr) => {
        acc += curr.size;
        return acc;
      }, 0);
    } else {
      totalSize = this.state.formData.media.filter((m, i) => i !== index).reduce((acc, curr) => {
        acc += (curr.size || 0);
        return acc;
      }, 0);
    }

    if (target.id === this.MEDIA_IMAGE) {
      if (type !== 'image/png' && type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/gif') {
        error = "Format non pris en charge.";
      } else if (this.state.formData.media.filter(m => m.type === 'image/gif').length > 0) {
        error = "Vous ne pouvez pas joindre d'autres images à un GIF.";
      } else if (type === 'image/gif') {
        if (this.state.formData.media.length > 0) {
          error = "Vous ne pouvez pas joindre un GIF à d'autres images."
        } else if (totalSize + size > 15 * 1024 * 1024) {
          error = "Votre GIF doit être inférieur à 15Mo."
        }
      } else if (totalSize + size > 5 * 1024 * 1024) {
        error = "La somme de vos fichiers image doit être inférieure à 5Mo.";
      }
    } else if (target.id === this.MEDIA_VIDEO) {
      if (type !== 'video/mp4') {
        error = "Format non pris en charge. (mp4 uniquement)";
      } else if (this.state.willPublishForLinkedin && size > 100 * 1024 * 1024) {
        error = "Votre vidéo doit être inférieur à 100Mo.";
      } else if (this.state.willPublishForTwitter && size > 13 * 1024 * 1024) {
        error = "Votre vidéo doit être inférieur à 13Mo.";
      }
    }

    if (!error) {
      if (!replace) {
        index = this.state.formData.media.length;
      }
      let formData = Object.assign({}, this.state.formData),
        imgSrc = [...this.state.imgSrc],
        reader = new FileReader(),
        mediaRes, error = "Une erreur est survenue lors du téléchargement de votre média.";

      this.setState({
        imageIsLoading: !isVideo ? this.USER_FORM ? index : true : false,
        videoIsLoading: isVideo,
      });

      reader.onload = async function (e) {

        try {
          mediaRes = await this.props.submitMedia(target.files[0], size);

          // If an error occur when uploading a media
          if (mediaRes.error) {
            error = mediaRes.error
            throw new Error()
          }

          formData.media[index] = {
            uploadcare_id: mediaRes.fileId,
            fileName: target.files[0].name,
            size: size,
            type: type
          };
          formData.mediaType = isVideo ? params.mediaTypes.VIDEO : params.mediaTypes.IMAGE;
        } catch (e) {
          if (!isVideo) {
            this.setState({
              imageError: error,
              videoError: null,
              imageIsLoading: false,
            })
          } else {
            this.setState({
              imageError: null,
              videoError: error,
              videoIsLoading: false,
            })
          }

          if (this.USER_FORM) {
            if (!isVideo) {

            } else {
              this.setState({ vidSrc: null });
            }
          }

          return;
        }

        if (!isVideo) {
          imgSrc[index] = e.target.result;
        } else {
          imgSrc.length = 0;
        }

        let networkIsLinkedin = this.state.formData.network === "linkedin" || (this.state.willPublishForLinkedin && !this.willPublishForTwitter)
        if (networkIsLinkedin) {
          // remove preview
          formData.share_ref = null
          formData.share_url = null
        }


        this.setState({
          [this.CONTENT_PUT_LINK_FORWARD]: networkIsLinkedin ? false : this.CONTENT_PUT_LINK_FORWARD,
          formData: formData,
          imageError: null,
          videoError: null,
          imageIsLoading: false,
          videoIsLoading: false,
          imgSrc: imgSrc,
          vidSrc: isVideo ? e.target.result : null
        }, () => {
          // we need to re-validate the message in order to handle link preview
          // i.e tweeter post with media don't have external link preview
          this.validateContentDebounced(this.state.formData.message, this.CONTENT_MESSAGE)
        });
      }.bind(this);

      reader.readAsDataURL(target.files[0]);
    } else if (!isVideo) {
      this.setState({
        imageError: error,
        videoError: null,
        imageIsLoading: false,
      })
    } else {
      this.setState({
        imageError: null,
        videoError: error,
        videoIsLoading: false,
      })
    }
  }

  handleFileRemove(index) {
    let formData = Object.assign({}, this.state.formData),
      imgSrc = this.state.imgSrc,
      vidSrc = this.state.vidSrc;

    formData.media.splice(index, 1);

    if (formData.mediaType === params.mediaTypes.IMAGE) {
      imgSrc.splice(index, 1);

    } else if (formData.mediaType === params.mediaTypes.VIDEO) {
      vidSrc = null
    }

    this.setState({
      formData: formData,
      imageError: null,
      videoError: null,
      isModified: true,
      isTweetContainGif: false,
      imgSrc: imgSrc,
      vidSrc: vidSrc
    }, () => {
      // we need to re-validate the message in order to handle link preview
      // i.e tweeter post with media don't have external link preview
      this.validateContentDebounced(this.state.formData.message, this.CONTENT_MESSAGE)
    })
  }

  async fetchSubPreview(tweet, type) {

    let sub_res = null
    let tweetUrls = tweet.entities && tweet.entities.urls

    if (tweetUrls && tweetUrls.length) {

      let is_quote_status = tweet.is_quote_status

      // link is internal
      if (is_quote_status) {

        let response = this.formatTweetResponse(tweet.quoted_status, type, true)
        sub_res = response.share_ref

      }

      // link is external
      // if no media in tweet we can fetch external preview
      else if (!tweet.entities || !tweet.entities.media || !tweet.entities.media.length) {
        let res = await this.props.fetchOpenGraphAction(tweetUrls[0].expanded_url)

        if (res.error) {
          sub_res = null
        } else {
          sub_res = res
        }

      }

    } else {
      sub_res = null
    }

    return sub_res

  }

  formatTweetResponse(res, type, isSubPreview = false) {
    let response = {
      share_ref: {},
      share_url: null
    }

    response.share_ref.url = `https://twitter.com/${res.user.screen_name}/status/${res.id_str}`;
    response.share_ref.name = res.user.name;
    response.share_ref.screen_name = res.user.screen_name;
    response.share_ref.message = res.display_text_range && res.display_text_range.length && res.full_text ? [...res.full_text].slice(res.display_text_range[0], res.display_text_range[1]) : res.full_text

    if (type !== this.CONTENT_URL) {
      // To manage the way to display or not, the URL twitter in the preview of a post
      response.share_url = `https://twitter.com/${res.user.screen_name}/status/${res.id_str}`;
    }

    if (res.extended_entities && 'media' in res.extended_entities && res.extended_entities.media.length) {
      response.share_ref.mediaType = res.extended_entities.media.length > 1 ? params.mediaTypes.IMAGE : res.extended_entities.media[0].type === 'photo' ? params.mediaTypes.IMAGE : params.mediaTypes.VIDEO
      response.share_ref.media_url_https = response.share_ref.mediaType === params.mediaTypes.IMAGE ? res.extended_entities.media.map(media => media.media_url_https) : (res.extended_entities.media[0].video_info.variants.filter(s => s.content_type === 'video/mp4'))[0].url
    }

    return response
  }

  fetchTweet(content, type) {
    let formData = Object.assign({}, this.state.formData)

    // Prevents retweet to be desplayed if there is no space or line break before the url
    const urlStart = content.substring(0, 3)
    if (!['www', 'twi', 'htt'].includes(urlStart)) {
      formData.share_ref = null;
      this.setState({
        formData: formData
      })
      return

    } else if (type === this.CONTENT_MESSAGE) {
      formData.share_ref = {};

    } else if (type === this.CONTENT_URL) {
      formData.share_ref = {};

    } else {
      return;
    }

    let twitterIdRegex = /status\/([0-9a-zA-Z]*)+$/ig,
      tweet_id = twitterIdRegex.exec(content);

    if (!tweet_id || tweet_id.length < 2) {

      if (type === this.CONTENT_URL) {
        formData.share_ref = null
        this.setState({ urlIsValid: !content ? undefined : false, formData });
      } else { // Cas citation
        formData.share_ref = null;
        this.setState({ formData: formData });
      }
    } else {
      /* Extrait l'id du tweet depuis l'url ensuite demande une vérification à l'api */
      if (tweet_id[1]) {
        if (type === this.CONTENT_MESSAGE
          && this.state.formData.share_ref
          && this.state.formData.share_ref.url
          && this.state.formData.share_ref.url.match(tweet_id[1])) { // Cas citation : si l'url n'a pas changé
          return;
        }
        this.setState({ urlIsLoading: type === this.CONTENT_URL }, () => {
          this.props.getTweetAction(tweet_id[1]).then(async (res) => {

            if (res.error) {
              if (type === this.CONTENT_URL) {
                formData.share_ref = null;

                this.setState({
                  urlIsValid: false,
                  urlIsLoading: false,
                  formData: formData
                });
              } else { // Cas citation
                formData.share_ref = null;
                formData.share_url = null;
                this.setState({ formData: formData })
              }
            } else {
              // format response
              let response = this.formatTweetResponse(res, type, false)
              formData.share_ref = response.share_ref
              if (response.share_url) formData.share_url = response.share_url

              // fetch sub preview
              let subPreview = await this.fetchSubPreview(res, type)
              if (subPreview) formData.share_ref.sub_preview = subPreview

              if (type === this.CONTENT_URL) {
                this.setState({
                  urlIsValid: true,
                  urlIsLoading: false,
                });
              }
              this.setState({
                formData: formData
              });
            }
          }).catch((err) => {
            console.error(err)
            if (type === this.CONTENT_URL) {
              formData.message = ""
              formData.share_ref = null;

              this.setState({
                urlIsValid: false,
                urlIsLoading: false,
                formData: formData
              });
            } else { // Cas citation
              formData.share_ref = null;
              formData.share_url = null;
              this.setState({ formData: formData })
            }
          })
        })
      } else {
        if (type === this.CONTENT_URL) {
          formData.share_ref = null;
          formData.message = ""

          this.setState({ urlIsValid: false, formData: formData });
        } else { // Cas citation
          formData.share_ref = null;
          this.setState({ formData: formData });
        }
      }
    }
  }

  async fetchInstagramPost(url) {
    let res = await this.props.getInstagramPostAction(url)
      .catch((err) => { return { error: err } })

    if (res && !res.error) {
      let formData = this.state.formData
      formData.share_url = res.link
      formData.share_ref.media_type = res.type
      formData.share_ref.message = res.caption
      formData.share_ref.image = res.image_high_resolution_url || res.image_standard_resolution_url || res.image_low_resolution_url
      formData.share_ref.thumbnail = res.image_thumbnail_url
      formData.share_ref.screen_name = res.username
      formData.share_ref.display_name = res.full_name
      formData.share_ref.link = res.link

      this.setState({ formData, urlIsValid: true, urlIsLoading: false })

    } else if (res && res.error) {
      console.error(res.error)
      this.setState({ urlIsValid: false, urlIsLoading: false })
    }
  }

  async fetchFacebookPosts(account) {
    let res = await this.props.getFacebookPostsAction(account, this.state.fbPages)
      .catch((err) => { return { error: err } })

    if (res && !res.error) {
      let formData = this.state.formData

      // Store the Facebook posts
      formData.share_ref.posts = formData.share_ref.posts ? formData.share_ref.posts : []

      let post_ids = formData.share_ref.posts.map(p => p.post_id)

      res = res.filter(r => !post_ids.includes(r.post_id))

      for (let r of res) {
        let og = null

        if (r.video) {
          og = await this.props.fetchOpenGraphAction(`https://www.facebook.com/${account}/posts/${r.post_id}`)
            .catch((err) => { return { error: err } })

          r.image = og && !og.error ? og.image : r.image

        } else if (r.link && !r.image) {
          og = await this.props.fetchOpenGraphAction(r.link)
            .catch((err) => { return { error: err } })

          r.shared_link = og && !og.error ? og : r.link
        }

        formData.share_ref.posts.push({
          post_link: `https://www.facebook.com/${account}/posts/${r.post_id}`,
          image: r.image,
          video: r.video,
          shared_link: r.shared_link,
          post_id: r.post_id,
          message: r.post_text || r.text || r.shared_text,
          screen_name: account,
          media_type: r.video ? 'video' : r.image ? 'image' : null
        })
      }

      // Store the selected index
      formData.share_ref.index_selected = formData.share_ref.index_selected ? formData.share_ref.index_selected : 0

      // Store the account
      formData.share_ref.screen_name = account

      this.setState({ formData, urlIsValid: true, urlIsLoading: false }, () => {
        this.moveFacebookPosts(formData.share_ref.index_selected)
      })

    } else if (res && res.error) {
      console.error(res.error)
      this.setState({ urlIsValid: false, urlIsLoading: false })
    }
  }

  moveFacebookPosts(index) {
    try {
      let scroll = document.querySelector('.tweetit-form__preview-panel-scroll')
      let container = document.querySelector('.tweetit-form__preview-panel-container')
      let el = document.querySelectorAll('.tweetit-form__preview-post')[index]

      // Moving the container to the selected post position
      let top = parseInt(el.offsetTop, 10)
      top = top < 0 ? (top * -1) : (top * -1)

      if (el.offsetHeight > 700) {
        let post = el.querySelector('.tweet-card__card-content')
        post.style.maxHeight = '500px'
        post.style.overflowY = 'auto'
      }

      scroll.style.height = `${el.offsetHeight}px`
      container.style.transform = `translateY(${top}px)`
    } catch (e) {
      console.error(e)
    }
  }

  loadMoreFacebookPosts() {
    let fbPages = this.state.fbPages + 2
    this.setState({ urlIsLoading: true, fbPages }, () => {
      this.fetchFacebookPosts(this.state.formData.share_ref.screen_name, fbPages)
    })
  }
}

TweetitForm.propTypes = {
  submitMedia: PropTypes.func.isRequired,
};

export default (TweetitForm);
