
const popoverNeedToAuthStyles = {
  background: "#525252",
  color: "#fff",
  textAlign: "center",
  padding: 10,
  fontSize: 13,
  fontWeight: 400,
  display: "inline-block",
  width: 240,

  "& > p": {
    marginBottom: 10
  },

  "& > button": {
    background: "#fff",
    color: "#697E8B",
    border: "1px solid #C3D0D7",
    padding: "8px 15px",
    fontSize: 15,
    borderRadius: 5,
    lineHeight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",

    "& > svg": {
      fill: "#0071A1",
      marginRight: 10
    }
  }
}

const styles = theme => ({

  popoverNeedToAuthTwitter: Object.assign({}, popoverNeedToAuthStyles, {

  }),

  popoverNeedToAuthLinkedin: Object.assign({}, popoverNeedToAuthStyles, {

  }),

  tooltipPlacementBottom: {
    margin: "10px 0"
  },
  tooltipPopover: {
    opacity: 1
  },

  dialogAppear: {
    opacity: 0,
    transform: "translateY(10px)"
  },
  dialogAppearActive: {
    opacity: 1,
    transform: "translateY(0)",
    transition: "opacity .05s, transform .3s",
    transitionTimingFunction: "cubic-bezier(.25, .1, .25, 1)",
  },
  dialogExit: {
    opacity: 1,
    transform: "translateY(0)",
  },
  dialogExitActive: {
    opacity: 0,
    transform: "translateY(-10px)",
    transition: "opacity .05s, transform .2s",
    transitionTimingFunction: "cubic-bezier(.25, .1, .25, 1)",
  },
  dialogPaper: {
    backgroundColor: "white",
    width: "100%",
    textTransform: 'none',
    paddingBottom: 0
  },
  dialogRoot: {
    '& > div:first-child': {
      backgroundColor: "rgba(255, 255, 255, 0.35)",
    }
  },
  dialogClose: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.background.paper,
    border: `solid 1px ${theme.cloudyBlue}`,
    borderRadius: 5,
    padding: 0,
    marginRight: 15
  },
  action: {
    marginRight: 20,
    margin: 0,
    "& .leftIcon": {
      height: 16,
      width: 16,
      marginRight: 10,
      verticalAlign: "sub"
    },
    "& .rightIcon": {
      height: 16,
      width: 16,
      marginLeft: 10,
      verticalAlign: "sub"
    }
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
  picker: {
    overflow: "hidden",
    padding: "5px 15px 20px"
  },
  pickerSubmit: {
    width: "calc(100% - 40px)",
    margin: "20px 20px 0"
  },
  buttonScheduleActive: {
    backgroundColor: "white",
    border: `solid 1px ${theme.cloudyBlue}`,
    color: theme.greyBlue,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "inherit",
      borderColor: "#abb6bd",
    },
  },
  buttonSchedule: {
    backgroundColor: theme.greyBlue,
    "&:hover": {
      backgroundColor: "#556670",
    },
    "& .leftIcon": {
      filter: "brightness(1000%)",
    },
  },
  buttonEdit: {
    margin: "0 20px 0 0",
    fontWeight: 600,
  },
  buttonAddImage: {
    padding: 0,
    backgroundColor: "#fff",
    borderRadius: "100%",
    width: 36,
    height: 36,
    textAlign: "center",
    lineHeight: 36,
    marginRight: 10,
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
  },
  errorText: {
    color: "red",
    whiteSpace: "pre",
    bottom: -25,
  },
  navLinkCancel: {
    color: theme.grapefruit,
    textDecoration: "underline"
  },

  shareUrlLabel: {
    color: theme.greyishBrown
  },

  shareUrlCheckbox: {
    padding: "10px"
  },

  formControl: {
    position: "relative",
    marginTop: 40,
  },
  select: {
    paddingTop: 5,
    paddingBottom: 0,
    display: 'flex',
    flexFlow: 'wrap',
    alignItems: 'center'
  },
  selectInput: {
    borderColor: theme.cloudyBlue,
  },
  selectRoot: {
    paddingLeft: 20,
    paddingRight: 14,
  },
  selectAdornment: {
    marginTop: 4,
  },
  selectChip: {
    marginRight: 5
  },
  groupChip: Object.assign({}, theme.groupChip, {
    marginBottom: 5
  }),
  tagChip: Object.assign({}, theme.tagChip, {
    fontSize: 11,
    borderRadius: 15,
    height: 30,
    "&:hover": {
      backgroundColor: "#ebdaf7",
    }
  }),
  message: {
    pointerEvents: "none",
    position: "absolute",
    zIndex: 1,
    top: 51,
    left: 22,
    right: 22,
    lineHeight: "1.1875em",
    "& span": {
      color: "#1ca1f2"
    }
  },
  chip: {
    height: 34,
    borderRadius: 17,
    fontSize: 14,
    color: 'white',
    marginRight: theme.spacing.unit,
    "&:active": {
      boxShadow: "none",
    }
  },
  inactiveChip: {
    color: theme.palette.primary.main,
    transitionProperty: "color, background-color",
    backgroundColor: 'transparent',
    "&:hover": {
      backgroundColor: "#e7e7e7",
    }
  },
  avatar: Object.assign({}, theme.userChip, {
    border: "none",
    marginRight: 5,
    marginBottom: 5
  }),
  input: {
    display: 'none',
  },
  mediaLoader: {
    marginRight: 18,
  },
  media: {
    minWidth: 116,
    objectFit: "cover",
    borderRadius: 5,
    width: "100%",
    height: "100%"
  },
  mediaAppear: {
    opacity: 0.01,
    transform: "translate(0, 15px)"
  },
  mediaAppearActive: {
    opacity: 1,
    transform: "translate(0, 0)",
    transition: "opacity .15s, transform .15s"
  },
  buttonRemoveMedia: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
    padding: 10
  },
  progress: {
    margin: 0,
  },
  progressBtn: {
    margin: "0 10px 0 0",
  },
  tweetCount: {
    position: "absolute",
    right: 10,
    bottom: 10,
    color: theme.mintyGreen,
    fontWeight: 600,
    fontSize: 12,
  },

  selectInputFocused: {
    borderColor: theme.greyBlue
  },

  selectInputRoot: {
    "&:hover": {
      "& fieldset": {
        borderColor: [theme.greyBlue, "!important"],
      }
    },
  },
  // Tooltip
  tooltipUser: {
    maxHeight: 400,
    overflow: "hidden auto",
    pointerEvents: "unset",
    opacity: 1
  },
  tooltip: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    lineHeight: 1.5,
  },
  tooltipTitle: {
    display: "inline-flex",
  },
  tooltipContainer: {
    width: "100%",
    top: -13,
    position: "absolute",
    paddingRight: 16,
    textAlign: "center"
  },
});

export default styles;