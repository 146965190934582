import React from 'react'

const IconCalendar = () => (
  <svg className="icon icon__calendar" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z" stroke="#697E8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 1V5" stroke="#697E8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 1V5" stroke="#697E8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 9H19" stroke="#697E8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default IconCalendar
