import React from 'react'

const AddImageIcon = () => (
  <svg className="icon__add-image" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4ZM5.89046 9.4285L6.87691 10.8787L9.4267 5.58135C9.67665 5.06205 10.2845 4.85157 10.7844 5.11121C11.0061 5.22636 11.1794 5.422 11.2715 5.66105L13.9273 12.5583C14.1349 13.0974 13.8825 13.7092 13.3636 13.9248C13.244 13.9745 13.1165 14 12.9877 14H9H7.01199H2.98156C2.43946 14 2 13.5693 2 13.038C2 12.848 2.05736 12.6624 2.16485 12.5043L4.25704 9.4285C4.55774 8.98641 5.16716 8.86695 5.61822 9.16168C5.72605 9.23213 5.81857 9.32281 5.89046 9.4285Z"
    />
  </svg>
)

export default AddImageIcon