const styles = theme => ({
  progress: {
    margin: "0 10px 0 0",
  },
  progressSquare: {
    margin: 0,
    marginRight: 10
  },
  imgProgress: {
    margin: "auto",
  },
  imgProgressContainer: {
    width: "100%",
    height: 200,
    marginTop: 20,
    backgroundColor: "rgba(183, 183, 183, 0.1)",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  date: {
    fontSize: 14,
    textAlign: "right",
    fontWeight: 600,
    color: "#525252",
    lineHeight: 1.2,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",

    "& > div": {
      display: "inline-block",
      verticalAlign: "middle",
      marginTop: 1,
      marginRight: 5,
    },
  },
  tagChip: Object.assign({}, theme.tagChip, {
    textTransform: "uppercase",
    fontSize: 11,
    borderRadius: 9.5,
    height: "auto",
    lineHeight: "13px",
    maxWidth: 180,
  }),
  tagChipLabel: {
    whiteSpace: "normal",
    padding: "4px 12px",
    wordBreak: "break-word",
  },
  tagChipAlignTop: {
    alignSelf: "flex-end",
    flexShrink: 0
  },
  retweetIcon: {
    display: "flex",
    fontSize: 15,
    fontWeight: 600,
    "& > div": {
      marginRight: 10
    }
  },
  manager: {
    fontSize: 13,
    textAlign: "right",
    fontWeight: 500,
    color: "#86a1b2",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  avatar: Object.assign({}, theme.userChip, {
    marginRight: -13,
  }),
  groupChip: Object.assign({}, theme.groupChip, {
    zIndex: 2,
    boxShadow: "0 0 0 2px white",
    cursor: "pointer"
  }),
  groupCount: {
    marginLeft: 10,
    color: theme.blueGrey,
    fontSize: 12,
    fontWeight: "bold",
    minWidth: 30
  },
  video: {
    opacity: 0.95,
  },
  media: {
    width: "100%",
    height: 200,
    objectFit: "cover",
    borderRadius: 5,
  },
  mediaSmall: {
    height: 97,
  },
  mediaPlay: {
    position: "absolute",
    fontSize: "50px",
    top: "calc(50% - 15px)",
    left: "calc(50% - 25px)",
    color: "white",
    cursor: "pointer",
    pointerEvents: "none"
  },
  square: {
    padding: 0,
    width: 36,
    minWidth: 36,
    height: 36,
    minHeight: 36,
  },
  bigAvatar: {
    width: 60,
    height: 60
  },
  profileName: {
    fontSize: 15,
    fontWeight: 600
  },
  profileScreenName: {
    fontSize: 13,
    fontWeight: 600
  },
  // Tooltip
  tooltipUser: {
    maxHeight: 400,
    overflow: "hidden auto",
    pointerEvents: "unset"
  },
  tooltip: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    lineHeight: 1.5,
  },
  tooltipTitle: {
    display: "inline-flex",
  },
  tooltipContainer: {
    width: "100%",
    top: -13,
    position: "absolute",
    paddingRight: 16,
    textAlign: "center",
    lineHeight: 2
  },
  textContainer: {
    whiteSpace: "pre-wrap"
  },
  buttonPrimary: { // TO-REVIEW feature/129
    color: theme.palette.primary.main,

    "& .icon__follow, & .icon__send, & .icon__retweet": {
      fill: theme.palette.primary.main,
      marginRight: 10
    },

    "& .icon__retweet": {
      stroke: theme.palette.primary.main
    },

    "&:hover": {
      color: theme.palette.primary.hover,

      "& .icon__follow, & .icon__send,": {
        fill: theme.palette.primary.hover,
      },

      "& .icon__retweet": {
        stroke: theme.palette.primary.hover
      },
    }
  }
});


export default styles