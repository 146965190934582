// Libs
import React from "react"
import Proptypes from "prop-types"

// Components
import Slide from "./Slide"

class Slider extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      cardWidth: 0,
      currentIndex: 0,
      translation: 0,

      pageY: 0,
      pageX: 0,
    }

    this.sliderRef = React.createRef()

    /** Bind */
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.handleMouseStart = this.handleMouseStart.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseEnd = this.handleMouseEnd.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  /** Lifecycle */

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  }

  componentWillMount() {
    window.removeEventListener("resize", this.handleResize)
  }

  componentDidUpdate(prevProps) {

    // Resize component when there is new children
    if (prevProps.children.length !== this.props.children.length) {
      this.handleResize()
    }
  }

  /** Events */

  handleTouchMove(e) {
    let touchobj = e.changedTouches[0];
    // Enable the scroll If we detect a  vertical movement higher than 50px
    if (Math.abs(this.state.pageY - touchobj.pageY) > 50) {
      e.preventDefault();
    }
  }

  handleTouchStart(e) {
    let touchobj = e.changedTouches[0];
    // Saving the first position of the user when he start touching the phone
    this.setState({
      pageX: touchobj.pageX,
      pageY: touchobj.pageY
    })
  }

  handleTouchEnd(e) {
    let touchobj = e.changedTouches[0];
    // Calculating the difference between the first horizontal position and the last horizontal position
    // If it's lower than 50 px we don't trigger the slide movement
    if (Math.abs(this.state.pageX - touchobj.pageX) < 50) {
      return;
    }

    // Calculating the difference between the first horizontal position and the last horizontal position
    // If it's POSITIVE, sliding to the RIGHT
    // If it's NEGATIVE, sliding to the LEFT
    if (this.state.pageX - touchobj.pageX < 0) {
      this.moveCard("left")
    } else if (this.state.pageX - touchobj.pageX > 0) {
      this.moveCard("right")
    }
  }

  handleMouseMove(e) {

    // Enable the scroll If we detect a  vertical movement higher than 50px
    if (Math.abs(this.state.pageY - e.clientY) > 50) {
      e.preventDefault();
    }
  }

  handleMouseStart(e) {

    e.preventDefault()

    // Saving the first position of the user when he start touching the phone
    this.setState({
      pageX: e.clientX,
      pageY: e.clientY
    })
  }

  handleMouseEnd(e) {

    let x = e.clientX

    // Calculating the difference between the first horizontal position and the last horizontal position
    // If it's lower than 50 px we don't trigger the slide movement
    if (Math.abs(this.state.pageX - x) < 50) {
      return;
    }

    // Calculating the difference between the first horizontal position and the last horizontal position
    // If it's POSITIVE, sliding to the RIGHT
    // If it's NEGATIVE, sliding to the LEFT
    if (this.state.pageX - x < 0) {
      this.moveCard("left")
    } else if (this.state.pageX - x > 0) {
      this.moveCard("right")
    }
  }

  moveCard(direction) {

    let currentIndex = this.state.currentIndex
    let nextIndex = currentIndex

    if ((this.props.children.length - currentIndex) <= 3) this.props.loadMore()

    // clamp to edge of slider
    if ((currentIndex === 0 && direction === "left") || (currentIndex === this.props.children.length - 1 && direction === "right")) return;

    // set next index
    if (direction === "left") {
      nextIndex -= 1
    }
    else if (direction === "right") {
      nextIndex += 1
    }


    this.setState({
      currentIndex: nextIndex,
      translation: -(this.state.cardWidth + this.props.slideSpacing) * nextIndex
    })
  }

  handleResize() {
    let slides = this.retrieveSlides()

    // get slide dimensions
    let width = slides[this.state.currentIndex] && slides[this.state.currentIndex].offsetWidth

    if (width) {
      this.setState({
        cardWidth: width,
      })
    }
  }

  /** Build */

  buildeSlides() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      // checking isValidElement is the safe way and avoids a typescript error too
      const props = { loadMore: this.props.loadMore, handleResize: this.handleResize, currentIndex: this.state.currentIndex, total_cards: this.props.children.length };
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    });

    //const childrenWithProps = this.props.children
    return childrenWithProps && childrenWithProps.map((child, index) => {
      return (
        <Slide
          key={index}
          left={(this.props.slideSpacing + this.state.cardWidth) * index}
        >
          {child}
        </Slide>
      )
    })
  }

  getMaxHeight() {
    let max = 456
    let slides = this.retrieveSlides()

    if (!slides || !slides.length) return max

    for (let s of slides) {
      max = s.offsetHeight > max ? s.offsetHeight : max
    }

    return max
  }

  retrieveSlides() {
    if (!(this.sliderRef && this.sliderRef.current)) return []

    return this.sliderRef.current.querySelectorAll('.slider__slide')
  }

  render() {

    let slides = this.buildeSlides()
    let height = this.getMaxHeight()

    return (
      <div
        className="slider"

        // mobile swipe
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}

        // desktop swipe
        onMouseDown={this.handleMouseStart}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseEnd}
        style={{ height: `${height}px` }}
        ref={this.sliderRef}
      >
        <div
          style={{ transform: `translate(${this.state.translation}px, 0px)` }}
          className="slider__track"
        >
          {slides}
        </div>
      </div>
    )
  }
}

Slider.defaultProps = {
  slideSpacing: 25,
  children: []
}

Slider.propTypes = {
  slideSpacing: Proptypes.number,

}

export default Slider