import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";

// Components
import params from "../../../utils/params";
import ProgressiveImage from '../progressiveImage/progressiveImage';

// Material styles
import styles from "./material-media-dialog";


class MediaDialog extends React.Component {
  state = {
    currentIndex: 0
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingImage: true,
      currentIndex: null
    }

    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open && (typeof (this.props.currentIndex) === 'number')) {
      this.setState({
        currentIndex: this.props.currentIndex
      })
    }
  }

  prev() {
    let length = this.props.media.native ? this.props.media.url.length : this.props.media.length
    this.setState({
      currentIndex: this.state.currentIndex - 1 >= 0 ? this.state.currentIndex - 1 : length - 1,
      loadingImage: true
    })
  }

  next() {
    let length = this.props.media.native ? this.props.media.url.length : this.props.media.length

    this.setState({
      currentIndex: (this.state.currentIndex + 1) % length,
      loadingImage: true
    })
  }

  handleLoad() {
    this.setState({
      loadingImage: false
    })
  }

  render() {

    return (this.props.media.length > 0 || this.props.media.native) && typeof (this.state.currentIndex) === 'number'
      ? <Dialog
        fullWidth={!!this.props.fullWidth}
        open={this.props.open}
        classes={{
          paper: this.props.fullWidth ? this.props.classes.fullWidthPaper : this.props.classes.paper
        }}
      >
        <ClickAwayListener
          onClickAway={this.props.handleClose}
        >
          <div className="media-dialog">
            {/* property media.native, is when the ressources comes from twitter and not uploadcare*/}
            {this.props.media.native === params.mediaTypes.VIDEO || (this.props.media[0] && (this.props.media[0].type === params.mediaTypes.VIDEO || this.props.media[0].type.includes('video')))
              ? <div className={this.props.classes.videoWrapper}>
                <video
                  controls
                  autoPlay={true}
                  className={classNames(this.props.classes.media, this.props.classes.mediaFullWidth)}
                  src={this.props.media.native ? this.props.media.url : this.props.getMediaUrl(this.props.media[0])}
                />
              </div>
              : <div className={`media-dialog__image-wrapper ${this.props.classes.imageWrapper}`}>

                <ProgressiveImage
                  src={this.props.media.native ? this.props.media.url[this.state.currentIndex] : this.props.getMediaUrl(this.props.media[this.state.currentIndex], true)}
                  placeholder={this.props.media.native ? `${this.props.media.url[this.state.currentIndex]}:small` : `${this.props.getMediaUrl(this.props.media[this.state.currentIndex])}-/resize/x50/ `}
                  onLoadFinish={this.handleLoad}
                >
                  {(src, loading) =>
                    <img
                      alt={params.mediaTypes.IMAGE}
                      data-src={loading}
                      className={`
												tweet-card__media ${this.state.loadingImage ? 'loading' : ''}
											`}
                      src={src}
                    />
                  }
                </ProgressiveImage>

                {this.props.media.length > 1 || (this.props.media.native && (this.props.media.url.length > 1)) ?
                  <Grid container alignItems={"center"} justify={"center"} className={this.props.classes.imageControls}>
                    <PlayArrowRoundedIcon className={this.props.classes.prev}
                      onClick={this.prev}
                    />
                    <Typography className={this.props.classes.pagination}>
                      {this.state.currentIndex + 1}/{this.props.media.length || this.props.media.url.length}
                    </Typography>
                    <PlayArrowRoundedIcon className={this.props.classes.next}
                      onClick={this.next}
                    />
                  </Grid>
                  : ''
                }
              </div>
            }
            <CloseRoundedIcon className={this.props.classes.close}
              onClick={this.props.handleClose}
            />
          </div>
        </ClickAwayListener>
      </Dialog>
      : null
  }
}

MediaDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  currentIndex: PropTypes.number,
  getMediaUrl: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  media: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired
};

export default withStyles(styles)(MediaDialog)
