import React from 'react';

// Init component.
function IconLinkedinBg(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#006699" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.025 21.1389V11.5989H7.8541V21.1389H11.025ZM9.43955 10.2964C10.5453 10.2964 11.2336 9.56383 11.2336 8.64834C11.2129 7.71224 10.5453 7 9.46052 7C8.37585 7 7.66666 7.71224 7.66666 8.64834C7.66666 9.56383 8.35475 10.2964 9.41888 10.2964H9.43949H9.43955Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7797 21.1389H15.9506V15.8114C15.9506 15.5263 15.9712 15.2414 16.0549 15.0376C16.2841 14.4679 16.8059 13.8779 17.6819 13.8779C18.8293 13.8779 19.2883 14.7528 19.2883 16.0352V21.1389H22.4589V15.6688C22.4589 12.7386 20.8945 11.3751 18.8083 11.3751C17.0977 11.3751 16.3467 12.3312 15.9295 12.9825H15.9507V11.5989H12.7798C12.8214 12.4941 12.7798 21.1389 12.7798 21.1389H12.7797Z" fill="white" />
    </svg>
  );
}

export default IconLinkedinBg;
