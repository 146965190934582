// Libs
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { Editor, EditorState, ContentState, CompositeDecorator, getDefaultKeyBinding, Modifier } from 'draft-js'

// Utils
import params from '../../../utils/params'
import GlobalUtils from '../../../utils/global'

// Components
import LinkedinWarning from './LinkedinWarning'
import TwitterDropdown from '../../TwitterDropdown'
import EmojiPicker from '../../emojiPicker/emojiPicker'

const TextEditor = ({
  emojisIsOpen,
  handleCloseEmojis,
  onChange,
  placeholder,
  searchAccountAction,
  value,
  warning,
  willPublishForLinkedin,
  willPublishForTwitter
}) => {
  const findWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText()
    let matchArr, start
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      callback(start, start + matchArr[0].length)
    }
  }

  const findWithUrlRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText()

    let matchArr, start

    while ((matchArr = regex.exec(text)) !== null) {
      let url = matchArr[0]

      // Remove dot, comma and two points at the end of url
      if (url.endsWith(',') || url.endsWith('.') || url.endsWith(':')) url = url.slice(0, url.length - 1)

      let urlHasProtocol = url.startsWith("http://") || url.startsWith("https://")
      let tldListIndex = -1

      try {
        let urlObject = new URL(!urlHasProtocol ? "https://" + url : url)

        let tld = urlObject.hostname.split(".").pop()
        tldListIndex = GlobalUtils.binarySearch(tld, params.TLD_LIST)

        // if url domain is unknow return plain text
        if (tldListIndex !== -1) {
          start = matchArr.index

          callback(start, start + url.length)
        }

      } catch (error) {
        console.error(error)
      }
    }
  }

  // Highlight URLs
  const urlStrategy = (contentBlock, callback, contentState) => {
    findWithUrlRegex(URL_REGEX, contentBlock, callback)
  }

  // Highlight handles and display mention dropdown
  const handleStrategy = (contentBlock, callback, contentState) => {
    findWithRegex(HANDLE_REGEX, contentBlock, callback)
  }

  // Highlight hashtags
  const hashtagStrategy = (contentBlock, callback, contentState) => {
    findWithRegex(HASHTAG_REGEX, contentBlock, callback)
  }

  // The order of strategy : From highest priority to least priority
  const compositeDecorator = new CompositeDecorator([
    { strategy: urlStrategy, component: UrlSpan },
    { strategy: hashtagStrategy, component: HashtagSpan },
    { strategy: handleStrategy, component: HandleSpan }
  ])

  const URL_REGEX = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig
  const HASHTAG_REGEX = /#(\S+\b)/igu
  const HANDLE_REGEX = /@([0-9a-zàâäéèêëîïôöùûü\-'%]+)/ig

  // State
  // const [anchorPos, SetAnchorPos] = useState(null)
  const [focused, setFocused] = useState(false)
  const [editor, setEditor] = useState(EditorState.createWithContent(ContentState.createFromText(value), compositeDecorator))
  useEffect(async () => {
    onChange(editor.getCurrentContent().getPlainText())
    if (willPublishForTwitter && focused) {
      await searchMention()
    } else if (willPublishForLinkedin) {
      toggleLinkedinWarning()
    }
  }, [editor])
  const [shift, setShift] = useState(false)
  const [mentionsProposition, setMentionsProposition] = useState([])
  const [editorComponent, setEditorComponent] = useState(null)
  const [displayLinkedinWarn, setDisplayLinkedinWarn] = useState(false)
  const [linkedinWarnPos, setLinkedinWarnPos] = useState({x: 0, y: 0})

  const handleChange = (newEditor) => {
    if (shift) {
      setEditor(newEditor)
      return
    }

    setEditor(
      EditorState.acceptSelection(
        EditorState.createWithContent(newEditor.getCurrentContent(), compositeDecorator),
        newEditor.getSelection()
      )
    )
  }

  const handleKeyCommand = (command) => {
    if (command === 'space-bar') {
      // Insert space at the selection
      const editedText = Modifier.insertText(editor.getCurrentContent(), editor.getSelection(), ' ', null)
      const newEditor = EditorState.push(editor, editedText, 'insert-characters')
      setEditor(newEditor)

      // Reset mention propositions
      setMentionsProposition([])

      return 'handled'
    }

    if (command === 'shift') {
      setShift(true)
      return 'handled'
    }

    if (command === 'backspace') {
      setMentionsProposition([])
      return 'not-handled'
    }

    return 'not-handled'
  }

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 32 || e.keyCode === 229) {
      return e.keyCode === 32 ? 'space-bar' : 'shift'
    }
    setShift(false)
    return getDefaultKeyBinding(e)
  }

  const onPaste = (text, html, newEditor) => {
    setEditor(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(newEditor.getCurrentContent().getBlocksAsArray()),
        compositeDecorator
      )
    )
  }

  // Call API to search for Twiiter mentions propositions
  const searchMention = async () => {
    const text = editor.getCurrentContent().getPlainText()

    // Find mentions
    const mentions = text.match(HANDLE_REGEX)

    // Stop of no mentions in the text
    if (!mentions || !mentions.length) {
      setMentionsProposition([])
      return
    }

    // Target current mention
    const currentMention = mentions.find((m) => {
      return text.indexOf(m) + m.length === editor.getSelection().getAnchorOffset()
    })

    if (!currentMention) {
      setMentionsProposition([])
      return
    }

    // Call API and update mentionProposition state
    let searchResult = await searchAccountAction({
      name: currentMention.replace('@', ''),
      page: null,
      network: 'twitter'
    })

    if (searchResult && searchResult.length) {
      searchResult = searchResult.map(r => { return { ...r, base: currentMention } })
      setMentionsProposition(searchResult)
      return
    }

    setMentionsProposition([])
  }

  const moveFocusToEnd = (editorState) => {
    editorState = EditorState.moveSelectionToEnd(editorState)

    setEditor(
      EditorState.forceSelection(editorState, editorState.getSelection())
    )
  }

  const handleAutocomplete = ({ base, screenName }) => {
    const selection = editor.getSelection()
    selection.set('anchorOffset', selection.getAnchorOffset() + screenName.length + 1)
    selection.set('focusOffset', selection.getFocusOffset() + screenName.lenght + 1)

    const newEditor = EditorState.acceptSelection(
      EditorState.createWithContent(
        ContentState.createFromText(value.replace(base, (screenName + ' '))),
        compositeDecorator
      ),
      selection
    )

    editorComponent.focus()
    moveFocusToEnd(newEditor)
    setMentionsProposition([])
  }

  const insertCharacter = (characterToInsert) => {
    let selection = editor.getSelection()
    selection = selection.set('anchorOffset', selection.getAnchorOffset() + 1)
    selection = selection.set('focusOffset', selection.getFocusOffset() + 1)

    setEditor(
      EditorState.acceptSelection(
        EditorState.createWithContent(
          Modifier.replaceText(
            editor.getCurrentContent(),
            editor.getSelection(),
            characterToInsert
          )
        ),
        selection
      )
    )

    handleCloseEmojis()
  }

  // Display warning when mhen mentionning a company on Linkedin post
  const toggleLinkedinWarning = () => {
    // find handle divs in the text
    const handles = document.getElementsByClassName('text-editor__handle')
    if (!handles || !handles.length) {
      setDisplayLinkedinWarn(false)
      return
    }

    const text = editor.getCurrentContent().getPlainText()

    // Determine if user is currently writting a handle
    const currentHandle = Object.values(handles).find((h) => {
      return text.indexOf(h.innerText) + h.innerText.length === editor.getSelection().getAnchorOffset()
    })

    if (!currentHandle) {
      // Hide warning
      setDisplayLinkedinWarn(false)
      return
    }

    // Find current handle position and display warning
    const rect = currentHandle.getBoundingClientRect()
    setLinkedinWarnPos({
      x: rect.x > 170 ? currentHandle.offsetLeft + 5 : 60,
      y: currentHandle.offsetTop + 30
    })
    setDisplayLinkedinWarn(true)
  }

  return (
    <React.Fragment>
      <EmojiPicker
        isOpen={emojisIsOpen}
        text={value}
        handleClose={handleCloseEmojis}
        onSelect={insertCharacter}
      />
      <Editor
        ref={setEditorComponent}
        placeholder={placeholder}
        editorState={editor}
        onChange={handleChange}
        handlePastedText={onPaste}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={myKeyBindingFn}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <TwitterDropdown
        list={mentionsProposition}
        handleAutocomplete={handleAutocomplete}
        anchorOffset={editor.getSelection().getAnchorOffset()}
      />
      <LinkedinWarning active={displayLinkedinWarn} text={warning} x={linkedinWarnPos.x} y={linkedinWarnPos.y} />
    </React.Fragment>
  )
}

const UrlSpan = ({ offsetKey, children }) => (
  <span
    className="text-editor__url"
    key={offsetKey}
  >
    {children}
  </span>
)

const HandleSpan = ({ offsetKey, children }) => (
  <span
    className="text-editor__handle"
    key={offsetKey}
  >
    {children}
  </span>
)

const HashtagSpan = ({ offsetKey, children }) => (
  <span
    className="text-editor__hashtag"
    key={offsetKey}
  >
    {children}
  </span>
)

TextEditor.propTypes = {
  emojisIsOpen: PropTypes.bool.isRequired,
  handleCloseEmojis: PropTypes.func.isRequired,
  isNewPost: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchAccountAction: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  warning: PropTypes.array.isRequired,
  willPublishForLinkedin: PropTypes.bool.isRequired,
  willPublishForTwitter: PropTypes.bool.isRequired
}

export default TextEditor