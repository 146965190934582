import React from 'react';

function IconSend() {
  return (
    <svg className="icon__comment fill-primary" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path d="M8 0C3.56667 0 0 3.22056 0 7.18432C0 9.45522 1.16667 11.4784 2.96667 12.7996L3.09128 15.4853C3.1145 15.986 3.45107 16.1493 3.84195 15.8509L6 14.2035C6.63333 14.3686 7.3 14.4099 8 14.4099C12.4333 14.4099 16 11.1894 16 7.2256C16 3.26184 12.4333 0 8 0Z" />
    </svg>
  )
}

export default IconSend;