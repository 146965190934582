import React from 'react';
import classNames from 'classnames';
import ReactSVG from "react-svg";
import ReactPhoneInput from 'react-phone-input-2';
import { uuid } from 'uuidv4';
import { Translation } from 'react-i18next';
import i18n from '../../i18n'

// Material
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from "@material-ui/core/Chip";
import CloseIcon from '@material-ui/icons/Close';
import SwitchM from "@material-ui/core/Switch";

// Assets
import clearIcon from "../../assets/images/clear-icon.svg";
import validateIcon from "../../assets/images/validate-icon.svg";
import TwitterIcon from '../../assets/icons/twitter-icon'

// Components
import params from "../../utils/params";
import Constants from '../../utils/params';
import SelectDialog from "../misc/selectDialog/select-dialog";
import ConfirmDialog from '../misc/confirm-dialog';
import LinkedinIcon from '../../assets/icons/linkedin-icon';
import LinkedinLogin from '../misc/LinkedinLogin/LinkedinLogin';
import TwitterLogin from '../misc/TwitterLogin/TwitterLogin';

const roles = [
  {
    value: 'User',
    label: i18n.t('manager', { returnObjects: true }).settings.edit_user.role_user,
  },
  {
    value: 'Manager',
    label: i18n.t('manager', { returnObjects: true }).settings.edit_user.role_manager,
  },
];

const style = {
  phoneInput: {
    paddingLeft: 20,
    height: 40,
    width: "100%",
    color: "#697e8b",
    borderColor: "#c3d0d7",
    position: "relative",
    fontSize: 14,
    letterSpacing: ".01rem",
    zIndex: 0,
    marginTop: [0, "!important"],
    marginBottom: [0, "!important"],
    marginLeft: 0,
    background: "#fff",
    border: "1px solid #cacaca",
    borderRadius: 5,
    lineHeight: "25px",
    oultine: "none"
  }
}

export default function Edit(props) {
  const { classes } = props;

  const fetchedUserLinkedin = (props.state.user && props.state.user.user_linkedin && props.state.user.user_linkedin.screen_name) || ""
  const fetchedUserTwitter = (props.state.user && props.state.user.user_twitter && props.state.user.user_twitter.screen_name) || ""
  const isCoTwitter = props.isCoTwitter
  const isCoLinkedin = props.isCoLinkedin
  const isCurrentUser = (props.user && props.state.user) ? (props.user.id === props.state.user.id) : false

  return (
    <Translation>
      {
        (t, { i18n }) => {
          t = t('manager', { returnObjects: true }).settings

          return (
            <div className="members__no-css-rule">
              {/* Popin confirmation */}
              <ConfirmDialog title={"Confirmation"}
                message={"Êtes-vous sûr de vouloir supprimer ce membre ?\nVous ne pourrez plus revenir en arrière."}
                handleCancel={props.handleCancelConfirm}
                handleClose={props.handleCancelConfirm}
                handleValid={() => props.handleValidConfirm(props.state.user.id, true)}
                open={props.state.confirmIsOpen} />

              {/* Title of the window */}
              <h2 className="members__title">
                {props.state.user && props.state.user.id ? t.edit_user.title : "Ajouter un utilisateur"}
              </h2>

              {/* Form */}
              <Paper elevation={1}>
                <form className="members__form" noValidate autoComplete="off">
                  {/* Name of the user */}
                  <FormControl className={classes.margin}>
                    <Typography className={classes.bootstrapFormLabel}>{t.edit_user.label_name}</Typography>
                    <input
                      type="text"
                      placeholder={t.edit_user.label_name}
                      name="name"
                      className="members__form-input"
                      onChange={props.handleChangeFormData}
                      value={props.state.name}
                    />
                  </FormControl>


                  {/* NETWORK */}
                  <div className="members__bloc">
                    <Typography className={classes.bootstrapFormLabel}>{t.edit_user.label_account}</Typography>

                    {/* Name of the twitter account */}
                    {process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true' ?
                      <div className="members__form-row twitter">
                        <label htmlFor="screen_name_twitter" className="members__form-row-label">
                          <TwitterIcon />
                        </label>
                        <div className="members__form-input-container network">
                          <input type="text" className="members__form-input disable" value={fetchedUserTwitter || "Compte Twitter non connecté"} readOnly />

                          {isCurrentUser &&
                            <TwitterLogin
                              onClick={props.handleClickButtonNetwork}
                              loginUrl={Constants.DOMAIN + "/auth/twitter"}
                              onFailure={props.onSocialAuthFailed("twitter")}
                              onSuccess={props.onSocialAuthSucceed('twitter')} // twitterAuthSucceed = key in this.state
                              register={true}
                              forceLogin={true}
                              showIcon={true}
                              className={`members__form-btn-network ${isCoTwitter ? 'deco' : ''}`}
                              requestTokenUrl={Constants.DOMAIN + `/auth/twitter/reverse?callback=${window.location.origin}`}
                            >
                              {isCoTwitter ? t.edit_user.button_twitter.disconnect : t.edit_user.button_twitter.connect}
                            </TwitterLogin>
                          }
                        </div>
                      </div>
                      : ''}

                    {/* Name of the linkedin account */}
                    {process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true' ?
                      <div className="members__form-row linkedin">
                        <label htmlFor="screen_name_linkedin" className="members__form-row-label">
                          <LinkedinIcon />
                        </label>
                        <div className="members__form-input-container network">
                          <input type="text" className="members__form-input disable" value={fetchedUserLinkedin || "Compte Linkedin non connecté"} readOnly />
                          {isCurrentUser ?

                            isCoLinkedin ?
                              <a className="members__form-btn-network deco" href="https://www.linkedin.com/m/logout/" rel="noopener noreferrer" target='_blank' onClick={() => props.handleClickButtonNetwork({ network: 'linkedin' })}>
                                <span>{t.edit_user.button_linkedin.disconnect}</span>
                              </a>
                              :
                              <LinkedinLogin
                                className="members__form-btn-network"
                                onSuccess={props.onSocialAuthSucceed("linkedin")}
                                onFailure={props.onSocialAuthFailed("linkedin")}
                                clientId={process.env.REACT_APP_LINKEDIN_ID}
                                redirectUri={`${window.location.origin}/linkedin`}
                                scope="r_emailaddress r_liteprofile w_member_social r_basicprofile rw_organization_admin r_organization_social"
                                state={uuid()}
                              >
                                {t.edit_user.button_linkedin.connect}
                              </LinkedinLogin>
                            : ''}
                        </div>
                      </div>
                      : ''}

                  </div>

                  {/* Role of the user */}
                  <FormControl className={classes.margin}>
                    <Typography className={classes.bootstrapFormLabel}>
                      {t.edit_user.title_role}
                    </Typography>
                    <Select
                      label="Rôle"
                      name="role"
                      className={classes.textField}
                      value={props.state.role}
                      onChange={props.handleChangeFormData}
                      disableUnderline
                      classes={{
                        root: classes.bootstrapRoot,
                        select: classes.bootstrapInput,
                        selectMenu: classes.bootstrapSelect,
                      }}
                    >
                      {roles.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <div className="members__subtitle-wrapper">
                      <Typography className={classes.subtitle}>
                        {t.edit_user.actions_manager.text}
                      </Typography>
                      {t.edit_user.actions_manager.cant.map(v => {
                        return (
                          <Typography component={"div"} className={classes.subtitle}>

                            <ReactSVG src={props.state.role === params.roles.MANAGER ? clearIcon : validateIcon} />
                            {v}
                          </Typography>
                        )
                      })}

                      {t.edit_user.actions_manager.can.map(v => {
                        return (
                          <Typography component={"div"} className={classes.subtitle}>
                            <ReactSVG src={props.state.role === params.roles.USER ? clearIcon : validateIcon} />
                            {v}
                          </Typography>
                        )
                      })}
                    </div>
                  </FormControl>

                  {/* SMS notification */}
                  {(props.state.role !== "Manager") && (
                    <FormControl error={!!props.errorPhone} className={classes.margin}>
                      <Typography className={classes.bootstrapFormLabel}>
                        Notifications SMS (optionnel)
                      </Typography>
                      <SwitchM
                        className='members__switch'
                        checked={props.state.smsNotification}
                        onChange={props.handleChangeFormData}
                        color="primary"
                        name={"smsNotification"}
                      />
                      <ReactPhoneInput label="Numéro de téléphone" id="phone" name="phone"
                        placeholder="33 6 01"
                        value={props.state.phone || '33'}
                        onChange={props.handleChangePhoneData}
                        defaultCountry='fr'
                        disableDropdown={true}
                        inputStyle={style.phoneInput}
                      />
                      {!!props.errorPhone &&
                        <FormHelperText>{props.errorPhone}</FormHelperText>
                      }
                      {/* <SwitchM
                        checked={props.state.smsNotification}
                        onChange={props.handleChangeFormData}
                        color="primary"
                        name={"smsNotification"}
                      /> */}
                      <div className="members__subtitle-wrapper">
                        <Typography className={classes.subtitle}>
                          Les membres recevront les notifications SMS dès qu’un nouveau post leur sera soumis.
                        </Typography>
                      </div>
                    </FormControl>
                  )}

                  {/* Lists */}
                  {props.state.role !== params.roles.MANAGER && <FormControl className={classes.margin}>
                    <Typography className={classes.bootstrapFormLabel}>
                      Groupes (optionnel)
                    </Typography>
                    <Select multiple displayEmpty name="groups" className={classes.textField}
                      value={
                        props.state.groups
                          .filter(g => g.selected)
                          .map(g => g.label)
                      }
                      onChange={props.handleChangeFormData}
                      disableUnderline
                      classes={{
                        root: classes.bootstrapRootFullWidth,
                        select: classes.bootstrapInput,
                        selectMenu: classes.bootstrapSelect,
                      }}
                      renderValue={selected => {
                        if (selected.length === 0) {
                          return "Choisir parmi les listes existantes";
                        }
                        return selected.map((groupLabel, index) =>
                          <Chip key={index} label={groupLabel} className={classes.groupChip}
                            onDelete={e => props.deleteItem(e, index)}
                            deleteIcon={<CloseIcon />} />
                        )
                      }}
                      open={false}
                      onOpen={() => { }}
                      onClose={() => { }}
                      onClick={props.openSelectDialog}
                    />
                    <SelectDialog
                      positionTop={props.state.groupsAnchorTop}
                      positionLeft={props.state.groupsAnchorLeft}
                      width={props.state.groupsWidth}
                      isMultiple={true}
                      items={
                        props.state.groups.map(g => Object.assign({
                          selected: props.state.user ? g.users.includes(props.state.user.id) : false
                        }, g))
                      }
                      itemClass={classes.groupChip}
                      selectedItems={props.state.groups}
                      setItems={props.setItems}
                      open={props.state.groupsIsOpen}
                      closeSelectDialog={props.closeSelectDialog} />
                  </FormControl>}


                  {/* Email notification */}
                  {
                    props.state.role === params.roles.MANAGER
                    &&
                    <FormControl error={!!props.errorEmail} className={classes.margin}>
                      <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
                        {t.edit_user.notif_title}
                      </InputLabel>
                      <div className="members__subtitle-wrapper">
                        <Typography className={classes.subtitle}>
                          {t.edit_user.notif_text}
                        </Typography>
                      </div>
                      <SwitchM
                        checked={props.state.emailNotifications}
                        onChange={props.handleChangeFormData}
                        color="primary"
                        name={"emailNotifications"}
                      />
                    </FormControl>

                  }


                  {/* Email */}
                  <FormControl error={!!props.errorEmail} className={classes.margin}>
                    <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
                      {t.edit_user.label_email}
                    </InputLabel>
                    <SwitchM
                      className='members__switch -margin-top'
                      checked={props.state.emailNotifications}
                      onChange={props.handleChangeFormData}
                      color="primary"
                      name={"emailNotifications"}
                    />
                    <InputBase label="Email" id="email" name="email"
                      value={props.state.email}
                      onChange={props.handleChangeFormData}
                      placeholder={"mon@email.com"}
                      classes={{
                        root: classes.bootstrapRoot,
                        input: classes.bootstrapInput,
                      }}
                    />
                    {!!props.errorEmail &&
                      <FormHelperText>{props.errorEmail}</FormHelperText>
                    }

                  </FormControl>

                  {/* TODO !!!! */}
                  {/* Edit lang */}
                  {/* <FormControl className={classes.margin}>
                                        <Typography className={classes.bootstrapFormLabel}>
                                            {t.edit_user.title_lang}
                                        </Typography>
                                        <Select 
                                            label="Lang"
                                            name="lang"
                                            id="lang"
                                            className={classes.textField}
                                            value={props.state.lang}
                                            onChange={props.handleChangeFormData}
                                            disableUnderline
                                            classes={{
                                                root: classes.bootstrapRoot,
                                                select: classes.bootstrapInput,
                                                selectMenu: classes.bootstrapSelect,
                                            }}
                                        >
                                            <MenuItem key="fr" value="fr">FR</MenuItem>
                                            <MenuItem key="en" value="en">EN</MenuItem>
                                        </Select>
                                    </FormControl> */}

                  {/* Test account option*/}
                  {
                    <FormControl className={classes.margin}>
                      <InputLabel shrink htmlFor="bootstrap-input" className={classes.bootstrapFormLabel}>
                        {t.edit_user.label_account_test}
                      </InputLabel>
                      <div className="members__subtitle-wrapper">
                        {t.edit_user.text_account_test}
                      </div>
                      <SwitchM
                        checked={props.state.is_user_test ? true : false}
                        onChange={props.handleChangeFormData}
                        color="primary"
                        name={"is_user_test"}
                      />
                    </FormControl>
                  }

                  {/* Buttons to save, cancel or delete */}
                  <div className="members__actions">
                    <Button variant="contained" color="primary" className={classNames(classes.action, classes.button)}
                      disabled={props.state.loading}
                      onClick={props.submitUser}
                    >
                      {props.state.loading && <CircularProgress size={24} className={classNames(classes.action, classes.buttonProgress)} />}
                      {t.edit_user.button_save}
                    </Button>
                    <Button variant="contained" className={classNames(classes.action)}
                      onClick={() => props.handleChangePage(0, null, true)}
                    >
                      {t.edit_user.button_cancel}
                    </Button>

                    {props.state.user &&
                      <Button variant="text" className={classNames(classes.action, classes.buttonDelete)}
                        onClick={props.handleOpenConfirm}
                      >
                        {t.edit_user.button_delete}
                      </Button>
                    }
                  </div>
                </form>
              </Paper>
            </div>
          )
        }
      }
    </Translation>

  );
}