const styles = theme => ({

  headerInfo: {
    background: "#EBF7FF",
    textAlign: "center",
    color: "#697E8B",
    fontSize: 12,
    width: "100%",
    padding: "15px 25px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    margin: "20px 0",

    "& > svg": {
      marginRight: 10
    },

    "& > .icon__twitter": {
      fill: "#1CA1F2"
    },

    "& > .icon__linkedin ": {
      fill: "#0071A1"
    },
  },

  rootGrid: {
    padding: "0 25px 30px 25px",
    margin: 0,
    width: "100%",
  },
  flex: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: "rgb(134, 161, 178)",
    display: "block",
    margin: "0 25px"
  },
  dialogClose: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.background.paper,
    border: `solid 1px ${theme.cloudyBlue}`,
    borderRadius: 5,
    padding: 0,
    marginRight: 15
  },
  sendIcon: {
    "& svg": {
      height: 16,
      width: 16,
      marginRight: 10,
      verticalAlign: "sub"
    }
  },
  textField: {
    backgroundColor: "#ffffff",
    width: "100%",
    margin: "34px 0 11px 0",
    borderRadius: 10,
    "& fieldset": {
      borderRadius: 10,
      border: "solid 1px #c3d0d7",
    },
    "& input": {
      padding: "18.5px 35px 18.5px 14px",
    }
  },
  multiline: {
    padding: 20,
  },
  textArea: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "20px",
  },
  buttonSubmit: {
    height: 36,
    margin: 0,
    padding: "5px 16px",
    textTransform: "Capitalize",
    float: "right",
  },
  buttonAddImage: {
    padding: 0,
    backgroundColor: "#fff",
    borderRadius: "100%",
    width: 36,
    height: 36,
    textAlign: "center",
    lineHeight: 36,
    marginBottom: 10,
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.15)",
  },
  tweetCount: {
    position: "absolute",
    right: 20,
    bottom: 20,
    color: theme.mintyGreen,
    fontWeight: 600,
    fontSize: 12,
  },
  commentIcon: {
    display: "flex",
    fontSize: 16,
    fontWeight: 600,
    "& > div": {
      marginRight: 10,
      position: "relative",
      top: 2,
    },
  },
  comment: {
    border: `solid 1px ${theme.cloudyBlue}`,
    borderRadius: 10,
    padding: [25, "!important"],
    marginTop: 20,
    wordBreak: "break-word",
    fontStyle: "italic",
    cursor: "default",
  },
  input: {
    display: 'none',
  },
  mediaLoader: {
    marginTop: 12,
    marginRight: 18,
  },
  mediaInput: {
    opacity: 0,
    position: "absolute",
    height: "200px",
    width: "100%",
    top: "11px",
  },
  mediaContainer: {
    position: "relative",
  },
  media: {
    marginTop: 10,
    width: "100%",
    height: 200,
    objectFit: "cover",
    borderRadius: 5,
    border: "1px solid rgb(219, 219, 219)",
  },
  mediaHint: {
    textAlign: "center",
    marginTop: 6,
    fontSize: 12,
  },
  mediaAppear: {
    opacity: 0.01,
    transform: "translate(0, 15px)"
  },
  mediaAppearActive: {
    opacity: 1,
    transform: "translate(0, 0)",
    transition: "opacity .15s, transform .15s"
  },
  buttonRemoveMedia: {
    position: "absolute",
    right: 0,
    top: 10,
    '& rect': {
      fill: "#525252",
    }
  },
  progress: {
    margin: "0 10px 0 0",
  },
  shareUrlCheckbox: {
    padding: "10px"
  },
});

export default styles;