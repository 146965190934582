import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import i18n from '../../i18n'

// Components
import AnalyticsGlobal from './analyticsGlobal'
import AnalyticsUsers from './analyticsUsers'
import AnalyticsGroups from './analyticsGroups'
import AnalyticsTags from './analyticsTags'
import AnalyticsContents from './analyticsContents'
import AnalyticsNetworks from './analyticsNetworks'

const wording = i18n.t('manager', { returnObjects: true }).analytics

class Analytics extends React.Component {
  constructor() {
    super()
    this.navRef = React.createRef()
    this.navBordeRef = React.createRef()

    this.initNavBar = this.initNavBar.bind(this)
  }

  componentDidMount() {
    // Init the position of the nav bar
    this.initNavBar()
  }

  componentDidUpdate() {
    this.initNavBar()
  }

  navBtnClick = (e) => {
    this.animateNavBar(e.currentTarget)
  }

  initNavBar() {
    try {
      const currentNav = this.navRef.current.querySelector('.analytics__nav-btn.active')
      this.animateNavBar(currentNav, { transition: false })
    } catch (e) { console.error(e) }
  }

  animateNavBar(currentNav, options = null) {
    try {
      if (options && options.transition === false) this.navBordeRef.current.style.transition = 'unset'
      else this.navBordeRef.current.style.transition = 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

      this.navBordeRef.current.style.width = `${currentNav.offsetWidth}px`
      this.navBordeRef.current.style.transform = `translateX(${currentNav.getBoundingClientRect().left - this.navRef.current.getBoundingClientRect().left}px)`


    } catch (e) { console.error(e) }
  }

  render() {
    return (
      <div className="analytics">
        {/* Nav */}
        <div className="analytics__nav" ref={this.navRef}>
          <NavLink className="analytics__nav-btn" activeClassName="active color-primary" exact to="/statistiques" onClick={this.navBtnClick}>
            {wording.tab_global}
          </NavLink>
          <NavLink className="analytics__nav-btn" activeClassName="active color-primary" to="/statistiques/utilisateurs" onClick={this.navBtnClick}>
            {wording.tab_users}
          </NavLink>
          <NavLink className="analytics__nav-btn" activeClassName="active color-primary" to="/statistiques/groupes" onClick={this.navBtnClick}>
            {wording.tab_groups}
          </NavLink>
          <NavLink className="analytics__nav-btn" activeClassName="active color-primary" to="/statistiques/tags" onClick={this.navBtnClick}>
            {wording.tab_thematics}
          </NavLink>
          <NavLink className="analytics__nav-btn" activeClassName="active color-primary" to="/statistiques/contenus" onClick={this.navBtnClick}>
            {wording.tab_contents}
          </NavLink>
          <NavLink className="analytics__nav-btn" activeClassName="active color-primary" to="/statistiques/reseaux" onClick={this.navBtnClick}>
            {wording.tab_networks}
          </NavLink>
          <div className="analytics__nav-border background-color-primary" ref={this.navBordeRef}></div>
        </div>

        {/* Sub routes */}
        <Switch>
          <Route exact path="/statistiques" render={() => <AnalyticsGlobal {...this.props} />} />
          <Route exact path="/statistiques/utilisateurs" render={() => <AnalyticsUsers  {...this.props} />} />
          <Route exact path="/statistiques/groupes" render={() => <AnalyticsGroups  {...this.props} />} />
          <Route exact path="/statistiques/tags" render={() => <AnalyticsTags  {...this.props} />} />
          <Route exact path="/statistiques/contenus" render={() => <AnalyticsContents  {...this.props} />} />
          <Route exact path="/statistiques/reseaux" render={() => <AnalyticsNetworks  {...this.props} />} />
        </Switch>

        <br /><br /><br /><br /><br /><br /><br />
      </div>
    )
  }
}

export default Analytics
