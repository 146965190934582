import React from 'react';

// Init component.
function IconGif(props) {
  return (
    <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.9375 5.58594H9.46875V11.5078C8.73438 11.7474 8.04167 11.9141 7.39062 12.0078C6.74479 12.1068 6.08333 12.1562 5.40625 12.1562C3.68229 12.1562 2.36458 11.651 1.45312 10.6406C0.546875 9.625 0.09375 8.16927 0.09375 6.27344C0.09375 4.42969 0.619792 2.99219 1.67188 1.96094C2.72917 0.929688 4.19271 0.414062 6.0625 0.414062C7.23438 0.414062 8.36458 0.648438 9.45312 1.11719L8.64844 3.05469C7.8151 2.63802 6.94792 2.42969 6.04688 2.42969C5 2.42969 4.16146 2.78125 3.53125 3.48438C2.90104 4.1875 2.58594 5.13281 2.58594 6.32031C2.58594 7.5599 2.83854 8.50781 3.34375 9.16406C3.85417 9.8151 4.59375 10.1406 5.5625 10.1406C6.06771 10.1406 6.58073 10.0885 7.10156 9.98438V7.60156H4.9375V5.58594Z" fill="white" />
      <path d="M12.1953 12V0.578125H14.6172V12H12.1953Z" fill="white" />
      <path d="M19.875 12H17.4922V0.578125H24.0391V2.5625H19.875V5.50781H23.75V7.48438H19.875V12Z" fill="white" />
    </svg>
  );
}

export default IconGif;
