import React from "react";
import ReactSVG from "react-svg";
import PropTypes from "prop-types";
import classNames from 'classnames';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import diff from 'deep-diff';
import { Translation } from 'react-i18next';

// Material
import { withStyles, Badge } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// Material icon
import EditIcon from '@material-ui/icons/Edit';

// Assets
import clickIcon from "../../../assets/images/click.svg";
import searchIcon from "../../../assets/images/search.svg";
import sendIcon from "../../../assets/images/send-grey.svg";
import SuggestedIcon from "../../../assets/icons/schedule.js";
import PublishedIcon from "../../../assets/icons/check.js";
import RefusedIcon from "../../../assets/icons/close.js";
import InsertLinkIcon from "../../../assets/icons/insert-link.js";
import commentIcon from "../../../assets/images/comment.svg";
import params from "../../../utils/params";
import GlobalUtils from "../../../utils/global";
import arrow from "../../../assets/images/arrow.svg";
import defaultUserIcon from "../../../assets/images/default_profile_normal.png";

// Material styles
import styles from './material-details-panel';

class DetailsPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      author: null,
      authorLabel: "",
      creatorLabel: null,
      creatorImage: null,
      filter: [],
      selectedChild: null,
      searchUsers: [],
      search: '',
      transition: false
    }

    // Ref
    this.panelRef = React.createRef()

    // Filter the users related to the actual tweet card selected
    this.handleSearch = this.handleSearch.bind(this);
    this.resizePanel = this.resizePanel.bind(this)
  }

  componentDidMount() {
    this.setAuthorInfo(this.props);
    window.addEventListener('resize', this.resizePanel);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizePanel);
  }

  componentDidUpdate(prevProps, prevState) {
    // If the props that we receive are the same we don't update the component
    if (!diff(prevProps, this.props)) return;

    this.setAuthorInfo(this.props);
    this.setState({
      selectedChild: null
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    // If the props and the state are the same don't update the component (Related to the time icon to remove for scheduled tweet)
    if (diff(nextProps, this.props) && diff(nextState, this.state)) return false

    return true
  }

  setAuthorInfo(props) {
    if (!props.tweetit || !props.users) {
      return;
    }
    let author = props.users.find(u => u.id === props.tweetit.updated_by);
    let creator = props.users.find(u => u.id === props.tweetit.user_id);

    if (!author && (props.tweetit.updated_by === this.props.user.id)) author = this.props.user;
    if (!creator && (props.tweetit.user_id === this.props.user.id)) creator = this.props.user;

    // get appriopriate screenName and profileImage for author (according network)
    const {
      screenNameThatShouldBeDisplayed: authorLabel,
      profileImageThatShouldBeDisplayed: authorImage
    } = GlobalUtils.getUserNameAndAvatar({ user: author })

    // get appriopriate screenName and profileImage for creator (according network)
    const {
      screenNameThatShouldBeDisplayed: creatorLabel,
      profileImageThatShouldBeDisplayed: creatorImage
    } = GlobalUtils.getUserNameAndAvatar({ user: creator })

    this.setState({
      authorLabel: authorLabel,
      authorImage: authorImage,
      creatorLabel: creatorLabel,
      creatorImage: creatorImage,
      filter: []
    }, () => {
      this.resizePanel()
      // Launch animation when changing the content
      this.setState({
        transition: false
      }, () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        // Set the previous width and the horizontal translation
        this.timeout = setTimeout(() => {
          this.setState({ transition: true });
        }, 10);
      })
    })
  }

  toggleFilter = (element, status) => {
    if (!Array.isArray(status)) {
      status = [status];
    }
    if (this.state.filter.length > 0 && this.state.filter.every((v, i) => v === status[i])) {
      status = [];
    }
    this.setState({
      filter: status,
      selectedChild: null,
    });
  };

  selectChild = child => {
    this.setState({
      selectedChild: child
    })
  };

  handleSearch = event => {
    // Array to store the users corresponding to the search
    let users = [],

      // Store the display name OR the screen name
      name = '',

      // Store the search that the user type in the search bar
      search = event.target.value;

    if (this.props.tweetit && this.props.tweetit.children.length) {
      // Loop through the users
      for (let child of this.props.tweetit.children) {

        // Store one of the name available

        if (child.user.name) {
          name = child.user.name
        } else {
          if (this.props.tweetit.network === "twitter" && child.user.user_twitter) {
            name = child.user.user_twitter.display_name || child.user.user_twitter.screen_name
          }
          else if (this.props.tweetit.network === "linkedin" && child.user.user_linkedin) {
            name = child.user.user_linkedin.screen_name
          }
        }

        try {
          // If the user correspond to the search, store it
          if (name && name.search(new RegExp(search, 'i')) !== -1) users.push(child.id)
        } catch (e) {
          return;
        }
      }
    }

    // Save in the state the users corresponding to the search, and the search text typed
    this.setState({
      searchUsers: users,
      search: search
    })
  };

  resizePanel() {
    if (!this.panelRef.current) return

    if ((window.innerHeight - (this.panelRef.current.getBoundingClientRect().bottom + this.panelRef.current.offsetTop)) < 76)
      this.panelRef.current.classList.remove('full-height')
    else
      this.panelRef.current.classList.add('full-height')
  }

  renderAvatarWithBadgeStatus(child, screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed) {
    // TO-REVIEW feature/149 replace badge component
    return child.status === params.statuses.SUGGESTED ?
      <Badge
        classes={{ badge: this.props.classes.badgeStatus }}
        overlap="circle"
        badgeContent={
          <div className="status-icon__status-icon-suggested  status-icon__status-icon--no-margin">
            <SuggestedIcon />
          </div>
        }
      >
        <Avatar className={this.props.classes.avatar + " " + this.props.classes.avatarActivity}
          alt={screenNameThatShouldBeDisplayed}
          src={profileImageThatShouldBeDisplayed}
          onError={(e) => { e.target.src = defaultUserIcon }}
        />
      </Badge>
      :
      child.status === params.statuses.PUBLISHED || child.status === params.statuses.EDITED ?
        <Tooltip classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
          title={
            <div className={this.props.classes.tooltipTitle}>
              <div className={this.props.classes.tooltipContainer}>
                <img alt="arrow" src={arrow} />
              </div>
              Publié le  {moment(child.updated_at).format('Do MMMM YYYY')} à {moment(child.updated_at).format('HH:mm')}
            </div>
          }
        >
          <Badge
            classes={{ badge: this.props.classes.badgeStatus }}
            overlap="circle"
            badgeContent={
              <div className="status-icon__status-icon-published status-icon__status-icon--no-margin">
                <PublishedIcon />
              </div>
            }
          >
            <Avatar className={this.props.classes.avatar + " " + this.props.classes.avatarActivity}
              alt={screenNameThatShouldBeDisplayed}
              src={profileImageThatShouldBeDisplayed}
              onError={(e) => { e.target.src = defaultUserIcon }}
            />
          </Badge>
        </Tooltip>
        :
        child.status === params.statuses.REMOVED ?
          <Tooltip classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
            title={
              <div className={this.props.classes.tooltipTitle}>
                <div className={this.props.classes.tooltipContainer}>
                  <img alt="arrow" src={arrow} />
                </div>
                Refusé le  {moment(child.updated_at).format('Do MMMM YYYY')} à {moment(child.updated_at).format('HH:mm')}
              </div>
            }
          >
            <Badge
              classes={{ badge: this.props.classes.badgeStatus }}
              overlap="circle"
              badgeContent={
                <div className="status-icon__status-icon-refused status-icon__status-icon--no-margin">
                  <RefusedIcon />
                </div>
              }
            >
              <Avatar className={this.props.classes.avatar + " " + this.props.classes.avatarActivity}
                alt={screenNameThatShouldBeDisplayed}
                src={profileImageThatShouldBeDisplayed}
                onError={(e) => { e.target.src = defaultUserIcon }}
              />
            </Badge>
          </Tooltip>
          :
          ''
  }

  render() {
    const isTweetEdited = this.props.tweetit && (this.props.tweetit.created_at !== this.props.tweetit.updated_at)
    const listUsers = this.props.tweetit && this.props.tweetit.children && this.props.tweetit.children.length ? this.props.tweetit.children.map(u => u.user_id) : []

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).home

            return (
              !this.props.tweetit ?
                <div className="details-panel__details  full-height details-panel__details-empty">
                  <ReactSVG src={clickIcon} />
                  <Typography className={this.props.classes.emptyText}>
                    {t.inspect_post_empty}
                  </Typography>
                </div >
                :
                <PerfectScrollbar
                  option={{
                    wheelSpeed: 1,
                    wheelPropagation: true,
                    swipeEasing: false,
                    minScrollbarLength: 5
                  }}
                >
                  <div className="details-panel__details" ref={this.panelRef}>
                    {/* Create information */}
                    <div className={`details-panel__details-create ${this.state.transition ? 'active' : ''} ${isTweetEdited ? 'margin' : ''}`}>
                      <p className="details-panel__details-create-date">
                        <EditIcon /> {t.inspect_post.created_at}  {moment(this.props.tweetit.created_at).format('Do MMMM YYYY')}  {moment(this.props.tweetit.created_at).format('HH:mm')} {t.inspect_post.by}
                      </p>
                      <Typography component={"span"} className={this.props.classes.detail}>
                        <Avatar className={this.props.classes.avatar + " " + this.props.classes.avatarActivity} alt={this.state.creatorLabel} src={this.state.creatorImage} onError={(e) => { e.target.src = defaultUserIcon }} />
                        {this.state.creatorLabel || '(Compte supprimé)'}
                      </Typography>
                    </div>

                    {/* Edit information */}
                    {isTweetEdited ?
                      <div className={`details-panel__details-edit ${this.state.transition ? 'active' : ''}`}>
                        <p className="details-panel__details-edit-date">
                          <EditIcon /> {t.inspect_post.updated_at}  {moment(this.props.tweetit.updated_at).format('Do MMMM YYYY')} {moment(this.props.tweetit.updated_at).format('HH:mm')} {t.inspect_post.by}
                        </p>

                        <Typography component={"span"} className={this.props.classes.detail}>
                          <Avatar className={this.props.classes.avatar + " " + this.props.classes.avatarActivity} alt={this.state.authorLabel} src={this.state.authorImage} onError={(e) => { e.target.src = defaultUserIcon }} />
                          {this.state.authorLabel || '(Compte supprimé)'}
                        </Typography>
                      </div>
                      : ''}

                    {/* Submission information */}

                    <div className={`details-panel__details-submit ${this.state.transition ? 'active' : ''}`}>
                      {this.props.tweetit.status !== params.statuses.DRAFT ?
                        <p className="details-panel__details-submit-date">
                          <img src={sendIcon} alt="send icon" /> {t.inspect_post.submitted_at} {this.props.tweetit.submitted_at ? `${moment(this.props.tweetit.submitted_at).format('Do MMMM YYYY')}  ${moment(this.props.tweetit.submitted_at).format('HH:mm')}` : ''}
                        </p>
                        : ''}

                      <p className="details-panel__details-submit-recipients">
                        <span className="color-primary">{this.props.tweetit && this.props.tweetit.children ? `${this.props.tweetit.children.length}` : '0 '} {t.inspect_post.recipient}</span>
                      </p>

                      <div className="group-users__targets">
                        {
                          this.props.tweetit.groups.map(group =>
                            <Chip key={group.id} component="div" label={(
                              <div className="group-users__targets-group-label">
                                <span>{group.label}</span> <span>{group.users ? group.users.filter(u => listUsers.includes(u.id)).length : ''}</span>
                              </div>
                            )} className={this.props.classes.groupChip} />
                          )
                        }
                        {
                          this.props.tweetit.children.map(child => {

                            // get appropriate screenName or profileName for child.user (according network)
                            const {
                              profileImageThatShouldBeDisplayed,
                              screenNameThatShouldBeDisplayed
                            } = GlobalUtils.getUserNameAndAvatar({ user: child.user })

                            return this.props.tweetit.orphans.includes(child.user.id) &&
                              <Tooltip key={child.id} classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                                title={
                                  <div className={this.props.classes.tooltipTitle}>
                                    <div className={this.props.classes.tooltipContainer}>
                                      <img alt="arrow" src={arrow} />
                                    </div>
                                    {screenNameThatShouldBeDisplayed}
                                  </div>
                                }
                              >
                                <Avatar className={this.props.classes.avatar + ' details-panel__avatar'}
                                  alt={screenNameThatShouldBeDisplayed}
                                  src={profileImageThatShouldBeDisplayed}
                                  onError={(e) => { e.target.src = defaultUserIcon }}
                                />
                              </Tooltip>
                          })
                        }
                      </div>
                    </div>


                    {/* Activity information */}
                    {this.props.tweetit.status !== params.statuses.DRAFT ?
                      <div className={`details-panel__details-activity ${this.state.transition ? 'active' : ''}`}>
                        <div className="details-panel__details-activity-title">
                          {t.inspect_post.activity}
                          <div className="details-panel__details-activity-status">
                            <Tooltip classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                              title={
                                <div className={this.props.classes.tooltipTitle}>
                                  <div className={this.props.classes.tooltipContainer}>
                                    <img alt="arrow" src={arrow} />
                                  </div>
                                  En attente
                                </div>
                              }
                            >
                              <Button size="small"
                                classes={{
                                  label: this.props.classes.filterButtonLabel
                                }}
                                className={this.state.filter.includes(params.statuses.SUGGESTED)
                                  ? classNames(this.props.classes.filterButton, this.props.classes.filterButtonActive, 'orange')
                                  : classNames(this.props.classes.filterButton, 'orange')
                                }
                                onClick={e => this.toggleFilter(e.currentTarget, params.statuses.SUGGESTED)}>

                                <div className={`status-icon__status-icon-suggested ${this.state.filter.includes(params.statuses.SUGGESTED) ? 'active' : ''}`}>
                                  <SuggestedIcon />
                                </div>
                                {this.props.tweetit.children.filter(child => child.status === params.statuses.SUGGESTED || child.status === params.userStatuses.WAITING).length}
                              </Button>
                            </Tooltip>

                            <Tooltip classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                              title={
                                <div className={this.props.classes.tooltipTitle}>
                                  <div className={this.props.classes.tooltipContainer}>
                                    <img alt="arrow" src={arrow} />
                                  </div>
                                  Posté
                                </div>
                              }
                            >
                              <Button size="small"
                                classes={{
                                  label: this.props.classes.filterButtonLabel
                                }}
                                className={this.state.filter.includes(params.statuses.PUBLISHED) || this.state.filter.includes(params.statuses.EDITED)
                                  ? classNames(this.props.classes.filterButton, this.props.classes.filterButtonActive, 'green')
                                  : classNames(this.props.classes.filterButton, 'green')
                                }
                                onClick={e => this.toggleFilter(e.currentTarget, [params.statuses.PUBLISHED, params.statuses.EDITED])}>
                                <div className={`status-icon__status-icon-published ${this.state.filter.includes(params.statuses.PUBLISHED) || this.state.filter.includes(params.statuses.EDITED) ? 'active' : ''}`}>
                                  <PublishedIcon />
                                </div>
                                {this.props.tweetit.children.filter(child => child.status === params.statuses.PUBLISHED || child.status === params.statuses.EDITED).length}
                              </Button>
                            </Tooltip>

                            <Tooltip classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                              title={
                                <div className={this.props.classes.tooltipTitle}>
                                  <div className={this.props.classes.tooltipContainer}>
                                    <img alt="arrow" src={arrow} />
                                  </div>
                                  Refusé
                                </div>
                              }
                            >
                              <Button size="small"
                                classes={{
                                  label: this.props.classes.filterButtonLabel
                                }}
                                className={this.state.filter.includes(params.statuses.REMOVED)
                                  ? classNames(this.props.classes.filterButton, this.props.classes.filterButtonActive, 'grapefruit')
                                  : classNames(this.props.classes.filterButton, 'grapefruit')
                                }
                                onClick={e => this.toggleFilter(e.currentTarget, params.statuses.REMOVED)}>
                                <div className={`status-icon__status-icon-refused ${this.state.filter.includes(params.statuses.REMOVED) ? 'active' : ''}`}>
                                  <RefusedIcon />
                                </div>
                                {this.props.tweetit.children.filter(child => child.status === params.statuses.REMOVED).length}
                              </Button>
                            </Tooltip>

                          </div>
                        </div>

                        {/* Search bar to find a user*/}
                        <TextField
                          variant="outlined"
                          type={'search'}
                          className={this.props.classes.search}
                          onChange={this.handleSearch}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ReactSVG src={searchIcon} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <List className="details-panel__details-activity-list-users">
                          {
                            // Sort the users : suggested/ waiting -> removed - > published
                            this.props.tweetit.children.sort((a, b) => {
                              let sort_value = {
                                [params.statuses.SUGGESTED]: 2, [params.statuses.REMOVED]: 1,
                                [params.statuses.PUBLISHED]: 0, [params.statuses.EDITED]: 0
                              }

                              if (sort_value[a.status] > sort_value[b.status]) return -1;

                              if (sort_value[a.status] < sort_value[b.status]) return 1;

                              return 0
                            }).map(child => {
                              const {
                                profileImageThatShouldBeDisplayed,
                                screenNameThatShouldBeDisplayed
                              } = GlobalUtils.getUserNameAndAvatar({ user: child.user })

                              return (
                                (   // If the user is in the right status
                                  (this.state.filter.length === 0 || this.state.filter.includes(child.status)) &&

                                  // If the user correspond to the search (If a search text exist)
                                  (!this.state.search || (this.state.search && this.state.searchUsers.includes(child.id))))

                                // If the user passed the two previous condition, display it
                                &&
                                <ListItem key={child.id} className={this.props.classes.userListItem}
                                  onClick={() => this.selectChild(child)}
                                >
                                  {this.renderAvatarWithBadgeStatus(child, screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed)}
                                  <ListItemText classes={{
                                    root: this.props.classes.userListItemTextRoot,
                                    primary: this.props.classes.userListItemTextPrimary
                                  }}>
                                    {/* User name */}

                                    <span className="details-panel__details-activity-user-name">
                                      {screenNameThatShouldBeDisplayed}
                                    </span>

                                    <div className="details-panel__details-activity-icons">
                                      {/* If the content has been edited */}
                                      {child.status === params.statuses.EDITED ?
                                        <Tooltip key={child.id} classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                                          title={
                                            <div className={this.props.classes.tooltipTitle}>
                                              <div className={this.props.classes.tooltipContainer}>
                                                <img alt="arrow" src={arrow} />
                                              </div>
                                              {t.inspect_post.content_edited}
                                            </div>
                                          }
                                        >
                                          <div className="details-panel__details-activity-icons-edit"> <EditIcon /></div>

                                        </Tooltip>
                                        : ''}

                                      {/* Comment  icon in case there is a refusal message */}
                                      {(child.status === params.statuses.REMOVED) ?

                                        // Retrieve the refusal message through the post activity 
                                        this.props.tweetit && this.props.tweetit.post_activity && this.props.tweetit.post_activity.map(activity => {
                                          // If the user is the same
                                          return (child.user_id === activity.user_id) && activity.message ?
                                            <Tooltip key={`${child.id}-status-0`} classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                                              title={
                                                <div className={this.props.classes.tooltipTitle}>
                                                  <div className={this.props.classes.tooltipContainer}>
                                                    <img alt="arrow" src={arrow} />
                                                  </div>
                                                  {activity.message}
                                                </div>
                                              }
                                            >
                                              <img alt="comment" src={commentIcon} />
                                            </Tooltip>
                                            : ''
                                        })
                                        : ''}

                                      {/* Post has been published */}
                                      {child.status === params.statuses.PUBLISHED || child.status === params.statuses.EDITED ?
                                        (this.props.tweetit.network === "twitter" && child.user.user_twitter && child.user.user_twitter.screen_name) && child.publish_id ?
                                          (
                                            <a href={`https://twitter.com/${child.user.user_twitter.screen_name}/status/${child.publish_id}`} rel="noopener noreferrer" target='_blank'>
                                              <InsertLinkIcon />
                                            </a>
                                          ) : ""
                                        : ""
                                      }
                                    </div>
                                  </ListItemText>
                                </ListItem>
                              )
                            })
                          }
                        </List>
                      </div>
                      : ''}
                  </div>
                </PerfectScrollbar>
            )
          }
        }
      </Translation>
    )
  }
}

DetailsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  tweetit: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailsPanel);
