import React from 'react'

const TrashIcon = () => (
  <svg className="icon__trash" width="16" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5709 0H4.54553L3.54046 0.888889H0.0227051V2.66667H14.0937V0.888889H10.576L9.5709 0ZM11.079 5.33332V14.2222H3.03838V5.33332H11.079ZM1.02793 3.55551H13.0888V14.2222C13.0888 15.2 12.1842 16 11.0787 16H3.03807C1.93249 16 1.02793 15.2 1.02793 14.2222V3.55551Z"
    />
  </svg>
)

export default TrashIcon