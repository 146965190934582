const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: "#ffffff",
    boxShadow: "none",
  },
  rootGrid: {
    padding: "0 25px 30px 25px",
    margin: 0,
    width: "100%",
  },
  flex: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: "rgb(134, 161, 178)",
  },
  actions: {
    justifyContent: "space-between",
    padding: "25px 25px 30px 25px",
    height: 36,
    minHeight: 36,
  },
  dialogClose: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.background.paper,
    border: `solid 1px ${theme.cloudyBlue}`,
    borderRadius: 5,
    padding: 0,
    marginRight: 15
  },
  textField: {
    backgroundColor: "#ffffff",
    width: "100%",
    margin: "34px 0 11px 0",
    borderRadius: 10,
    "& fieldset": {
      borderRadius: 10,
      border: "solid 1px #c3d0d7",
    },
    "& input": {
      padding: "18.5px 35px 18.5px 14px",
    }
  },
  buttonSubmit: {
    height: 36,
    margin: 0,
    padding: "5px 16px",
    textTransform: "Capitalize",
    float: "right",
  },
  buttonEdit: {
    height: 36,
    margin: 0,
    padding: "5px 16px",
    fontWeight: 600,
    textTransform: "Capitalize",
    '& img': {
      padding: "0 9px 0 0",
    }
  },
  tweetCount: {
    position: "absolute",
    right: 40,
    top: 345,
    color: theme.mintyGreen,
    fontWeight: 600,
    fontSize: 12,
  },
  checkCircle: {
    position: "absolute",
    color: theme.mintyGreen,
    width: 19,
    height: 19,
    right: 37,
    top: 171,
  },
  input: {
    display: 'none',
  },
  media: {
    marginTop: 10,
    width: "100%",
    height: 200,
    objectFit: "cover",
    borderRadius: 5,
    border: "1px solid rgb(219, 219, 219)",
  },
  buttonRemoveMedia: {
    position: "absolute",
    right: 0,
    top: 10,
    '& rect': {
      fill: "#525252",
    }
  },
  progress: {
    margin: "0 10px 0 0",
  },
});

export default styles