import React from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment'
import styles from "./material-analytics"
import i18n from '../../i18n'

// Components
import Filter from './../misc/filter/filter'
import IconStar from "../../assets/icons/star"
import IconCheck from '../../assets/icons/check'
import IconSchedule from "../../assets/icons/schedule.js";
import IconClose from "../../assets/icons/close.js";
import defaultUserIcon from "../../assets/images/default_profile_normal.png";

// Assets
import arrow from "../../assets/images/arrow.svg"

// utils
import global from "../../utils/global";
import EventEmitter from '../../utils/EventEmitter';
import params from '../../utils/params';

const wording = i18n.t('manager', { returnObjects: true }).analytics.users
moment.locale(i18n.language)

class AnalyticsUsers extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      filters: null
    }
    this.applyFilter = this.applyFilter.bind(this)
    this.getData = this.getData.bind(this)
  }

  async componentDidMount() {
    let data = await this.props.getUsersRankAction()

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = null
    }

    this.setState({ data })
  }

  applyFilter(filters) {
    this.setState({ filters })
  }

  getData() {
    let data = Object.assign({}, this.state.data)
    let filters = this.state.filters

    if (filters && filters.network) {
      data.all = data.all.filter(d => {
        let u = d.user
        let isValid = false
        if (filters.network.includes('twitter') && filters.network.includes('linkedin') && u.user_twitter && u.user_linkedin) isValid = true
        if (filters.network.includes('twitter') && !filters.network.includes('linkedin') && u.user_twitter) isValid = true
        if (!filters.network.includes('twitter') && filters.network.includes('linkedin') && u.user_linkedin) isValid = true
        return isValid
      })
    }

    if (filters && filters.group) {
      data.all = data.all.filter(d => d.user && d.user.groups && d.user.groups.length && d.user.groups.some(g => filters.group.includes(g.id)))
    }

    if (filters && filters.name) {
      data.all = data.all.filter(d => global.getUserNameAndAvatar({ user: d.user }).screenNameThatShouldBeDisplayed && global.getUserNameAndAvatar({ user: d.user }).screenNameThatShouldBeDisplayed.search(new RegExp(filters.name, 'i')) !== -1)
    }
    return data.all
  }

  getPercent(n, d) {
    let value = 0
    if (d) value = n / d
    return Math.round(value * 100)
  }

  buildRow() {
    let rows = [], data = this.state.data

    if (!data) return []

    rows = this.getData().map((r, i) => {
      const { screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed } = global.getUserNameAndAvatar({ user: r.user })
      return (
        <div className={`analytics-users__row ${(i % 2) !== 1 ? 'odd' : ''}`} key={i}>
          <div className="analytics-users__col">
            <span className="analytics-users__rank">
              {i + 1}
            </span>
            <div className="analytics-users__pic">
              <img alt="member pic" src={profileImageThatShouldBeDisplayed} onError={(e) => { e.target.src = defaultUserIcon }} />
            </div>
            <div className="analytics-users__name">
              {screenNameThatShouldBeDisplayed}
            </div>
          </div>
          <div className="analytics-users__col">
            <span className="analytics-users__date">{r.last_suggested ? moment(r.last_suggested).lang(i18n.language).format('L') : ''}</span>
          </div>
          <div className="analytics-users__col">
            <span className="analytics-users__date">{r.last_published ? moment(r.last_published).lang(i18n.language).format('L') : ''}</span>
          </div>
          <div className="analytics-users__col">
            <div className="analytics-users__status">
              <div className="analytics-users__status-bloc">
                <div className="analytics-users__status-icon wait"><IconSchedule /></div>
                {this.getPercent(r.suggested, (r.suggested + r.published + r.removed))}%
              </div>
              <div className="analytics-users__status-bloc">
                <div className="analytics-users__status-icon accept"><IconCheck /></div>
                {this.getPercent(r.published, (r.suggested + r.published + r.removed))}%
              </div>
              <div className="analytics-users__status-bloc">
                <div className="analytics-users__status-icon refuse"><IconClose /></div>
                {this.getPercent(r.removed, (r.suggested + r.published + r.removed))}%
              </div>
            </div>
          </div>
          <div className="analytics-users__col">
            <span className="analytics-users__suggest">{r.suggested + r.published + r.removed}</span>
          </div>
          <div className="analytics-users__col">
            <span className="analytics-users__shares">{r.published}</span>
          </div>
          <div className="analytics-users__col">

            {r.total ?
              <Tooltip
                interactive
                classes={{ popper: this.props.classes.tooltipUser, tooltip: this.props.classes.tooltip }}
                placement="left"
                title={
                  <div className={this.props.classes.tooltipTitle}>
                    <div className={this.props.classes.tooltipContainer}>
                      <img alt="arrow" src={arrow} />
                    </div>
                    <div className="analytics-users__tooltip-outer">
                      {r.flux_post_click ? <p>(Flux) Clique sur un post : {r.flux_post_click} points </p> : ''}
                      {r.flux_post_like ? <p>(Flux) Like sur un post : {r.flux_post_like} points </p> : ''}
                      {r.flux_post_pass ? <p>(Flux) Passer un post: {r.flux_post_pass} points </p> : ''}
                      {r.flux_post_published_linkedin ? <p>(Flux) Post Linkedin partagé : {r.flux_post_published_linkedin} points </p> : ''}
                      {r.flux_post_published_twitter ? <p>(Flux) Post Twitter partagé : {r.flux_post_published_twitter} points </p> : ''}
                      {r.post_published_post_linkedin ? <p>Post Linkedin publié : {r.post_published_post_linkedin} points </p> : ''}
                      {r.post_published_post_twitter ? <p>Post Twitter partagé : {r.post_published_post_twitter} points </p> : ''}
                      {r.post_published_profile_twitter ? <p>Suivre un profil Twitter : {r.post_published_profile_twitter} points</p> : ''}
                      {r.post_published_repost_twitter ? <p>Retweet publié : {r.post_published_repost_twitter} points</p> : ''}
                      {r.post_published_repost_facebook ? <p>Post Facebook publié : {r.post_published_repost_facebook} points</p> : ''}
                      {r.post_published_repost_instagram ? <p>Post Instagram publié : {r.post_published_repost_instagram} points</p> : ''}
                      {r.post_refused_linkedin ? <p>Post Linkedin refusé : {r.post_refused_linkedin} points</p> : ''}
                      {r.post_refused_twitter ? <p>Post Twitter refusé : {r.post_refused_twitter} points</p> : ''}
                      {r.post_refused_instagram ? <p>Post Instagram refusé : {r.post_refused_instagram} points</p> : ''}
                      {r.post_refused_facebook ? <p>Post Facebook refusé : {r.post_refused_facebook} points</p> : ''}
                      {r.user_app_logged ? <p>Connexion à l'application : {r.user_app_logged} points</p> : ''}
                      {r.user_app_opened ? <p>Ouverture de l'application : {r.user_app_opened} points</p> : ''}

                    </div>
                  </div>
                }
              >
                <span className="analytics-users__score">{r.total} <IconStar /></span>
              </Tooltip>

              :
              <span className="analytics-users__score">{r.total} <IconStar /></span>}
          </div>
        </div>
      )
    })

    return rows
  }

  render() {
    if (!this.state.data) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.loading_data}</p> <CircularProgress /></div>
    if (this.state.data && this.state.data.all && !this.state.data.all.length) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.empty_data}</p></div>
    return (
      <div className="analytics-users">
        <div className="analytics-users__container">
          {/* HEAD */}
          <div className="analytics-users__head">
            {wording.title} <span className="analytics-users__head-info">{this.state.data.all.length} {wording.total_users} - {this.props.users.filter(u => (u.user_twitter || u.user_linkedin) && u.role === 'Manager' && u.status === 'enabled').length + 1} {wording.total_managers}</span>
          </div>

          {/* FILTER */}
          <Filter filters={['group', 'name', 'network']} groups={this.props.groups} applyFilter={this.applyFilter} disableDelay={true} />

          {/* TABLE */}
          <div className="analytics-users__table">
            <div className="analytics-users__row head">
              <div className="analytics-users__col">
                {wording.label_name}
              </div>
              <div className="analytics-users__col w-40">
                {wording.label_last_suggest}
              </div>
              <div className="analytics-users__col w-40">
                {wording.label_last_share}
              </div>
              <div className="analytics-users__col">
                {wording.label_accept}
              </div>
              <div className="analytics-users__col">
                {wording.label_suggest}
              </div>
              <div className="analytics-users__col">
                {wording.label_share}
              </div>
              <div className="analytics-users__col">
                {wording.score}
              </div>
            </div>
            {this.buildRow()}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AnalyticsUsers)
