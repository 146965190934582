import React from 'react';

// Init component.
function IconClose(props) {
  return (
    <svg width="6" height="6" viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.74713 0.252867C5.40998 -0.0842889 4.86334 -0.0842889 4.52619 0.252867L3 1.77905L1.47381 0.252867C1.13666 -0.0842889 0.590022 -0.0842889 0.252867 0.252867C-0.0842888 0.590022 -0.0842888 1.13666 0.252867 1.47381L1.77905 3L0.252867 4.52619C-0.0842891 4.86334 -0.0842888 5.40998 0.252867 5.74713C0.590022 6.08429 1.13666 6.08429 1.47381 5.74713L3 4.22095L4.52619 5.74713C4.86334 6.08429 5.40998 6.08429 5.74713 5.74713C6.08429 5.40998 6.08429 4.86334 5.74713 4.52619L4.22095 3L5.74713 1.47381C6.08429 1.13666 6.08429 0.590022 5.74713 0.252867Z" />
    </svg>
  );
}

export default IconClose;