import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ content, handleAutocomplete }) => {
  const handleClick = () => {
    handleAutocomplete({
      base: content.base,
      screenName: '@' + content.screen_name
    })
  }
  return (
    <>
      <div className="twitter-dropdown__item" onClick={handleClick}>
        <img className="twitter-dropdown__item__image" src={content.profile_image_url_https} alt={content.name} />
        <div className="twitter-dropdown__item__details">
          <p className="twitter-dropdown__item__details__name">
            {content.name}
          </p>
          <p className="twitter-dropdown__item__details__name--screen">
            @{content.screen_name}
          </p>
        </div>
      </div>
    </>
  )
}

ListItem.propTypes = {
  content: PropTypes.object.isRequired,
  handleAutocomplete: PropTypes.func.isRequired
}

export default ListItem