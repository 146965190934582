const styles = theme => ({
  groupAdd: {
    color: theme.palette.background.paper,
    boxShadow: "none",
    transition: "none",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_COLOR_HOVER ? process.env.REACT_APP_COLOR_HOVER : '#0B3569',
    },
    "&:active": {
      boxShadow: "none",
    }
  },
  groupList: {
    marginTop: 15,
    paddingLeft: 0,
    paddingRight: 0,
  },
  groupItem: {
    padding: "5px 0",
  },
  groupItemText: Object.assign({}, theme.groupChip, {
    paddingLeft: [13, "!important"],
    paddingRight: 10,
    color: theme.greyBlue,
  }),
  groupItemTextSelected: {
    color: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
  },
  buttonIcon: {
    marginRight: 10,
  },
  groupName: {
    fontSize: 18,
    fontWeight: 600,
  },
  action: {
    margin: "0 5px",
  },
  input: {
    fontSize: 15,
    fontWeight: 600,
    color: theme.greyBlue,
  },
  searchInput: {
    cursor: "pointer",
  },
  inputDisabled: {
    cursor: "not-allowed"
  },
  inputOutline: {
    borderColor: [theme.cloudyBlue, "!important"],
  },
  searchRoot: {
    paddingLeft: 20,
    paddingRight: 14,
    width: "calc(100% - 34px)"
  },
  selectAdornment: {
    marginTop: 4,
  },
  userList: {
    padding: "15px 10px",
  },
  userItemContainer: {
    "&:nth-of-type(2n + 1)": {
      backgroundColor: "#f5f8fa",
      borderRadius: 5,
    },
  },
  userItem: {
    height: 46,
    margin: "5px 0",
    padding: "5px 20px",
  },
  userItemText: {
    fontSize: 15,
    color: theme.greyBlue,
  },
  avatar: Object.assign({}, theme.userChip, {
    width: 35,
    height: 35,
  }),
  userDeleteAction: {
    "&:hover": {
      background: "none",
    }
  }
});

export default styles