import React from 'react';

// Init component.
function IconCheck(props) {
  return (
    <svg className="icon__check" width="8" height="6" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.27882 0.0884781C7.15073 -0.0294927 6.94017 -0.0294927 6.81216 0.0884781L2.67366 3.90012C2.54541 4.01839 2.33516 4.01839 2.20708 3.90012L1.18798 2.96152C1.05949 2.84355 0.849486 2.84355 0.720917 2.96152L0.0959459 3.53789C-0.031982 3.65556 -0.031982 3.84915 0.0959459 3.9672L2.20708 5.91147C2.33516 6.02951 2.54541 6.02951 2.67366 5.91147L7.90387 1.09378C8.03204 0.975657 8.03204 0.782137 7.90387 0.663941L7.27882 0.0884781Z" />
    </svg>
  );
}

export default IconCheck;