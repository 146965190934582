import React from 'react';

function IconSend() {
  return (
    <svg className="icon__like fill-primary" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path d="M9.79602 1.00002H9.81551C10.5966 1.01363 10.8464 1.7074 10.8464 1.7074C10.8464 1.7074 11.5141 2.97256 10.8906 4.51999C10.3646 5.82766 10.2565 5.93309 10.2565 5.93309C10.2565 5.93309 10.0865 6.16946 10.625 6.15925C10.625 6.15925 13.9124 6.14565 14.0258 6.14565C14.2684 6.14565 15.0425 6.3582 14.9982 7.30537C14.9663 8.00596 14.5129 8.25084 14.2808 8.33076C14.2507 8.34096 14.2348 8.37326 14.2472 8.40217C14.2525 8.41407 14.2613 8.42429 14.2737 8.43109C14.5111 8.56373 14.9964 8.89871 14.9858 9.41226C14.9716 10.0346 14.7112 10.2642 14.1692 10.407C14.1391 10.4138 14.1196 10.4444 14.1285 10.4733C14.132 10.4887 14.1427 10.5006 14.1551 10.5074C14.3605 10.6196 14.713 10.8934 14.6917 11.4477C14.6705 12.0123 14.2454 12.2112 14.0134 12.2792C13.9832 12.2877 13.9655 12.3183 13.9744 12.3473C13.9779 12.3592 13.9868 12.3711 13.9974 12.3779C14.1444 12.4748 14.3623 12.6942 14.3517 13.1448C14.3463 13.3829 14.2631 13.5563 14.1586 13.6804C13.9939 13.876 13.7353 13.978 13.4731 13.9797L6.17913 13.9933C6.17913 13.9933 5.78237 13.9933 5.78237 13.4849V8.8664C5.78413 7.72878 6.11536 6.61498 6.74061 5.64911C7.12143 5.06415 7.97517 4.12719 8.45341 3.64255C8.55968 3.53372 8.9458 3.21403 8.9458 3.03888C8.9458 2.81442 8.97238 2.45222 8.98124 1.77713C8.98655 1.34861 9.34966 0.996623 9.79602 1.00002ZM1.418 7.75089H4.34234C4.57261 7.75089 4.76036 7.92944 4.76036 8.1522V14.5987C4.76036 14.8197 4.57438 15 4.34234 15H1.418C1.18774 15 1 14.8214 1 14.5987V8.15051C1.00176 7.92944 1.18774 7.75089 1.418 7.75089Z" />
    </svg>
  )
}

export default IconSend;