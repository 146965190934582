import React from 'react';

// Init component.
function IconChevron(props) {
  return (
    <svg className="icon__chevron" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 13L7 7L1 1" className="stroke-primary" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconChevron;