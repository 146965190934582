import React from 'react';

// Init component.
function IconSearch(props) {
  return (
    <svg className="icon__search" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7972 11.6606L15.7833 14.6156C16.0765 14.9351 16.0765 15.4409 15.7566 15.7604C15.6233 15.9201 15.41 16 15.1967 16C14.9834 16 14.7968 15.9201 14.6369 15.7604L11.6775 12.8053C10.4244 13.817 8.82477 14.376 7.19845 14.376C5.2522 14.376 3.46592 13.604 2.10621 12.2729C0.746506 10.9151 0 9.13145 0 7.18802C0 5.24459 0.746506 3.4609 2.10621 2.10316C3.46592 0.745424 5.27886 0 7.19845 0C9.11804 0 10.9043 0.745424 12.264 2.10316C14.8501 4.68552 15.0634 8.8386 12.7972 11.6606ZM11.0155 11.0155C12.0581 9.999 12.6316 8.61754 12.6316 7.15789C12.6316 5.67218 12.0321 4.34286 11.0155 3.30025C9.999 2.25764 8.61754 1.68421 7.15789 1.68421C5.67218 1.68421 4.34286 2.28371 3.30025 3.30025C2.25764 4.31679 1.68421 5.69825 1.68421 7.15789C1.68421 8.61754 2.28371 9.97293 3.30025 11.0155C4.31679 12.0581 5.69825 12.6316 7.15789 12.6316C8.61754 12.6316 9.97293 12.0321 11.0155 11.0155Z" />
    </svg>
  );
}

export default IconSearch;