import Constants from '../utils/params';
import { catchErrors, refreshTokenAction, getUserInfosAction, checkToken } from './utils';
import i18n from '../i18n'
/*
  Récupère un nouveau token
 */
export function getNewTokenAction(refresh_token) {
  return (dispatch) => {
    return refreshTokenAction();
  }
}

/*
  Récupère les informations de l'utilisateur connecté
 */
export function getCurrentUserAction(skipToken) {
  return (dispatch) => {
    if (!skipToken) {
      return checkToken().then(() => {
        return getUserInfosAction()
          .then(res => {
            // Change lang
            if (res && res.lang) i18n.changeLanguage(res.lang)

            dispatch({
              type: Constants.UPDATE_USER,
              currentUser: true,
              user: res
            });
            return res;
          })
          .catch((catchErrors))
      });
    }
    return getUserInfosAction()
      .then(res => {
        // Change lang
        if (res && res.lang) i18n.changeLanguage(res.lang)

        dispatch({
          type: Constants.UPDATE_USER,
          currentUser: true,
          user: res
        });
        return res;
      })
      .catch(catchErrors)
  }
}

/*
  Met à jour les informations de l'utilisateur
*/
export function updateUserAction(user) {
  return (dispatch) => {
    dispatch({
      type: Constants.UPDATE_USER,
      currentUser: true,
      user: user
    });
  }
}

/*
  Met à jour les informations des utilisateurs
*/
export function updateUsersAction(users) {
  return (dispatch) => {
    dispatch({
      type: Constants.UPDATE_USERS,
      users: users
    });
  }
}

/*
  Met à jour isAuthenticated
*/
export function updateIsAuthenticated(value) {
  return (dispatch) => {
    dispatch({
      type: Constants.UPDATE_ISAUTHENTICATED,
      isAuthenticated: value
    });
  }
}

/*
  Linkedin : exchange Authorization Code for an Access Token
*/
export function getLinkedinAccessToken(params) {
  return async (dispatch) => {

    let headers = {
      'Content-Type': 'application/json'
    }

    if (params.profil) {
      let res = await checkToken()
        .catch(() => { return null })

      if (res && res.token) headers["x-auth-token"] = res.token
    }

    return fetch(`${Constants.DOMAIN}/auth/linkedin${params.register ? '?register=1' : ''}`, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({
        code: params.code,
        redirect_uri: params.redirect_uri,
        client_id: process.env.REACT_APP_LINKEDIN_ID,
        email_token: params.email_token
      })
    })
      .then(res => {
        return (res)
      })
      .catch((err) => {
        return { error: err }
      })
  }
}

export function logUserOut () {
  return async (dispatch) => {
    try {

      const headers = {
        'Content-Type': 'application/json'
      }
  
      const res = await checkToken()
      if (res && res.token) headers["x-auth-token"] = res.token

      await fetch(`${Constants.DOMAIN}/auth/logout`, {
        method: 'post',
        headers: headers
      })
      
    } catch (err) {
      console.error(err)
      return { error: err }
    }
  }
}