import React from 'react';
import PropTypes from 'prop-types';
import windowSize from 'react-window-size';
import ReactSVG from 'react-svg'
import Linkify from 'react-linkify';
import classNames from 'classnames';
import moment from 'moment';
import StringReplace from 'react-string-replace';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n'

// Components
import params from '../../../utils/params'
import GlobalUtils from '../../../utils/global'
import ProgressiveImage from '../progressiveImage/progressiveImage'

// Material
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";

// Assets
import commentIcon from "../../../assets/images/comment.svg";
import scheduleIcon from "../../../assets/images/schedule-grey.svg";
import CombinedShapeIcon from '../../../assets/images/combined-shape.svg';
import IconPeople from '../../../assets/icons/people';
import IconGif from '../../../assets/icons/gif';
import SuggestedIcon from '../../../assets/icons/schedule.js';
import PublishedIcon from '../../../assets/icons/check.js';
import RefusedIcon from '../../../assets/icons/close.js';
import TwitterIcon from '../../../assets/icons/twitter-icon'
import LinkedinIcon from '../../../assets/icons/linkedin-icon';
import IconFacebook from "../../../assets/icons/facebook"
import IconInstagram from "../../../assets/icons/instagram"
import arrow from "../../../assets/images/arrow.svg";
import EditIcon from '../../../assets/icons/edit-icon';
import SendIcon from '../../../assets/icons/send';
import ShareIcon from '../../../assets/icons/share';
import CommentIcon from '../../../assets/icons/comment';
import LikeIcon from '../../../assets/icons/like';
import CardCancelIcon from '../../../assets/icons/card-cancel';
import TrashIcon from '../../../assets/icons/trash';
import FollowIcon from '../../../assets/icons/follow';
import IconRetweet from '../../../assets/icons/retweet';
import defaultUserIcon from "../../../assets/images/default_profile_normal.png";

// Material styles
import styles from './material-tweet-card';

const wording = i18n.t('user', { returnObjects: true })

class TweetCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      old_props: null,
      isHover: false,
      listImgNotLoading: [],
      listGifIds: [],
      gapBetweenHeadersElements: null,
      confirmIsOpen: null
    };

    // Ref
    this.postCardRef = React.createRef()

    // Move a tweetit submited to the draft column
    this.handleRemove = this.handleRemove.bind(this);

    // Submit a post
    this.handleSubmit = this.handleSubmit.bind(this);

    // Edit a post
    this.handleEdit = this.handleEdit.bind(this);

    // Set the hover state on a card
    this.setHover = this.setHover.bind(this);

    // Add the image ref to the list of images who has loaded
    this.handleLoad = this.handleLoad.bind(this);

    // Handle video event
    this.handleVideoClick = this.handleVideoClick.bind(this);
    this.handleVideoOver = this.handleVideoOver.bind(this);
    this.handleVideoOut = this.handleVideoOut.bind(this);
    this.handleVideoLoad = this.handleVideoLoad.bind(this);
    this.handleMouseMoveVideo = this.handleMouseMoveVideo.bind(this);

    // Handle gif event
    this.handleGifOver = this.handleGifOver.bind(this);
    this.handleGifOut = this.handleGifOut.bind(this);

    this.headerInfos = React.createRef()
    this.headerSwitchContainer = React.createRef()
    this.headerContainer = React.createRef()

    // renderer
    this.renderMessage = this.renderMessage.bind(this)

    // popin confirm
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this)

  }

  async componentDidMount() {

    // If there is no media
    if (!this.props.media) return;

    // Get the list of id
    let ids = this.props.media.map(m => m.uploadcare_id)

    // Init the list who will contain the id of gif media
    let listGifIds = []

    for (let id of ids) {
      let imageInfo = await this.props.getFileInfoUploadCare(id);

      if (imageInfo && imageInfo.format === "GIF") listGifIds.push(imageInfo.id)
    }

    if (listGifIds.length) {
      this.setState({
        listGifIds: listGifIds
      }, () => {
        if (this.props.triggerUserMediaOnScroll) this.props.triggerUserMediaOnScroll(null, { init: 'gif' })
      })
    }

  }

  componentDidUpdate(prevProps) {

    // If the spinner was active, disable it when we receive the result from the call api
    if (prevProps.posting && !this.props.posting && this.state.submitted) {
      this.setState({
        submitted: false
      })
    }
  }

  handleVideoClick(data, index) {
    try {
      // Pause the video in the post card
      if (this.postCardRef && this.postCardRef.current) {
        let videos = this.postCardRef.current.getElementsByTagName('video')

        if (videos && videos.length) {
          for (let video of videos) {
            video.pause()
            video.currentTime = video.duration < 2 ? 0 : video.duration / 2
          }
        }
      }
    } catch (e) {
      console.error(e)
    }

    // Display the popin to show the video
    this.props.enlargeMedia(data, index)
  }

  handleVideoLoad(e) {
    // To not interfer with the function triggerUserMediaOnScroll (In order to trigger the video with current time at 0)
    if (this.props.isUser && window.innerWidth <= 767) return

    try {
      e.currentTarget.currentTime = e.currentTarget.duration < 2 ? 0 : e.currentTarget.duration / 2
    } catch (err) {
      console.error(err)
    }
  }

  handleVideoOver(e) {

    try {
      let isDesktopMode = this.props.windowWidth > 1024

      if (isDesktopMode) {
        let track = e.currentTarget.parentElement.querySelector(".js-track")
        if (track) {
          track.classList.add("visible")
        }
      }

      e.currentTarget.play()

      if (!isDesktopMode) {
        e.currentTarget.playbackRate = 8
        e.currentTarget.currentTime = 0
      }

    } catch (err) {
      console.error(err)
    }
  }

  handleVideoOut(e) {
    try {
      let isDesktopMode = this.props.windowWidth > 1024

      if (isDesktopMode) {
        let track = e.currentTarget.parentElement.querySelector(".js-track")
        if (track) {
          track.classList.remove("visible")
        }
      }

      e.currentTarget.pause()

      if (!isDesktopMode) {
        e.currentTarget.currentTime = e.currentTarget.duration < 2 ? 0 : e.currentTarget.duration / 2
      }
    } catch (err) {
      console.error(err)
    }
  }

  handleGifOver(e) {
    try {
      e.currentTarget.src = e.currentTarget.src.replace('-/resize/x200/', '')
    } catch (err) {
      console.error(err)
    }
  }

  handleMouseMoveVideo(e) {
    try {
      let target = e.currentTarget

      // target dimension
      let rect = target.getBoundingClientRect()

      // mouse position relative to target  + clamped between [0, target.width]
      let x = e.clientX - rect.left
      x = Math.max(Math.min(x, Math.floor(rect.width) - 3), 0)

      // mouse percent position
      let percentX = Math.max(Math.min(x / rect.width, 1), 0)

      let track = target.querySelector(".js-track")
      let video = target.querySelector("video")

      if (track && video) {
        track.style.transform = "translateX(" + x + "px)"

        let duration = video.duration
        let nextCurrentTime = duration * percentX

        video.currentTime = nextCurrentTime
      }
    } catch (e) {
      console.error(e)
    }

  }

  handleGifOut(e) {
    try {
      e.currentTarget.src = e.currentTarget.src + '-/resize/x200/'
    } catch (err) {
      console.error(err)
    }
  }

  handleRemove(e) {
    // Prevent propagation of the current event to the card component
    e.stopPropagation();

    // Retrieve the data of the tweetit
    let data = Object.assign({}, this.props);

    // Changing the status to "draft"
    data.status = params.statuses.DRAFT;

    // Format data
    data.comment = data.comment.length > 0 ? data.comment : null;
    data.share_url = data.share_url ? data.share_url : null;
    data.share_ref = data.share_ref ? data.share_ref : null;
    data.users = data.references.map(ref => ref.user.id);
    data.groups = data.groups.map(group => group.id);
    data.tag = data.tag.length > 0 ? data.tag.map(tag => tag.id) : null;
    data.updated_by = !this.props.isUser ? this.props.user.id : this.props.author;

    // Move a tweetit submited to the draft column
    this.props.handleRemove(this.props.id, data)
  }

  async handleSubmit() {
    // Enable loader on the button to submit
    await this.setState({ submitted: true })

    await this.props.handleSubmit(
      this.props.id,
      {
        message: this.props.message,
        comment: this.props.comment,
        media: this.props.media,
        mediaType: this.props.media.length > 0 ? this.props.media[0].type : params.mediaTypes.IMAGE,
        status: params.statuses.SUGGESTED,
        type: this.props.type,
        tag: this.props.tag.map(tag => tag.id),
        groups: this.props.groups.map(group => group.id),
        users: this.props.references.map(child => child.user.id),
        share_ref: this.props.share_ref,
        share_url: this.props.share_url,
        scheduled_at: this.props.scheduled_at ? this.props.scheduled_at : null,
        submitted_at: moment(),
        updated_by: !this.props.isUser ? this.props.user.id : this.props.author,
        email_notif: this.props.email_notif,
        sms_notif: this.props.sms_notif,
        notify_users: this.props.notify_users,
        network: this.props.network,
      }
    )

    // Disable loader on the button to submit
    if (!this.props.isUser) {
      this.setState({ submitted: false })
    }
  }

  handleEdit(e) {
    // Prevent propagation of the current event to the card component
    e.stopPropagation();

    // Edit the tweet
    this.props.handleEdit({
      id: this.props.id,
      media: this.props.media,
      mediaType: this.props.media[0] ? this.props.media[0].type : params.mediaTypes.IMAGE,
      status: this.props.status,
      message: this.props.message,
      network: this.props.network,
      share_url: this.props.share_url,
      share_ref: this.props.share_ref,
      comment: this.props.comment,
      type: this.props.type,
      tag: this.props.tag,
      groups: this.props.groups,
      references: this.props.references,
      screen_name: this.props.share_ref && this.props.share_ref.screen_name ? this.props.share_ref.screen_name : "",
      scheduled_at: this.props.scheduled_at ? moment(this.props.scheduled_at) : this.props.scheduled_at,
      submitted_at: this.props.submitted_at,
      updated_by: !this.props.isUser ? this.props.user.id : this.props.author,
      updated_at: this.props.updated_at,
      email_notif: this.props.email_notif,
      sms_notif: this.props.sms_notif,
      notify_users: this.props.notify_users,
      orphans: this.props.orphans,
    })
  }

  renderImage(image, index, className, single) {
    // Get the medial url from uploadcare
    let imageTweet = this.props.getMediaUrl(image);

    let imageTweetPreview = imageTweet;

    // Define If the media is a gif
    const isGif = this.state.listGifIds.includes(image.uploadcare_id) || ((typeof (image) === 'string') && image.includes('image/gif')) || this.props.isTweetContainGif;

    if (!isGif) {
      imageTweetPreview = imageTweet ? imageTweet.replace('/resize/x200/', '/resize/x50/') : null;
    }

    if (imageTweetPreview) {
      return (
        <ProgressiveImage src={imageTweet} placeholder={imageTweetPreview} key={index} dataRef={`u${index}`} onLoadFinish={this.handleLoad}>
          {(src, loading) =>
            <div className={`tweet-card__media-wrapper ${!single ? 'half' : ''} ${!this.state.listImgNotLoading.includes(`u${index}`) ? 'loading' : ''}`}>
              <img className={`${className} tweet-card__media ${!this.state.listImgNotLoading.includes(`u${index}`) ? 'loading' : ''}`}
                alt={image.alt || params.mediaTypes.IMAGE}
                data-src={loading}
                data-type={isGif ? params.mediaTypes.GIF : params.mediaTypes.IMAGE}
                src={src}
                onClick={() => this.props.enlargeMedia(this.props.media, index)}
                onMouseOver={isGif ? this.handleGifOver : null}
                onMouseOut={isGif ? this.handleGifOut : null}
              />
              {isGif ?
                <div className="tweet-card__media-icon-gif" onClick={() => this.props.enlargeMedia(this.props.media, index)}>
                  <IconGif />
                </div>
                : ''}
            </div>
          }
        </ProgressiveImage>
      )
    } else {
      return (
        <div key={index} className={`tweet-card__media-wrapper ${!single ? 'half' : ''}`}>
          <img className={className}
            alt={image.alt || params.mediaTypes.IMAGE}
            src={imageTweet || image}
            onClick={() => this.props.enlargeMedia(this.props.media, index)}
          />
        </div>
      )
    }
  }

  async setHover(e) {
    // wont hover if post is a draft or is a preview
    if (this.props.isPreview || this.props.status === params.statuses.DRAFT) return;

    await this.setState({
      isHover: e.type === 'mouseenter'
    });

    // Compute distance between headerSwitchContainer and headerInfos

    if ((!this.props.isUser && !this.props.isPreview) && (this.headerContainer.current && this.headerSwitchContainer.current && this.headerInfos.current)) {
      this.setState({
        gapBetweenHeadersElements: this.headerContainer.current.offsetWidth - (
          this.headerInfos.current.offsetWidth + this.headerSwitchContainer.current.offsetWidth)
      })
    }
  }

  handleLoad(ref) {
    let listImgNotLoading = this.state.listImgNotLoading;

    listImgNotLoading.push(ref)

    this.setState({
      listImgNotLoading: listImgNotLoading
    })
  }

  transformOldShareRef(share_ref) {
    return {
      url: this.props.share_url,
      name: share_ref.user.name,
      screen_name: share_ref.user.screen_name,
      message: this.props.message,
      media_url_https: share_ref.mediaUrl,
      mediaType: share_ref.mediaType,
      sub_preview: share_ref.sub_preview
    }
  }

  buildLink(message, url, j) {
    try {

      // Remove dot, comma and two points at the end of url
      if (url.endsWith(',') || url.endsWith('.') || url.endsWith(':')) url = url.slice(0, url.length - 1)

      return StringReplace(message, url, (match, i) => {

        let urlHasProtocol = url.startsWith("http://") || url.startsWith("https://")
        let urlObject = null
        let tldListIndex = -1

        try {
          urlObject = new URL(!urlHasProtocol ? "https://" + url : url)

          let tld = urlObject.hostname.split(".").pop()
          tldListIndex = GlobalUtils.binarySearch(tld, params.TLD_LIST)

        } catch (error) {
          console.error(error)
          return url
        }

        // if url domain is unknow return plain text
        if (tldListIndex === -1) {
          return url
        }

        // Delete protocol
        let simplifiedUrl = `${urlObject.hostname.replace('www.', '')}${urlObject.pathname}`

        // Reduce length
        simplifiedUrl = simplifiedUrl.length > 26 ? `${simplifiedUrl.substring(0, 26)}...` : simplifiedUrl

        // If the url finish with "/" remove it
        simplifiedUrl = simplifiedUrl && simplifiedUrl.endsWith('/') ? simplifiedUrl.slice(0, simplifiedUrl.length - 1) : simplifiedUrl


        return <a key={`link-${j}-${i}`} href={!urlHasProtocol ? "https://" + url : url} title={url} rel="noopener noreferrer" target='_blank'>{simplifiedUrl}</a>
      })

    } catch (e) {
      console.error(e)
      return url
    }
  }

  transformLink(message, network) {
    // #hashtag become links
    message = StringReplace(message, RegExp(/#(\S+\b)/ig), (match, i) => {

      if (!network) return <span key={"#" + i} className="hashtag">#{match}</span>

      const url = network === 'twitter' ? 'https://twitter.com/hashtag/' + match + '?src=hash' : network === 'linkedin' ? `https://www.linkedin.com/feed/hashtag/?keywords=${match}` : network === 'instagram' ? `https://www.instagram.com/explore/tags/${match}/` : network === 'facebook' ? `https://www.facebook.com/hashtag/${match}` : ''

      return <a key={"#" + i} title={url} rel="noopener noreferrer" target='_blank'
        href={url}>#{match}</a>
    })

    // @screenName become links
    message = StringReplace(message, RegExp(/@([0-9a-zàâäéèêëîïôöùûü\-'%]+)/ig), (match2) => {
      const randomId = Math.floor(Math.random() * 1000000)

      if (network === 'twitter') {
        const url = 'https://twitter.com/' + match2
        return <a key={"tw" + randomId} title={url} rel="noopener noreferrer" target='_blank'
          href={url}>@{match2}</a>

      } else if (network === 'linkedin') {
        const url = 'https://www.linkedin.com/company/' + match2
        return <a key={"lk" + randomId} title={url} rel="noopener noreferrer" target='_blank'
          href={url}>@{match2}</a>

      } else if (['facebook'].includes(network) || !network) {
        return <span key={"fb" + randomId} className="handle">@{match2}</span>

      } else if (network === 'instagram') {
        const url = 'https://instagram.com/' + match2
        return <a key={"ig" + randomId} title={url} rel="noopener noreferrer" target='_blank'
          href={url}>@{match2}</a>
      }
    })

    return message
  }

  renderMessage() {

    // if tweetcard contains message or share url
    if (this.props.message || this.props.share_url) {

      let message = this.props.message

      // remove preview url at the end of message
      if (this.props.share_ref && this.props.share_url && message.endsWith(this.props.share_url)) {
        message = message.replace(this.props.share_url, "")
      }

      // Retrieve url with or without protocol
      const regexUrl = /(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)/gm

      let urls = (typeof (message) === 'string') && message.match(regexUrl)

      if (urls) {
        let j = 0
        for (let url of urls) {
          message = this.buildLink(message, url, j)
          j++
        }
      }

      message = this.transformLink(message, this.props.network)

      // transform urld in clickable links
      return (
        <Typography className={this.props.classes.textContainer} component="p">
          {message}
        </Typography>
      )

    } else {
      return ""
    }
  }

  renderSubPreview() {

    // post has subpreview
    if (this.props.share_ref && this.props.share_ref.sub_preview) {

      let subPreview = this.props.share_ref.sub_preview

      return (
        <div className="tweet-card__ogPreview-subPreview">
          {
            this.renderPreviewOf(subPreview, false)
          }
        </div>
      )
    } else {
      return ""
    }

  }

  renderPreviewOf(preview, mustRenderSubPreview = false) {
    // Add ref extension to media for progressive image
    const m = mustRenderSubPreview ? '-sub' : ''
    const isDesktopMode = this.props.windowWidth > 1024


    if (!preview) return;

    return (
      // show website preview with og parsing
      preview && (['linkedin', 'facebook'].includes(this.props.network) || !GlobalUtils.isUrlFromTwitter(preview.url)) ? (
        <div className="tweet-card__ogPreview">
          {preview.image && <div className="tweet-card__ogPreview-image">
            <img src={preview.image} alt="" />
          </div>}
          {preview.title && <p className="tweet-card__ogPreview-title">{preview.title}</p>}
          {preview.url && <p className="tweet-card__ogPreview-url">{preview.url}</p>}
        </div>
      )
        // show tweet preview from twitter post
        : preview ? (
          // show retweet preview
          <div className="tweet-card__retweet">
            <div className="tweet-card__retweet-title-con">
              {preview.name}
            </div>
            <div className="tweet-card__retweet-subtitle-con">
              @{preview.screen_name}
            </div>
            {preview.message && <div className="tweet-card__retweet-message-con">
              <Linkify properties={{ target: '_blank' }}>
                {this.transformLink(preview.message, 'twitter')}
              </Linkify>
            </div>}
            {(preview && preview.media_url_https && preview.media_url_https.length) && (
              preview.mediaType === params.mediaTypes.IMAGE ?
                preview.media_url_https.length === 3 ?
                  <div className="tweet-card__media-grid">
                    <div className="tweet-card__media-container">
                      <ProgressiveImage src={`${preview.media_url_https[0]}:small`} placeholder={`${preview.media_url_https[0]}:thumb`} dataRef={`t1${m}`} onLoadFinish={this.handleLoad}>
                        {(src, loading) =>
                          <div className={`tweet-card__media-wrapper ${!this.state.listImgNotLoading.includes(`t1${m}`) ? 'loading' : ''}`}>
                            <img alt={preview.mediaType} src={src} className={`${this.props.classes.media} tweet-card__media ${!this.state.listImgNotLoading.includes(`t1${m}`) ? 'loading' : ''}`} style={{ marginTop: 0 }} data-src={loading} onClick={() => this.props.enlargeMedia({ native: params.mediaTypes.IMAGE, url: preview.media_url_https }, 0)} />
                          </div>
                        }
                      </ProgressiveImage>
                    </div>
                    <div className="tweet-card__media-container">
                      <ProgressiveImage src={`${preview.media_url_https[1]}:small`} placeholder={`${preview.media_url_https[1]}:thumb`} dataRef={`t2${m}`} onLoadFinish={this.handleLoad}>
                        {(src, loading) =>
                          <div className={`tweet-card__media-wrapper ${!this.state.listImgNotLoading.includes(`t2${m}`) ? 'loading' : ''}`}>
                            <img alt={preview.mediaType} src={src} className={`${classNames(this.props.classes.media, this.props.classes.mediaSmall)} tweet-card__media ${!this.state.listImgNotLoading.includes(`t2${m}`) ? 'loading' : ''}`} style={{ marginTop: 0 }} data-src={loading} onClick={() => this.props.enlargeMedia({ native: params.mediaTypes.IMAGE, url: preview.media_url_https }, 1)} />
                          </div>
                        }
                      </ProgressiveImage>
                      <ProgressiveImage src={`${preview.media_url_https[2]}:small`} placeholder={`${preview.media_url_https[2]}:thumb`} dataRef={`t3${m}`} onLoadFinish={this.handleLoad}>
                        {(src, loading) =>
                          <div className={`tweet-card__media-wrapper ${!this.state.listImgNotLoading.includes(`t3${m}`) ? 'loading' : ''}`}>
                            <img alt={preview.mediaType} src={src} className={`${classNames(this.props.classes.media, this.props.classes.mediaSmall)} tweet-card__media ${!this.state.listImgNotLoading.includes(`t3${m}`) ? 'loading' : ''}`} style={{ marginTop: 0 }} data-src={loading} onClick={() => this.props.enlargeMedia({ native: params.mediaTypes.IMAGE, url: preview.media_url_https }, 2)} />
                          </div>
                        }
                      </ProgressiveImage>
                    </div>
                  </div>
                  :
                  <div className="tweet-card__media-grid">
                    {
                      preview.media_url_https.map((media, index) => {
                        return (
                          <ProgressiveImage src={`${media}:small`} placeholder={`${media}:thumb`} key={index} dataRef={`t${index}${m}`} onLoadFinish={this.handleLoad}>
                            {(src, loading) =>
                              <div className={`tweet-card__media-wrapper ${preview.media_url_https.length > 1 ? 'half' : ''} ${!this.state.listImgNotLoading.includes(`t${index}${m}`) ? 'loading' : ''}`} >
                                <img
                                  data-src={loading}
                                  alt={preview.mediaType} src={src}
                                  className={`${classNames(this.props.classes.media, preview.media_url_https.length === 4 ? this.props.classes.mediaSmall : '')} tweet-card__media ${!this.state.listImgNotLoading.includes(`t${index}${m}`) ? 'loading' : ''}`}
                                  style={{ marginTop: 0 }}
                                  onClick={() => this.props.enlargeMedia({ native: params.mediaTypes.IMAGE, url: preview.media_url_https }, index)}
                                />
                              </div>
                            }
                          </ProgressiveImage>
                        )
                      })
                    }
                  </div>
                :
                preview.mediaType === params.mediaTypes.VIDEO ?
                  <div className="tweet-card__media-grid" onMouseMove={isDesktopMode ? this.handleMouseMoveVideo : null}>
                    <div className="tweet-card__video-preview">
                      <div className="tweet-card__video-preview-track js-track"></div>
                    </div>
                    <video muted src={preview.media_url_https} className={this.props.classes.media} style={{ marginTop: 0 }}
                      onMouseOver={this.handleVideoOver}
                      onMouseOut={this.handleVideoOut}
                      onLoadedData={this.handleVideoLoad}
                      onClick={() => this.handleVideoClick({ native: params.mediaTypes.VIDEO, url: preview.media_url_https }, 0)}
                    />
                    <PlayArrowRoundedIcon className={this.props.classes.mediaPlay} />
                  </div>
                  : ''
            )}
            {mustRenderSubPreview ? this.renderSubPreview() : ""}

          </div>
        ) : ""
    )
  }
  handleOpenConfirm(e) {
    this.props.handleOpenConfirm(e, this.props.id)
  }

  renderSubmitIcon() {

    if (this.props.isUser) {
      // REPOST
      if (this.props.type === params.types.REPOST) {

        if (this.props.network === "twitter") {
          return <IconRetweet />
        }
      }

      // Render icon for Instagram and Facebook post
      if (['instagram', 'facebook'].includes(this.props.network)) {
        let type = this.props.share_ref && this.props.share_ref.type ? this.props.share_ref.type : null

        return type === 'share' ? <ShareIcon /> : type === 'comment' ? <CommentIcon /> : type === 'like' ? <LikeIcon /> : ''
      }
    }

    return <SendIcon />
  }

  render() {
    const props = this.props;

    const isDesktopMode = props.windowWidth > 1024

    let author = null, screenNameThatShouldBeDisplayed = "";
    if ('users' in props) {

      author = props.users.find(u => u.id === props.updated_by);

      if (!author && props.updated_by === props.user.id) {
        author = props.user;
      }
      // If we find the last manager who update the post
      if (author)
        // get appropriate screenName and profileImage (according network)
        screenNameThatShouldBeDisplayed = GlobalUtils.getUserNameAndAvatar({ user: author }).screenNameThatShouldBeDisplayed

      if (!author || !screenNameThatShouldBeDisplayed)
        screenNameThatShouldBeDisplayed = "(compte supprimé)"
    }

    // Display the manager name who update the card
    const willDisplayManagerName = props.status === params.statuses.DRAFT && !props.isPreview

    const displayManagerName =
      willDisplayManagerName && (
        <Typography className={props.classes.manager}>
          <Translation>
            {
              (t, { i18n }) => {
                t = t('manager', { returnObjects: true }).home.post

                return (
                  <>{t.by} {screenNameThatShouldBeDisplayed}</>
                )
              }
            }
          </Translation>
        </Typography>
      )

    const scheduledAtText = (
      props.hideScheduledAt ? "" :
        props.isScheduled && props.scheduled_at
          ? moment(props.scheduled_at).isAfter(new Date())
            ? moment(props.scheduled_at).format('Do MMM HH:mm')
            : moment(props.scheduled_at).format('Do MMM HH:mm')
          : moment(props.updated_at).format('Do MMM HH:mm')
    )

    const displayScheduledAt =
      props.hideScheduledAt ? "" :
        props.isScheduled && props.scheduled_at
          ? moment(props.scheduled_at).isAfter(new Date())
            ? <Typography component={"span"} className={props.classes.date}>
              <ReactSVG src={scheduleIcon} />
              {moment(props.scheduled_at).format('Do MMM HH:mm')}
            </Typography>
            : <Typography component={"span"} className={props.classes.date}>
              {moment(props.scheduled_at).format('Do MMM HH:mm')}
            </Typography>
          : <Typography component={"span"} className={props.classes.date}>
            {moment(props.updated_at).format('Do MMM HH:mm')}
          </Typography>

    let cardContents = <div>
      {
        this.props.showHeader ?
          <div className="tweet-card__header">
            {!props.isPreview && props.isActivity ?
              <div className="tweet-card__header-activity background-color-primary"></div>
              : ''}
            <div ref={this.headerContainer} className="tweet-card__header-container">
              <div ref={this.headerSwitchContainer} className="tweet-card__header-switch-container">
                <div className={`tweet-card__header-switch ${this.state.isHover ? 'hover' : ''}`} >
                  <div className="tweet-card__header-avatars">
                    {
                      this.props.hideAvatars ? '' : (() => {
                        const totalUsers = props.isPreview ? 'members' : 'references'

                        // If there is only one group
                        if ((props.groups.length && (props.groups.length === 1) && !props.orphans.length)) {
                          return (
                            <Chip label={(
                              <div className="group-users__targets-group-label">
                                <span>{props.groups[0].label}</span> <span>{props[totalUsers] ? props[totalUsers].length : ''}</span>
                              </div>
                            )} className={props.classes.groupChip} />
                          )
                        }

                        // If there is only one user
                        if ((props.groups.length === 0) && (props[totalUsers].length === 1)) {


                          // get appropriate screenName and profileImage of user related to post (according network)
                          const {
                            screenNameThatShouldBeDisplayed: label,
                            profileImageThatShouldBeDisplayed: icon
                          } = GlobalUtils.getUserNameAndAvatar({ user: props[totalUsers][0].user })

                          let status = null;

                          if (!props.isPreview) {
                            switch (props[totalUsers][0].status) {
                              case params.statuses.PUBLISHED:
                              case params.statuses.EDITED:
                                status = (
                                  <div className="status-icon__status-icon-published">
                                    <PublishedIcon />
                                  </div>
                                )
                                break;
                              case params.statuses.REMOVED:
                                status = (
                                  <div className="status-icon__status-icon-refused">
                                    <RefusedIcon />
                                  </div>
                                )
                                break;
                              case params.statuses.SUGGESTED:
                                status = (
                                  <div className="status-icon__status-icon-suggested">
                                    <SuggestedIcon />
                                  </div>
                                )
                                break;
                              default: break;
                            }
                          }

                          return (
                            <div className="tweet-card__header-status">
                              <Avatar alt={label} className={props.classes.avatar}
                                src={icon} onError={(e) => { e.target.src = defaultUserIcon }}>
                              </Avatar>
                              {status ? status : ''}
                            </div>
                          )
                        }

                        // If there is multiple groups, multiple users or both
                        return (
                          <Chip label={(
                            <div className="group-users__targets-group-label">
                              <span>{<IconPeople />}</span> <span>{props[totalUsers].length}</span>
                            </div>
                          )} className={props.classes.groupChip} />
                        )
                      })()
                    }
                  </div>
                  {(!props.isPreview && !props.isUser) ?
                    <div className="tweet-card__header-statuses">
                      {this.state.isHover ?
                        <React.Fragment>
                          <div className="tweet-card__header-statuses-el">
                            <div className="status-icon__status-icon-suggested">
                              <SuggestedIcon />
                            </div>
                            {this.props.references.filter(user => user.status === params.statuses.SUGGESTED).length}
                          </div>
                          <div className="tweet-card__header-statuses-el">
                            <div className="status-icon__status-icon-published">
                              <PublishedIcon />
                            </div>
                            {this.props.references.filter(user => user.status === params.statuses.PUBLISHED || user.status === params.statuses.EDITED).length}
                          </div>
                          <div className="tweet-card__header-statuses-el">
                            <div className="status-icon__status-icon-refused">
                              <RefusedIcon />
                            </div>
                            {this.props.references.filter(user => user.status === params.statuses.REMOVED).length}
                          </div>
                        </React.Fragment>
                        : ''}
                    </div>
                    : ''}
                </div>
              </div>
              <div ref={this.headerInfos} className={`tweet-card__header-infos ${this.state.isHover ? 'hover' : ''} ${this.props.hideAvatars ? "tweet-card__header-infos-fullwidth" : ""}`}>
                <div className={`tweet-card__header-infos-top ${(props.status !== params.statuses.DRAFT) && !this.props.isUser ? 'max-width' : ''} ${this.props.isUser ? 'is-user' : ''}`}>
                  {/** Display social icon (FOR MEMBERS) */}
                  {
                    !props.hideSocialIcon && props.isUser ?
                      props.network === 'twitter' ?
                        <div className="tweet-card__header-infos-action twitter background-color-secondary">
                          <TwitterIcon />
                          {props.type === params.types.POST ? wording.post.to_publish : props.type === params.types.REPOST ? wording.post.to_retweet : wording.post.to_follow}
                        </div>
                        :
                        props.network === 'linkedin' ?
                          <div className="tweet-card__header-infos-action linkedin background-color-secondary"> <LinkedinIcon /> {wording.post.to_publish} </div>
                          :
                          ['facebook', 'instagram'].includes(props.network) ?
                            <div className={`tweet-card__header-infos-action ${props.network} background-color-secondary`}>
                              {props.network === 'instagram' ? <IconInstagram /> : <IconFacebook />}
                              {props.share_ref ?
                                <span>
                                  {props.share_ref.type === 'share' ? wording.post.to_share : ''}
                                  {props.share_ref.type === 'like' ? wording.post.to_like : ''}
                                  {props.share_ref.type === 'comment' ? wording.post.to_comment : ''}
                                </span>
                                : ''}
                            </div>
                            : ''
                      : ''
                  }
                  {/** Show managerName or scheduledAt */}
                  {
                    // If the gap between elements in the header of the post card is below 5 px, show tooltip to display the full content
                    (willDisplayManagerName || displayScheduledAt) && this.state.gapBetweenHeadersElements !== null && this.state.gapBetweenHeadersElements < 5 ? (
                      <Tooltip
                        title={
                          <div className={this.props.classes.tooltipTitle}>
                            <div className={this.props.classes.tooltipContainer}>
                              <img alt="arrow" src={arrow} />
                            </div>
                            {willDisplayManagerName ? screenNameThatShouldBeDisplayed : scheduledAtText}
                          </div>
                        }
                      >
                        {willDisplayManagerName ? displayManagerName : displayScheduledAt}
                      </Tooltip>
                    ) : (
                      willDisplayManagerName ? displayManagerName :
                        <div className="tweet-card__header-infos-details">
                          { displayScheduledAt }
                          {
                            props.tag.map((t, i) =>
                              <Chip key={i} label={t.label} classes={{ root: classNames(props.classes.tagChip, this.props.hideAvatars ? props.classes.tagChipAlignTop : undefined), label: props.classes.tagChipLabel }} />
                            )
                          }
                        </div>
                    )
                  }
                </div>
                {
                  // Show managerName or scheduledAt
                  willDisplayManagerName && displayScheduledAt
                }
                {/* {
                  props.tag.map((t, i) =>
                    <Chip key={i} label={t.label} classes={{ root: classNames(props.classes.tagChip, this.props.hideAvatars ? props.classes.tagChipAlignTop : undefined), label: props.classes.tagChipLabel }} />
                  )
                } */}
              </div>
            </div>
          </div>
          :
          null
      }
      {
        (this.props.showTag && !this.props.showHeader) &&
        <div className="tweet-card__header">
          <div className="tweet-card__header-infos">
            {
              props.tag.map((tag, i) =>
                <Chip key={i} label={tag.label} className={props.classes.tagChip} />
              )
            }
          </div>
        </div>
      }
      {(() => {
        let comment = this.props.comment &&
          <div className="tweet-card__comment">

            <div className="tweet-card__comment-header">
              <ReactSVG className="tweet-card__comment-header-icon" src={commentIcon} />
              <span className="tweet-card__comment-header-text">
                {
                  this.props.isUser ? wording.post.comment_administrator : wording.post.comment_member
                }
              </span>
            </div>
            <p className="tweet-card__comment-body">
              {this.props.comment}
            </p>
          </div>
          ;
        if (props.isPreview && !props.message && !props.share_ref && !props.share_url && props.media.length === 0) {
          return (
            <div className="tweet-card__card-content">
              <Typography color={"textSecondary"} align={"center"}>
                <Translation>
                  {
                    (t, { i18n }) => {
                      t = t('manager', { returnObjects: true }).home.post

                      return (
                        <>{t.refresh_content.map(v => {
                          return (<>{v} <br /> </>)
                        })}</>
                      )
                    }
                  }
                </Translation>
              </Typography>
            </div>
          )

        } else if (props.type === params.types.POST) {
          return (
            <div className="tweet-card__card-content">
              {
                this.renderMessage()
              }
              {(() => {
                let images = [];

                if (props.mediaType === params.mediaTypes.VIDEO && props.media.length > 0) {
                  return (
                    <div className="tweet-card__media-grid tweet-card__video-wrapper" onMouseMove={isDesktopMode ? this.handleMouseMoveVideo : null}>
                      <div className="tweet-card__video-preview">
                        <div className="tweet-card__video-preview-track js-track"></div>
                      </div>
                      <video
                        muted
                        className={classNames(props.classes.media, props.classes.video)}
                        src={props.vidSrc || props.getMediaUrl(props.media[0])}
                        // Mobile only - open modal media
                        onClick={() => this.handleVideoClick(props.media, 0)}
                        onMouseOver={this.handleVideoOver}
                        onMouseOut={this.handleVideoOut}
                        onLoadedData={this.handleVideoLoad}
                      />
                      <PlayArrowRoundedIcon className={props.classes.mediaPlay} />
                    </div>
                  )
                }

                if (!props.isPreview && props.media.length > 0) {
                  images.push(...props.media);
                } else if (props.isPreview) {

                  images.push(
                    ...props.media.filter(m => !(m instanceof File) && !m.fileName), // Retirer les fichiers ajoutés durant l'édition
                    ...props.imgSrc.filter(m => m) // L'édition crée des valeurs indéfinies s'il y avait déjà des média
                  );
                }

                if (images.length === 3) {
                  return (
                    <div className="tweet-card__media-grid">
                      <div className="tweet-card__media-container">
                        {this.renderImage(images[0], 0, props.classes.media, true)}
                      </div>
                      <div className="tweet-card__media-container">
                        {this.renderImage(images[1], 1, classNames(props.classes.media, props.classes.mediaSmall), true)}
                        {this.renderImage(images[2], 2, classNames(props.classes.media, props.classes.mediaSmall), true)}
                      </div>
                    </div>
                  )
                }

                if (images.length > 0) {
                  return (
                    <div className="tweet-card__media-grid">
                      {images.map((image, index) =>
                        this.renderImage(image, index,
                          images.length < 3
                            ? props.classes.media
                            : classNames(props.classes.media, props.classes.mediaSmall),
                          images.length < 2
                        )
                      )}
                    </div>
                  )
                }
              })()}

              {this.renderPreviewOf(this.props.share_ref, true)}

              {comment}
            </div>
          )

          // REPOST TWITTER
        } else if ((props.type === params.types.REPOST) && (this.props.network === 'twitter')) {
          let tweet_preview = this.props.share_ref && this.props.share_ref.user && this.props.network === 'twitter' ? this.transformOldShareRef(this.props.share_ref) : this.props.share_ref

          return (
            <div className="tweet-card__card-content">
              <div className="tweet-card__no-css-rule">

                {/* Display the retweet icon for TWITTER */}
                {(this.props.network === 'twitter') &&
                  <Typography component={"div"} color={"primary"} className={props.classes.IconRetweet}>
                    <ReactSVG src={CombinedShapeIcon} />
                  </Typography>
                }

                {/* Display preview of the post */}
                {this.renderPreviewOf(tweet_preview, true)}

                {comment}
              </div>
            </div>
          )

          // REPOST INSTAGRAM / FACEBOOK
        } else if ((props.type === params.types.REPOST) && (this.props.network !== 'twitter')) {
          return (
            <div className="tweet-card__card-content">
              {this.props.share_ref.message ?
                <Linkify properties={{ target: '_blank' }}>
                  {this.transformLink(this.props.share_ref.message, props.network)}
                </Linkify>
                : ''}

              {this.props.share_ref.image || this.props.share_ref.thumbnail ?
                <div className="tweet-card__media-grid">
                  <div className="tweet-card__media-wrapper">
                    <img alt="insta pic" className={this.props.classes.media} src={this.props.share_ref.image || this.props.share_ref.thumbnail} onClick={() => this.props.enlargeMedia({ native: "image", url: [this.props.share_ref.image || this.props.share_ref.thumbnail] }, 0)} />
                  </div>
                  {this.props.share_ref.media_type === 'video' ?
                    <PlayArrowRoundedIcon className={props.classes.mediaPlay} />
                    : ""}

                </div>
                : ''}

              {/* Facebook shared link preview */}
              {this.props.share_ref.shared_link && (typeof (this.props.share_ref.shared_link) === "object") ?
                this.renderPreviewOf(this.props.share_ref.shared_link)
                : ''}

              {comment}
            </div>
          )

        } else if (props.type === params.types.PROFILE) {
          return (
            <div className="tweet-card__card-content">
              {
                this.props.share_ref &&
                <div className="tweet-card__profile">
                  <Avatar className={props.classes.bigAvatar}
                    alt={this.props.share_ref.name}
                    onError={(e) => { e.target.src = defaultUserIcon }}
                    src={this.props.share_ref.profile_image.replace('_normal', '_bigger')} />
                  <div className="tweet-card__profile-name-plate">
                    <Typography color={"textPrimary"} className={props.classes.profileName}>
                      {this.props.share_ref.name}
                    </Typography>
                    <Typography color={"textSecondary"} className={props.classes.profileScreenName}>
                      @{this.props.share_ref.screen_name}
                    </Typography>
                  </div>
                </div>
              }
              {comment}
            </div>
          )
        }
      })()}
    </div>;

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).home.post

            return (
              <div className={`tweet-card__card ${props.isUser ? 'tweet-card__card-user' : ''} ${props.isPreview ? 'tweet-card__preview' : ''} ${props.isSelected ? 'shadow-hover-primary' : ''}`} onClick={props.isPreview ? null : this.props.onClick} onMouseEnter={this.setHover} onMouseLeave={this.setHover} ref={this.postCardRef}>
                {/* Display a message to connect to right network to view the post */}
                {props.isUser && ((props.network === 'linkedin' && !this.props.authenticatedOnLinkedin) || (props.network === 'twitter' && !this.props.authenticatedOnTwitter)) ?
                  <div className="tweet-card__card-reauth">

                    {/* TWITTER */}
                    {(props.network === 'twitter' && !this.props.authenticatedOnTwitter) ?
                      wording.text_log_twitter
                      : ''
                    }

                    {/* LINKEDIN */}
                    {(props.network === 'linkedin' && !this.props.authenticatedOnLinkedin) ?
                      wording.text_log_linkedin
                      : ''}
                  </div>
                  : ''}

                {cardContents}

                {(this.props.isEditable || this.props.isSubmitable || this.props.isFollowable) && (
                  <div className={`tweet-card__card-actions ${this.props.isSubmitable && this.props.isEditable && (this.props.status === params.statuses.DRAFT) ? 'padding' : ''}`}>
                    <div className={classNames("tweet-card__card-actions-container", ((this.props.windowWidth < 370 || (this.props.windowWidth > 1024 && this.props.windowWidth < 1030)) && this.props.isSubmitable && !this.props.isUser) && "tweet-card__card-actions-container-mobile")}>
                      {/* Boutton refuser */}
                      {this.props.isRemovable && (
                        <button className={"tweet-card__button tweet-card__button-refuse"}
                          onClick={() => this.props.handleRemove({
                            id: this.props.id,
                            media: this.props.media,
                            mediaType: this.props.media.length > 0 ? this.props.media[0].type : params.mediaTypes.IMAGE,
                            status: this.props.status,
                            message: this.props.message,
                            type: this.props.type,
                            references: this.props.references,
                            share_url: this.props.share_url,
                            share_ref: this.props.share_ref,
                            screen_name: this.props.share_ref && this.props.share_ref.screen_name ? this.props.share_ref.screen_name : ""
                          })}
                        >
                          <CardCancelIcon />
                          {wording.post.button_refuse}
                        </button>
                      )}

                      {/* Boutton editer */}
                      {(this.props.isEditable && !this.props.isCancelable) && (
                        <button
                          className={`tweet-card__button tweet-card__button-editer ${this.props.isSubmitable ? 'margin' : ''}`}
                          onClick={this.handleEdit}

                        >
                          <EditIcon />
                          {wording.post.button_edit}
                        </button>
                      )}

                      {/* Boutton supprimer */}
                      {this.props.status === params.statuses.DRAFT && (
                        <button className={`tweet-card__button tweet-card__button-delete ${this.props.isSubmitable ? 'margin' : ''}`}
                          popin-type='delete'
                          onClick={this.handleOpenConfirm}>
                          <TrashIcon />
                          {t.btn_delete}
                        </button>
                      )}

                      {/* Boutton Annuler la soumission */}
                      {this.props.isCancelable && (
                        <button className="tweet-card__button tweet-card__button-cancel"
                          onClick={this.handleRemove}>
                          <CardCancelIcon />
                          {t.btn_cancel}
                        </button>
                      )}

                      {/* Button "soumettre"  TWITTER & LINKEDIN */}

                      {this.props.isSubmitable && !['instagram', 'facebook'].includes(this.props.network) && (
                        <button
                          className={classNames(
                            "tweet-card__button tweet-card__button-soumettre",
                            !this.props.submitLabel && "square",
                            this.props.classes.buttonPrimary,
                          )}
                          disabled={this.state.submitted}
                          onClick={this.handleSubmit}
                        >
                          {this.state.submitted ? (
                            <CircularProgress className={this.submitLabel ? this.props.classes.progress : this.props.classes.progressSquare} size={20} />
                          ) : this.renderSubmitIcon()}
                          {this.props.submitLabel}
                        </button>
                      )}

                      {/* Button "soumettre"  INSTAGRAM / FACEBOOK */}
                      {this.props.isSubmitable && ['instagram', 'facebook'].includes(this.props.network) && (
                        <a
                          className={classNames(
                            "tweet-card__button tweet-card__button-soumettre",
                            !this.props.submitLabel && "square",
                            this.props.classes.buttonPrimary,
                          )}
                          rel="noopener noreferrer" target="_blank"
                          href={this.props.network === 'instagram' ? this.props.share_url : this.props.share_ref.post_link}
                          onClick={this.handleSubmit}
                        >
                          {this.state.submitted ? (
                            <CircularProgress className={this.submitLabel ? this.props.classes.progress : this.props.classes.progressSquare} size={20} />
                          ) : this.renderSubmitIcon()}
                          {this.props.submitLabel}
                        </a>
                      )}

                      {/* Boutton Follow */}
                      {this.props.isFollowable
                        && <button
                          disabled={this.state.submitted}
                          className={classNames(
                            "tweet-card__button tweet-card__button-follow color-primary",
                            this.props.classes.buttonPrimary
                          )}
                          onClick={() => {
                            this.setState({ submitted: true });
                            this.props.handleFollow(this.props.id, this.props.share_ref.screen_name)
                          }}
                        >
                          {this.state.submitted
                            ? <CircularProgress className={this.props.classes.progressSquare} size={20} />
                            : (
                              <React.Fragment>
                                <FollowIcon />
                                {wording.post.button_follow}
                              </React.Fragment>
                            )
                          }
                        </button>
                      }
                    </div>
                  </div>
                )}
              </div>

            )
          }
        }
      </Translation>

    );
  }
}

TweetCard.defaultProps = {
  references: [],
  members: [],
  orphans: [],
  groups: [],
  tag: [],
  media: [],
  share_ref: null,
  share_url: null
};

TweetCard.propTypes = {
  classes: PropTypes.object.isRequired,
  windowWidth: PropTypes.number.isRequired,
  user_id: PropTypes.number,
  references: PropTypes.arrayOf(PropTypes.object),
  members: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  tag: PropTypes.array,
  message: PropTypes.string.isRequired,
  media: PropTypes.arrayOf(PropTypes.object),
  share_url: PropTypes.string,
  share_ref: PropTypes.object,
  status: PropTypes.oneOf([...Object.values(params.statuses), null]),
  type: PropTypes.oneOf(Object.values(params.types)),
  imgSrc: PropTypes.arrayOf(PropTypes.string),
  vidSrc: PropTypes.string,
  isSubmitable: PropTypes.bool.isRequired,
  isCancelable: PropTypes.bool,
  isFollowable: PropTypes.bool,
  showHeader: PropTypes.bool.isRequired,
  showTag: PropTypes.bool,
  submitLabel: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  isRemovable: PropTypes.bool.isRequired,
  isScheduled: PropTypes.bool,
  isPreview: PropTypes.bool,
  isSelected: PropTypes.bool,
  isUser: PropTypes.bool,
  handleRemove: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleFollow: PropTypes.func,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  scheduled_at: PropTypes.string,
  getMediaUrl: PropTypes.func.isRequired,
  enlargeMedia: PropTypes.func,
  hideAvatars: PropTypes.bool,
  network: PropTypes.string,
  hideScheduledAt: PropTypes.bool,
  handleOpenConfirm: PropTypes.func
};

export default withStyles(styles)(windowSize(TweetCard));