import React from "react";

class CGU extends React.Component {
  render() {
    const app_name = process.env.REACT_APP_NAME === 'tweetit' ? 'Tweet-It' : process.env.REACT_APP_NAME
    return (
      <div className="cgu">
        <div className="grid">
          <h1 className="cgu__title">
            Conditions Générales d’Utilisation de l’Application <span className="capitalize">{app_name}</span>
          </h1>



          <h2 className="cgu__subtitle">Préambule</h2>
          <p className="cgu__text">
            Les présentes conditions générales d’utilisation régissent
            l’ensemble des relations entre l’application <span className="capitalize">{app_name}</span> (ci-après
            désignée « l’Application ») édité par  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France (ci-après <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>), dont l’identité et les coordonnées sont précisées à la
            rubrique « mentions légales», et l’Utilisateur.
          </p>
          <br />
          <p className="cgu__text">
            Tout Utilisateur qui souhaite utiliser l’Application <span className="capitalize">{app_name}</span> est
            réputé avoir pris connaissance des présentes conditions générales
            d’utilisation.
          </p>
          <br />
          <p className="cgu__text">
            Ces conditions générales d’utilisation ne sont pas des conditions
            générales de vente ou des conditions générales de prestations de
            services.
          </p>
          <br />

          <p className="cgu__text">
            Elles ont vocation à constituer la règle que doit respecter tout
            Utilisateur se rendant sur l’Application <span className="capitalize">{app_name}</span>.
          </p>
          <br />
          <p className="cgu__text">
            Tout Utilisateur tenu à chaque visite de prendre connaissance
            d’éventuelles évolutions de ces dernières,  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> se réservant le
            droit de les modifier à tout moment.
          </p>


          <h2 className="cgu__subtitle">1. Définitions</h2>
          <p className="cgu__text">
            Les termes ci-dessous définis auront entre les Parties la
            signification suivante :
          </p>
          <br />
          <ul className="cgu__list">
            <li className="cgu__list-item">
              « Internet » : réseau de réseaux permettant l'échange
              d'informations à partir d'un protocole dénommé TCP/IP. Les données
              sont acheminées à travers des réseaux de nature différente qui
              sont capables de lire les messages selon cette norme technique.
              Chaque élément de ce réseau appartient à des organismes privés ou
              publics qui les exploitent en coopération sans nécessairement
              impliquer une obligation bilatérale de qualité ;
            </li>
            <li className="cgu__list-item">
              « Utilisateur » : toute personne accédant et mettant en œuvre les
              fonctionnalités de l’Application ;
            </li>
            <li className="cgu__list-item">
              « Parties » : désigne au singulier indistinctement  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> ou
              l’Utilisateur, et au pluriel  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> et l’Utilisateur ensemble ;
            </li>
            <li className="cgu__list-item">
              « Règlementation relative à la protection des données à caractère
              personnel » :
            </li>
            <li className="cgu__list no-style">
              <ul className="cgu__list sublist">
                <li className="cgu__list-item">
                  la loi relative à l’informatique, aux fichiers et aux libertés
                  n° 78-17 du 6 janvier 1978 modifiée et ses mises à jour ou
                  modification ; et
                </li>
                <li className="cgu__list-item">
                  le Règlement (UE) 2016/679 du Parlement européen et du Conseil
                  du 27 avril 2016 (règlement général sur la protection des
                  données) ;
                </li>
              </ul>
            </li>
          </ul>



          <h2 className="cgu__subtitle">2. Objet et description</h2>
          <p className="cgu__text">
            Les présentes ont pour objet de fixer les conditions d’utilisation
            de l’Application.
          </p>
          <br />
          <p className="cgu__text">Cette Application permet aux Utilisateurs de voir
            et partager des contenus postés par  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> sur les réseaux
            sociaux Linkedin, Twitter, Facebook et Instagram</p>
          <br />
          <p className="cgu__text">Dans la mesure où
            il ne s’agit pas d’un site marchand, il n’existe ni conditions
            générales de vente, ni mentions relatives à la sécurité des modes de
            paiement.</p>

          <h2 className="cgu__subtitle">3. Entrée en vigueur – Durée</h2>
          <p className="cgu__text">
            Les présentes conditions générales d’utilisation entrent en vigueur
            à la date de leur mise en ligne et seront opposables à la date de la
            première utilisation de l’Application par l’Utilisateur.
          </p>
          <br />
          <p className="cgu__text">Les
            présentes conditions générales d’utilisation sont opposables pendant
            toute la durée d’utilisation de l’Application et jusqu’à ce que de
            nouvelles conditions générales d’utilisation remplacent les
            présentes.</p>
          <br />
          <p className="cgu__text">L’Utilisateur peut à tout moment renoncer à utiliser l’Appliation mais reste responsable de toute utilisation antérieure.</p>

          <h2 className="cgu__subtitle">4. Accès à l’Application</h2>
          <h2 className="cgu__subsubtitle">4.1 Accès général</h2>
          <div className="cgu__text">
            <p>L’accès à l’Application est libre. Elle est en principe accessible
              24h/24 et 7 jours/7. </p>
            <br />
            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> s’efforce de fournir un accès de
              qualité et de permettre aux Utilisateurs d’utiliser les moyens de
              communication mis à leur disposition dans les meilleures conditions
              possibles.</p>
            <br />
            <p>En raison de la nature et de la complexité du réseau de
              l’Internet, et en particulier, de ses performances techniques et des
              temps de réponse pour consulter, interroger ou transférer les
              données d’informations,  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> s’efforcera dans la mesure du
              possible de permettre l’accès et l’utilisation de l’Application. <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> ne saurait en effet assurer une accessibilité ou une
              disponibilité absolue de l’Application. </p>
            <br />

            <p>Toutefois,  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> se
              réserve le droit, sans préavis, ni indemnité, de fermer
              temporairement ou définitivement l’Application notamment pour
              effectuer une mise à jour, des opérations de maintenance, des
              modifications ou changements sur les méthodes opérationnelles et les
              serveurs, sans que cette liste ne soit limitative. </p>
            <br />

            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> n’est
              pas tenu responsable des dommages de toute nature qui peuvent
              résulter de ces changements ou d’une indisponibilité temporaire ou
              encore de la fermeture définitive de tout ou partie de l’Application
              ou des services qui y sont associés.</p>
            <br />

            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> se réserve le droit
              de compléter ou de modifier, à tout moment, l’Application et les
              services qui y sont disponibles en fonction de l’évolution des
              technologies</p>
            <br />
            <p>Il appartient à l’Utilisateur de veiller aux
              possibilités d’évolution des moyens informatiques et de transmission
              à sa disposition pour que ces moyens puissent s’adapter aux
              évolutions de l’Application.</p>
          </div>


          <h2 className="cgu__subsubtitle">4.2 Création d’un compte</h2>
          <p className="cgu__text">
            L’accès à l’Application nécessite la création d’un compte par
            l’Utilisateur. Pour pouvoir se connecter à l’Application,
            l’Utilisateur doit suivre les deux étapes décrites ci-après :
          </p>
          <br />
          <ul className="cgu__list">
            <li className="cgu__list-item">
              Dans un premier temps, l’Utilisateur doit renseigner son nom,
              prénom et e-mail professionnel sur tweetit.io. Un e-mail de
              confirmation est alors envoyé à l’Utilisateur sur sa boîte mail
              professionnelle. Une fois l’e-mail de confirmation réceptionné,
              l’Utilisateur doit valider son inscription en cliquant sur le
              bouton « envoyer un lien de vérification ».
            </li>
            <br />
            <li className="cgu__list-item">
              L’Utilisateur doit ensuite utiliser son compte auprès de certains
              services de réseaux sociaux tiers (Twitter ou LinkedIn) pour
              pouvoir se connecter.
            </li>
          </ul>
          <br />
          <div className="cgu__text">
            <p>Il est formellement interdit pour l’Utilisateur de se créer un
              compte sous l’identité d’un tiers. Il est en effet responsable
              exclusif de la confidentialité de ses identifiants (identifiant et
              mot de passe de son compte Twitter ou LinkedIn) lui permettant
              d’utiliser son compte. Toute utilisation de son compte par le biais
              de ses identifiant et mot de passe personnels est présumée avoir été
              effectuée par l’Utilisateur ou un tiers autorisé par l’Utilisateur.</p>
            <br />
            <p>L’Utilisateur s’engage à communiquer des renseignements exacts
              notamment lors de la création de son compte.</p>
            <br />
            <p>En cas de non-respect des conditions générales d’utilisation de l’Application,  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>
              se réserve la faculté de désactiver, de plein droit et sans
              indemnité, le compte de l’Utilisateur sans avoir à respecter de
              préavis, après l’envoi d’un courrier électronique ou d’une lettre
              recommandée avec demande d’avis de réception de mise en demeure,
              resté sans effet pendant plus de 30 jours.</p>
            <br />
            <p>Dans le cas d’un manquement et/ou d’une fraude manifeste(s) et significatif(s) de
              l’Utilisateur, la désactivation du compte se fera de plein droit,
              sans préavis, sans formalité et sans indemnité.</p>
          </div>
          <h2 className="cgu__subsubtitle">4. 3 Suppression d’un compte</h2>
          <p className="cgu__text">
            L’Utilisateur reste également libre de supprimer son compte. Pour
            pouvoir supprimer son compte l’Utilisateur doit effectuer une
            demande par e-mail à reseaux_sociaux@{process.env.REACT_APP_CLIENT_NAME ? process.env.REACT_APP_CLIENT_NAME.replace(/\s/g, '') : 'societe'}.com.
          </p>

          <h2 className="cgu__subtitle">5. Liens hypertextes</h2>
          <div className="cgu__text">
            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> se réserve la possibilité de mettre en place des
              hyperliens sur son Application donnant accès à des pages web autres
              que celles de l’Application sur lesquels  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> n’exerce aucun
              contrôle.</p>
            <br />

            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> décline toute responsabilité quant aux
              conditions d’accès à ces sites, à leur fonctionnement, à leur
              utilisation des données à caractère personnel des Utilisateurs et
              quant au contenu des informations fournies sur ces sites au titre de
              l’activation des hyperliens.</p>
            <br />
            <p>L’Utilisateur qui dispose d’un site
              personnel et désire placer, à des fins personnelles, sur son site un
              lien simple renvoyant directement sur la page d’accueil de
              l’Application, doit obtenir l’autorisation expresse de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> pour établir ce lien.</p>
            <br />

            <p>En aucun cas, cette autorisation ne pourra
              être qualifiée de convention d’implicite d’affiliation. </p>
            <br />

            <p>En toute hypothèse, les liens hypertextes renvoyant à l’Application sans
              l’autorisation de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> devront être retirés à première demande
              de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>.</p>
          </div>

          <h2 className="cgu__subtitle">6. Données à caractère personnel</h2>
          <div className="cgu__text">
            <p>Conformément à la Réglementation relative à la protection des
              données à caractère personnel, l’Utilisateur est informé que <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>, en tant que responsable du traitement, met en œuvre un
              traitement de données à caractère personnel.</p>
            <br />
            <p>Pour en savoir plus sur
              ce traitement de données à caractère personnel et sur l’étendue de
              leurs droits, les Utilisateurs sont invités à se reporter à la
              Politique externe de protection des données personnelles, accessible
              à l’adresse tweetit.io</p>
          </div>

          <h2 className="cgu__subtitle">7. Cookies</h2>
          <h2 className="cgu__subsubtitle">7.1. Qu’est-ce qu’un cookie ?</h2>
          <p className="cgu__text">
            Un cookie (ci-après « Cookies Web ») est un petit fichier texte
            stocké sur le disque dur du terminal (ordinateur, smartphone, etc.)
            de l’Utilisateur lorsqu’il visite un site Internet. Il a été conçu
            pour contenir une petite quantité de données rattachées au terminal
            de l’Utilisateur, et peut uniquement être lu par le serveur du site
            Internet qui l’a déposé. Cela permet au site Internet de fournir une
            page adaptée à un utilisateur particulier, de reconnaître ses
            précédents réglages et de faciliter la navigation. Une page Internet
            peut elle-même contenir un script qui grâce au cookie, va être
            capable de transmettre des informations sur la navigation et les
            préférences de réglages d’une visite d’un site à la visite suivante.
          </p>

          <h2 className="cgu__subsubtitle">
            7.2. Est-ce qu’il y a aussi des cookies sur l’Application <span className="capitalize">{app_name}</span>?
          </h2>
          <p className="cgu__text">
            Il n’y a pas de cookies sur les applications mobiles.
          </p>

          <h2 className="cgu__subtitle">8. Responsabilité de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span></h2>
          <h2 className="cgu__subsubtitle">8.1 Responsabilité générale</h2>
          <p className="cgu__text"> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> ne saurait être responsable :</p>
          <br />
          <ul className="cgu__list">
            <li className="cgu__list-item">
              de la qualité de l’Application, celle-ci étant proposée « en
              l’état » ;
            </li>
            <li className="cgu__list-item">
              de la perturbation de l’utilisation de l’Application ;
            </li>
            <li className="cgu__list-item">
              de l’impossibilité d’utiliser l’Application ;
            </li>
            <li className="cgu__list-item">
              des atteintes à la sécurité informatique, pouvant causer des
              dommages aux matériels informatiques des Utilisateurs et à leurs
              données ;
            </li>
            <li className="cgu__list-item">
              de l’atteinte aux droits des Utilisateurs de manière générale.
            </li>
          </ul>
          <h2 className="cgu__subsubtitle">
            8.2 Responsabilité à l’égard des informations présentes sur
            l’Application
          </h2>
          <div className="cgu__text">
            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> ne saurait être tenue responsable des dommages directs ou
              indirects qui pourraient résulter de l’accès ou de l’utilisation de
              l’Application ou des informations y figurant quelle qu’en soit la
              nature.</p>
            <br />
            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> fait ses meilleurs efforts pour proposer sur son
              Application des informations à jour. Toutes les informations portées
              à la connaissance des personnes accédant à l’Application ont été
              sélectionnées à une date déterminée.</p>
            <br />
            <p>Les informations figurant sur
              l’Application reflètent une opinion au moment de sa réalisation et
              non au moment de sa consultation. Elles peuvent nécessiter une mise
              à jour qui a pu ne pas intervenir à la date de leur consultation
              pour des motifs techniques ou non.</p>
            <br />
            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> ne saurait être tenue
              responsable de toute erreur ou omission. Toute mise à jour, nouvelle
              prestation ou nouvelle caractéristique qui améliore ou augmente un
              ou plusieurs contenus d’informations existants sera soumis aux
              présentes conditions.</p>
            <br />

            <p> <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> décline toute responsabilité
              concernant l’opportunité des décisions et les modalités de son
              exécution prises par les Utilisateurs sur la seule foi des
              informations publiées sur l’Application.</p>
          </div>



          <h2 className="cgu__subtitle">9. Obligations de l’Utilisateur</h2>
          <div className="cgu__text">
            <p>L’Utilisateur s’engage à n’utiliser l’Application ainsi que
              l’ensemble des informations auxquelles il pourra avoir accès que
              pour obtenir des informations et dans un but conforme à l’ordre
              public, aux bonnes mœurs et aux droits des tiers. </p>
            <br />
            <p>Ainsi, il lui est
              interdit de publier ou de transmettre via l’Application tout élément
              illicite, préjudiciable, diffamatoire, pornographique, haineux,
              raciste ou autrement attentatoire à la dignité humaine notamment. Au
              cas où  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> serait informé de la présence de contenus illicites
              sur l’Application,  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> serait en droit d'en supprimer
              immédiatement le contenu.</p>
            <br />
            <p>L’Utilisateur s’engage, par ailleurs, à ne
              pas perturber l’usage que pourraient faire les autres Utilisateurs
              de l’Application et de ne pas accéder à des parties de l’Application
              dont l’accès est réservé.</p>
          </div>



          <h2 className="cgu__subtitle">10. Propriété intellectuelle</h2>
          <div className="cgu__text">
            <p>Le contenu de l’Application, la structure générale ainsi que les
              logiciels, textes, images animées ou non, photographies, son
              savoir-faire et tous les autres éléments composant l’Application
              sont la propriété exclusive de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> ou des tiers qui lui ont
              concédé une licence.</p>
            <br />

            <p>Les présentes conditions générales
              d’utilisation n’emportent aucune cession d’aucune sorte de droit de
              propriété intellectuelle sur les éléments appartenant à  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> ni
              sur les éléments des partenaires ou tiers qui lui ont concédé une
              licence, tels que les sons, photographies, images, textes
              littéraires, travaux artistiques, logiciels, marques, chartes
              graphiques, logos au bénéfice du consommateur.</p>
            <br />

            <p>L'Utilisateur
              s'interdit notamment de modifier, copier, reproduire, télécharger,
              diffuser, transmettre, exploiter commercialement et/ou distribuer de
              quelque façon que ce soit les pages de l’Application, ou les codes
              informatiques des éléments composant l’Application.</p>
            <br />

            <p>Toute
              reproduction et/ou représentation, totale ou partielle d’un de ces
              droits, sans l’autorisation expresse de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>, est interdite et
              constituerait une contrefaçon susceptible d'engager la
              responsabilité civile et pénale du contrefacteur.</p>
            <br />
            <p>En conséquence,
              l'Utilisateur s'interdit tout agissement et tout acte susceptible de
              porter atteinte directement ou non aux droits de propriété
              intellectuelle de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>. </p>
            <br />
            <p>Il en est de même des bases de données
              figurant, le cas échéant, sur l’Application qui sont protégées par
              les articles du Code de la propriété intellectuelle. Toute
              extraction ou réutilisation, totale ou partielle desdites bases et
              de leur contenu est ainsi interdite sans l’autorisation préalable
              expresse de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>.</p>
            <br />
            <p>Les signes distinctifs de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> et de ses
              partenaires, tels que les noms de domaine, marques, dénominations
              ainsi que les logos figurant sur l’Application sont protégés par le
              Code de la propriété intellectuelle. Toute reproduction totale ou
              partielle de ces signes distinctifs effectuée à partir des éléments
              de l’Application sans autorisation expresse de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> est donc
              prohibée, au sens du Code de la propriété intellectuelle.</p>
            <br />
            <p>Seule une
              utilisation conforme à la destination de l’Application est
              autorisée.</p>
            <br />
            <p>Toute autre utilisation, non expressément autorisée par
              écrit et au préalable par  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>, est prohibée et constitutive de
              contrefaçon.</p>
          </div>

          <h2 className="cgu__subtitle">11. Tolérance</h2>
          <div className="cgu__text">
            <p>Les Parties conviennent réciproquement que le fait pour l’une des
              Parties de tolérer une situation, quelles qu’aient pu en être la
              fréquence et la durée, n’a pas pour effet d’accorder à l’autre
              Partie des droits acquis, ni de modifier les présentes conditions
              générales d’utilisation.</p>
            <br />
            <p>De plus, une telle tolérance ne peut être
              interprétée comme une renonciation à faire valoir les droits en
              cause.</p>
          </div>



          <h2 className="cgu__subtitle">12. Titres</h2>
          <p className="cgu__text">
            En cas de difficultés d’interprétation résultant d’une contradiction
            entre l’un quelconque des titres figurant en tête des clauses et
            l’une quelconque des clauses les titres seront déclarés inexistants.
          </p>

          <h2 className="cgu__subtitle">13. Nullité</h2>
          <p className="cgu__text">
            Si une ou plusieurs stipulations des présentes conditions générales
            d’utilisation sont tenues pour non valides ou déclarées comme telles
            en application d’une loi, d’un règlement ou à la suite d’une
            décision passée en force de chose jugée d’une juridiction
            compétente, les autres stipulations garderont toute leur force et
            leur portée.
          </p>

          <h2 className="cgu__subtitle">14. Domiciliation</h2>
          <p className="cgu__text">
            Pour l’exécution de la présente convention et sauf dispositions
            particulières, l’Utilisateur convient d’adresser toute
            correspondance au siège social de  <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span>.
          </p>

          <h2 className="cgu__subtitle">15. Loi applicable</h2>
          <div className="cgu__text">
            <p>Les présentes conditions générales d’utilisation de l’Application
              sont régies par la loi française.</p>
            <br />
            <p>Il en est ainsi pour les règles de
              fond et les règles de forme et ce, nonobstant les lieux d’exécution
              des obligations substantielles ou accessoires.</p>
          </div>

          <h2 className="cgu__subtitle">16. Juridiction</h2>
          <p className="cgu__text">
            En cas de litige à l’occasion de l’interprétation ou de l’exécution
            des présentes conditions générales d’utilisation, la juridiction
            compétente sera déterminée au regard des dispositions de droit
            commun.
          </p>
        </div>
      </div>
    );
  }
}

export default CGU;
