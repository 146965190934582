import React from 'react'

const IconRetweet = () => (
  <svg className="icon__retweet stroke-primary" width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" d="M7.99992 1L10.4999 1C11.6045 1 12.4999 1.89543 12.4999 3L12.4999 8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M14.9999 7.5L12.4999 10L9.99992 7.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M7.99992 10H5.49992C4.39535 10 3.49992 9.10457 3.49992 8L3.49992 2.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path fill="none" d="M0.999924 3.5L3.49992 1L5.99992 3.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default IconRetweet