// Libs
import React from 'react'
import ReactPhoneInput from 'react-phone-input-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as EmailValidator from 'email-validator';
import { uuid } from 'uuidv4';
import history from '../../history'
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie'
import i18n from '../../i18n'

// Components
import LinkedinLogin from '../../components/misc/LinkedinLogin/LinkedinLogin';
import TwitterLogin from '../../components/misc/TwitterLogin/TwitterLogin';

// Icons
import TwitterIcon from '../../assets/icons/twitter-icon';
import LinkedinIcon from '../../assets/icons/linkedin-icon';

// Utils
import global from '../../utils/global'
import params from '../../utils/params';
import Constants from '../../utils/params';
import EventEmitter from '../../utils/EventEmitter';

// Material-Ui
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const wording = i18n.t('user', { returnObjects: true })
class Profil extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: Object.assign({}, this.props.user),
      loading: false,
      isCoTwitter: Cookies.get('twitter_key'),
      isCoLinkedin: Cookies.get('linkedin')
    }
    this.handleClickButton = this.handleClickButton.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeLang = this.handleChangeLang.bind(this)
    this.editUser = this.editUser.bind(this)
  }

  handleClickButton(data) {
    const network = data.network

    if (network === 'twitter' && this.state.isCoTwitter) {
      Cookies.remove('twitter_key')
      this.setState({ isCoTwitter: null })
    } else if (network === 'linkedin' && this.state.isCoLinkedin) {
      Cookies.remove('linkedin')
      this.setState({ isCoLinkedin: null })
    }
  }

  handleChange(type) {
    return (e) => {
      let user = this.state.user

      if (type === 'name') {
        user[type] = e.currentTarget.value
      } else if (type === 'phone') {
        user[type] = e
      } else if (type === 'email') {
        user[type] = e.currentTarget.value
      } else if (type === 'sms_notification') {
        user[type] = user[type] === 1 ? 0 : 1
      } else if (type === 'email_notification') {
        user[type] = !user[type]
      } else if (type === 'newsletter_notification') {
        user[type] = user[type] === 1 ? 0 : 1
      }

      this.setState({ user })
    }
  }

  handleChangeLang(e) {
    let user = this.state.user
    user.lang = e.target.value
    i18n.changeLanguage(user.lang)
    this.setState({ user })
  }

  async editUser() {
    let user = this.state.user
    user.phone = user.phone ? user.phone.replace('+', '') : user.phone

    // Check the input
    if (!user.name || (user.name && user.name.trim().split(/\s+/).length < 2)) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.profil.popin_message.error_name, style: "error" })
      return;

    } else if (!user.email || (user.email && !EmailValidator.validate(user.email))) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.profil.popin_message.error_mail, style: "error" })
      return;

    } else if (!user.phone || (user.phone && !(/^[0-9]{5,}$/g.test(user.phone.replace(/\s/g, ''))))) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.profil.popin_message.error_phone, style: "error" })
      return;
    }


    // Sending the user info 

    // Enable loading (circular progress)
    await this.setState({ loading: true })

    let res = await this.props.editProfilUserAction(user)

    // If an error occur
    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.profil.popin_message.error_api, style: "error" })
      // Disable loading
      this.setState({ loading: false })
      return;
    } else {
      history.push('/')
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.profil.popin_message.saved })
    }
  }

  onSocialAuthSucceed = social => async response => {
    try {
      // Twitter
      if (social === "twitter" && response.ok) {
        const token = response.headers.get('x-auth-token');

        response.json().then(user => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur.`)

          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { secure: true });
          Cookies.set('twitter_key', user['twitter_key'], { secure: true });
          this.setState({ isCoTwitter: user['twitter_key'] })
        })
      } else if (social === "twitter" && !response.ok) {
        response.json().then((err) => {
          this.onSocialAuthFailed()(err.error)
        })
        return
      }

      // Linkedin
      if (social === "linkedin") {
        let res = await this.props.getLinkedinAccessToken({
          code: response.code,
          redirect_uri: `${window.location.origin}/linkedin`,
          register: true,
          profil: true
        })

        if (!res.ok) {
          res.json().then((err) => {
            this.onSocialAuthFailed()(err.error)
          })
          return;
        }

        res.json().then(user => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur`)

          const token = res.headers.get('x-auth-token');
          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { secure: true });
          Cookies.set(
            'linkedin', 
            user['linkedin_key'], 
            { 
              expires: Math.ceil((user['linkedin_token_expires_in'] - new Date().getTime()) / (1000 * 3600 * 24)),
              secure: true
            }
          );
          this.setState({ isCoLinkedin: Cookies.get('linkedin') })
        })
      }

      // Retrieve the information of the user register in DB
      await this.props.getCurrentUserAction()

      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.profil.popin_message.account_connected })

    } catch (e) {
      this.onSocialAuthFailed(social)(e.message)
    }
  }

  onSocialAuthFailed = social => response => {
    if (response && response.message === 'Popup has been closed by user') return;

    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, {
      message: response && typeof (response) === 'string' ? response : `Une erreur s'est produite.`,
      style: "error"
    })
  }

  render() {
    const isCoTwitter = this.state.isCoTwitter
    const isCoLinkedin = this.state.isCoLinkedin
    const company_name = process.env.REACT_APP_CLIENT_NAME ? process.env.REACT_APP_CLIENT_NAME.replace(/\s/g, '') : 'societe'
    return (
      <div className="grid grid--alt">
        <div className="profil">
          {/* Information personnelles */}
          <div className="profil__section">
            <div className="profil__section-title">{wording.profil.title_info}</div>
            <div className="profil__section-body">
              <div className="profil__section-body-row">
                <label htmlFor="fullname" className="profil__label">{wording.profil.label_name}</label>
                <input id="fullname" type="text" value={this.state.user.name || ''} className="profil__input" placeholder="Prénom nom" onChange={this.handleChange('name')} />
              </div>
              <div className="profil__section-body-row">
                <label htmlFor="phone" className="profil__label">{wording.profil.label_phone}</label>
                <ReactPhoneInput label="Numéro de téléphone" id="phone" name="phone"
                  placeholder="33 6 01"
                  value={this.state.user.phone || '33'}
                  defaultCountry='fr'
                  disableDropdown={true}
                  inputClass="profil__input"
                  onChange={this.handleChange('phone')}
                />
              </div>
              <div className="profil__section-body-row">
                <label htmlFor="fullname" className="profil__label">{wording.profil.label_email}</label>
                <input id="fullname" type="mail" value={this.state.user.email || ''} onChange={this.handleChange('email')} className="profil__input" placeholder={`nom@${company_name}.com`} />
              </div>
            </div>
          </div>

          {/* Compte reliés */}
          <div className="profil__section">
            <div className="profil__section-title">{wording.profil.title_account_linked}</div>
            <div className="profil__section-body">
              {process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true' ?
                <div className="profil__section-body-row with-cols">
                  <div className="profil__network-icon twitter">
                    <TwitterIcon />
                  </div>
                  <TwitterLogin
                    onClick={this.handleClickButton}
                    loginUrl={Constants.DOMAIN + "/auth/twitter"}
                    onFailure={this.onSocialAuthFailed("twitter")}
                    onSuccess={this.onSocialAuthSucceed('twitter')} // twitterAuthSucceed = key in this.state
                    register={true}
                    forceLogin={true}
                    showIcon={true}
                    className={`profil__btn ${isCoTwitter ? 'deco' : ''}`}
                    requestTokenUrl={Constants.DOMAIN + `/auth/twitter/reverse?callback=${window.location.origin}`}
                  >
                    {isCoTwitter ? wording.profil.button_twitter.disconnect : wording.profil.button_twitter.connect}
                  </TwitterLogin>
                </div>
                : ''}

              {process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true' ?
                <div className="profil__section-body-row with-cols relative">
                  <div className="profil__network-icon linkedin">
                    <LinkedinIcon />
                  </div>

                  {isCoLinkedin ?
                    <a className="profil__btn deco" href="https://www.linkedin.com/m/logout/" rel="noopener noreferrer" target='_blank' onClick={() => this.handleClickButton({ network: 'linkedin' })}>
                      {wording.profil.button_linkedin.disconnect}
                    </a>
                    :
                    <LinkedinLogin
                      className="profil__btn"
                      onSuccess={this.onSocialAuthSucceed("linkedin")}
                      onFailure={this.onSocialAuthFailed("linkedin")}
                      clientId={process.env.REACT_APP_LINKEDIN_ID}
                      redirectUri={`${window.location.origin}/linkedin`}
                      scope="r_emailaddress r_liteprofile w_member_social r_basicprofile rw_organization_admin r_organization_social"
                      state={uuid()}
                    >
                      {wording.profil.button_linkedin.connect}
                    </LinkedinLogin>
                  }
                </div>
                : ''}
            </div>
          </div>

          {/* Notifications */}
          <div className="profil__section">
            <div className="profil__section-title">{wording.profil.notification.title}</div>
            <div className="profil__section__network">
              <label className="profil__section-text">{wording.profil.notification.sms}</label>
              <div className="profil__section-body">
                <button className={`profil__switch ${this.state.user.sms_notification ? "checked background-color-primary" : ""}`} onClick={this.handleChange('sms_notification')}>
                  <div className="profil__switch-track"></div>
                </button>
              </div>
            </div>
            <div className="profil__section__network">
              <label className="profil__section-text">{wording.profil.notification.email}</label>
              <div className="profil__section-body">
                <button className={`profil__switch ${this.state.user.email_notification ? "checked background-color-primary" : ""}`} onClick={this.handleChange('email_notification')}>
                  <div className="profil__switch-track"></div>
                </button>
              </div>
            </div>
            {process.env.REACT_APP_FUNCTION_NEWSLETTER && (
              <div className="profil__section__network">
                <label className="profil__section-text">{wording.profil.notification.newsletter}</label>
                <div className="profil__section-body">
                  <button className={`profil__switch ${this.state.user.newsletter_notification ? "checked background-color-primary" : ""}`} onClick={this.handleChange('newsletter_notification')}>
                    <div className="profil__switch-track"></div>
                  </button>
                </div>
              </div>
            )}
          </div>


          {/* Données et confidentialité  MICIV */}
          {process.env.REACT_APP_NAME === 'miciv' ?
            <div className="profil__section">
              <div className="profil__section-title">{wording.profil.title_data_privacy}</div>
              <div className="profil__section-body">
                <a className="profil__link" href={wording.profil.conditions_use.url} rel="noopener noreferrer" target='_blank'>{wording.profil.conditions_use.text}</a>
                <a className="profil__link" href={wording.profil.mentions.url} rel="noopener noreferrer" target='_blank'>{wording.profil.mentions.text}</a>
                <a className="profil__link" href={wording.profil.privacy.url} rel="noopener noreferrer" target='_blank'>{wording.profil.privacy.text}</a>
              </div>
            </div>
            : ''}

          {/* Données et confidentialité TWEETIT */}
          {process.env.REACT_APP_NAME === 'tweetit' ?
            <div className="profil__section">
              <div className="profil__section-title">Données et confidentialité</div>
              {process.env.REACT_APP_NAME === 'tweetit' ?
                <div className="profil__section-text">Pour en savoir sur la gestion des données et de vos droits par <span className="capitalize">{process.env.REACT_APP_CLIENT_NAME}</span> France sur le présent site : <br /><br /></div>
                : ''}

              <div className="profil__section-body">
                <NavLink className="profil__link" to="/cgu" target="_blank">Conditions Générales d'utilisation</NavLink>
                <NavLink className="profil__link" to="/mentions-legales" target="_blank">Mentions légales</NavLink>
                <NavLink className="profil__link" to="/politique-confidentialite" target="_blank">Politique de confidentialité</NavLink>
              </div>
            </div>
            : ''}

          {/* Lang*/}
          <div className="profil__section">
            <div className="profil__section-title">{wording.profil.label_lang}</div>
            <div className="profil__lang">
              <Select
                disableUnderline
                value={this.state.user.lang}
                onChange={this.handleChangeLang}
              >
                <MenuItem key="fr" value="fr">{wording.profil.lang_fr}</MenuItem>
                <MenuItem key="en" value="en"> {wording.profil.lang_en}</MenuItem>
              </Select>

            </div >
          </div>

          {/* Button delete account */}
          <a className="profil__btn-delete" href={`mailto:${process.env.REACT_APP_NAME === 'tweetit' ? 'reseaux_sociaux@carrefour.com' : 'support@miciv.io'}?subject=${process.env.REACT_APP_NAME === 'tweetit' ? 'Tweetit' : 'Miciv'} : demande de suppression de compte`}>
            {wording.profil.button_delete_account}
          </a>

          {/* Save button */}
          <button className={`profil__save-btn ${this.state.loading ? 'loading' : ''} background-color-primary`} onMouseEnter={global.manageHover} onMouseLeave={global.manageHover} onClick={this.editUser}>
            {wording.profil.button_save}

            {this.state.loading &&
              <span className="members-invitation__loading">
                <CircularProgress size={20} />
              </span>
            }
          </button>
        </div>
      </div>
    )
  }
}

export default Profil