import { logUserOut, updateUserAction, updateIsAuthenticated } from './auth';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie'
import Constants from '../utils/params';

export async function logout () {
  await logUserOut();
  updateUserAction({});
  updateIsAuthenticated(false);
  Cookies.remove('token');
  Cookies.remove('refresh_token');
  Cookies.remove('twitter_key')
  Cookies.remove('linkedin')
  localStorage.removeItem('flux_network')
}

export function checkToken(dispatch) {
  return new Promise(async function (resolve, reject) {
    let refresh_token = Cookies.get('refresh_token');
    let token = Cookies.get('token');
    if (tokenIsExpired() && token) {
      if (refresh_token) {
        let res = await refreshTokenAction()

        // If an error when getting a new token
        if ((res && res.error) || !res) {
          logout();
          reject(res);
          return;
        }

        Cookies.set('token', res.token, { expires: 90, secure: true})
        Cookies.set('refresh_token', res.refresh_token, { expires: 90, secure: true })
        resolve({ token: res.token, refresh_token: res.refresh_token, secure: true })

      } else {
        logout();
        reject(false);
      }
    } else if (token && refresh_token) {
      resolve({ token: token, refresh_token: refresh_token })
    } else {
      logout();
      reject(false);
    }
  });
}

export function tokenIsExpired() {
  let token = Cookies.get('token');
  if (!token)
    return true

  try {
    let tokenDecoded = jwtDecode(token)
    let now = Date.now() / 1000;
    return (tokenDecoded.exp <= now)
  } catch (e) {
    console.error(e)
    return true
  }

}

/*
  Récupère un nouveau token
 */
export function refreshTokenAction() {
  let refresh_token = Cookies.get('refresh_token');

  return fetch(`${Constants.DOMAIN}/token`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refresh_token: refresh_token })
  }).then(handleErrors)
    .then(res => res)
    .catch(catchErrors)
}

// Retrieve the user informatiton
export function getUserInfosAction() {
  let token = Cookies.get('token');
  return fetch(`${Constants.DOMAIN}/auth/me`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token
    }
  }).then(handleErrors)
}

export function handleErrors(res) {
  if (!res.ok) {
    throw res.json()
  } else {
    try {
      let response = res.json()
      return response;
    } catch (e) {
      console.error(e)
    }
  }
}

export function catchErrors(err) {
  if (!err.then) return { error: err instanceof TypeError ? err.message : `Une erreur technique est survenue.` }

  return err.then((error) => {
    return error
  })
}

export function getFileInfoUploadCare(uuid) {
  return dispatch => {
    return fetch(`https://ucarecdn.com/${uuid}/-/json/`)
      .then(res => res.json())
      .then(res => { return res })
      .catch(res => { return res })
  }
}

export function fetchOpenGraphAction(url) {
  return (dispatch) => {
    return checkToken().then(t => {
      return fetch(Constants.DOMAIN + "/open_graph?url=" + url, {
        method: 'get',
        headers: {
          'x-auth-token': t.token
        },
      }).then(handleErrors)
        .catch(catchErrors)
    });
  }
}