import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import { uuid } from 'uuidv4';
import i18n from '../../i18n'

// Material
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Typography from '@material-ui/core/Typography';

// Components
import LinkedinLogin from '../misc/LinkedinLogin/LinkedinLogin';
import TwitterLogin from '../misc/TwitterLogin/TwitterLogin';
import TweetCard from '../misc/tweetCard/tweet-card';
import UserTweetitForm from '../misc/userTweetitForm/user-tweetit-form';
import UserTweetitRefusalForm from '../misc/userTweetitFormRefusal/user-tweetit-form-refusal';
import MediaDialog from "../misc/mediaDialog/media-dialog";
import params from "../../utils/params";
import Constants from '../../utils/params';

// Material styles
import styles from './material-homeUser';

// Assets
import LinkedinIcon from '../../assets/icons/linkedin-icon';
import emptyFace from '../../assets/images/empty-face.svg';
import TwitterIcon from '../../assets/icons/twitter-icon';

const wording = i18n.t('user', { returnObjects: true })
moment.locale(i18n.language)

class HomeUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lastScrollPos: 0,
      direction: null,
      originScrollPos: 0
    };
    this.userScrollbar = React.createRef();
  }

  componentDidMount() {
    this.props.setUserScrollRef(this.userScrollbar)
  }


  render() {

    const { classes } = this.props;

    const isTwitter = this.props.user.user_twitter && !this.props.authenticatedOnTwitter && this.props.tweetits.some(s => s.network === "twitter")
    const isLinkedin = this.props.user.user_linkedin && !this.props.authenticatedOnLinkedin && this.props.tweetits.some(s => s.network === "linkedin")

    return (
      <div className="home-user">
        <div className="grid" ref={this.userScrollbar} id="scrollableDiv">
          <UserTweetitRefusalForm
            formData={this.props.formData}
            tweetits={this.props.tweetits}
            user={this.props.user}
            editTweetitUserAction={this.props.editTweetitUserAction}
            getTweetAction={this.props.getTweetAction}
            getTweetitsUserAction={this.props.getTweetitsUserAction}
            open={this.props.dialogRefusalIsOpen}
            handleClose={this.props.closeTweetitRefusalDialog}
          />
          <UserTweetitForm
            formData={this.props.formData}
            tweetits={this.props.tweetits}
            user={this.props.user}
            submitMedia={this.props.submitMedia}
            getMedia={this.props.getMedia}
            getMediaUrl={this.props.getMediaUrl}
            sendPostAction={this.props.sendPostAction}
            createReTweetAction={this.props.createReTweetAction}
            editTweetitUserAction={this.props.editTweetitUserAction}
            searchAccountAction={this.props.searchAccountAction}
            getTweetAction={this.props.getTweetAction}
            getTweetitsUserAction={this.props.getTweetitsUserAction}
            fetchOpenGraphAction={this.props.fetchOpenGraphAction}
            open={this.props.dialogIsOpen}
            handleClose={this.props.closeTweetitDialog}
          />
          <MediaDialog
            fullWidth={true}
            open={this.props.mediaIsOpen}
            currentIndex={this.props.mediaIndex}
            media={this.props.media}
            getMediaUrl={this.props.getMediaUrl}
            handleClose={this.props.minimizeMedia}
          />
          <InfiniteScroll
            dataLength={this.props.tweetits.filter(t => t.status === 'suggested').length}
            next={() => this.props.loadMoreTweetits(this.props.tweetits.filter(t => t.status === 'suggested').length, 2, 'suggested')}
            hasMore={this.props.hasMore}
            scrollableTarget="scrollableDiv"
          >
            <div className="home-user__scrollbar">
              {/* Button TWITTER // TO-REVIEW */}
              {isTwitter && (process.env.REACT_APP_FUNCTION_CONNECT_TWITTER === 'true') ?
                <TwitterLogin
                  loginUrl={Constants.DOMAIN + "/auth/twitter"}
                  onFailure={this.props.onSocialAuthFailed("twitter")}
                  onSuccess={this.props.onSocialAuthSucceed('twitter')} // twitterAuthSucceed = key in this.state
                  showIcon={true}
                  className="signin__twitter-button"
                  requestTokenUrl={Constants.DOMAIN + `/auth/twitter/reverse?callback=${window.location.origin}`}>

                  {/* Twitter icon */}
                  <TwitterIcon />
                  <Typography>
                    {wording.button_twitter}
                  </Typography>
                </TwitterLogin>
                : ''}

              {/* Button LINKEDIN // TO-REVIEW */}
              {isLinkedin && (process.env.REACT_APP_FUNCTION_CONNECT_LINKEDIN === 'true') ?
                <LinkedinLogin
                  className="signin__linkedin-button"
                  onSuccess={this.props.onSocialAuthSucceed("linkedin")}
                  onFailure={this.props.onSocialAuthFailed("linkedin")}
                  clientId={process.env.REACT_APP_LINKEDIN_ID}
                  redirectUri={`${window.location.origin}/linkedin`}
                  scope="r_emailaddress r_liteprofile w_member_social r_basicprofile rw_organization_admin r_organization_social"
                  state={uuid()}
                >
                  <LinkedinIcon />
                  <Typography>
                    {wording.button_linkedin}
                  </Typography>
                </LinkedinLogin>
                : ''}

              {
                this.props.isLoading ?
                  <div className="home-user__loading-scroll">
                    <CircularProgress />
                  </div>
                  :
                  (() => {
                    let items = this.props.tweetits.filter(t => t.status === 'suggested');

                    const momentConfig = {
                      lastDay: '[' + wording.post.lastDay + ']',
                      sameDay: '[' + wording.post.sameDay + ']',
                      nextDay: '[' + wording.post.nextDay + ']',
                      lastWeek: i18n.language === 'fr' ? 'dddd [' + wording.post.lastWeek + ']' : '[' + wording.post.lastWeek + '] dddd',
                      nextWeek: 'dddd',
                      sameElse: 'L'
                    }

                    return items.length === 0 ?
                      <div className="home-user__empty">
                        <img className="home-user__empty-face" src={emptyFace} alt="empty" />
                        <p className="home-user__empty-title">{wording.post.no_publication}</p>
                        <p className="home-user__empty-paragraph">{wording.post.notification}</p>
                      </div>
                      :
                      items.map((tweetit, index, array) => {
                        let last_tweetit = array[index - 1];
                        let display_title = null;
                        if (last_tweetit) {
                          let last_tweetit_title = moment(last_tweetit.created_at).lang(i18n.language).calendar(null, momentConfig)
                          let tweetit_title = moment(tweetit.created_at).lang(i18n.language).calendar(null, momentConfig)
                          if (last_tweetit_title !== tweetit_title) {
                            display_title = tweetit_title;
                          }
                        } else {
                          display_title = moment(tweetit.created_at).lang(i18n.language).calendar(null, momentConfig)
                        }

                        // Instagram  & fb button label
                        const instaFbLabel = {
                          share: wording.post.button_share,
                          like: wording.post.button_like,
                          comment: wording.post.button_comment
                        }

                        // Submit button label
                        const submitLabel = tweetit.type === params.types.REPOST && tweetit.network === "twitter" ? wording.post.button_retweet : ["instagram", "facebook"].includes(tweetit.network) ? instaFbLabel[tweetit.share_ref.type] : wording.post.button_publish

                        return (
                          <div key={tweetit.id} className="home-user__scroll-bar-item">
                            {display_title && (
                              <Typography variant="h5" component="h3" className={classes.title}>
                                {display_title.charAt(0).toUpperCase() + display_title.slice(1)}
                              </Typography>
                            )}
                            <TweetCard
                              hideAvatars={true}
                              showHeader={true}
                              showTag={true}
                              id={tweetit.id}
                              posting={this.props.posting}
                              author={tweetit.updated_by}
                              getMedia={this.props.getMedia}
                              getMediaUrl={this.props.getMediaUrl}
                              media={tweetit.media}
                              mediaType={tweetit.media.length > 0 ? tweetit.media[0].type : params.mediaTypes.IMAGE}
                              message={tweetit.message}
                              network={tweetit.network}
                              comment={tweetit.comment ? tweetit.comment : ""}
                              share_url={tweetit.share_url ? tweetit.share_url : null}
                              share_ref={tweetit.share_ref ? tweetit.share_ref : null}
                              created_at={""}
                              editedAt={""}
                              groups={tweetit.groups ? tweetit.groups : []}
                              references={tweetit.children ? tweetit.children : []}
                              tag={tweetit.tag ? [tweetit.tag] : []}
                              type={tweetit.type}
                              status={tweetit.status}
                              handleRemove={this.props.openTweetitRefusalDialog}
                              handleEdit={this.props.openTweetitDialog}
                              handleSubmit={this.props.fastSubmitTweetit}
                              handleFollow={this.props.fastFollowProfile}
                              enlargeMedia={this.props.enlargeMedia}
                              getFileInfoUploadCare={this.props.getFileInfoUploadCare}
                              minimizeMedia={this.props.minimizeMedia}
                              submitLabel={submitLabel}
                              isSubmitable={tweetit.type !== params.types.PROFILE}
                              isEditable={tweetit.type !== params.types.PROFILE && tweetit.type !== params.types.REPOST}
                              isFollowable={tweetit.type === params.types.PROFILE}
                              isRemovable={true}
                              isUser={true}
                              loading={this.props.loading}
                              hideScheduledAt={true}
                              onSocialAuthSucceed={this.props.onSocialAuthSucceed}
                              onSocialAuthFailed={this.props.onSocialAuthFailed}
                              authenticatedOnTwitter={this.props.authenticatedOnTwitter}
                              authenticatedOnLinkedin={this.props.authenticatedOnLinkedin}
                              triggerUserMediaOnScroll={this.props.triggerUserMediaOnScroll}
                            />
                          </div>
                        )
                      })
                  })()
              }
              {this.props.loadingMoreSuggested && (
                <div className="home-user__loading-scroll">
                  <CircularProgress />
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

HomeUser.propTypes = {
  classes: PropTypes.object.isRequired,
  submitMedia: PropTypes.func.isRequired,
  getMediaUrl: PropTypes.func.isRequired,
  mediaIsOpen: PropTypes.bool.isRequired,
  mediaIndex: PropTypes.number,
  enlargeMedia: PropTypes.func.isRequired,
  minimizeMedia: PropTypes.func.isRequired,
  fastFollowProfile: PropTypes.func.isRequired,
};

export default withStyles(styles)(HomeUser);