import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Router, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import i18n from './i18n'

// Redux
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from "react-redux";
import thunk from "redux-thunk";

// Components
import reducers from "./reducers/index.js";
import App from './App.js';
import history from './history';

// Material styles
import THEME from './material-index';
moment.locale(i18n.language);

let store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
)

ReactDOM.render(
  <MuiThemeProvider theme={THEME}>
    <Provider store={store}>
      <Router history={history}>
        <Route component={App} />
      </Router>
    </Provider>
  </MuiThemeProvider>
  , document.getElementById('root'));