import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from "react-redux";
import * as EmailValidator from 'email-validator';
import PropTypes from "prop-types";
import Cookies from 'js-cookie'
import i18n from '../../i18n'

// Material
import { withStyles } from "@material-ui/core";

// Import components
import history from '../../history';
import * as actionCreators from "../../actions/actionCreators";
import Members from "../../components/members/members.js";
import Groups from "../../components/groups/groups.js";
import Constants from "../../utils/params"

// Material styles
import styles from './material-members-container'
import EventEmitter from '../../utils/EventEmitter';
import params from '../../utils/params';
import SettingsFlux from '../../components/settingsFlux/settingsFlux.js';

class MembersCon extends React.Component {
  constructor(props) {
    super(props);

    this.MEMBERS_LIST_PAGE = 0;
    this.MEMBERS_EDIT_PAGE = 1;

    this.state = {
      page: 0,
      user: null,
      name: "",
      email: "",
      screen_name: "",
      phone: "",
      groups: [...props.groups],
      role: "User",
      emailNotifications: true,
      smsNotification: false,
      is_user_test: 0,
      loading: false,
      success: false,
      errorPhone: null,
      errorEmail: null,
      errorName: null,
      invitationSent: false,
      groupsAnchorTop: 0,
      groupsAnchorLeft: 0,
      groupsWidth: 0,
      groupsIsOpen: false,
      confirmIsOpen: false,

      isFormErrorSnackbarOpened: false,
      isPopinInvitationOpen: false,

      isCoTwitter: Cookies.get('twitter_key'),
      isCoLinkedin: Cookies.get('linkedin')
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeFormData = this.handleChangeFormData.bind(this);
    this.handleChangePhoneData = this.handleChangePhoneData.bind(this);
    this.openSelectDialog = this.openSelectDialog.bind(this);
    this.closeSelectDialog = this.closeSelectDialog.bind(this);
    this.togglePopinInvitation = this.togglePopinInvitation.bind(this);
    this.setItems = this.setItems.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.updateUserInfos = this.updateUserInfos.bind(this)
    this.submitGroup = this.submitGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.resetFormData = this.resetFormData.bind(this)
    this.handleClickButtonNetwork = this.handleClickButtonNetwork.bind(this)
  }

  componentDidUpdate(prevProps) {
    // Retrieve the pathname
    const pathname = history.location.pathname

    // Retrieve the query string
    const search = history.location.search

    // If there is a query
    if (search && (pathname === '/members/edit')) {
      // Retrieve the params
      let params = new URLSearchParams(history.location.search);
      let id = params.get('user')
      id = id ? Number(id) : null

      if ((id && (id !== this.state.id)) || (!this.state.groups.length && this.props.groups.length)) {

        let user = this.props.users.find(u => u.id === id);

        if (!user && this.props.user.id === id) {
          user = this.props.user;
        }


        // If the user has been found, update the state to display the infos of the user
        if (user) this.updateUserInfos(user)
      }
    }
    // IF on page /members/create and we receive props.groups THEN setState(groups)
    else if (pathname === '/members/create' && prevProps.groups.length !== this.props.groups.length) {
      this.setState({ groups: this.props.groups })
    }
  }

  resetFormData() {
    this.setState({
      invitationSent: false,
      name: "",
      email: "",
      phone: "",
      groups: this.props.groups,
      role: "User",
      emailNotifications: true,
      smsNotification: false,
      is_user_test: 0,
      errorPhone: null,
      errorEmail: null,
      errorName: null,
    });
  }

  handleClickButtonNetwork(data) {
    const network = data.network

    if (network === 'twitter' && this.state.isCoTwitter) {
      Cookies.remove('twitter_key')
      this.setState({ isCoTwitter: null })
    } else if (network === 'linkedin' && this.state.isCoLinkedin) {
      Cookies.remove('linkedin')
      this.setState({ isCoLinkedin: null })
    }
  }

  handleChangePage(page, user, replace) {
    this.setState({ page: page, user: user }, () => {
      switch (page) {
        case this.MEMBERS_LIST_PAGE:
          if (replace)
            history.push('/members');
          this.resetFormData()
          break;
        case this.MEMBERS_EDIT_PAGE:
          if (user) this.updateUserInfos(user)
          if (replace) history.push('/members/edit?user=' + user.id);
          break;
        default:
          break;
      }
    })
  }

  updateUserInfos(user) {
    this.setState({
      id: user.id,
      name: user.name ? user.name : "",
      email: user.email ? user.email : "",
      phone: user.phone ? user.phone : "",
      groups: this.props.groups.map(g => Object.assign({
        selected: g.users.includes(user.id)
      }, g)),
      user: user,
      role: user.role ? user.role : "",
      emailNotifications: user.emailNotifications || !!user.emailNotifications,
      smsNotification: user.smsNotification || !!user.sms_notification,
      is_user_test: user.is_user_test,
    });
  }

  handleChangeFormData(event) {
    let value = event.target.value;

    if (['emailNotifications', 'is_user_test', 'smsNotification'].includes(event.target.name)) {
      value = event.target.checked;
    }

    this.setState({ [event.target.name]: value })
  }

  handleChangePhoneData(number) {
    this.setState({ phone: number })
  }

  handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ errorPhone: null, errorEmail: null, errorName: null });
  };

  openSelectDialog = e => {
    let rect = e.currentTarget.getBoundingClientRect();
    this.setState({
      groupsAnchorTop: rect.y,
      groupsAnchorLeft: rect.x,
      groupsWidth: e.currentTarget.scrollWidth,
      groupsIsOpen: true
    });
  };

  closeSelectDialog = () => {
    this.setState({
      groupsIsOpen: false
    });
  };

  togglePopinInvitation() {
    this.setState((prevState) => ({
      isPopinInvitationOpen: !prevState.isPopinInvitationOpen
    }))
  }

  setItems = items => {
    if (items.length === 0) {
      this.closeSelectDialog();
      return;
    }

    this.setState({ groups: items }, () => {
      this.closeSelectDialog();
    });
  };

  deleteItem = (e, index) => {

    let groupsItemSelectedIndex = 0

    // unselect item with groupsItemSelectedIndex === index
    this.setState({
      groups: this.state.groups.map((groupsItem, groupsItemIndex) => {

        if (groupsItem.selected === true) {
          if (groupsItemSelectedIndex === index) {
            groupsItem.selected = false
          }
          groupsItemSelectedIndex++
        }
        return groupsItem
      })
    })
  };

  async submitUser() {

    const user = this.state.user, msg_popin = i18n.t('manager', { returnObjects: true }).settings.edit_user.popin_message
    let phone = this.state.phone
    phone = phone ? phone.replace('+', '') : phone

    if (this.state.loading) {
      return;
    }

    if (!this.state.name || (this.state.name && this.state.name.trim().split(/\s+/).length < 2)) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: msg_popin.error_name, style: "error" })
      return;
    }

    if (phone && !(/^[0-9]{5,}$/g.test(phone.replace(/\s/g, ''))) && (user.role === params.roles.USER)) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: msg_popin.error_phone, style: "error" })
      return
    }

    if (this.state.email && !EmailValidator.validate(this.state.email)) {
      this.setState({ errorEmail: "Adresse email non valide" });
      return;
    }

    await this.setState({
      success: false,
      loading: true,
      errorPhone: null,
      errorEmail: null,
      errorName: null
    });

    try {
      /**
       * EDIT USER
       */
      if (user && user.id) {


        let res = await this.props.editAccountAction(
          user.id,
          this.state.name,
          this.state.email,
          phone,
          user.status,
          // Add groups only for role User
          this.state.role === Constants.roles.USER ?
            this.state.groups
              .filter(g => g.selected)
              .map(g => g.id)
            : []
          ,
          this.state.role,
          this.state.emailNotifications,
          this.state.smsNotification,
          this.state.is_user_test
        );

        if (res && res.error) {
          EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
          this.setState({ loading: false })
          return
        }
      }

      // Retrieve the group list 
      this.props.getGroupsAction();

      // user is editing himself
      if (user.id === this.props.user.id) {
        // Retrieve user info
        await this.props.getCurrentUserAction();
      } else {
        // Retrieve the users list
        await this.props.getUsersAction();
      }

      await this.setState({
        success: true,
        loading: false
      });
      this.handleChangePage(this.MEMBERS_LIST_PAGE, null, true);
    } catch (e) {
      console.error(e)
      let err = await e;
      this.setState({
        success: false,
        loading: false,
        errorEmail: err.target === 'email' ? err.message : null,
        errorName: err.message
      });
    }
  }

  async submitGroup(group) {
    let res = null

    if (group.id) {
      res = await this.props.editGroupAction(group);
    } else {
      res = await this.props.createGroupAction(group);
    }

    if (res && res.error) EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
  }

  async deleteGroup(id) {
    let res = await this.props.deleteGroupAction(id);
    if (res && res.error) EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
  }

  async deleteUser(user_id, changePage) {
    if (!user_id) {
      return;
    }

    // Init error message
    let error = ""

    // Delete a user
    let res = await this.props.deleteUserAction(user_id);
    error = res && res.error ? res.error : ""

    // Retrieve users
    res = await this.props.getUsersAction();
    error = res && res.error ? error ? `${error} | ${res.error}` : res.error : error

    if (error) EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: error, style: "error" })

    if (changePage) {
      await this.setState({
        confirmIsOpen: false
      });
      this.handleChangePage(this.MEMBERS_LIST_PAGE, null, true);
    }
  }

  onSocialAuthSucceed = social => async response => {
    try {
      // Twitter
      if (social === "twitter" && response.ok) {
        const token = response.headers.get('x-auth-token');

        response.json().then(user => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur.`)

          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { secure: true });
          Cookies.set('twitter_key', user['twitter_key'], { secure: true });
          this.setState({ isCoTwitter: user['twitter_key'] })
        })
      } else if (social === "twitter" && !response.ok) {
        response.json().then((err) => {
          this.onSocialAuthFailed()(err.error)
        })
        return
      }

      // Linkedin
      if (social === "linkedin") {
        let res = await this.props.getLinkedinAccessToken({
          code: response.code,
          redirect_uri: `${window.location.origin}/linkedin`,
          register: true,
          profil: true
        })

        if (!res.ok) {
          res.json().then((err) => {
            this.onSocialAuthFailed()(err.error)
          })
          return;
        }

        res.json().then(user => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur`)

          const token = res.headers.get('x-auth-token');
          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { secure: true });
          Cookies.set(
            'linkedin', 
            user['linkedin_key'], 
            { 
              expires: Math.ceil((user['linkedin_token_expires_in'] - new Date().getTime()) / (1000 * 3600 * 24)),
              secure: true
            }
          );
          this.setState({ isCoLinkedin: Cookies.get('linkedin') })
        })
      }

      // Retrieve the information of the user register in DB
      await this.props.getCurrentUserAction()

      this.updateUserInfos(this.props.user)
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.edit_user.popin_message.account_connected })

    } catch (e) {
      this.onSocialAuthFailed(social)(e.message)
    }
  }

  onSocialAuthFailed = social => response => {
    if (response && response.message === 'Popup has been closed by user') return;

    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, {
      message: response && typeof (response) === 'string' ? response : `Une erreur s'est produite.`,
      style: "error"
    })
  }

  render() {

    return (
      <div className="members-container">
        <Switch>
          {/* Route for members */}
          <Route path="/members" render={() =>
            <Members
              handleCloseError={this.handleCloseError}
              errorPhone={this.state.errorPhone}
              errorEmail={this.state.errorEmail}
              errorName={this.state.errorName}
              history={this.props.history}
              state={this.state}
              user={this.props.user}
              users={this.props.users}
              groups={this.props.groups}
              handleChangePage={this.handleChangePage}
              handleChangeFormData={this.handleChangeFormData}
              handleChangePhoneData={this.handleChangePhoneData}
              openSelectDialog={this.openSelectDialog}
              closeSelectDialog={this.closeSelectDialog}
              togglePopinInvitation={this.togglePopinInvitation}
              setItems={this.setItems}
              deleteItem={this.deleteItem}
              page={this.state.page}
              submitUser={this.submitUser}
              editAccountAction={this.props.editAccountAction}
              invitationSent={this.state.invitationSent}
              deleteUser={this.deleteUser}
              handleOpenConfirm={() => this.setState({ confirmIsOpen: true })}
              handleCancelConfirm={() => this.setState({ confirmIsOpen: false })}
              handleValidConfirm={this.deleteUser}
              isVisible={this.props.isVisible}
              openFormErrorSnackbar={this.openFormErrorSnackbar}
              closeFormErrorSnackbar={this.closeFormErrorSnackbar}
              isFormErrorSnackbarOpened={this.state.isFormErrorSnackbarOpened}
              isPopinInvitationOpen={this.state.isPopinInvitationOpen}
              createInviteAction={this.props.createInviteAction}
              editInviteAction={this.props.editInviteAction}
              getAllInviteAction={this.props.getAllInviteAction}

              // Network
              onSocialAuthSucceed={this.onSocialAuthSucceed}
              onSocialAuthFailed={this.onSocialAuthFailed}
              handleClickButtonNetwork={this.handleClickButtonNetwork}
              isCoTwitter={this.state.isCoTwitter}
              isCoLinkedin={this.state.isCoLinkedin}
            />
          } />

          {/* Route for groups */}
          <Route path="/groups" render={() =>
            <Groups
              groups={this.props.groups}
              users={this.props.users}
              submitGroup={this.submitGroup}
              deleteGroup={this.deleteGroup}
            />
          } />

          {process.env.REACT_APP_FUNCTION_FLUX === 'true' ?
            <Route path="/settings-flux" render={() =>
              <SettingsFlux
                groups={this.props.groups}
                user={this.props.user}
                users={this.props.users}
                createFluxAction={this.props.createFluxAction}
                editFluxAction={this.props.editFluxAction}
                deleteFluxAction={this.props.deleteFluxAction}
                getAllFluxAction={this.props.getAllFluxAction}
                searchAccountAction={this.props.searchAccountAction}
              />
            } />
            : ''}
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
};

MembersCon.defaultProps = {
  groups: [],
};

MembersCon.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  groups: PropTypes.array,
  editAccountAction: PropTypes.func.isRequired,
  deleteUserAction: PropTypes.func.isRequired,
  getUsersAction: PropTypes.func.isRequired,
  createGroupAction: PropTypes.func.isRequired,
  editGroupAction: PropTypes.func.isRequired,
  deleteGroupAction: PropTypes.func.isRequired,
  getGroupsAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators, null, { pure: false })(withStyles(styles)(MembersCon));
