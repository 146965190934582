import React from 'react'

const WrongIcon = () => (
  <svg className="icon__wrong" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M10.914 9.5l1.768 1.768a1 1 0 0 1-1.414 1.414L9.5 10.914l-1.768 1.768a1 1 0 0 1-1.414-1.414L8.086 9.5 6.318 7.732a1 1 0 0 1 1.414-1.414L9.5 8.086l1.768-1.768a1 1 0 1 1 1.414 1.414L10.914 9.5z" />
    </g>
  </svg>
)

export default WrongIcon