import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition } from "react-transition-group";
import ReactSVG from "react-svg";
import i18n from '../../../i18n'
import { Translation } from 'react-i18next';

// Material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Menu from "@material-ui/core/Menu/Menu";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

// Assets
import clearIcon from "../../../assets/images/clear-icon.svg";
import twitterIcon from '../../../assets/images/twitter.svg';
import linkedinIcon from '../../../assets/images/linkedin.svg';
import scheduleIcon from '../../../assets/images/schedule.svg';
import IconPlus from '../../../assets/icons/plus';
import IconSearch from '../../../assets/icons/search';
import arrow from "../../../assets/images/arrow.svg";
import defaultUserIcon from "../../../assets/images/default_profile_normal.png";

// Components
import params from "../../../utils/params";
import GlobalUtils from "../../../utils/global";

// Material styles
import styles from "./material-datable";
import EventEmitter from '../../../utils/EventEmitter';

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  // get sorting function
  let sortingFunction = rows.find(r => r.id === orderBy).sortingFunctions
  //                                                                  "-" reverse the sign of the return value ( 1 => -1 )
  return order === 'desc' ? (a, b) => sortingFunction(a, b) : (a, b) => -sortingFunction(a, b)
}

/** Sorting functions */

// sort by name
function sortByName(a, b) {

  // get display name
  let { screenNameThatShouldBeDisplayed: aName } = GlobalUtils.getUserNameAndAvatar({ user: a })
  let { screenNameThatShouldBeDisplayed: bName } = GlobalUtils.getUserNameAndAvatar({ user: b })

  if (!aName || !bName) return 0

  // case-insensitive
  aName = aName.toLowerCase()
  bName = bName.toLowerCase()

  if (bName < aName) {
    return -1
  }
  else if (bName > aName) {
    return 1
  }
  return 0
}

// sort by role
function sortByRole(a, b) {

  if (a.is_me) {
    return 1
  }
  else if (b.is_me) {
    return -1
  }

  // case-insensitive
  let roleA = a.role.toLowerCase()
  let roleB = b.role.toLowerCase()

  if (roleB < roleA) {
    return -1
  }
  else if (roleB > roleA) {
    return 1
  }
  return 0
}

// count status.waitings, twitter account waiting, linkedin account wating
function countWaitings(user) {
  let waitings = (user.status === params.userStatuses.WAITING) ? 1 : 0
  waitings += (user.user_twitter && !user.user_twitter.display_name) ? 1 : 0
  waitings += (user.user_linkedin && !user.user_linkedin.linkedin_id) ? 1 : 0

  return waitings
}

// sort by networks count
function sortByNetworks(a, b) {

  // get networks
  let aNetworks = [a.user_twitter, a.user_linkedin]
  let bNetworks = [b.user_twitter, b.user_linkedin]

  // count available networks
  let aNetworksCount = aNetworks.filter(f => f).length
  let bNetworksCount = bNetworks.filter(f => f).length

  // count waitings accounts
  let aWaitings = countWaitings(a)
  let bWaitings = countWaitings(b)

  // compare networks count
  if (bNetworksCount < aNetworksCount) {
    return -1
  }
  else if (bNetworksCount > aNetworksCount) {
    return 1
  }
  // compare waitings count
  else if (bWaitings < aWaitings) {
    return 1
  }
  else if (bWaitings > aWaitings) {
    return -1
  }
  // on same waitings count, compare if twitter is available
  else if (aNetworksCount === 1 && bNetworksCount === 1) {
    if (!bNetworks[0] && aNetworks[0]) {
      return -1
    }
    else if (bNetworks[0] && !aNetworks[0]) {
      return 1
    }
  }
  return 0
}

function sortByGroups(a, b) {

  // get groups count
  let aGroupCount = a.groups.length
  let bGroupCount = b.groups.length

  if (bGroupCount < aGroupCount) {
    return -1
  }
  else if (bGroupCount > aGroupCount) {
    return 1
  }
  // same groups count
  else {

    // compare group.label
    let groupLabelComparaison = a.groups.map((g, index) => {
      if (b.groups[index].label < g.label) {
        return -1
      }
      else if (b.groups[index].label > g.label) {
        return 1
      }
      else {
        return 0
      }
    })

    // sum of all comparaison
    let sumOfComparaison = !groupLabelComparaison.length ? 0 : groupLabelComparaison.reduce((acc, curr) => acc + curr)

    if (sumOfComparaison > 0) {
      return -1
    }
    else if (sumOfComparaison < 0) {
      return 1
    }
    else {
      return 0
    }
  }
}

const wording = i18n.t('manager', { returnObjects: true }).settings.users

const rows = [
  { id: 'Nom', numeric: false, disablePadding: true, label: wording.label_name, sortingFunctions: sortByName },
  { id: 'Comptes liés', numeric: false, disablePadding: true, label: wording.label_account, sortingFunctions: sortByNetworks },
  { id: 'Type de compte', numeric: false, disablePadding: true, label: wording.label_account_type, sortingFunctions: sortByRole },
  { id: 'Groupes', numeric: false, disablePadding: true, label: wording.label_groups, sortingFunctions: sortByGroups },
  { id: 'Actions', numeric: false, disablePadding: true, label: "" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, index) => {
            return (
              <TableCell
                key={index}
                align={row.numeric ? 'right' : 'inherit'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{
                  border: "none"
                }}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class EnhancedTable extends React.Component {
  state = {
    // default sorting function
    order: 'asc',
    orderBy: 'Type de compte',
    selected: [],
    anchorEl: null,
    anchorElUser: null,
    openConfirmModal: false,
    search: ''
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleMenuOpen = (event, user) => {
    this.setState({ anchorEl: event.currentTarget, anchorElUser: user });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSearch = (e) => {
    this.setState({ search: e.currentTarget.value });
  };

  async disableUser() {
    let res = await this.props.editAccountAction(
      this.state.anchorElUser.id,
      null,
      null,
      null,
      this.state.anchorElUser.status === "enabled" ? "disabled" : "enabled",
      [],
      this.state.anchorElUser.role
    );

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      return;
    }

    this.handleMenuClose()
  }

  openDialogRemoveUser(user) {
    if (user) {
      this.setState({ openConfirmModal: true });
      this.handleMenuClose();
    }
  }

  removeUser() {
    if (this.state.anchorElUser && this.state.anchorElUser.id) {
      this.props.deleteUser(this.state.anchorElUser.id);
      this.setState({ openConfirmModal: false });
    }
  }

  handleCloseRemoveDialog = () => {
    this.setState({ anchorElUser: null, openConfirmModal: false });
  };

  buildStatus(options) {
    const u = options.user;
    const classes = options.classes;

    if (u.status === params.userStatuses.DISABLED) return <ReactSVG src={clearIcon} />

    const isTwitterWaiting = (u.status === params.userStatuses.WAITING) || (u.user_twitter && !u.user_twitter.display_name)
    const isLinkedinWaiting = (u.status === params.userStatuses.WAITING) || (u.user_linkedin && !u.user_linkedin.linkedin_id)

    // params.userStatuses.WAITING:
    // params.userStatuses.ENABLED:
    // TO-REVIEW  juste un icon waiting désormais
    return (
      <React.Fragment>
        {u.user_twitter && (
          <div className={`members__block-status ${isTwitterWaiting ? 'is-waiting' : ''}`}>
            <a className={classes.cell__availableNetwork} target="_blank" rel="noopener noreferrer" href={"https://twitter.com/" + u.user_twitter.screen_name}>
              <ReactSVG src={twitterIcon} />
              {isTwitterWaiting ? <ReactSVG className="members__status-waiting" style={{ color: "#ffa447" }} src={scheduleIcon} /> : ''}
            </a>
          </div>
        )}
        {u.user_linkedin && (
          <div className="members__block-status">
            <ReactSVG className={classes.cell__availableNetwork} style={{ display: "inline-block" }} src={linkedinIcon} />
            {isLinkedinWaiting ? <ReactSVG className="members__status-waiting" style={{ color: "#ffa447" }} src={scheduleIcon} /> : ''}
          </div>
        )}
      </React.Fragment>
    )
  }

  render() {
    const isMenuOpen = Boolean(this.state.anchorEl);
    const { classes } = this.props;
    let users = [...this.props.users, ...[this.props.user]];
    const { order, orderBy, selected } = this.state;

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).settings.users

            return (
              <Paper className={classes.root} elevation={0}>
                <Dialog
                  open={this.state.openConfirmModal}
                  onClose={this.handleCloseRemoveDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
                      Êtes-vous sûr de vouloir supprimer ce membre ?
                      Vous ne pourrez plus revenir en arrière.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" onClick={this.handleCloseRemoveDialog}>
                      Annuler
                    </Button>
                    <Button variant="contained" className={classes.dialogBtnRemove} onClick={this.removeUser.bind(this)} color="secondary" autoFocus>
                      Supprimer
                    </Button>
                  </DialogActions>
                </Dialog>


                <div className={classes.tableWrapper}>
                  <div className="datatable__header">
                    {/* BUTTON ADD A USER*/}
                    <Tooltip classes={{ popper: classes.tooltipUser, tooltip: classes.tooltip }}
                      title={
                        <div className={classes.tooltipTitle}>
                          <div className={classes.tooltipContainer}>
                            <img alt="arrow" src={arrow} />
                          </div>
                          {wording.btn_add}
                        </div>
                      }
                    >
                      <button className="datatable__create background-color-primary" onClick={this.props.togglePopinInvitation}>
                        <IconPlus />
                      </button>
                    </Tooltip>

                    {/* SEARCH BAR */}
                    <div className="datatable__search">
                      <IconSearch />
                      <input type="text" className="datatable__search-input" value={this.state.search || ''} onChange={this.handleSearch} />
                    </div>
                  </div>

                  <Menu
                    className="popover_class"
                    PaperProps={{
                      style: {
                        marginTop: -10,
                        width: 200,
                        textAlign: "center",
                        borderRadius: 5,
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        overflow: "visible",
                      },
                    }}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    getContentAnchorEl={null}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isMenuOpen}
                    onClose={this.handleMenuClose}
                  >
                    {
                      this.state.anchorElUser
                      && <MenuItem onClick={() => this.props.handleChangePage(1, this.state.anchorElUser, true)}>
                        <ListItemText className={classes.menuItem} inset
                          primary={t.btn_edit}
                        />
                      </MenuItem>
                    } {
                      this.state.anchorElUser
                      && this.props.user.id !== this.state.anchorElUser.id
                      && this.state.anchorElUser.status === params.userStatuses.ENABLED
                      && <MenuItem onClick={this.disableUser.bind(this)}>
                        <ListItemText style={{ color: "#f2615e" }} className={classes.menuItem} inset
                          primary={t.btn_disable}
                        />
                      </MenuItem>
                    } {
                      this.state.anchorElUser
                      && this.props.user.id !== this.state.anchorElUser.id
                      && this.state.anchorElUser.status === params.userStatuses.DISABLED
                      && <MenuItem onClick={this.disableUser.bind(this)}>
                        <ListItemText style={{ color: "#23e27f" }} className={classes.menuItem} inset
                          primary={t.btn_enable}
                        />
                      </MenuItem>
                    } {
                      this.state.anchorElUser
                      && this.props.user.id !== this.state.anchorElUser.id
                      && <MenuItem onClick={() => this.openDialogRemoveUser(this.state.anchorElUser)}>
                        <ListItemText style={{ color: "#f2615e" }} className={classes.menuItem} inset
                          primary={t.btn_delete}
                        />
                      </MenuItem>
                    }
                  </Menu>
                  <CSSTransition
                    in={this.props.isVisible}
                    appear={true}
                    enter={true}
                    exit={true}
                    timeout={{
                      enter: 500,
                      exit: 300,
                    }}
                    classNames={{
                      appear: classes.itemEnter,
                      appearActive: classes.itemEnterActive,
                      enter: classes.itemEnter,
                      enterActive: classes.itemEnterActive,
                      exit: classes.itemExit,
                      exitActive: classes.itemExitActive,
                      exitDone: classes.itemExitDone,
                    }}
                  >
                    <Table className={classes.itemEnter} aria-labelledby="tableTitle">
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        rowCount={users.length}
                      />
                      <TableBody>
                        {stableSort(users, getSorting(order, orderBy))
                          .filter(u => u.user_twitter || u.user_linkedin)
                          .filter(u => (this.state.search && GlobalUtils.getUserNameAndAvatar({ user: u }).screenNameThatShouldBeDisplayed && GlobalUtils.getUserNameAndAvatar({ user: u }).screenNameThatShouldBeDisplayed.search(new RegExp(this.state.search, 'i')) !== -1) || !this.state.search)
                          .map(u => {

                            // Get appropiate screenName and profileImage (twitter > linkedin > default)
                            const {
                              screenNameThatShouldBeDisplayed,
                              profileImageThatShouldBeDisplayed
                            } = GlobalUtils.getUserNameAndAvatar({ user: u })

                            return (
                              <TableRow
                                key={u.id}
                                role="checkbox"
                                aria-checked={this.isSelected(u.id)}
                                tabIndex={-1}
                                selected={this.isSelected(u.id)}
                                className={classes.row}
                              >
                                <TableCell component="th" scope="row" padding="none"
                                  className={classNames(classes.name, classes.cell)} >
                                  <Avatar src={profileImageThatShouldBeDisplayed} onError={(e) => { e.target.src = defaultUserIcon }} className={classes.avatar} />
                                  <Typography>{screenNameThatShouldBeDisplayed}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none" className={classes.cell}>
                                  {this.buildStatus({ user: u, classes: classes })}
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none" className={classes.cell}>
                                  {(function () {
                                    switch (u.role) {
                                      case "User":
                                        return t.role_user;
                                      case "Manager":
                                        return t.role_manager;
                                      default:
                                        return u.role;
                                    }
                                  })()}
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none" className={classes.cell}>
                                  {
                                    this.props.groups
                                      .filter(group => group.users.includes(u.id) && u.role === params.roles.USER)
                                      .map(group =>
                                        <Chip key={group.id} className={classes.groupChip} label={group.label} />
                                      )
                                  }
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none" className={classes.cell}>
                                  <IconButton
                                    className={classes.moreButton + " noOver"}
                                    aria-label="actions"
                                    aria-owns={isMenuOpen ? 'material-appbar' : null}
                                    aria-haspopup="true"
                                    disableRipple
                                    onClick={(e) => this.handleMenuOpen(e, u)}
                                    color="inherit"
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </CSSTransition>
                </div>
              </Paper>

            )
          }
        }
      </Translation>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default withStyles(styles)(EnhancedTable);