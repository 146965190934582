import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class ConfirmDialog extends React.Component {

  render() {
    return (
      <div className="confirm-dialog__no-css-rule">
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{
            padding: "50px 50px 0",
            textAlign: "center",
          }}>
            <DialogContentText id="alert-dialog-description">
              {this.props.message.split('\n').map((item, key) => {
                return <span key={key} style={{
                  color: "#525252",
                  fontWeight: 600,
                  fontSize: 15,
                }}>{item}<br /></span>
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
            paddingTop: 50,
            paddingBottom: 50,
            margin: 0,
          }}>
            <Button onClick={() => this.props.handleClose()} variant="contained" >
              {this.props.label && this.props.label.buttonClose ? this.props.label.buttonClose : 'Annuler'}
            </Button>
            <Button onClick={() => this.props.handleValid()} style={{ marginLeft: 20, }} variant="contained" color={this.props.label && this.props.label.styleValid ? this.props.label.styleValid : 'secondary'} autoFocus>
              {this.props.label && this.props.label.buttonValid ? this.props.label.buttonValid : 'Supprimer'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmDialog;