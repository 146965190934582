import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import Cookies from 'js-cookie'
import history from './history';


import { checkToken } from './actions/utils'

// Material
import CircularProgress from "@material-ui/core/CircularProgress";

// Import components
import * as actionCreators from "./actions/actionCreators"
import HeaderCon from "./containers/header-container.js"
import HomeManagerCon from "./containers/homeManager-container.js"
import HomeUserCon from "./containers/homeUser-container.js"
import SignInCon from "./containers/signIn-container.js"
import OnboardingCon from "./containers/onboarding-container.js"

import Mentions from "./components/mentions/mentions.js"
import CGU from "./components/cgu/cgu.js"
import Politics from "./components/politics/politics.js"
import Profil from "./components/profil/profil.js"
import params from "./utils/params";
import MobileNav from './components/mobileNav/mobileNav';
import GlobalSnackbar from './components/misc/globalSnackbar/global-snackbar';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userScrollRef: null,
      isAuthenticating: true,
    }

    this.scrollToTop = this.scrollToTop.bind(this);
    this.setUserScrollRef = this.setUserScrollRef.bind(this);
    this.triggerUserMediaOnScroll = this.triggerUserMediaOnScroll.bind(this)
  }

  async componentDidMount() {
    // If it's a redirection from the newsletter
    if (history.location.pathname === '/redir') {
      let params = new URLSearchParams(history.location.search)
      await this.props.createNewsletterActivityAction({
        user_id: params.get('user_id'),
        flux_post_id: params.get('flux_post_id'),
        parent_id: params.get('nl_id'),
        label: 'nl_post_click',
      }).catch((err) => { console.error(err) })

      window.location.replace(params.get('url'))

      return
    }

    // Set the name of the application in the title tag of the tab
    document.querySelector('title').innerHTML = process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : 'Tweet It!';

    // Set the background of the app
    document.body.style.backgroundColor = process.env.REACT_APP_COLOR_SECONDARY ? process.env.REACT_APP_COLOR_SECONDARY : '#EBF2FF';

    // Set the favicon
    let linkIcon = document.querySelector("link[rel='icon']");
    linkIcon.setAttribute('href', process.env.REACT_APP_FAVICON ? process.env.REACT_APP_FAVICON : '')

    let primary = process.env.REACT_APP_COLOR_PRIMARY ? process.env.REACT_APP_COLOR_PRIMARY : '#1757A6';
    let secondary = process.env.REACT_APP_COLOR_SECONDARY ? process.env.REACT_APP_COLOR_SECONDARY : '#EBF2FF';
    let hover = process.env.REACT_APP_COLOR_HOVER ? process.env.REACT_APP_COLOR_HOVER : '#0B3569';
    let style = document.createElement('style');

    style.innerHTML = `
        .color-primary {
            color: ${primary};
        }
        .color-primary-i {
            color: ${primary} !important;
        }
        .color-secondary {
            color: ${secondary};
        }
        .color-hover {
            color: ${hover};
        }
        .border-color-primary {
            border-color: ${primary};
        }
        .border-color-primary-i {
            border-color: ${primary} !important;
        }
        .background-color-primary {
            background-color: ${primary};
        }
        .background-color-secondary {
            background-color: ${secondary};
        }
        .background-color-hover {
            background-color: ${hover};
        }
        .shadow-hover-primary {
            box-shadow: 0 0 0 3px ${primary}
        }
        .shadow-hover-primary:hover {
            box-shadow: 0 0 0 3px ${primary}
        }
        .fill-primary {
            fill: ${primary};
        }
        .fill-secondary {
            fill: ${secondary};
        }
        .stroke-primary {
            stroke: ${primary};
        }
        .stroke-secondary {
            stroke: ${secondary};
        }
        /* DatePicker*/
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
            background-color: ${primary} !important;
        }
        .datepicker .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: ${secondary} !important;
        }
        .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
            background-color: ${secondary} !important;
        }
        `;
    document.head.appendChild(style);

    if (window.location.hostname === "tweetit.io") {
      let script = document.createElement('script');
      script.setAttribute("src", "https://cdn.usefathom.com/script.js")
      script.setAttribute("site", "RXCKQGVG")
      script.setAttribute("defer", "")
      document.head.appendChild(script);
    }

    // When to prevent executing code while the user is authenticating to Linkedin
    if (history.location.pathname === '/linkedin' || history.location.search.includes('oauth_token')) return;
    this.loadFromCache();
  }

  async loadFromCache() {
    // If the token of the user is still valid
    let t = await checkToken()
      .catch(async () => {
        await this.props.updateIsAuthenticated(false)
      });

    // Connect the user
    if (t && 'refresh_token' in t) {

      let networkAuth = await this.props.verifyNetworkAuth()

      if (networkAuth && networkAuth.error) {
        this.logout()
        return
      }

      if (networkAuth && !(Object.values(networkAuth.networks).every(parameter => parameter === false))) {

        // If the user is not authenticated on one of the network
        if (!networkAuth.networks.twitter) Cookies.remove('twitter_key')
        if (!networkAuth.networks.linkedin) Cookies.remove('linkedin')

        let res = await this.props.getCurrentUserAction(true)

        if (res && res.error) {
          this.logout()
          return
        }

        else {
          await this.props.updateIsAuthenticated(true)
          await this.props.createUserActivityAction(params.label_activity.USER_APP_OPENED, 'user opened')
            .catch((err) => console.error(err))

        }

      } else {
        this.logout()
      }
    }

    this.setState({ isAuthenticating: false })
  }

  logout = async () => {
    await this.props.logUserOut();
    this.setState({ isAuthenticating: false })
    await this.props.updateUserAction({});
    await this.props.updateUsersAction([]);
    this.props.updateIsAuthenticated(false);
    Cookies.remove('token');
    Cookies.remove('refresh_token');
    Cookies.remove('twitter_key')
    Cookies.remove('linkedin')
    history.push(('/'))
  };

  setUserScrollRef(input) {
    this.setState({ userScrollRef: input.current });
  }

  scrollToTop() {
    if (this.state.userScrollRef) {
      const myDomNode = ReactDOM.findDOMNode(this.state.userScrollRef);
      myDomNode.scrollTo(0, 0);
    }
  }

  triggerUserMediaOnScroll(e, options) {
    try {

      if (window.innerWidth > 767) return;

      let container = document.querySelector('.home-user__scrollbar')

      let post_cards = container && container.querySelectorAll('.tweet-card__card')

      if (post_cards && post_cards.length) {

        for (let post_card of post_cards) {

          // If the post card enter almost in the viewport
          if (post_card.getBoundingClientRect().top < ((window.innerHeight * 2) / 3)) {

            // Start searching video or gif that are fully visible in the viewport, and trigger them
            // Retrieve images
            let imgs = post_card.getElementsByTagName('img')

            // Trigger gif, If the gif is fully visible by the user
            if (imgs && imgs.length) {
              for (let img of imgs) {
                let dataType = img.getAttribute('data-type')

                if (dataType === params.mediaTypes.GIF) {
                  if (img.src.includes('/resize/x200/') && ((window.innerHeight - img.getBoundingClientRect().top) > img.offsetHeight)) {
                    img.src = img.src.replace('/resize/x200/', '')
                  }
                }
              }
            }

            // Video
            let videos = post_card.getElementsByTagName('video')

            // Trigger video, If the video is fully visible by the user
            if (videos && videos.length) {
              if (options && (options.init === true)) videos = [videos[0]]

              for (let video of videos) {
                if (video.paused && ((window.innerHeight - video.getBoundingClientRect().top) > video.offsetHeight)) {
                  video.play()
                  video.playbackRate = 1
                  video.currentTime = 0
                }
              }
            }
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }


  /* Causing animation issue on the header 
  displayContent(content, option) {

     
      if (window.innerWidth > 1024 && (history.location.pathname.includes('/members') || history.location.pathname.includes('/groups')) &&  option.role !== params.roles.USER) {
          return (
               <PerfectScrollbar
                   option={{
                       wheelSpeed: 1,
                       wheelPropagation: true,
                       swipeEasing: false,
                       minScrollbarLength: 5
                   }}
               >
                   {content}
               </PerfectScrollbar>
          )
       } else {
           return content
       }
   }*/

  getAppClass(role) {
    let value = (history.location.pathname === '/') && role === params.roles.MANAGER ? '' : 'overflow '
    value += role === params.roles.USER ? 'relative ' : ''
    value += history.location.pathname === '/profil' ? 'bg-white ' : ''
    return value
  }

  render() {
    // Get current role 
    const ROLE = this.props.user ? this.props.user.role : ''
    const path = this.props.location.pathname
    const pageWithNoHeaderFooter = ["/cgu", "/mentions-legales", "/politique-confidentialite", "/redir"].includes(path)

    return (
      <div className="app">
        <GlobalSnackbar />
        {
          this.props.isAuthenticated ?
            <div className={`app__container ${this.getAppClass(ROLE)}`} onScroll={(ROLE === params.roles.USER) && (window.innerWidth <= 767) ? this.triggerUserMediaOnScroll : null}>
              <React.Fragment>
                {/* Header */}
                {!pageWithNoHeaderFooter && <HeaderCon {...this.props} scrollToTop={this.scrollToTop} role={ROLE} />}

                {/* Mobile navigation for user only */}
                {((ROLE === params.roles.USER) && (!pageWithNoHeaderFooter || (path === '/leaderboard'))) && <MobileNav />}

                <Switch>
                  {/* profile of the user */}
                  {(ROLE === params.roles.USER) &&
                    <Route path="/profil" component={() => <Profil {...this.props} />} />
                  }

                  {/* Terms */}
                  <Route exact path="/mentions-legales" component={Mentions} />
                  <Route exact path="/cgu" component={CGU} />
                  <Route exact path="/politique-confidentialite" component={Politics} />

                  {ROLE === params.roles.MANAGER ?
                    <Route path="/" component={HomeManagerCon} />
                    : ROLE === params.roles.USER ?
                      <Route path="/" render={() => <HomeUserCon  {...this.props} triggerUserMediaOnScroll={(window.innerWidth <= 767) ? this.triggerUserMediaOnScroll : null} setUserScrollRef={this.setUserScrollRef} />} />
                      : ''}
                </Switch>
              </React.Fragment>
            </div>
            : this.state.isAuthenticating ?
              <div className="app__container-loader">
                <CircularProgress />
              </div>
              :
              <Switch>
                <Route exact path="/" component={SignInCon} />
                <Route exact path="/invite" component={OnboardingCon} />
                {/* Terms */}
                <Route exact path="/mentions-legales" component={Mentions} />
                <Route exact path="/cgu" component={CGU} />
                <Route exact path="/politique-confidentialite" component={Politics} />
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
};

export default connect(mapStateToProps, actionCreators)(App);
