import React from 'react';
import { connect } from "react-redux";
import TimerMixin from 'react-timer-mixin';
import reactMixin from 'react-mixin';
import hash from 'object-hash'
import { Switch, Route } from 'react-router-dom'
import Cookies from 'js-cookie'
import history from './../history';
import i18n from '../i18n'

// Import components
import UserFlux from '../components/userFlux/userFlux';
import Home from "../components/homeUser/homeUser.js";
import Leaderboard from "../components/leaderboard/leaderboard.js";

// Utils
import params from "../utils/params";
import EventEmitter from '../utils/EventEmitter';

import * as actionCreators from "../actions/actionCreators"

const wording = i18n.t('user', { returnObjects: true })

class HomeCon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpen: false,
      dialogRefusalIsOpen: false,
      confirmIsOpen: false,
      mediaIsOpen: false,
      mediaIndex: 0,
      media: {},
      formData: {},
      lastTweetitsLength: 0,
      hasMore: true,
      posting: false,
      loadingMoreSuggested: false,
      flux_list: null,
      users_rank: null,
      authenticatedOnTwitter: Cookies.get('twitter_key') ? true : false,
      authenticatedOnLinkedin: Cookies.get('linkedin') ? true : false,
    };

    this.loadMoreTweetits = this.loadMoreTweetits.bind(this);
    this.fastSubmitTweetit = this.fastSubmitTweetit.bind(this);
    this.fastFollowProfile = this.fastFollowProfile.bind(this);
    this.minimizeMedia = this.minimizeMedia.bind(this);
    this.formatUploadUrl = this.formatUploadUrl.bind(this);
  }

  /** Retrieve flux  */
  async getAllFlux() {

    let networks = ["twitter", "instagram", "linkedin", "facebook"]
    let allFlux = []

    // perform action for each network and check if there is an error
    await Promise.all(
      networks.map(network => (async () => {

        // flux from this network
        let fluxList = await this.props.getAllFluxUserAction({ network: network })

        // check for api error
        fluxList = fluxList.error ? [] : fluxList

        // push new flux
        allFlux.push(...fluxList)

      })())
    )

    return allFlux
  }

  async componentDidMount() {
    let res = await this.props.getTweetitsUserAction(5);

    if (res && res.length && this.props.triggerUserMediaOnScroll) this.props.triggerUserMediaOnScroll(null, { init: true })

    /* Auto refresh */
    this.timer = setInterval(async () => {
      if (this.props.tweetits_suggested.length > 0) {
        let latestTweetit = this.props.tweetits_suggested.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        }),
          latestUpdate = latestTweetit[0].updated_at;

        let res = await this.props.getNewUserTweetitsAction(latestUpdate, this.props.tweetits_suggested.map(t => t.id));
        let len = this.props.tweetits_suggested.length + (res ? res.length : 0);

        this.props.refreshUserTweetitsAction(
          this.props.tweetits_suggested.map(t => t.id),
          len,
          hash(this.props.tweetits_suggested.filter(t => t.id !== this.state.formData.id).concat(res)), // Ignorer le tweetit en cours d'édition
          this.state.formData.id
        );
      } else {
        this.props.getTweetitsUserAction(5);
      }
    }, 15000);

    // Get flux
    if (process.env.REACT_APP_FUNCTION_FLUX === 'true') {
      let flux_list = await this.getAllFlux()

      if (flux_list && !flux_list.error && Array.isArray(flux_list)) this.setState({ flux_list })
      else this.setState({ flux_list: [] })
    }

    if (process.env.REACT_APP_FUNCTION_LEADERBOARD === 'true') {
      // Get users rank
      let users_rank = await this.props.getUsersRankAction()
        .catch((err) => { return { error: err } })

      if (users_rank && !users_rank.error) this.setState({ users_rank })
    }

    let params = new URLSearchParams(history.location.search)
    const actu = params.get('actu')
    const profil = params.get('profil')

    if (actu) history.push('/flux')
    else if (profil) history.push('/profil')


  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  openTweetitRefusalDialog = (data) => {
    this.setState({ dialogRefusalIsOpen: true, formData: Object.assign(data, this.state.formData) });
  };

  closeTweetitRefusalDialog = () => {
    this.setState({ dialogRefusalIsOpen: false, formData: {} });
  };

  openTweetitDialog = (data) => {
    this.setState({ dialogIsOpen: true, formData: Object.assign(data, this.state.formData) });
  };

  closeTweetitDialog = () => {
    this.setState({ dialogIsOpen: false, formData: {} });
  };

  async fastSubmitTweetit(post_id, data) {
    // Init value who will receive the value of the request
    let res = null
    data.status = "published";

    this.setState({ posting: true }, async () => {

      // For Instagram and Facebook
      if (['instagram', 'facebook'].includes(data.network)) {
        this.props.editTweetitUserAction(post_id, data).then(res => {
          this.props.getTweetitsUserAction(this.props.tweetits_suggested.length);
          // Manage the the component CircularProgress on the post card
          this.setState({ posting: false })
        })

        // Post
      } else if (data.type === params.types.POST) {

        // Retrieve the result of the request
        res = await this.props.sendPostAction(post_id, data.network)


        // If an error occur display the message to the user
        if (res && res.error) {
          EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" });
          this.setState({ posting: false })
          return;
        }

        EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.post.popin_message.content_published, style: "success" })

        this.props.editTweetitUserAction(post_id, data).then(res => {
          this.props.getTweetitsUserAction(5);
          // Manage the the component CircularProgress on the post card
          this.setState({ posting: false })
        })

        // Repost
      } else if (data.type === params.types.REPOST) {

        let twitterIdRegex = /status\/([0-9a-zA-Z]*)+$/ig
        let tweet_id = twitterIdRegex.exec(data.share_url);

        if (tweet_id[1]) {
          // Retrieve the result of the request
          res = await this.props.createReTweetAction(tweet_id[1], post_id)


          // If an error occur display the message to the user
          if (res && res.error) {
            EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
            this.setState({ posting: false })
            return
          }

          EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.post.popin_message.retweeted, style: "success" })

          this.props.editTweetitUserAction(post_id, data).then(res => {
            this.props.getTweetitsUserAction(this.props.tweetits_suggested.length);
            // Manage the the component CircularProgress on the post card
            this.setState({ posting: false })
          })
        }
      }

    })
  }

  async fastFollowProfile(post_id, screen_name) {
    // Init value who will receive the value of the request
    let res = null

    this.setState({ posting: true });

    if (!screen_name) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.post.popin_message.profil_invalide, style: "error" })
      this.setState({ posting: false })
      return;
    }

    res = await this.props.followProfileAction(post_id, screen_name);

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      this.setState({ posting: false })
      return;
    }

    await this.props.getTweetitsUserAction(this.props.tweetits_suggested.length);
    this.setState({ posting: false });
    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: wording.post.popin_message.follow.replace('[[account]]', res.name), style: "success" })
  }

  formatUploadUrl(media, originalSize) {
    if (!media || !media.uploadcare_id) {
      return null;
    }
    if (media.type === params.mediaTypes.VIDEO) {
      return `https://ucarecdn.com/${media.uploadcare_id}/video.mp4`;
    }
    return `https://ucarecdn.com/${media.uploadcare_id}/${originalSize ? '' : '-/resize/x200/'}`;
  }

  enlargeMedia = (data, index) => {

    this.setState({
      mediaIsOpen: true,
      mediaIndex: index,
      media: data,
    });
  };

  minimizeMedia() {
    this.setState({ mediaIsOpen: false });
  }

  loadMoreTweetits(length, nb, status) {
    this.setState({ loadingMoreSuggested: true }, () => {
      this.props.getTweetitsUserAction(nb + length).then(res => {
        if (this.state.lastTweetitsLength === res.filter(t => t.status === status).length) {
          this.setState({ loadingMoreSuggested: false, hasMore: false, lastTweetitsLength: res.filter(t => t.status === status).length });
        } else {
          this.setState({ loadingMoreSuggested: false, lastTweetitsLength: res.filter(t => t.status === status).length })
        }
      })
        .catch(e => {
          this.setState({ loadingMoreSuggested: false })
        });
    })
  }

  onSocialAuthSucceed = social => async response => {
    try {
      // Twitter
      if (social === "twitter" && response.ok) {
        const token = response.headers.get('x-auth-token');

        response.json().then(async (user) => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur.`)

          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { secure: true });
          Cookies.set('twitter_key', user['twitter_key'], { secure: true });
          this.setState({ authenticatedOnTwitter: true })

          await this.props.getCurrentUserAction()
        })
      } else if (social === "twitter" && !response.ok) {
        response.json().then((err) => {
          this.onSocialAuthFailed()(err.error)
        })
        return
      }

      // Linkedin
      if (social === "linkedin") {
        const register = this.props.user.user_linkedin ? false : true
        let res = await this.props.getLinkedinAccessToken({
          code: response.code,
          redirect_uri: `${window.location.origin}/linkedin`,
          register: register,
          profil: register
        })

        if (!res.ok) {
          res.json().then((err) => {
            this.onSocialAuthFailed()(err.error)
          })
          return;
        }

        res.json().then(async (user) => {

          // If the user id is not the same to the actual user
          if (user.id !== this.props.user.id) return this.onSocialAuthFailed()(`Compte associé à un autre utilisateur`)

          const token = res.headers.get('x-auth-token');
          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { secure: true });
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { secure: true });
          Cookies.set(
            'linkedin', 
            user['linkedin_key'], 
            { 
              expires: Math.ceil((user['linkedin_token_expires_in'] - new Date().getTime()) / (1000 * 3600 * 24)),
              secure: true 
            }
          )
          this.setState({ authenticatedOnLinkedin: true })

          await this.props.getCurrentUserAction()
        })
      }

    } catch (e) {
      this.onSocialAuthFailed(social)(e.message)
    }
  }

  onSocialAuthFailed = social => response => {
    if (response && response.message === 'Popup has been closed by user') return;

    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, {
      message: response && typeof (response) === 'string' ? response : `Une erreur s'est produite.`,
      style: "error"
    })
  }

  render() {

    let tweetits = this.props.tweetits_suggested ? this.props.tweetits_suggested.sort((a, b) => { return new Date(b.updated_at) - new Date(a.updated_at); }) : []

    return (
      <Switch>
        <Route exact path="/">
          <Home
            history={this.props.history}
            formData={this.state.formData}
            posting={this.state.posting}
            user={this.props.user}
            getMediaUrl={this.formatUploadUrl}
            tweetits={tweetits}
            isLoading={this.props.isLoading_suggested}
            dialogIsOpen={this.state.dialogIsOpen}
            dialogRefusalIsOpen={this.state.dialogRefusalIsOpen}
            confirmIsOpen={this.state.confirmIsOpen}
            openTweetitDialog={this.openTweetitDialog}
            closeTweetitDialog={this.closeTweetitDialog}
            mediaIsOpen={this.state.mediaIsOpen}
            mediaIndex={this.state.mediaIndex}
            enlargeMedia={this.enlargeMedia}
            minimizeMedia={this.minimizeMedia}
            media={this.state.media}
            submitMedia={this.props.submitMedia}
            openTweetitRefusalDialog={this.openTweetitRefusalDialog}
            closeTweetitRefusalDialog={this.closeTweetitRefusalDialog}
            getTweetAction={this.props.getTweetAction}
            getTweetitsUserAction={this.props.getTweetitsUserAction}
            fastSubmitTweetit={this.fastSubmitTweetit}
            fastFollowProfile={this.fastFollowProfile}
            editTweetitUserAction={this.props.editTweetitUserAction}
            searchAccountAction={this.props.searchAccountAction}
            sendPostAction={this.props.sendPostAction}
            createReTweetAction={this.props.createReTweetAction}
            getFileInfoUploadCare={this.props.getFileInfoUploadCare}
            loadMoreTweetits={this.loadMoreTweetits}
            hasMore={this.state.hasMore}
            loading={this.props.loading}
            loadingMoreSuggested={this.state.loadingMoreSuggested}
            setUserScrollRef={this.props.setUserScrollRef}
            getLinkedinAccessToken={this.props.getLinkedinAccessToken}
            triggerUserMediaOnScroll={this.props.triggerUserMediaOnScroll}
            fetchOpenGraphAction={this.props.fetchOpenGraphAction}
            tweetits_suggested={this.props.tweetits_suggested}
            tweetits_draft={this.props.tweetits_draft}
            authenticatedOnTwitter={this.state.authenticatedOnTwitter}
            authenticatedOnLinkedin={this.state.authenticatedOnLinkedin}
            onSocialAuthSucceed={this.onSocialAuthSucceed}
            onSocialAuthFailed={this.onSocialAuthFailed}
          />
        </Route>

        {process.env.REACT_APP_FUNCTION_FLUX === 'true' ?
          <Route path="/flux">
            <UserFlux
              // Popin media
              media={this.state.media}
              mediaIsOpen={this.state.mediaIsOpen}
              mediaIndex={this.state.mediaIndex}
              enlargeMedia={this.enlargeMedia}
              minimizeMedia={this.minimizeMedia}
              flux_list={this.state.flux_list}
              editTweetitUserAction={this.props.editTweetitUserAction}
              postContentFluxAction={this.props.postContentFluxAction}
              getAllFluxUserAction={this.props.getAllFluxUserAction}
              fetchOpenGraphAction={this.props.fetchOpenGraphAction}
              shareFluxLinkedinAction={this.props.shareFluxLinkedinAction}
              getUploadCareUrlAction={this.props.getUploadCareUrlAction}
              createFluxPostActivityAction={this.props.createFluxPostActivityAction}
              user={this.props.user}
              authenticatedOnTwitter={this.state.authenticatedOnTwitter}
              authenticatedOnLinkedin={this.state.authenticatedOnLinkedin}
              onSocialAuthSucceed={this.onSocialAuthSucceed}
              onSocialAuthFailed={this.onSocialAuthFailed}
            />
          </Route>
          : ''}

        {/*process.env.REACT_APP_FUNCTION_LEADERBOARD === 'true' ?
                <Route path="/leaderboard">
                    <Leaderboard 
                        users_rank={this.state.users_rank}
                        user={this.props.user}
                    />
                </Route>
            : ''*/}
        <Route path="/leaderboard">
          <Leaderboard
            users_rank={this.state.users_rank}
            user={this.props.user}
          />
        </Route>
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return state
};

reactMixin(HomeCon.prototype, TimerMixin);
export default connect(mapStateToProps, actionCreators)(HomeCon);
