import React from 'react';

// Init component.
function IconArrowRounded(props) {
  return (
    <svg className="icon__arrow-rounded" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6442 9.48434L11.1314 14.8985C11.0212 15.0067 10.8809 15.0803 10.7282 15.1101C10.5754 15.14 10.4171 15.1247 10.2732 15.0661C10.1293 15.0076 10.0063 14.9085 9.9198 14.7813C9.83326 14.6542 9.78705 14.5047 9.78702 14.3517V12.0442C4.54145 12.2194 2.48445 14.11 2.46334 14.13C2.34186 14.2459 2.18563 14.3203 2.01777 14.3422C1.8499 14.3641 1.67931 14.3324 1.53122 14.2517C1.38313 14.1711 1.2654 14.0457 1.19545 13.8943C1.1255 13.7428 1.10703 13.5733 1.14278 13.4107C1.15932 13.3373 2.819 6.36374 9.78702 5.8712V3.52329C9.78705 3.37034 9.83326 3.22083 9.9198 3.09367C10.0063 2.96651 10.1293 2.86739 10.2732 2.80887C10.4171 2.75034 10.5754 2.73502 10.7282 2.76485C10.8809 2.79468 11.0212 2.86832 11.1314 2.97646L16.6442 8.39067C16.7174 8.46245 16.7754 8.5477 16.815 8.64153C16.8546 8.73536 16.875 8.83593 16.875 8.9375C16.875 9.03907 16.8546 9.13964 16.815 9.23347C16.7754 9.3273 16.7174 9.41255 16.6442 9.48434V9.48434Z" stroke="black" strokeWidth="2" />
    </svg>
  );
}

export default IconArrowRounded;