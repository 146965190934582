const styles = theme => ({
  paper: {
    overflow: "initial",
  },
  fullWidthPaper: {
    margin: 0,
    padding: 0,
    overflow: "hidden",
  },
  videoWrapper: {
    display: "flex",
    alignItems: "baseline",
  },
  imageWrapper: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    height: 400,
    alignItems: "center",
    justifyContent: "center"
  },
  imageControls: {
    position: "absolute",
    bottom: 0,
    height: 40,
    background: "rgba(0, 0, 0, 0.6)",
  },
  close: {
    cursor: "pointer",
    position: "absolute",
    color: "white",
    right: 10,
    top: 10,
  },
  prev: {
    cursor: "pointer",
    color: "white",
    fontSize: 20,
    transform: "scaleX(-1)",
  },
  next: {
    cursor: "pointer",
    color: "white",
    fontSize: 20,
  },
  pagination: {
    color: "white",
    fontSize: 15,
    fontWeight: 500,
    margin: "0 50px",
  },
  mediaFullWidth: {
    width: "100%",
  }
});

export default styles;