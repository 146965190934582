// Modules
import React from 'react'

// Material
import {
  withStyles,
  Snackbar,
  SnackbarContent
} from '@material-ui/core'

// Assets
import styles from './material-global-snackbar'

// Utils
import EventEmitter from '../../../utils/EventEmitter'
import params from '../../../utils/params'

class GlobalSnackbar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      message: null,
      style: null
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  componentWillUnmount() {
    // Unsubscribe to OPEN_SNACKBAR event
    EventEmitter.removeListener(params.EVENT_OPEN_SNACKBAR, this.handleOpen)
  }

  componentDidMount() {
    // Subscribe to OPEN_SNACKBAR event
    EventEmitter.addListener(params.EVENT_OPEN_SNACKBAR, this.handleOpen)
  }

  handleClose() {
    this.setState({
      isOpen: false,
    })
  }

  onExited() {
    this.setState({
      message: null,
      style: null
    })
  }

  handleOpen({ message, style }) {
    this.setState({
      isOpen: true,
      message: message,
      style: style
    })
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={this.state.isOpen}
        onClose={this.handleClose}
        onExited={this.onExited}
        autoHideDuration={1250}
      >
        <SnackbarContent
          className={this.props.classes["snackbar_" + (this.state.style ? this.state.style : "success")]}
          aria-describedby="snackbar-message"
          message={
            <span id="snackbar-message">{this.state.message}</span>
          }
        />
      </Snackbar>
    )
  }
}

export default withStyles(styles)(GlobalSnackbar)