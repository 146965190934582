const styles = theme => ({
  itemEnter: {
    opacity: 0,
    transform: "translate(0, 15px)"
  },
  itemEnterActive: {
    opacity: 1,
    transform: "translate(0, 0)",
    transition: "opacity .25s, transform .5s",
  },
  itemExit: {
    opacity: 1,
  },
  itemExitActive: {
    opacity: 0,
    transition: "opacity .3s",
  },
  itemExitDone: {
    opacity: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: "#697e8b",
  },
  scrollbarGrid: {
    marginTop: 10,
    margin: 0,
    paddingRight: 20,
    display: "block",
    width: "100%",
    overflowY: "auto",
    [theme.breakpoints.down('1024')]: {
      maxHeight: "calc(100vh - (209px))",
    }
  },
  buttonAddTweetit: {
    padding: "12px 0",
    margin: "10px 0px",
    height: 60,
    fontSize: 16,
    fontWeight: 700,
    WebkitTextSizeAdjust: "none",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale"
  },
  AddCircleIcon: {
    color: "white",
    marginRight: 20,
    height: 16,
    width: 16,
  }
});

export default styles