import React from 'react'

const AlertInfoIcon = () => (
  <svg className="icon__alert-info" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5H11V7H9V5Z" />
    <path d="M9 9H11V15H9V9Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd" d="M0 10C0 4.48 4.47998 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.47998 20 0 15.52 0 10ZM2 10C2 14.41 5.58997 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.58997 2 2 5.59 2 10Z"

    />
  </svg>

)

export default AlertInfoIcon