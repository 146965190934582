import React from 'react';
import { connect } from "react-redux";
import { Switch, Route } from 'react-router-dom'
import Cookies from 'js-cookie'


// Import components
import * as actionCreators from "../actions/actionCreators"
import SignIn from "../components/signIn/signIn"
import history from '../history';

// utils
import utilsParams from '../utils/params'

class SignInCon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      error: false,
      userNetworks: null,
      needSecondAuth: true,
      twitterAuthSucceed: false,
      linkedinAuthSucceed: false,
      email_token: null,
      member: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailed = this.onFailed.bind(this);
    // Function who will receive code and state  (prevent CSRF attacks), after user logged to linkedin
    this.handleAuthentificationLinkedin = this.handleAuthentificationLinkedin.bind(this);
  }

  async componentDidMount() {
    let params = new URLSearchParams(history.location.search);

    // Get email token
    let email_token = params.get('m');

    // Get member key
    let member = params.get('member');

    // get token from URL
    let network_token = params.get('token');

    let userNetworks = {
      twitter: true,
      linkedin: true
    }

    // If token exists fetch user invitation data
    if (network_token) {
      userNetworks = await this.props.getUserNetworksAction(network_token)
      userNetworks = userNetworks && userNetworks.error ? null : userNetworks
    }

    this.setState({
      userNetworks: userNetworks || { twitter: true, linkedin: true },
      needSecondAuth: (userNetworks && Object.values(userNetworks).every(parameter => parameter === true)),
      email_token: email_token,
      member: member
    })
  }

  async componentWillUnmount() {
    // Confirm the mail after the user sign to one network at least
    if (this.state.email_token && (this.state.twitterAuthSucceed || this.state.linkedinAuthSucceed)) {
      let res = await this.props.editConfirmMailUserAction()

      if (res && res.error) {
        console.error(res.error)
      }
    }
  }

  onSuccess = (params) => {
    let response = params.response,
      shouldBlockRouteChange = params.shouldBlockRouteChange;

    try {
      const token = response.headers.get('x-auth-token');

      response.json().then(async (user) => {
        // If the user id is not the same to the actual user
        if (this.props.user && ('id' in this.props.user) && (this.props.user.id !== user.id)) {
          this.onFailed(`La connexion à ce compte ${response.network.charAt(0).toUpperCase() + response.network.slice(1)}, correspond à un autre utilisateur.`, response.network)
          return;
        }

        if (token) {

          // Set token and refresh_token  (Use for the internal system of the app)
          Cookies.set('token', token, { expires: 90, secure: true })
          Cookies.set('refresh_token', user.refresh_token.refresh_token, { expires: 90, secure: true })

          if (response.network === 'twitter') {
            Cookies.set('twitter_key', user['twitter_key'], { expires: 90, secure: true })
          }
          // Set key, token and refresh_token  (Use to access linkedin API)    
          else if (response.network === 'linkedin') {
            Cookies.set(
              'linkedin', 
              user['linkedin_key'], 
              { 
                expires: Math.ceil((user['linkedin_token_expires_in'] - new Date().getTime()) / (1000 * 3600 * 24)), 
                secure: true 
              }
            )
          }

          // Retrieve the information of the user register in DB
          let res = await this.props.getCurrentUserAction(true)

          if (res && !res.error) {
            if (!shouldBlockRouteChange) {

              this.props.updateIsAuthenticated(true);

              let params = new URLSearchParams(history.location.search)
              const actu = params.get('actu')
              const profil = params.get('profil')

              history.push(actu ? '/flux' : profil ? '/profil' : '')
              await this.props.createUserActivityAction(utilsParams.label_activity.USER_APP_LOGGED, 'user logged')
                .catch((err) => console.error(err))
            }

          } else if (res.error) {
            this.onFailed(res.error, response.network)
          }
        }
      })
        .catch((err) => { this.onFailed(err, response.network) })

    } catch (e) {
      this.onFailed(e.message, response.network)
    }
  };

  onFailed(error, network) {
    let newState = {}
    if (error && error.message !== 'Popup has been closed by user') {
      newState.error = error && typeof (error) === 'string' ? error : `Une erreur s'est produite.`
    }

    newState[network === 'twitter' ? 'twitterAuthSucceed' : 'linkedinAuthSucceed'] = false

    this.setState(newState)
    this.props.updateIsAuthenticated(false);
  };

  // authSucceedStateKey = "twitterAuthSucceed" or "linkedinAuthSucceed" from this.state
  onAuthSucceed = authSucceedStateKey => response => {
    if (response && !response.ok) {

      try {
        response.json().then((err) => {
          this.onFailed(err.error)
        })
      } catch (e) {
        this.onFailed()
      }
      return;
    }

    response.network = authSucceedStateKey !== 'linkedinAuthSucceed' ? 'twitter' : 'linkedin'

    // save auth response
    this.setState({
      [authSucceedStateKey]: response,
      error: false
    },
      () => {
        const shouldBlockRouteChange = this.state.needSecondAuth && (!this.state.twitterAuthSucceed || !this.state.linkedinAuthSucceed)

        this.onSuccess({
          response: response,
          shouldBlockRouteChange: shouldBlockRouteChange
        })
      }
    )
  }

  goToDashboard = async () => {
    this.props.updateIsAuthenticated(true)
    let params = new URLSearchParams(history.location.search)
    const actu = params.get('actu')
    const profil = params.get('profil')

    history.push(actu ? '/flux' : profil ? '/profil' : '')

    await this.props.createUserActivityAction(utilsParams.label_activity.USER_APP_LOGGED, 'user logged')
      .catch((err) => console.error(err))
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  async handleAuthentificationLinkedin(response) {
    let res = await this.props.getLinkedinAccessToken({
      code: response.code,
      redirect_uri: `${window.location.origin}/linkedin`,
      email_token: this.state.email_token,
      register: this.state.email_token ? true : false
    })

    if (res) {
      this.onAuthSucceed('linkedinAuthSucceed')(res)

    } else {
      this.onFailed()
    }
  }

  render() {

    return (
      <Switch>
        <Route exact path="/" render={() =>
          <SignIn
            error={this.state.error}
            email={this.state.email}
            password={this.state.password}
            userNetworks={this.state.userNetworks}
            needSecondAuth={this.state.needSecondAuth}
            linkedinAuthSucceed={this.state.linkedinAuthSucceed}
            twitterAuthSucceed={this.state.twitterAuthSucceed}
            handleChange={this.handleChange}
            updateIsAuthenticated={this.props.updateIsAuthenticated}
            onAuthSucceed={this.onAuthSucceed}
            onFailed={this.onFailed}
            goToDashboard={this.goToDashboard}
            handleAuthentificationLinkedin={this.handleAuthentificationLinkedin}
            email_token={this.state.email_token}
            member={this.state.member}
          />
        } />
      </Switch>

    )
  }
}

const mapStateToProps = (state) => {
  return state
};

export default connect(mapStateToProps, actionCreators)(SignInCon);
