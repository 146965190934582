
import React from 'react';
import PropTypes from 'prop-types';
import history from '../../history';
import { Translation } from 'react-i18next';

// Material
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import RootRef from '@material-ui/core/RootRef';

// Assets
import defaultUserIcon from "../../assets/images/default_profile_normal.png";

// Components
import params from "../../utils/params"
import GlobalUtils from '../../utils/global'

// Material styles
import styles from './material-header';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: this.getPathValue(history.location.pathname),
      isMenuOpen: false
    };

    // Refs
    this.menuAnchorRef = React.createRef()

    this.handleChangeTabs = this.handleChangeTabs.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);

    this.DASHBOARD_TAB = 0;
    this.MEMBERS_TAB = 1;
    this.GROUPS_TAB = 2;
    this.SETTINGS_FLUX_TAB = 3;
    this.ANALYTICS_TAB = 4;
  }

  getPathValue(path) {
    let value = 0

    switch (path) {
      case '/':
        value = 0
        break;
      case '/members':
      case '/members/edit':
        value = 1
        break;
      case '/groups':
        value = 2
        break;
      case '/settings-flux':
        value = 3
        break;
      case '/statistiques':
      case '/statistiques/utilisateurs':
      case '/statistiques/groupes':
      case '/statistiques/tags':
      case '/statistiques/contenus':
      case '/statistiques/reseaux':
        value = 4
        break;
      default:
        break;
    }
    return value
  }

  handleChangeTabs = (event, tabValue) => {
    switch (tabValue) {
      case this.DASHBOARD_TAB:
        history.push('/');
        break;
      case this.MEMBERS_TAB:
        history.push('/members');
        break;
      case this.GROUPS_TAB:
        history.push('/groups');
        break;
      case this.SETTINGS_FLUX_TAB:
        history.push('/settings-flux');
        break;
      case this.ANALYTICS_TAB:
        history.push('/statistiques');
        break;

      default:
        break;
    }
    this.setState({ tabValue });
  };

  handleLogoClick() {
    this.handleChangeTabs(null, this.DASHBOARD_TAB);
    this.props.scrollToTop();
  }

  handleProfileMenuOpen = event => {
    this.setState({ isMenuOpen: true });
  };

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  };

  render() {
    const anchorEl = this.menuAnchorRef.current ? this.menuAnchorRef.current : null
    const { classes } = this.props;
    const pathValue = this.getPathValue(history.location.pathname)

    const renderMenu = (
      <Menu
        className="popover_class"
        PaperProps={{
          style: {
            marginLeft: 0,
            marginTop: this.props.user.role !== "User" ? 0 : 10,
            width: 200,
            textAlign: "center",
            borderRadius: 5,
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "visible",
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: -10, horizontal: 'center' }}
        anchorReference={"anchorEl"}
        open={this.state.isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.props.logout}>
          <Translation>
            {
              (t, { i18n }) => {
                t = t('manager', { returnObjects: true }).header

                return (
                  <ListItemText className={classes.menuItemText} inset primary={t.disconnect} />
                )
              }
            }
          </Translation>
        </MenuItem>
      </Menu>
    );

    const { screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed } = GlobalUtils.getUserNameAndAvatar({
      user: this.props.user
    })

    return (
      <div className="header">
        <div className="grid">
          <div className="row">
            <div className="header__app-bar">
              {/* Logo */}
              <div className="cell-3">
                <img onClick={this.handleLogoClick} className="header__logo" alt={process.env.REACT_APP_NAME} src={process.env.REACT_APP_LOGO} />
              </div>

              {/* Navigation */}
              {this.props.role === params.roles.MANAGER ?
                <Translation>
                  {
                    (t, { i18n }) => {
                      t = t('manager', { returnObjects: true }).header

                      return (
                        <div className="cell-6 prepend-1">
                          <Tabs
                            value={[this.DASHBOARD_TAB, this.ANALYTICS_TAB].includes(this.state.tabValue) ? this.state.tabValue : 1}
                            onChange={this.handleChangeTabs}
                            indicatorColor="primary"
                            textColor="primary"
                            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, flexContainer: classes.flexContainer }}
                            variant="fullWidth"
                          >
                            <Tab
                              label={
                                <Typography>{t.dashboard}</Typography>
                              }
                              classes={{
                                root: classes.tabRoot,
                                wrapper: classes.wrapper,
                                selected: classes.tabSelected,
                                labelContainer: classes.tabLabel,
                              }}
                              value={this.DASHBOARD_TAB}
                            />
                            <Tab
                              label={
                                <Typography>{t.settings}</Typography>
                              }
                              classes={{
                                root: classes.tabRoot,
                                wrapper: classes.wrapper,
                                selected: classes.tabSelected,
                                labelContainer: classes.tabLabel,
                              }}
                              value={this.MEMBERS_TAB}
                            />

                            {process.env.REACT_APP_FUNCTION_ANALYTICS === 'true' ?
                              <Tab
                                label={
                                  <Typography>{t.analytics}</Typography>
                                }
                                classes={{
                                  root: classes.tabRoot,
                                  wrapper: classes.wrapper,
                                  selected: classes.tabSelected,
                                  labelContainer: classes.tabLabel,
                                }}
                                value={this.ANALYTICS_TAB}
                              />
                              : ''}
                          </Tabs>
                        </div>

                      )
                    }
                  }
                </Translation>

                : ''}

              {/* User profile */}
              <div className="cell-3">
                <IconButton
                  aria-owns={this.state.isMenuOpen ? 'material-appbar' : null}
                  aria-haspopup="true"
                  disableRipple
                  onClick={this.handleProfileMenuOpen}
                  color="inherit"
                  className={`${classes.avatarButton} noOver `}
                >
                  {/* Name */}
                  {this.props.user.role !== "User" && (
                    <p className="header__username">
                      {
                        screenNameThatShouldBeDisplayed
                      }
                    </p>
                  )}

                  {/* Profile picture */}

                  <RootRef
                    rootRef={this.menuAnchorRef}
                  >
                    <Avatar
                      alt={screenNameThatShouldBeDisplayed}
                      src={profileImageThatShouldBeDisplayed}
                      className={classes.avatar}
                      onError={(e) => { e.target.src = defaultUserIcon }}
                    />
                  </RootRef>

                </IconButton>
              </div>
            </div>

            {![this.DASHBOARD_TAB, this.ANALYTICS_TAB].includes(pathValue) ?
              <div className="header__app-bar-secondary">
                <hr />
                <Translation>
                  {
                    (t, { i18n }) => {
                      t = t('manager', { returnObjects: true }).settings

                      return (
                        <Tabs
                          value={pathValue}
                          onChange={this.handleChangeTabs}
                          indicatorColor="primary"
                          textColor="primary"
                          classes={{
                            root: this.props.classes.secondaryTabs_tabsRoot,
                            indicator: this.props.classes.secondaryTabs_tabsIndicator,
                            flexContainer: this.props.classes.secondaryTabs_flexContainer
                          }}
                          variant="fullWidth"
                        >
                          <Tab
                            label={
                              <Typography>{t.page_users}</Typography>
                            }
                            classes={{
                              root: this.props.classes.secondaryTabs_tabRoot,
                              wrapper: this.props.classes.secondaryTabs_wrapper,
                              selected: this.props.classes.secondaryTabs_tabSelected,
                              labelContainer: this.props.classes.secondaryTabs_tabLabel,
                            }}
                            value={this.MEMBERS_TAB}
                          />
                          <Tab
                            label={
                              <Typography>{t.page_groups}</Typography>
                            }
                            classes={{
                              root: this.props.classes.secondaryTabs_tabRoot,
                              wrapper: this.props.classes.secondaryTabs_wrapper,
                              selected: this.props.classes.secondaryTabs_tabSelected,
                              labelContainer: this.props.classes.secondaryTabs_tabLabel,
                            }}
                            value={this.GROUPS_TAB}
                          />
                          {process.env.REACT_APP_FUNCTION_FLUX === 'true' ?
                            <Tab
                              label={
                                <Typography>{t.page_flux}</Typography>
                              }
                              classes={{
                                root: this.props.classes.secondaryTabs_tabRoot,
                                wrapper: this.props.classes.secondaryTabs_wrapper,
                                selected: this.props.classes.secondaryTabs_tabSelected,
                                labelContainer: this.props.classes.secondaryTabs_tabLabel,
                              }}
                              value={this.SETTINGS_FLUX_TAB}
                            />
                            : ''}
                        </Tabs>

                      )
                    }
                  }
                </Translation>
              </div>
              : ''}
            {renderMenu}
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);