import React from 'react';
import Datatable from "../misc/datatable/datatable.js";

export default function (props) {
  return (
    <div className="members__no-css-rule">
      <Datatable
        handleChangePage={props.handleChangePage}
        togglePopinInvitation={props.togglePopinInvitation}
        deleteUser={props.deleteUser}
        editAccountAction={props.editAccountAction}
        user={props.formatUser(props.user, props.groups, true)}
        users={props.users.map(user => props.formatUser(user, props.groups, props.user.id === user.id))}
        groups={props.groups}
        isVisible={props.isVisible}
      />
    </div>
  );
}