// Libs
import React from "react"
import i18n from '../../../i18n'

// Components
import FluxCardHeader from "./fluxCardHeader"
import FluxCardContent from './fluxCardContent'
import FluxCardFooter from './fluxCardFooter'

// Utils
import params from '../../../utils/params'

const wording = i18n.t('user', { returnObjects: true })

class FluxCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pageX: 0,
      canClick: true,
    }

    this.openPostLink = this.openPostLink.bind(this)
    this.handleMouseStart = this.handleMouseStart.bind(this)
    this.handleMouseEnd = this.handleMouseEnd.bind(this)
  }

  getPostLink() {

    let link = "/"
    let network = this.props.account.network
    let post = this.props.post

    switch (network) {
      case "twitter":
        link = `https://twitter.com/${post.user.screen_name}/status/${post.id_str}`
        break;
      case "linkedin":
        link = `https://www.linkedin.com/feed/update/${post.id}`
        break;
      case "facebook":
        link = `https://www.facebook.com/${this.props.account.screen_name}/posts/${post.post_id}`
        break;
      case "instagram":
        link = post.link
        break;
      default:
        link = "/"
    }

    return link
  }

  async openPostLink() {

    window.open(this.getPostLink())

    await this.props.createFluxPostActivityAction(this.props.id, params.label_activity.FLUX_POST_CLICK, 'user clicked on the post')
      .catch((err) => console.error(err))
  }

  handleMouseStart(e) {

    // if target is an interactive element we prevent from triggering click
    if (e.target.tagName === "A" || e.target.classList.contains("js__interactive-media")) {
      this.setState({ canClick: false })
    }

    e.preventDefault()

    // Saving the first position of the user when he start touching the phone
    this.setState({
      pageX: e.clientX,
    })
  }

  handleMouseEnd(e) {

    let x = e.clientX

    // slider won't slide so we can open link
    if (Math.abs(this.state.pageX - x) < 50 && this.state.canClick) {
      this.openPostLink()
      this.setState({ canClick: true })
      return;
    }

    this.setState({ canClick: true })
  }

  render() {

    return (
      <div className="flux-card">
        {this.props.options && ((this.props.options.isTwitter && this.props.account.network === 'twitter') || (this.props.options.isLinkedin && this.props.account.network === 'linkedin')) ?
          <div className="flux-card__connect">
            <span>{this.props.options.isTwitter ? wording.text_log_twitter : wording.text_log_linkedin}</span>
          </div>
          : ''}
        <div
          onMouseDown={this.handleMouseStart}
          onMouseUp={this.handleMouseEnd}
          className="flux-card__container"
        >
          <FluxCardHeader {...this.props} />
          <FluxCardContent {...this.props} />
        </div>
        <FluxCardFooter {...this.props} />
      </div>
    )
  }
}

export default FluxCard