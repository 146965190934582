import types from '../utils/params.js'

let defaultState = {
  user: null,
  users: [],
  loading: false,
  header: {
    direction: null,
    position: 0
  },
  tweetits_draft: [],
  tweetits_suggested: [],
  isLoading_draft: true,
  isLoading_suggested: true,
  isAuthenticated: false
};

const mainReducer = (state = defaultState, action) => {
  switch (action.type) {

    case types.UPDATE_LOADING: {
      return Object.assign({}, state, {
        loading: action.loading
      });
    }

    case types.ADD_USER: {
      return Object.assign({}, state, {
        users: [...state.users, action.user]
      });
    }

    case types.UPDATE_USERS: {
      return Object.assign({}, state, {
        users: action.users
      });
    }

    case types.ADD_GROUP: {
      return Object.assign({}, state, {
        groups: [...state.groups, action.group]
      });
    }

    case types.UPDATE_GROUPS: {
      return Object.assign({}, state, {
        groups: action.groups
      });
    }

    case types.UPDATE_TAGS: {
      return Object.assign({}, state, {
        tags: action.tags
      });
    }

    case types.ADD_TAG: {
      return Object.assign({}, state, {
        tags: [...state.tags, action.tag]
      });
    }

    case types.UPDATE_USER: {
      if (action.currentUser) {
        return Object.assign({}, state, {
          user: action.user
        });
      } else {
        return Object.assign({}, state, {
          users: state.users.map(user => {
            if (action.user && user.id === action.user.id) {
              return action.user;
            } else {
              return user;
            }
          })
        });
      }
    }

    case types.UPDATE_GROUP: {
      return Object.assign({}, state, {
        groups: state.groups.map(group => {
          if (action.group && group.id === action.group.id) {
            return action.group;
          } else {
            return group;
          }
        })
      });
    }

    case types.UPDATE_TWEETITS: {
      return Object.assign({}, state, {
        tweetits_draft: action.tweetits.filter(tw => tw.status === "draft"),
        tweetits_suggested: action.tweetits.filter(tw => tw.status === "suggested"),
      });
    }

    case types.UPDATE_DRAFT_TWEETITS: {
      return Object.assign({}, state, {
        tweetits_draft: action.concat ? state.tweetits_draft.concat(action.tweetits) : action.tweetits,
        isLoading_draft: false
      });
    }

    case types.UPDATE_SUGGESTED_TWEETITS: {
      return Object.assign({}, state, {
        tweetits_suggested: action.concat ? state.tweetits_suggested.concat(action.tweetits) : action.tweetits,
        isLoading_suggested: false
      });
    }

    case types.REMOVE_TWEETIT: {
      return Object.assign({}, state, {
        tweetits_draft: state.tweetits_draft.filter(draft => draft.id !== action.post_id),
        tweetits_suggested: state.tweetits_suggested.filter(suggested => suggested.id !== action.post_id)
      });
    }

    case types.ADD_TWEETIT: {
      let status = null, tweetits = null;

      status = action.tweetit[0].status
      tweetits = status === 'draft' ? [...state.tweetits_draft].concat(action.tweetit) : [...state.tweetits_suggested].concat(action.tweetit)

      if (status === "draft") {
        return Object.assign({}, state, {
          tweetits_draft: tweetits,
        });
      } else if (status === "suggested") {
        return Object.assign({}, state, {
          tweetits_suggested: tweetits,
        });
      }
      return null

    }

    case types.REPLACE_TWEETIT: {
      /* Supprime l'ancien tweetit */
      let newState = Object.assign({}, state, {
        tweetits_draft: state.tweetits_draft.filter(draft => draft.id !== action.post_id),
        tweetits_suggested: state.tweetits_suggested.filter(suggested => suggested.id !== action.post_id),
      });

      /* Ajoute le nouveau */
      if (action.tweetit[0].status === 'draft') {
        newState = Object.assign({}, newState, {
          tweetits_draft: [...newState.tweetits_draft, action.tweetit[0]]
        });
      } else if (action.tweetit[0].status === 'suggested') {
        newState = Object.assign({}, newState, {
          tweetits_suggested: [...newState.tweetits_suggested, action.tweetit[0]]
        });
      }

      return newState;
    }

    case types.ADD_TWEETITS: {
      if (action.status === "draft") {
        return Object.assign({}, state, {
          tweetits_draft: action.tweetits.concat(state.tweetits_draft),
        });
      } else if (action.status === "suggested") {
        return Object.assign({}, state, {
          tweetits_suggested: action.tweetits.concat(state.tweetits_suggested),
        });
      }
      return null

    }

    case types.UPDATE_TWEETIT: {
      return Object.assign({}, state, {
        tweetits_draft: state.tweetits_draft.map(draft => {
          if (draft._id === action.tweetit._id) {
            return Object.assign(draft, action.tweetit);
          } else {
            return draft
          }
        })
      });
    }

    case types.REMOVE_TAG: {
      return Object.assign({}, state, {
        tags: state.tags.filter(tag => tag.id !== action.tag_id),
      });
    }

    case types.REMOVE_GROUP: {
      return Object.assign({}, state, {
        groups: state.groups.filter(group => group.id !== action.group_id),
      });
    }

    case types.UPDATE_ISAUTHENTICATED: {
      return Object.assign({}, state, {
        isAuthenticated: action.isAuthenticated
      });
    }

    default:
      return state;
  }
};

export default mainReducer;
