import React from 'react'

const FollowIcon = () => (
  <svg width="16" height="16" className="icon__follow" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7C6.65685 7 8 5.65685 8 4C8 2.34315 6.65685 1 5 1C3.34315 1 2 2.34315 2 4C2 5.65685 3.34315 7 5 7Z" />
    <path d="M10 13C10 10.2663 7.76142 8 5 8C2.23858 8 0 10.2663 0 13C0 15.7337 10 15.7337 10 13Z" />
    <path d="M12 3H14V5H16V7H14V9H12V7H10V5H12V3Z" />
  </svg>
)

export default FollowIcon