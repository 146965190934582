const styles = theme => ({
  root: {
    width: '100%'
  },
  tableWrapper: {
    overflow: "hidden",
    margin: "0 40px",
    paddingBottom: "20px"
  },
  toolBar: {
    margin: "0 40px",
  },
  name: {
    "& div": {
      float: "left",
      margin: "20px 0",
      width: 35,
      height: 35,
    },
    "& p": {
      margin: "25px 0 0 20px",
      float: "left",
      fontSize: 15,
      fontWeight: 600,
      color: theme.greyBlue,
    },
    paddingLeft: 20,
  },
  moreButton: {
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  menuItem: {
    textAlign: "center",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    fontSize: "15px !important",
    color: "#697e8b"
  },
  dialogContentText: {
    padding: "50px 100px",
    textAlign: "center",
    fontSize: 15,
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#525252",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    margin: "0 0 50px 0",
  },
  dialogBtnRemove: {
    margin: "0 0 0 20px"
  },
  groupChip: Object.assign(theme.groupChip, {
    marginBottom: 5,
    '&:nth-child(1)': {
      marginTop: "5px",
    }
  }),
  itemEnter: {
    opacity: 0,
    transform: "translate(0, 15px)",
  },
  itemEnterActive: {
    opacity: 1,
    transform: "translate(0, 0)",
    transition: "opacity .25s, transform .5s",
  },
  itemExit: {
    opacity: 1,
  },
  itemExitActive: {
    opacity: 0,
    transition: "opacity .3s",
  },
  itemExitDone: {
    opacity: 0,
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: "#f5f8fa",
    },
  },
  cell: {
    border: "none",
    '&:nth-child(4)': {
      maxWidth: "300px",
    }
  },

  cell__availableNetwork: {
    display: "inline-block",
    marginRight: 15,

    "&:last-child": {
      marginRight: 0
    }
  },
  // Tooltip
  tooltipUser: {
    maxHeight: 400,
    overflow: "hidden auto",
    pointerEvents: "unset",
    opacity: 1
  },
  tooltip: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    lineHeight: 1.5,
  },
  tooltipTitle: {
    display: "inline-flex",
  },
  tooltipContainer: {
    width: "100%",
    top: -13,
    position: "absolute",
    paddingRight: 16,
    textAlign: "center"
  },
});

export default styles