// Libs
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import i18n from '../../../i18n'

// Assets
import IconCalendar from '../../../assets/icons/calendar'

const wording = i18n.t('manager', { returnObjects: true }).analytics.datepicker

class DatePicker extends React.Component {
  constructor(props) {
    super();
    this.state = {
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.,
      isDisplay: false
    };

    // Ref
    this.popinRef = React.createRef()

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (this.popinRef.current && !this.popinRef.current.contains(e.target) && this.state.isDisplay) {
      this.togglePicker()
    }
  }


  getInitialState() {
    return { from: null, to: null, enteredTo: null };
  }

  handleDayClick(day) {
    if (DateUtils.isDayAfter(day, new Date())) return

    let { from, to } = this.state;
    if (from && to) {
      from = null
    }

    if (!from) {
      this.setState({ from: day, to: null, enteredTo: null });
    } else {
      if (DateUtils.isDayAfter(from, day)) this.setState({ from: day, to: from, enteredTo: from });
      else this.setState({ to: day, enteredTo: day });
    }
  }

  handleDayMouseEnter(day) {
    if (DateUtils.isDayAfter(day, new Date()) || (this.state.from && this.state.to)) return

    this.setState({
      enteredTo: day,
    });
  }

  togglePicker() {
    this.setState((prevState) => ({
      isDisplay: !prevState.isDisplay
    }), () => {
      let date = null

      if (this.state.from && this.state.to) {
        let from = moment(this.state.from).toDate(), to = moment(this.state.to).toDate()
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);

        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(0);

        date = { from: from, to: to }
      }

      if (!this.state.isDisplay) this.props.applyDate(date)
    })
  }

  reset() {
    this.setState({ from: null, to: null })
  }

  render() {
    const { from, to, enteredTo } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: null, after: new Date() };
    const selectedDays = [from, { from, to: enteredTo }];
    return (
      <div className="datepicker">
        <div className="datepicker__button" onClick={this.togglePicker}>
          {!to && !from ?
            wording.label
            : ''}
          {from && !to && !enteredTo ?
            `${moment(from).format('Do MMM YYYY')} à`
            : ''}
          {from && (to || enteredTo) ?
            `${moment(from).format('Do MMM YYYY')} à ${moment((to || enteredTo)).format('Do MMM YYYY')}`
            : ''}
          <IconCalendar />
        </div>

        {this.state.isDisplay ?
          <div className="datepicker__popin" ref={this.popinRef}>
            <DayPicker
              className="Range color-primary-i"
              numberOfMonths={1}
              months={['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']}
              selectedDays={selectedDays}
              disabledDays={disabledDays}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              onDayMouseEnter={this.handleDayMouseEnter}
              weekdaysShort={['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']}
              locale="fr"
              firstDayOfWeek={1}
            />

            <div className="datepicker__buttons">
              <div className="datepicker__button-reset" onClick={this.reset}>
                {wording.btn_reset}
              </div>
              <div className={`datepicker__button-validate background-color-primary ${this.state.from && this.state.to ? 'disable' : ''}`} onClick={this.togglePicker}>
                {wording.btn_save}
              </div>
            </div>
          </div>
          : ''}

      </div>
    );
  }
}

export default DatePicker