import React from 'react';

// Init component.
function IconEmoji() {
  return (
    <svg className="icon icon__emoji" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM6.44871 11.6838C6.27406 11.1599 5.70774 10.8767 5.1838 11.0513C4.65986 11.226 4.3767 11.7923 4.55134 12.3163C5.18932 14.2302 7.36637 16 10 16C12.6337 16 14.8107 14.2302 15.4487 12.3163C15.6234 11.7923 15.3402 11.226 14.8163 11.0513C14.2923 10.8767 13.726 11.1599 13.5513 11.6838C13.1893 12.7699 11.7846 14 10 14C8.21541 14 6.81074 12.7699 6.44871 11.6838ZM9 8C9 9.10457 8.10457 10 7 10C5.89543 10 5 9.10457 5 8C5 6.89543 5.89543 6 7 6C8.10457 6 9 6.89543 9 8ZM13 10C14.1046 10 15 9.10457 15 8C15 6.89543 14.1046 6 13 6C11.8954 6 11 6.89543 11 8C11 9.10457 11.8954 10 13 10Z" />
    </svg>
  );
}

export default IconEmoji;