import React from 'react';

// Init component.
function IconPhone(props) {
  return (
    <svg className="icon__phone" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path className="stroke-primary" d="M15.1875 1.3125H5.81248C4.77695 1.3125 3.93748 2.13518 3.93748 3.15V17.85C3.93748 18.8648 4.77695 19.6875 5.81248 19.6875H15.1875C16.223 19.6875 17.0625 18.8648 17.0625 17.85V3.15C17.0625 2.13518 16.223 1.3125 15.1875 1.3125Z" strokeWidth="2.63158" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke-primary" d="M10.5 15.75H10.5082" strokeWidth="2.63158" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPhone;
