import React from 'react';

// Init component.
function IconMouse(props) {
  return (
    <svg className="icon__mouse" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="stroke-primary" d="M1.3125 1.3125L8.42104 18.375L10.9447 10.9447L18.375 8.42104L1.3125 1.3125Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path className="stroke-primary" d="M11.8125 11.8125L17.0625 17.0625" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconMouse;
