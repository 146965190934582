import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from './locales/fr/messages.json'
import en from './locales/en/messages.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: en,
  fr: fr
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

// i18n.setDefaultNamespace('en')
// if (process.env.REACT_APP_LANG_DEFAULT) i18n.changeLanguage(process.env.REACT_APP_LANG_DEFAULT)
// if (process.env.REACT_APP_LANG_DEFAULT) i18n.setDefaultNamespace(process.env.REACT_APP_LANG_DEFAULT)

export default i18n;