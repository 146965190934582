import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n'

// Material
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

// Components
import params from '../../../utils/params.js';

// Material styles
import styles from "./material-user-tweetit-form-refusal"
import EventEmitter from '../../../utils/EventEmitter.js';

const wording = i18n.t('user', { returnObjects: true })

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FullScreenRefusalDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refusalMessage: "",
      posting: false,
    };
  }

  handleChange = event => {
    this.setState({ refusalMessage: event.target.value });
  };

  editTweetit() {
    this.setState({ posting: true }, async () => {
      let res = await this.props.editTweetitUserAction(this.props.formData.id, {
        refusalMessage: this.state.refusalMessage,
        media: [],
        mediaType: this.props.formData.mediaType,
        status: params.statuses.REMOVED,
        type: this.props.formData.type
      })

      this.setState({
        refusalMessage: "",
      }, () => {
        EventEmitter.emit(params.EVENT_OPEN_SNACKBAR,
          {
            message: res && res.error ? res.error : wording.post.text_refuse_confirm,
            style: (res && res.error) ? "error" : "success"
          })
        this.props.getTweetitsUserAction(this.props.tweetits.filter(t => t.status === params.statuses.SUGGESTED).length);
        this.props.handleClose();
        this.setState({ posting: false })
      })
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="user-tweetit-form-refusal__no-css-rule">
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.actions}>
              <IconButton className={classes.dialogClose} onClick={this.props.handleClose}>
                <ArrowLeftRoundedIcon />
              </IconButton>
              <Button variant="contained" color="secondary" className={classes.buttonSubmit} disabled={this.state.posting}
                onClick={(e) => this.editTweetit(e, params.statuses.REMOVED)}>
                {this.state.posting ? (
                  <CircularProgress className={this.props.classes.progress} size={20} />
                ) : ""}

                {wording.post.button_refuse}
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container className={classes.rootGrid} spacing={16} >
            <Grid item xs={12} style={{ padding: 0 }} >
              <Typography className={classes.title}>
                {wording.post.button_refuse}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: 0 }} >
              <TextField
                id="outlined-multiline-flexible"
                label=""
                multiline={true}
                rows="10"
                placeholder={wording.post.text_refuse_comment}
                rowsMax="10"
                value={this.state.refusalMessage}
                onChange={this.handleChange}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Dialog>
      </div>
    );
  }
}

FullScreenRefusalDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenRefusalDialog);