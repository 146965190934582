import React from 'react';

// Init component.
function EditIcon() {
  return (
    <svg className="icon__edit" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6737 1.88138L14.2222 0.422776C13.6612 -0.140925 12.7504 -0.140925 12.1875 0.422776L10.7971 1.81998L14.0519 5.09068L15.6737 3.46092C16.1088 3.02368 16.1088 2.31859 15.6737 1.88138ZM9.94919 2.67019L13.204 5.94087L4.96526 14.2199L1.71234 10.9492L9.94919 2.67019ZM0.457113 15.9892C0.190506 16.0544 -0.0501753 15.8143 0.00905723 15.5464L0.831082 11.8348L4.084 15.1055L0.457113 15.9892Z" />
    </svg>
  );
}

export default EditIcon;