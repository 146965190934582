// Libs
import React from 'react';
import Proptypes from "prop-types"

class Slide extends React.Component {
  render() {
    return (
      <div
        className="slider__slide"
        style={{
          left: this.props.left
        }}
      >
        <div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Slide.propTypes = {
  left: Proptypes.number,
}

export default Slide
