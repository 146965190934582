// Libs
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactPhoneInput from 'react-phone-input-2';
import * as EmailValidator from 'email-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Translation } from 'react-i18next';
import i18n from '../../../i18n'

// Material-Ui
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close';
import Chip from "@material-ui/core/Chip";

// Import assets
import IconcloseThin from '../../../assets/icons/close-thin'
import IconSend from '../../../assets/icons/send'
import AlertInfoIcon from '../../../assets/icons/alert-info'
import IconRadio from '../../../assets/icons/radio'
import IconRadioChecked from '../../../assets/icons/radio-checked'
import IconInsertLink from '../../../assets/icons/insert-link'
import IconCopy from '../../../assets/icons/copy'
import TrashIcon from '../../../assets/icons/trash'
import Congrats from '../../../assets/images/congrats.svg'

// Import components
import SelectDialog from '../../misc/selectDialog/select-dialog';
import global from '../../../utils/global'
import ConfirmDialog from '../../misc/confirm-dialog';

// Utils
import EventEmitter from '../../../utils/EventEmitter';
import params from '../../../utils/params';

class MembersInvitation extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tab: "sms",
      isCongrats: false,
      sms_sent: 0,
      sms_error_message: "",
      users: [{ role: 'User' }, { role: 'User' }, { role: 'User' }, { role: 'User' }],
      groups: this.props.groups.map(group => Object.assign({}, group)),
      email_domain: '',
      radio_email: 'all',
      role: 'User',
      expire: '7',
      confirmIsOpen: false,
      inviteIdDisable: null,
      invite_links_created: [],
      loading: false
    }
    this.popinRef = React.createRef();
    this.changeTab = this.changeTab.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleChangeUser = this.handleChangeUser.bind(this)
    this.handleChangeInvite = this.handleChangeInvite.bind(this)
    this.deleteItemGroup = this.deleteItemGroup.bind(this)
    this.resetData = this.resetData.bind(this)
    this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this)
    this.copyLink = this.copyLink.bind(this)
    this.getAllInvite = this.getAllInvite.bind(this)
    this.disableInvite = this.disableInvite.bind(this)
    this.createInvite = this.createInvite.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    // Get all the invite links created
    this.getAllInvite()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  changeTab(e) {
    this.setState({ tab: e.currentTarget.getAttribute('data-tab') })
  }

  handleClickOutside(e) {
    if (this.popinRef.current && !this.popinRef.current.contains(e.target) && (e.target.className === 'members-invitation')) {
      this.props.togglePopinInvitation();
    }
  }

  handleChangeUser(type, index) {
    return (e) => {
      // Get users
      let users = this.state.users

      if (type === 'user-name') {
        let user = users[index]
        user.name = e.currentTarget.value
        users[index] = user

      } else if (type === 'user-phone') {

        let user = users[index]
        user.phone = e
        users[index] = user

      } else if (type === 'user-role') {
        let user = users[index]
        user.role = e.target.value
        users[index] = user
      }

      if (index === users.length - 1) users.push({ role: 'User' })

      this.setState({ users })
    }
  }

  handleChangeInvite(type) {
    return (data) => {
      if (type === 'groups') {
        this.setState({ groups: data }, () => { this.props.closeSelectDialog() })

      } else if (type === 'email_domain') {
        this.setState({ email_domain: data.currentTarget.value })

      } else if (type === 'radio_email') {
        this.setState({ radio_email: data.currentTarget.value, email_domain: data.currentTarget.value === 'all' ? null : '@' })

      } else if (type === 'role') {
        let role = data.target.value
        this.setState({ role: role, groups: (role === 'Manager') && (this.state.tab === 'link') ? this.props.groups.map(group => Object.assign({}, group)) : this.state.groups })

      } else if (type === 'expire') {
        this.setState({ expire: data.target.value })
      }
    }
  }

  deleteItemGroup = (e, index) => {
    let groupsItemSelectedIndex = 0
    // unselect item with groupsItemSelectedIndex === index
    this.setState({
      groups: this.state.groups.map((groupsItem, groupsItemIndex) => {
        if (groupsItem.selected === true) {
          if (groupsItemSelectedIndex === index) groupsItem.selected = false
          groupsItemSelectedIndex++
        }
        return groupsItem
      })
    })
  };

  toggleConfirmDialog(e) {
    this.setState({
      confirmIsOpen: !this.state.confirmIsOpen,
      inviteIdDisable: e && e.currentTarget && e.currentTarget.getAttribute('data-id') ? e.currentTarget.getAttribute('data-id') : this.state.inviteIdDisable
    })
  }

  copyLink(e) {
    try {
      document.getElementById(e.currentTarget.getAttribute('data-id')).select();
      document.execCommand("copy");
      if (window.getSelection) { window.getSelection().removeAllRanges(); }
      else if (document.selection) { document.selection.empty(); }

      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.invit_user.popin_message.link_copy })
    } catch (err) {
      console.error(err)
    }
  }

  resetData() {
    this.setState({
      tab: "sms",
      isCongrats: false,
      users: [{ role: 'User' }, { role: 'User' }, { role: 'User' }, { role: 'User' }],
      groups: this.props.groups.map(group => Object.assign({}, group)),
      email_domain: '',
      radio_email: 'all',
      role: 'User',
      expire: '7'
    })
  }

  async getAllInvite() {
    // Get all the invite links created
    let res = await this.props.getAllInviteAction()

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
    } else if (res) {
      this.setState({ invite_links_created: res })
    }
  }

  async disableInvite(e) {
    let res = await this.props.editInviteAction({ id: this.state.inviteIdDisable, disable: 1 })
    this.toggleConfirmDialog();

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      return
    }
    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.invit_user.popin_message.link_delete })
    this.getAllInvite()
  }

  async createInvite() {
    // Check the values entered bu the user
    if (this.state.tab === 'sms' && !this.isInviteSmsValid()) return
    else if (this.state.tab === 'link' && !this.isInviteLinkValid()) return

    await this.setState({ loading: true })

    let data = {
      type: this.state.tab,
      role: this.state.role,
      users: this.state.users.filter(u => u.name && (u.phone && (/^[0-9]{5,}$/g.test(u.phone.replace(/\s/g, ''))))),
      email_domain: !this.state.radio_email && this.state.email_domain ? this.state.email_domain : null,
      groups: this.state.groups && this.state.groups.length ? this.state.groups.filter(g => g.selected).map(g => g.id) : [],
      disable_time: parseInt(this.state.expire, 10)
    }

    let res = await this.props.createInviteAction(data)

    this.setState({ loading: false })

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
      return
    }

    if (this.state.tab === "link") {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.invit_user.popin_message.link_create })
      this.getAllInvite()
    } else {
      this.setState({
        isCongrats: true,
        sms_sent: res.sms_sent,
        sms_error_message: typeof (res.sms_error_message) === 'string' ? res.sms_error_message : JSON.stringify(res.sms_error_message)
      })
    }
  }

  isInviteSmsValid() {

    if (!this.state.radio_email && this.state.email_domain && (!this.state.email_domain.startsWith('@') || !EmailValidator.validate(`test${this.state.email_domain}`))) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: "Domaine de l'email incorrect (@company.com)", style: "error" })
      return false
    }

    for (let user of this.state.users) {
      if (user.name && user.phone && (/^[0-9]{5,}$/g.test(user.phone.replace(/\s/g, '')))) {
        return true
      }
    }
    EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.invit_user.popin_message.users_empty, style: "error" })
    return false
  }

  isInviteLinkValid() {
    if (!this.state.radio_email && this.state.email_domain && (!this.state.email_domain.startsWith('@') || !EmailValidator.validate(`test${this.state.email_domain}`))) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.invit_user.popin_message.email_error, style: "error" })
      return false
    }

    return true
  }

  buildFormMail(options) {
    return (
      <React.Fragment>
        <div className="members-invitation__radio">
          <div className="members-invitation__radio-icon">
            {this.state.radio_email === "all" ? <IconRadioChecked /> : <IconRadio />}
          </div>
          <input name="acceptEmail" id="allEmail" type="radio" className="members-invitation__radio-input" value="all" onChange={this.handleChangeInvite('radio_email')} />
          <label htmlFor="allEmail" className="members-invitation__radio-label">{options.t.accept_email}</label>
        </div>

        <div className="members-invitation__radio">
          <div className="members-invitation__radio-icon">
            {this.state.radio_email === "" ? <IconRadioChecked /> : <IconRadio />}
          </div>
          <input name="acceptEmail" id="restrictedEmail" type="radio" className="members-invitation__radio-input" value="" onChange={this.handleChangeInvite('radio_email')} />
          <label htmlFor="restrictedEmail" className="members-invitation__radio-label">{options.t.accept_email2}</label>
          <input type="text" placeholder="@company.com" className={`members-invitation__input ${this.state.radio_email === 'all' ? 'disable' : ''}`} value={this.state.email_domain || ''} onChange={this.handleChangeInvite('email_domain')} />
        </div>
      </React.Fragment>
    )
  }

  buildGroups(options) {
    return (
      <Select
        onClick={this.props.openSelectDialog}
        value={
          this.state.groups
            .filter(g => g.selected)
            .map(g => g.label)
        }
        classes={{
          root: `${this.props.classes.bootstrapRootFullWidth} members-invitation__sms-groups-select`,
          select: this.props.classes.bootstrapInput,
          selectMenu: this.props.classes.bootstrapSelect,
        }}
        renderValue={selected => {
          if (selected.length === 0) {
            return options.t.no_group;
          }
          return selected.map((groupLabel, index) =>
            <Chip key={index} label={groupLabel} className={this.props.classes.groupChip}
              onDelete={e => this.deleteItemGroup(e, index)}
              deleteIcon={<CloseIcon />} />
          )
        }}
        multiple displayEmpty disableUnderline
        open={false} onOpen={() => { }} onClose={() => { }}
      />
    )
  }

  buildUsers(options) {
    return this.state.users.map((user, key) => {
      return (
        <div className="members-invitation__table-row" key={key}>
          {/* Name */}
          <div className="members-invitation__table-col">
            <input className="members-invitation__input" type="text" placeholder="Neil Amstrong" value={user.name || ''} onChange={this.handleChangeUser('user-name', key)} />
          </div>

          {/* Phone */}
          <div className="members-invitation__table-col">
            <ReactPhoneInput label="Numéro de téléphone" id="phone" name="phone"
              placeholder="33 6 01"
              value={user.phone || '33'}
              onChange={this.handleChangeUser('user-phone', key)}
              defaultCountry='fr'
              disableDropdown={true}
              inputClass="members-invitation__input phone"
            />
          </div>

          {/* Role */}
          <div className="members-invitation__table-col">
            <Select
              disableUnderline
              value={user.role}
              classes={{
                root: `${this.props.classes.bootstrapRootFullWidth} members-invitation__select-role`,
                select: this.props.classes.bootstrapInput,
                selectMenu: this.props.classes.bootstrapSelect,
              }}
              onChange={this.handleChangeUser('user-role', key)}
            >
              <MenuItem key="User" value="User">{options.t.role_user}</MenuItem>
              <MenuItem key="Manager" value="Manager"> {options.t.role_manager}</MenuItem>
            </Select>
          </div>
        </div>
      )
    })
  }

  buildLinksCreated(options) {
    return this.state.invite_links_created.map((link, index) => {

      let groups = link.groups && link.groups.length && (link.groups.map(g => g.label).join(', '))
      groups = groups ? ` groupe ${groups} - ` : ' aucun groupe - '

      return (
        <div className="members-invitation__link-created-item" key={index}>
          <div className="members-invitation__link-created-bloc">
            <input
              value={`${window.location.origin}/invite?t=${link.invite_token}`}
              readOnly
              className="members-invitation__link-created-value members-invitation__input"
              id={`g-l-${index}`}
            />
            <button type="button" className="members-invitation__link-created-action copy" data-id={`g-l-${index}`} onClick={this.copyLink}>
              <IconCopy />
            </button>
            <button type="button" className="members-invitation__link-created-action delete" data-id={link.id} onClick={this.toggleConfirmDialog}>
              <TrashIcon />
            </button>
          </div>
          <div className="members-invitation__link-created-subtext">
            Accès {link.role === 'User' ? options.t.role_user : options.t.role_manager} -
            {groups}
            {link.email_domain ? ` email en ${link.email_domain} - ` : 'tous les emails - '}
            crée il y a {global.giveTimeFromADate(link.created_at)} -
            expire dans {global.giveTimeFromADate(link.disable_date, true)}
          </div>
        </div>
      )
    })
  }

  buildExpireTime(options) {
    return (
      <Select
        disableUnderline
        value={this.state.expire}
        classes={{
          root: this.props.classes.bootstrapRootFullWidth,
          select: this.props.classes.bootstrapInput,
          selectMenu: this.props.classes.bootstrapSelect,
        }}
        onChange={this.handleChangeInvite('expire')}
      >
        <MenuItem key="1" value="1">{options.t["1_day"]}</MenuItem>
        <MenuItem key="7" value="7"> {options.t["7_days"]}</MenuItem>
        <MenuItem key="30" value="30"> {options.t["30_days"]}</MenuItem>
      </Select>
    )
  }
  render() {
    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).settings.invit_user

            return (
              <div className="members-invitation">{/* className linked to handleClickOutside function*/}
                <div className="members-invitation__container" ref={this.popinRef}>

                  {/* TITLE OF THE POPIN*/}
                  <div className="members-invitation__header">
                    <h1 className="members-invitation__header-title">{this.state.isCongrats || t.title}</h1>
                    <button onClick={this.props.togglePopinInvitation} className="members-invitation__header-close">
                      <IconcloseThin />
                    </button>
                  </div>
                  {!this.state.isCongrats ?
                    <>
                      {/* NAVIGATION */}
                      <div className="members-invitation__nav">
                        <button className={`members-invitation__nav-item ${this.state.tab === 'sms' ? 'color-primary' : ''}`} onClick={this.changeTab} data-tab="sms">
                          {t.tab_sms}
                        </button>
                        <button className={`members-invitation__nav-item ${this.state.tab === 'link' ? 'color-primary' : ''}`} onClick={this.changeTab} data-tab="link">
                          {t.tab_link}
                        </button>
                        <span className={`members-invitation__nav-bar ${this.state.tab === 'link' ? 'right' : ''} background-color-primary`}></span>
                      </div>

                      <PerfectScrollbar
                        option={{
                          wheelSpeed: 1,
                          wheelPropagation: true,
                          swipeEasing: false,
                          minScrollbarLength: 5
                        }}
                      >
                        <div className={`members-invitation__invite ${this.state.tab === "sms" ? 'padding' : ''}`}>
                          {/* INVITE BY SMS */}
                          {this.state.tab === "sms" ?
                            <div className="members-invitation__sms">

                              {/* TABLE */}
                              <div className="members-invitation__table">

                                {/* TABLE HEAD*/}
                                <div className="members-invitation__table-row head">
                                  <div className="members-invitation__table-col">{t.label_name}</div>
                                  <div className="members-invitation__table-col">{t.label_phone}</div>
                                </div>

                                {/* TABLE USERS DATA*/}
                                {this.buildUsers({ t })}
                              </div>

                              {/* GROUPS */}
                              <div className="members-invitation__sms-groups">
                                <p className="members-invitation__text">{t.add_group}</p>
                                <div className="members-invitation__select">
                                  {this.buildGroups({ t })}
                                </div>
                              </div>

                              {/* MAIL FORMAT */}
                              {this.buildFormMail({ t })}

                              {/* EXPIRE TIME */}
                              <div className="members-invitation__expire">
                                <p className="members-invitation__text">{t.time_expire}</p>
                                <div className="members-invitation__select">
                                  {this.buildExpireTime({ t })}
                                </div>
                              </div>

                              {/* FOOTER SMS */}
                              <div className="members-invitation__sms-footer">
                                <div className="members-invitation__sms-footer-info">
                                  <AlertInfoIcon />
                                  <p>{t.info_sms}</p>
                                </div>
                                <button className={`members-invitation__sms-footer-submit ${this.state.loading ? 'loading' : ''} background-color-primary`} onMouseEnter={global.manageHover} onMouseLeave={global.manageHover} onClick={this.createInvite}>
                                  <IconSend />
                                  {t.button_sms}

                                  {this.state.loading &&
                                    <span className="members-invitation__loading">
                                      <CircularProgress size={20} />
                                    </span>
                                  }
                                </button>
                              </div>
                            </div>
                            : ''}

                          {/* INVITE BY LINK */}
                          {this.state.tab === "link" ?
                            <div className="members-invitation__link">

                              {/* ROLE */}
                              <div className="members-invitation__link-role">
                                <p className="members-invitation__text">{t.text1}</p>
                                <div className="members-invitation__select">
                                  <Select
                                    disableUnderline
                                    value={this.state.role}
                                    classes={{
                                      root: `${this.props.classes.bootstrapRootFullWidth} members-invitation__select-role`,
                                      select: this.props.classes.bootstrapInput,
                                      selectMenu: this.props.classes.bootstrapSelect,
                                    }}
                                    onChange={this.handleChangeInvite('role')}
                                  >
                                    <MenuItem key="User" value="User">{t.role_user}</MenuItem>
                                    <MenuItem key="Manager" value="Manager"> {t.role_manager}</MenuItem>
                                  </Select>
                                </div>
                                <p className="members-invitation__text"> {t.text2}</p>
                              </div>

                              {/* GROUPS */}
                              <div className="members-invitation__link-groups">
                                <p className="members-invitation__text">{t.text3}</p>
                                <div className={`members-invitation__select ${(this.state.role === 'Manager') && (this.state.tab === 'link') ? 'disable' : ''}`}>
                                  {this.buildGroups({ t })}
                                </div>
                              </div>

                              {/* MAIL FORMAT */}
                              {this.buildFormMail({ t })}

                              {/* EXPIRE TIME */}
                              <div className="members-invitation__expire">
                                <p className="members-invitation__text">Délai d'expiration du lien</p>
                                <div className="members-invitation__select">
                                  {this.buildExpireTime({ t })}
                                </div>
                              </div>

                              {/* FOOTER LINK */}
                              <div className="members-invitation__link-footer">
                                <button className={`members-invitation__link-footer-submit ${this.state.loading ? 'loading' : ''} background-color-primary`} onMouseEnter={global.manageHover} onMouseLeave={global.manageHover} onClick={this.createInvite}>
                                  <IconInsertLink />
                                  {t.button_link}
                                  {this.state.loading &&
                                    <span className="members-invitation__loading">
                                      <CircularProgress size={20} />
                                    </span>
                                  }
                                </button>
                                <p className="members-invitation__link-footer-info">
                                  <AlertInfoIcon />
                                  {t.info_link}
                                </p>
                              </div>

                              {/* CREATED LINKS */}
                              <div className="members-invitation__link-created">
                                <div className="members-invitation__link-created-hr"></div>

                                <div className="members-invitation__text">{t.invite_link_created}</div>
                                {this.buildLinksCreated({ t })}
                              </div>
                            </div>
                            : ''}
                        </div>
                      </PerfectScrollbar>
                    </>
                    :
                    <div className="members-invitation__congrats">

                      {this.state.sms_sent > 0 ?
                        <>
                          <img className="members-invitation__congrats-illu" src={Congrats} alt="congrats" />
                          <p className="members-invitation__congrats-message">
                            Félicitations, <span className="color-primary">{this.state.sms_sent} sms</span> d’invitation{this.state.sms_sent > 1 ? 's' : ''} <br /> {this.state.sms_sent > 1 ? `ont été envoyés` : 'a été envoyé'} !
                          </p>

                          <p className="members-invitation__congrats-legend">
                            Vous pouvez vérifier qu’il{this.state.sms_sent > 1 ? 's' : ''} {this.state.sms_sent > 1 ? 'se sont' : `s'est`} bien connecté{this.state.sms_sent > 1 ? 's' : ''} à un <br /> réseau dans la liste des utilisateurs.
                          </p>
                        </>
                        :
                        <p className="members-invitation__congrats-legend error">
                          Aucun sms n'a été envoyé. Veuillez contacter l'administrateur. <br />
                          {process.env.REACT_APP_SUPPORT_MAIL}<br /><br />

                          {this.state.sms_error_message ? `Message d'erreur : \n ${this.state.sms_error_message}` : ''}
                        </p>
                      }

                      <button className="members-invitation__congrats-btn white background-color-primary" onClick={this.resetData} onMouseEnter={global.manageHover} onMouseLeave={global.manageHover}>
                        Inviter d'autres membres
                      </button>

                      <button className="members-invitation__congrats-btn" onClick={this.props.togglePopinInvitation}>
                        Revenir à la liste des utilisateurs
                      </button>
                    </div>
                  }
                </div>

                {/* POPIN WITH LIST OF GROUPS */}
                <SelectDialog
                  setItems={this.handleChangeInvite('groups')}

                  // Value to open the list of groups
                  open={this.props.state.groupsIsOpen}

                  // Value for positionning the dialog
                  positionTop={this.props.state.groupsAnchorTop}
                  positionLeft={this.props.state.groupsAnchorLeft}
                  width={this.props.state.groupsWidth}

                  isMultiple={true}
                  items={this.state.groups}
                  itemClass={this.props.classes.groupChip}
                />

                {/* POPIN CONFIRMATION LINK DELETE*/}
                <ConfirmDialog title={"Confirmation"}
                  message={"Êtes-vous sûr de vouloir supprimer ce lien ?"}
                  handleCancel={this.toggleConfirmDialog}
                  handleClose={this.toggleConfirmDialog}
                  handleValid={this.disableInvite}
                  open={this.state.confirmIsOpen}
                />


              </div>
            )
          }
        }
      </Translation>

    )
  }
}

export default MembersInvitation
