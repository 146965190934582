const styles = theme => ({
  textField: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
    minWidth: 200,
  },
  bootstrapRoot: {
    'label + &': {
      marginTop: 30,
    },
    width: "auto",
  },
  bootstrapRootFullWidth: {
    'label + &': {
      marginTop: 30,
    },
    width: "100%",
  },
  bootstrapFormLabel: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#525252",
    marginBottom: 10,
    fontSize: 15,
    transform: "none",
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    color: theme.greyBlue,
    border: `1px solid ${theme.cloudyBlue}`,
    fontSize: 15,
    fontWeight: 600,
    padding: '10px 30px 10px 20px',
    height: 40,
    boxSizing: "border-box",
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto',
      '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"',
      '"Segoe UI Emoji"', '"Segoe UI Symbol"',
    ].join(','),
    minHeight: "auto",
  },
  disabledInput: {
    backgroundColor: "#eee",
  },
  bootstrapSelect: {
    minWidth: 150,
    marginRight: -10,
    padding: "4px 40px 4px 20px",
    lineHeight: "30px",
  },
  groupChip: Object.assign(theme.groupChip, {
    verticalAlign: "bottom",
    marginRight: 5
  }),
  margin: {
    width: "100%",
    maxWidth: 330,
    margin: "0 0 30px 0",
  },
  buttonProgress: {
    color: "#ffffff",
    marginRight: 10,
  },
  button: {
    color: "#ffffff",
  },
  buttonDelete: {
    color: theme.grapefruit,
    textDecoration: "underline",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    marginRight: "10px",
    fontSize: 15,
    fontWeight: 600,
    height: 40,
  },
  subtitle: {
    color: "#86a1b2",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    fontSize: 13,
    display: "flex",
    margin: "10px 0",
    "& > div": {
      marginRight: 10,
    }
  },
  sendLink: {
    padding: 0,
    minHeight: "auto",
    textTransform: "inherit",
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export default styles;