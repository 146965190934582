import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ListItem from './ListItem'

const TwitterDropdown = ({ list, handleAutocomplete, anchorOffset }) => {
  const [y, setY] = useState(60)

  // Set list position according to anchor position
  const updatePosition = () => {
    const breakpoints = [
      { value: 256, y: 140 },
      { value: 190, y: 120 },
      { value: 126, y: 100 },
      { value: 66, y: 80 },
      { value: 0, y: 60 }
    ]

    for (let i = 0; i < breakpoints.length; i++) {
      if (anchorOffset > breakpoints[i].value) {
        setY(breakpoints[i].y)
        break
      }
    }
  }

  useEffect(() => {
    updatePosition()
  }, [anchorOffset])

  return (
    <>
      {!!list.length && (
        <div className="twitter-dropdown" style={{ top: y + 'px' }}>
          {list.map(item => <ListItem key={item.screen_name} content={item} handleAutocomplete={handleAutocomplete} />)}
        </div>
      )}
    </>
  )
}

TwitterDropdown.propTypes = {
  list: PropTypes.array.isRequired,
  handleAutocomplete: PropTypes.func.isRequired,
  anchorOffset: PropTypes.number.isRequired
}

export default TwitterDropdown