import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { NavLink } from 'react-router-dom'
import { Translation } from 'react-i18next';

// Material
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import global from '../../utils/global';

// Assets
import emptyFace from '../../assets/images/empty-face.svg'
import congrats from '../../assets/images/congrats.svg'
import IconCheckbox from "../../assets/icons/checkbox";
import IconCheckboxChecked from "../../assets/icons/checkbox-checked";

class Onboarding extends React.Component {

  render() {
    const e_domain = (this.props.invite && this.props.invite.email_domain) || null
    const company_name = process.env.REACT_APP_CLIENT_NAME ? process.env.REACT_APP_CLIENT_NAME.replace(/\s/g, '') : 'societe'

    return (
      <div className="onboarding">
        <Translation>
          {
            (t, { i18n }) => {
              t = t('onboarding', { returnObjects: true })

              return (
                <div className="onboarding__container">
                  {/* STEP : START */}
                  {this.props.step === 'start' &&
                    <div className="onboarding__step start">
                      {/* Logo */}
                      <img className="onboarding__logo" alt="logo" src={process.env.REACT_APP_LOGO} />

                      <div className="onboarding__text">
                        {t.step_start.replace('[[app_name]]', global.capitalize(process.env.REACT_APP_NAME))}
                      </div>
                    </div>
                  }

                  {/* STEP : NAME */}
                  {this.props.step === 'name' &&
                    <div className="onboarding__step name">
                      <div className="onboarding__title">
                        {t.step_name.text}
                      </div>

                      <input type="text" placeholder={t.step_name.input_placeholder} className="onboarding__input" value={this.props.name || ''} onChange={this.props.handleChange('name')} />
                    </div>
                  }

                  {/* STEP : PHONE */}
                  {this.props.step === 'phone' &&
                    <div className="onboarding__step phone">
                      <div className="onboarding__title">
                        {t.step_phone.title}
                      </div>

                      <div className="onboarding__text">
                        {t.step_phone.text}
                      </div>

                      <ReactPhoneInput label="Numéro de téléphone" id="phone" name="phone"
                        placeholder="33 6 01"
                        value={this.props.phone || '33'}
                        onChange={this.props.handleChange('phone')}
                        defaultCountry='fr'
                        disableDropdown={true}
                        inputClass="onboarding__input"
                      />
                    </div>
                  }


                  {/* STEP : RGPD */}
                  {this.props.step === 'rgpd' &&
                    <div className="onboarding__step email">
                      <div className="onboarding__title">
                        Termes & conditions
                      </div>

                      {/* RGPD TWEETIT */}
                      {process.env.REACT_APP_NAME === 'tweetit' ?
                        <div className="onboarding__legend-bloc">
                          <div className="onboarding__legend more" >
                            En tant que responsable du traitement, <span className="onboarding__legend-upper">{company_name}</span> France met en œuvre un traitement de données personnelles vous concernant afin de vous permettre de naviguer sur la présente application et d'utiliser les services de Tweet-It.
                            Vous disposez d'un droit d'accès, de rectification et d'effacement des informations vous concernant, de limitation du traitement, d'un droit à la portabilité des données, ainsi que d'un droit au retrait de votre consentement le cas échéant. Vous disposez également d'un droit de formuler des directives spécifiques et générales concernant la conservation, l'effacement et la communication de vos données post-mortem. Vous disposez d'un droit d'opposition au traitement de données à caractère personnel ayant pour finalité l'exécution d'une mission d'intérêt public, relevant de l'exercice de l'autorité publique ou la réalisation des intérêts légitimes poursuivis par <span className="onboarding__legend-upper">{company_name}</span> France, à moins qu'un motif impérieux ne prévale. Enfin, vous avez le droit d'introduire une réclamation auprès de la Commission nationale de l'informatique et des libertés.
                            Pour en savoir plus sur la gestion de vos données et de vos droits par <span className="onboarding__legend-upper">{company_name}</span> France sur le présent site : <NavLink to="/politique-confidentialite" target="_blank" className="onboarding__legend underline cursor" >cliquez ici</NavLink>.
                          </div>
                          <span className="onboarding__legend" >
                            En poursuivant votre inscription vous acceptez les <br />
                            <NavLink to="/cgu" target="_blank" className="onboarding__legend underline cursor" >conditions générales d'utilisations</NavLink> et la <NavLink to="/politique-confidentialite" target="_blank" className="onboarding__legend underline cursor" >politique de confidentialité</NavLink>
                          </span>

                          <div className="onboarding__checkbox" onClick={() => { this.props.handleCheck('terms') }}>
                            <input name="showTweets" id="showTweets" type="checkbox" className="onboarding__checkbox-input" value="showTweets" />
                            {this.props.checkbox.terms ? <IconCheckboxChecked /> : <IconCheckbox />}
                            J'ai lu et j'accepte
                          </div>
                        </div>
                        : ''}

                    </div>
                  }

                  {/* STEP : EMAIL */}
                  {this.props.step === 'email' &&
                    <div className="onboarding__step email">
                      <div className="onboarding__title">
                        {t.step_email.title}
                      </div>

                      <div className="onboarding__text">
                        {t.step_email.text}
                      </div>
                      <div className={`onboarding__bloc-email ${e_domain ? 'domain' : ''}`}>
                        <input
                          type="text"
                          placeholder={e_domain ? 'nom' : `nom@${company_name}.com`}
                          className={`onboarding__input ${e_domain ? 'domain' : ''}`}
                          value={this.props.email || ''}
                          onChange={this.props.handleChange('email')}
                        />

                        {e_domain &&
                          <div className="onboarding__text domain" >{e_domain}</div>
                        }
                      </div>
                      {/* RGPD MICIV */}
                      {process.env.REACT_APP_NAME === 'miciv' ?
                        <div className="onboarding__legend-bloc">
                          <span className="onboarding__legend" >
                            {t.step_email.conditions} <br />
                            <a className="onboarding__legend underline cursor" href={t.step_email.link_1.url} rel="noopener noreferrer" target='_blank'>{t.step_email.link_1.text}</a> {t.step_email.and} <a className="onboarding__legend underline cursor" href={t.step_email.link_2.url} rel="noopener noreferrer" target='_blank'>{t.step_email.link_2.text}</a>

                          </span>

                          <div className="onboarding__checkbox" onClick={() => { this.props.handleCheck('terms') }}>
                            <input name="showTweets" id="showTweets" type="checkbox" className="onboarding__checkbox-input" value="showTweets" />
                            {this.props.checkbox.terms ? <IconCheckboxChecked /> : <IconCheckbox />}
                            {t.step_email.accept_condition}
                          </div>
                        </div>
                        : ''}


                    </div>
                  }

                  {/* STEP : EMAIL CONFIRM */}
                  {this.props.step === 'email_confirm' &&
                    <div className="onboarding__step email_confirm">
                      {!this.props.error ?
                        <>
                          <div className="onboarding__title">
                            {t.step_confirm.title}
                          </div>
                          <div className="onboarding__text">
                            {t.step_confirm.text1.split('[[mail_adress]]')[0]} <span className="onboarding__text highlight">{this.props.email}{(this.props.invite && this.props.invite.email_domain) || ''}</span>
                            <br /> <br />
                            {t.step_confirm.text2}
                          </div>

                          <div className="onboarding__legend">
                            {t.step_confirm.legend.text} <span className="onboarding__legend underline cursor" onClick={this.props.stepContinue} >{t.step_confirm.legend.text_link}</span>
                          </div>
                        </>
                        :
                        <>
                          <div className="onboarding__title">
                            An error occur when sending the mail
                          </div>
                          <div className="onboarding__text">
                            Error message : {this.props.error}
                          </div>

                          <div className="onboarding__legend">
                            <span className="onboarding__legend underline cursor" onClick={this.props.stepContinue} >{t.step_confirm.legend.text_link}</span>

                            {process.env.REACT_APP_SUPPORT_MAIL ?
                              <>
                                <br /> <br />
                                or <a className="onboarding__legend underline cursor" href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}> contact the admin.</a>
                              </>
                              : ''}
                          </div>
                        </>
                      }
                    </div>
                  }

                  {/* EMAIL CONFIRM VALID */}
                  {this.props.step === 'email_confirm_valid' &&
                    <div className="onboarding__step email_confirm_valid">
                      <img src={congrats} alt="congrats" /> <br /> <br />
                      <div className="onboarding__title">
                        {t.step_confirm__email.title}
                      </div>
                      <div className="onboarding__text">
                        {t.step_confirm__email.text}
                      </div>
                    </div>
                  }

                  {/* LINK NOT VALID */}
                  {this.props.step === 'not_valid' &&
                    <div className="onboarding__step">
                      <img src={emptyFace} alt="empty" /> <br /> <br />
                      <div className="onboarding__title">
                        {this.props.error}
                      </div>
                    </div>
                  }

                  {/* ERROR */}
                  {this.props.step === 'error' &&
                    <div className="onboarding__step">
                      <img src={emptyFace} alt="empty" /> <br /> <br />
                      <div className="onboarding__title">
                        An  error occure, please refresh the page

                        {process.env.REACT_APP_SUPPORT_MAIL ?
                          <>
                            <br /> <br />
                            or <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>contact the admin.</a>
                          </>
                          : ''}
                      </div>
                      <br /><br />
                      {this.props.error &&
                        <div className="onboarding__text">
                          Message d'erreur :  <br />
                          {this.props.error}
                        </div>
                      }
                    </div>
                  }

                  {/* LOADING */}
                  {this.props.step === 'loading' &&
                    <div className="onboarding__step">
                      <CircularProgress />
                    </div>
                  }

                  {/* BUTTON TO CONTINUE*/}
                  {!['loading', 'error', 'not_valid', 'email_confirm'].includes(this.props.step) &&
                    <button
                      className={`onboarding__button ${!this.props.isBtn ? 'disable' : ''} background-color-primary`}
                      onClick={this.props.stepContinue}
                      onMouseEnter={global.manageHover}
                      onMouseLeave={global.manageHover}
                    >
                      {this.props.step === 'email' ? t.step_email.button_send_link : t.button_continue}
                    </button>
                  }
                </div>
              )
            }
          }
        </Translation>
      </div>
    )
  }
}

export default Onboarding;
