import React from 'react'

const OkIcon = () => (

  <svg className="icon__ok" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <g fill="none" fillRule="evenodd">
      <rect width="19" height="19" fill="#23E27F" fillRule="nonzero" rx="9.5" />
      <path fill="#FFF" d="M13.189 6.106a.377.377 0 0 0-.525 0l-4.656 4.586a.376.376 0 0 1-.525 0L6.336 9.563a.377.377 0 0 0-.525 0l-.703.694a.363.363 0 0 0 0 .516l2.375 2.34c.144.141.38.141.525 0l5.884-5.797a.363.363 0 0 0 0-.517l-.703-.693z" />
    </g>
  </svg>
)

export default OkIcon
