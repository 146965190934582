import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import history from '../../history';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CSSTransition } from "react-transition-group";
import diff from 'deep-diff';
import { Translation } from 'react-i18next';

// Material
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from "@material-ui/core/Button/Button";
import ConfirmDialog from "../misc/confirm-dialog"

// Components
import TweetCard from '../misc/tweetCard/tweet-card';
import TweetitDialog from '../misc/tweetitForm/tweetit-form';
import MediaDialog from "../misc/mediaDialog/media-dialog";
import DetailsPanel from "../misc/detailsPanel/details-panel";
import MembersCon from "../../containers/membersContainer/members-container";
import AnalyticsCon from "../../containers/analytics-container";

import params from '../../utils/params';

// Material styles
import styles from './material-homeManager'
import EventEmitter from '../../utils/EventEmitter';

function TabContainer({ children }) {
  return (
    <Typography component="div" className="max-height">
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class HomeManager extends React.Component {

  state = {
    tabValue: 0,
    selectedTweetit: null,
    confirmIsOpen: false,
    confirmOpenedBy: null
  };

  constructor(props) {
    super(props);

    // Ref on the selected tweet
    this.tweetSelectedRef = React.createRef();

    // Ref on the bloc inspector on the right
    this.detailsRef = React.createRef();

    // Ref on the bloc who contain tweet draft
    this.tweetDraftRef = React.createRef();

    // Ref on the bloc who contain tweet submit
    this.tweetSubmitRef = React.createRef();

    this.selectTweetit = this.selectTweetit.bind(this);

    // If the user click outside the selected tweet
    this.handleClickOutside = this.handleClickOutside.bind(this)

    // Resize the columns containing tweet draft and tweet submit
    this.resizeColumnTweet = this.resizeColumnTweet.bind(this)

    // Closing the popin by choosing the cancel button
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    // Closing the popin by choosing the validate button
    this.handleValidConfirm = this.handleValidConfirm.bind(this);
    // Open the popin
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this);

    this.deleteTweetit = this.deleteTweetit.bind(this)

    this.handleMobileColumnScroll = this.handleMobileColumnScroll.bind(this)

    this.TWEETS_TAB = 0;
  }

  async componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.resizeColumnTweet);
    this.resizeColumnTweet()

    switch (history.location.pathname) {
      case "/":
        this.setState({ tabValue: this.TWEETS_TAB });
        break;
      default:
        this.setState({ tabValue: "none" })
        break;
    }

  }

  componentWillUnmount() {
    clearInterval(this.timer);
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.resizeColumnTweet);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dialogIsOpen && !this.props.dialogIsOpen) this.setState({ selectedTweetit: null })

    if (diff(prevProps.tweetits, this.props.tweetits) || (history.location.pathname === '/')) {
      this.resizeColumnTweet()
    }
  }

  handleClickOutside(e) {
    // Reset the tweet selected
    if (this.tweetSelectedRef.current && !this.tweetSelectedRef.current.contains(e.target) && !this.detailsRef.current.contains(e.target) && this.state.selectedTweetit) {
      this.setState({ selectedTweetit: null })
    }
  }

  resizeColumnTweet() {
    if (this.tweetDraftRef.current && this.tweetSubmitRef.current) {
      this.tweetDraftRef.current.style.height = window.innerWidth > 1024 ? `${this.tweetDraftRef.current.offsetHeight + (window.innerHeight - (this.tweetDraftRef.current.offsetTop + this.tweetDraftRef.current.offsetHeight)) - 20}px` : 'initial'
      this.tweetSubmitRef.current.style.height = window.innerWidth > 1024 ? `${this.tweetSubmitRef.current.offsetHeight + (window.innerHeight - (this.tweetSubmitRef.current.offsetTop + this.tweetSubmitRef.current.offsetHeight)) - 20}px` : 'initial'
    }

    // Find the bloc for the scroll on mobile
    let blocMobileScroll = document.querySelector('.home-manager__mobile-scroll')

    // Force the update in case we need or not, to display the bloc for the scroll on mobile 
    if ((window.innerWidth > 1024) && (history.location.pathname === '/') && blocMobileScroll) this.forceUpdate()

    else if ((window.innerWidth <= 1024) && (history.location.pathname === '/') && !blocMobileScroll) this.forceUpdate()
  }

  handleMobileColumnScroll(e) {
    if ((e.currentTarget.offsetHeight + e.currentTarget.scrollTop) === e.currentTarget.scrollHeight) {
      if (e.currentTarget.getAttribute('data-type') === 'draft') {
        this.props.loadMoreTweetits({ scrollTop: e.currentTarget.scrollTop }, this.props.tweetits.filter(t => t.status === "draft").length, 2, ["draft"], "loadingMoreDraft")
      } else {
        this.props.loadMoreTweetits({ scrollTop: e.currentTarget.scrollTop }, this.props.tweetits.filter(t => t.status === "suggested").length, 2, ["suggested"], "loadingMoreSuggested")
      }
    }
  }

  selectTweetit = tweetit => () => {
    // If there is new activity and the user click on the post
    tweetit = this.isNewActvity(tweetit, { reset: true })

    this.setState({
      selectedTweetit: tweetit
    });
  };

  isNewActvity(tweetit, options) {
    let activityLength = tweetit && tweetit.post_activity && tweetit.post_activity.length

    // If there is new activity on the ppost
    if (activityLength && tweetit.post_activity[activityLength - 1].seen) {

      // Indicate that there is new activity on the card
      if (!options) return true

      // Loop to reset the seen property
      for (let i = 0; i < activityLength; i++) {

        // Reset If the manager clicked on the post
        if (options && options.reset && tweetit.post_activity[i].seen)
          tweetit.post_activity[i].seen = 0
      }

      // Edit the activity on the post
      this.props.editPostActivityAction(tweetit.id, this.props.user.id).then((res) => {
        if (res && res.error) {
          EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
        }
      })

    } else if (!options) {
      return false
    }

    return tweetit
  }

  displayContent(content, option) {
    let element = "";

    if (window.innerWidth > 1024) {
      element = !option.isMemberPage ?
        (
          <PerfectScrollbar
            id="scrollableDraftDiv"
            className={this.props.classes.scrollbarGrid}
            onYReachEnd={
              (e) => option.type === 'draft' ?
                this.props.loadMoreTweetits(e, this.props.tweetits.filter(t => t.status === "draft").length, 2, ["draft"], "loadingMoreDraft")
                :
                this.props.loadMoreTweetits(e, this.props.tweetits.filter(t => t.status === "suggested").length, 2, ["suggested"], "loadingMoreSuggested")
            }
            option={{
              wheelSpeed: 1,
              wheelPropagation: true,
              swipeEasing: false,
              minScrollbarLength: 5
            }}
          >
            {content}
          </PerfectScrollbar>
        )
        :
        (
          <PerfectScrollbar
            option={{
              wheelSpeed: 1,
              wheelPropagation: true,
              swipeEasing: false,
              minScrollbarLength: 5
            }}
          >
            {content}
          </PerfectScrollbar>
        )
    } else {
      element = (
        <div className="home-manager__mobile-scroll height" onScroll={this.handleMobileColumnScroll} data-type={option.type}>
          {content}
        </div>
      )
    }
    return element;
  }

  async deleteTweetit() {
    if (this.state.confirmOpenedBy) {
      let res = await this.props.deleteTweetitAction(this.state.confirmOpenedBy);

      if (res && res.error) {
        EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })

        this.handleCloseConfirm();
        return
      }
    }

    this.handleCloseConfirm();
  }

  handleCloseConfirm() {
    // If the popin open is for deleting a tweet
    if (this.state.confirmIsOpen === 'delete') this.setState({ confirmIsOpen: false, confirmOpenedBy: null });
  }

  handleValidConfirm() {
    // If the popin open is for deleting a tweet
    if (this.state.confirmIsOpen === 'delete') this.deleteTweetit();
  }

  handleOpenConfirm(e, postId) {
    this.setState({
      confirmIsOpen: e.currentTarget.getAttribute('popin-type'),
      confirmOpenedBy: postId
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).home

            return (
              <div className={`home-manager ${history.location.pathname === '/' ? 'height' : 'height-initial'}`}>

                <ConfirmDialog title={"Confirmation"}
                  message={t.post.popin_delete.message.join('\n')}
                  handleClose={this.handleCloseConfirm}
                  handleValid={this.handleValidConfirm}
                  label={{ buttonClose: t.post.popin_delete.btn_cancel, buttonValid: t.post.popin_delete.btn_delete }}
                  open={this.state.confirmIsOpen === 'delete'} />

                <div className="grid max-height">

                  {/* Window to edit a tweet */}
                  <TweetitDialog
                    formData={this.props.formData}
                    user={this.props.user}
                    getMedia={this.props.getMedia}
                    getMediaUrl={this.props.getMediaUrl}
                    users={this.props.users && this.props.users.filter(user => user.role === "User")}
                    tags={this.props.tags}
                    groups={this.props.groups}
                    submitMedia={this.props.submitMedia}
                    createTweetitAction={this.props.createTweetitAction}
                    editTweetitAction={this.props.fastSubmitTweetit}
                    deleteTweetitAction={this.props.deleteTweetitAction}
                    getTweetAction={this.props.getTweetAction}
                    getInstagramPostAction={this.props.getInstagramPostAction}
                    getFacebookPostsAction={this.props.getFacebookPostsAction}
                    fetchOpenGraphAction={this.props.fetchOpenGraphAction}
                    getProfileAction={this.props.getProfileAction}
                    getFileInfoUploadCare={this.props.getFileInfoUploadCare}
                    createTagAction={this.props.createTagAction}
                    deleteTagAction={this.props.deleteTagAction}
                    open={this.props.dialogIsOpen}
                    handleClose={this.props.closeTweetitDialog}
                    enlargeMedia={this.props.enlargeMedia}
                    orphans={this.state.selectedTweetit ? this.state.selectedTweetit.orphans : this.props.formData.orphans}
                    getLinkedinAccessToken={this.props.getLinkedinAccessToken}
                  />
                  <MediaDialog
                    open={this.props.mediaIsOpen}
                    currentIndex={this.props.mediaIndex}
                    media={this.props.media}
                    getMediaUrl={this.props.getMediaUrl}
                    handleClose={this.props.minimizeMedia}
                  />

                  {/* Organize the routes */}
                  <Switch>
                    {/* Route for the dashboard */}
                    <Route exact path="/" render={() =>
                      <TabContainer>
                        <div className="row max-height">
                          <div className="cell-4 cell-t-12">
                            <div className="home-manager__column" ref={this.tweetDraftRef}>
                              <Typography component="h3" className={classes.title}>
                                {t.draft}
                              </Typography>
                              {this.displayContent(
                                <React.Fragment>
                                  <Button fullWidth variant="contained" color="primary"
                                    onClick={() => this.props.openTweetitDialog({})}
                                    className={classes.buttonAddTweetit} disableRipple={true}>
                                    <AddCircleIcon className={classes.AddCircleIcon} />
                                    {t.btn_new}
                                  </Button>
                                  {
                                    this.props.isLoadingDraft ?
                                      <div className="home-manager__empty">
                                        <CircularProgress />
                                      </div>
                                      :
                                      (() => {
                                        let items = this.props.tweetits
                                          .filter(t => (t.status === "draft") && (t.clone_parent_id === null))
                                          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

                                        return items.length === 0 ?
                                          <div className="home-manager__empty">
                                            <p>{t.post_empty.txt.draft} </p>
                                            <button className="home-manager__empty-create-new" onClick={() => this.props.openTweetitDialog({})}>{t.post_empty.txt2}</button>
                                          </div>
                                          :
                                          items.map(tweetit =>
                                            <CSSTransition
                                              key={tweetit.id}
                                              in={this.state.tabValue === this.TWEETS_TAB}
                                              appear={true}
                                              enter={true}
                                              exit={true}
                                              timeout={{
                                                enter: 500,
                                                exit: 300,
                                              }}
                                              classNames={{
                                                appear: classes.itemEnter,
                                                appearActive: classes.itemEnterActive,
                                                enter: classes.itemEnter,
                                                enterActive: classes.itemEnterActive,
                                                exit: classes.itemExit,
                                                exitActive: classes.itemExitActive,
                                                exitDone: classes.itemExitDone,
                                              }}
                                            >
                                              <div className="home-manager__scroll-bar-item" ref={this.state.selectedTweetit ? this.state.selectedTweetit.id === tweetit.id ? this.tweetSelectedRef : null : null}>
                                                <TweetCard
                                                  showHeader={true}
                                                  id={tweetit.id}
                                                  user={this.props.user}
                                                  users={this.props.users}
                                                  user_id={tweetit.user_id}
                                                  getMedia={this.props.getMedia}
                                                  getMediaUrl={this.props.getMediaUrl}
                                                  media={tweetit.media}
                                                  mediaType={tweetit.media.length > 0 ? tweetit.media[0].type : params.mediaTypes.IMAGE}
                                                  message={tweetit.message ? tweetit.message : ""}
                                                  comment={tweetit.comment ? tweetit.comment : ""}
                                                  created_at={tweetit.created_at}
                                                  updated_at={tweetit.updated_at}
                                                  scheduled_at={tweetit.scheduled_at}
                                                  submitted_at={tweetit.submitted_at}
                                                  email_notif={tweetit.email_notif}
                                                  sms_notif={tweetit.sms_notif}
                                                  notify_users={tweetit.notify_users}
                                                  groups={tweetit.groups ? tweetit.groups : []}
                                                  references={tweetit.children ? tweetit.children : []}
                                                  orphans={tweetit.orphans}
                                                  share_url={tweetit.share_url ? tweetit.share_url : null}
                                                  share_ref={tweetit.share_ref ? tweetit.share_ref : null}
                                                  tag={tweetit.tag ? [tweetit.tag] : []}
                                                  type={tweetit.type}
                                                  status={tweetit.status}
                                                  handleEdit={this.props.openTweetitDialog}
                                                  handleSubmit={this.props.fastSubmitTweetit}
                                                  enlargeMedia={this.props.enlargeMedia}
                                                  getFileInfoUploadCare={this.props.getFileInfoUploadCare}
                                                  minimizeMedia={this.props.minimizeMedia}
                                                  isSubmitable={tweetit.children.length + tweetit.groups.length > 0}
                                                  submitLabel={tweetit.scheduled_at ? t.post.btn_submit_schedule : t.post.btn_submit}
                                                  editLabel={t.post.btn_edit}
                                                  isEditable={true}
                                                  isRemovable={false}
                                                  isScheduled={false}
                                                  isSelected={this.state.selectedTweetit ? this.state.selectedTweetit.id === tweetit.id : false}
                                                  onClick={this.selectTweetit(tweetit)}
                                                  updated_by={tweetit.updated_by}
                                                  isUser={false}
                                                  network={tweetit.network}
                                                  handleOpenConfirm={this.handleOpenConfirm}
                                                />
                                              </div>
                                            </CSSTransition>
                                          )
                                      })()
                                  }
                                  {this.props.loadingMoreDraft && (
                                    <div className="home-manager__scroll-bar-item home-manager__loading-scroll">
                                      <CircularProgress />
                                    </div>
                                  )}
                                </React.Fragment>
                                , { type: 'draft' })}
                            </div>
                          </div>
                          <div className="cell-4 cell-t-12">
                            <div className="home-manager__column" ref={this.tweetSubmitRef}>
                              <Typography component="h3" className={classes.title}>
                                {t.submit}
                              </Typography>
                              {this.displayContent(<React.Fragment>
                                {
                                  this.props.isLoadingSuggested ?
                                    <div className="home-manager__empty">
                                      <CircularProgress />
                                    </div>
                                    : (() => {
                                      let items = this.props.tweetits
                                        // Filter t.clone_parent_id === null  =>>> If a person with role "user" is deleted
                                        .filter(t => (t.status === 'suggested') && (t.clone_parent_id === null))
                                        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

                                      return items.length === 0 ?
                                        <div className="home-manager__empty">
                                          <p>{t.post_empty.txt.submit} </p>
                                          <button className="home-manager__empty-create-new" onClick={() => this.props.openTweetitDialog({})}>{t.post_empty.txt2}</button>
                                        </div>
                                        :
                                        items.map(tweetit =>
                                          <CSSTransition
                                            key={tweetit.id}
                                            in={this.state.tabValue === this.TWEETS_TAB}
                                            appear={true}
                                            enter={true}
                                            exit={true}
                                            timeout={{
                                              enter: 500,
                                              exit: 300
                                            }}
                                            classNames={{
                                              appear: classes.itemEnter,
                                              appearActive: classes.itemEnterActive,
                                              enter: classes.itemEnter,
                                              enterActive: classes.itemEnterActive,
                                              exitActive: classes.itemExitActive,
                                              exitDone: classes.itemExitDone,
                                            }}
                                          >
                                            <div key={tweetit.id} className="home-manager__scroll-bar-item" ref={this.state.selectedTweetit ? this.state.selectedTweetit.id === tweetit.id ? this.tweetSelectedRef : null : null}>
                                              <TweetCard
                                                showHeader={true}
                                                network={tweetit.network}
                                                id={tweetit.id}
                                                user={this.props.user}
                                                users={this.props.users}
                                                user_id={tweetit.user_id}
                                                getMedia={this.props.getMedia}
                                                getMediaUrl={this.props.getMediaUrl}
                                                media={tweetit.media}
                                                mediaType={tweetit.media.length > 0 ? tweetit.media[0].type : params.mediaTypes.IMAGE}
                                                message={tweetit.message ? tweetit.message : ''}
                                                email_notif={tweetit.email_notif}
                                                sms_notif={tweetit.sms_notif}
                                                notify_users={tweetit.notify_users}
                                                comment={tweetit.comment ? tweetit.comment : ''}
                                                share_url={tweetit.share_url ? tweetit.share_url : null}
                                                share_ref={tweetit.share_ref ? tweetit.share_ref : null}
                                                created_at={tweetit.created_at}
                                                updated_at={tweetit.updated_at}
                                                scheduled_at={tweetit.scheduled_at}
                                                submitted_at={tweetit.submitted_at}
                                                groups={tweetit.groups ? tweetit.groups : []}
                                                references={tweetit.children}
                                                orphans={tweetit.orphans}
                                                tag={tweetit.tag ? [tweetit.tag] : []}
                                                type={tweetit.type ? tweetit.type : params.types.POST}
                                                status={tweetit.status}
                                                handleEdit={this.props.openTweetitDialog}
                                                handleRemove={this.props.fastSubmitTweetit}
                                                enlargeMedia={this.props.enlargeMedia}
                                                getFileInfoUploadCare={this.props.getFileInfoUploadCare}
                                                minimizeMedia={this.props.minimizeMedia}
                                                isSubmitable={false}
                                                submitLabel={"Soumettre"}
                                                editLabel={t.post.btn_edit}
                                                isEditable={tweetit.children ? tweetit.children.filter(c => c.status === params.statuses.SUGGESTED).length > 0 : false}
                                                isRemovable={false}
                                                isCancelable={tweetit.children ? tweetit.children.filter(c => c.status === params.statuses.SUGGESTED).length > 0 : false}
                                                isScheduled={!!tweetit.scheduled_at}
                                                isSelected={this.state.selectedTweetit ? this.state.selectedTweetit.id === tweetit.id : false}
                                                onClick={this.selectTweetit(tweetit)}
                                                isUser={false}
                                                isActivity={this.isNewActvity(tweetit)}
                                              />
                                            </div>
                                          </CSSTransition>
                                        )
                                    })()
                                }
                                {this.props.loadingMoreSuggested && (
                                  <div className="home-manager__scroll-bar-item home-manager__loading-scroll">
                                    <CircularProgress />
                                  </div>
                                )}
                              </React.Fragment>
                                , { type: 'suggest' })}
                            </div>

                          </div>

                          <div className="cell-4">
                            <div className="home-manager__details-panel" ref={this.detailsRef}>
                              <DetailsPanel
                                tweetit={this.props.tweetits && this.state.selectedTweetit ? this.props.tweetits.filter(t => t.id === this.state.selectedTweetit.id)[0] : null}
                                users={this.props.users}
                                user={this.props.user}
                              />
                            </div>
                          </div>
                        </div>
                      </TabContainer>
                    } />

                    {/* Route for members and groups */}
                    <Route path="/:path(|members|groups|settings-flux)" render={() =>
                      <TabContainer>
                        <MembersCon
                          isVisible={true}
                          history={this.props.history}
                          formData={this.state.formData}
                          user={this.props.user}
                          users={this.props.users}
                          groups={this.props.groups}
                        />
                      </TabContainer>
                    } />

                    {/* Route for analytics */}
                    <Route path="/statistiques" render={() =>
                      <TabContainer>
                        <AnalyticsCon enlargeMedia={this.props.enlargeMedia} />
                      </TabContainer>
                    } />

                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </div>
              </div>
            )
          }
        }
      </Translation>
    );
  }
}

HomeManager.propTypes = {
  classes: PropTypes.object.isRequired,
  submitMedia: PropTypes.func.isRequired,
  createTweetitAction: PropTypes.func.isRequired,
  createReTweetitAction: PropTypes.func,
  editTweetitAction: PropTypes.func.isRequired,
  deleteTweetitAction: PropTypes.func.isRequired,
  getTweetAction: PropTypes.func.isRequired,
  fetchOpenGraphAction: PropTypes.func.isRequired,
  getProfileAction: PropTypes.func.isRequired,
  deleteTagAction: PropTypes.func.isRequired,
  createTagAction: PropTypes.func.isRequired,
  getMediaUrl: PropTypes.func.isRequired,
  isLoadingDraft: PropTypes.bool.isRequired,
  isLoadingSuggested: PropTypes.bool.isRequired,
  mediaIsOpen: PropTypes.bool.isRequired,
  mediaIndex: PropTypes.number,
  enlargeMedia: PropTypes.func.isRequired,
  minimizeMedia: PropTypes.func.isRequired,
};

export default withStyles(styles)(HomeManager);
