import React from 'react';
import { CircularProgress } from '@material-ui/core';
import i18n from '../../i18n'

// Components
import Filter from './../misc/filter/filter'

// Assets
import IconTwitter from "../../assets/icons/twitter-icon"
import IconLinkedin from "../../assets/icons/linkedin-icon"
import IconFacebook from "../../assets/icons/facebook"
import IconInstagram from "../../assets/icons/instagram"
import IconCheck from '../../assets/icons/check'
import IconSchedule from "../../assets/icons/schedule.js";
import IconClose from "../../assets/icons/close.js";

// utils
import EventEmitter from '../../utils/EventEmitter';
import params from '../../utils/params';

const wording = i18n.t('manager', { returnObjects: true }).analytics.tag

class analyticsTags extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      filters: null
    }
    this.applyFilter = this.applyFilter.bind(this)
  }

  async componentDidMount() {
    let data = await this.props.getAnalyticsTagsAction()

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = null
    }

    this.setState({ data })
  }

  async applyFilter(filters) {
    await this.setState({ filters })
    let data = await this.props.getAnalyticsTagsAction({ filters })

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = this.state.data
    }

    this.setState({ data })
  }

  buildRow() {
    let rows = []
    if (!this.state.data) return rows

    let tags = this.state.data.tags, i = 0

    for (let t in tags) {
      rows.push(
        <div className={`analytics-tags__row ${(i % 2) !== 1 ? 'odd' : ''}`} key={i}>
          <div className="analytics-tags__col">
            <span className="analytics-tags__rank">
              {i + 1}
            </span>
            <div className="analytics-tags__tag">{tags[t].label}</div>
          </div>
          <div className="analytics-tags__col">
            <span className="analytics-tags__suggest">
              {tags[t].suggested + tags[t].published + tags[t].removed}
            </span>
          </div>
          <div className="analytics-tags__col">
            <div className="analytics-tags__network">
              <IconTwitter /> {tags[t].twitter ? ((tags[t].twitter / (tags[t].twitter + tags[t].linkedin + tags[t].facebook + tags[t].instagram)) * 100).toFixed(0) : 0}%
            </div>
            <div className="analytics-tags__network">
              <IconLinkedin />{tags[t].linkedin ? ((tags[t].linkedin / (tags[t].twitter + tags[t].linkedin + tags[t].facebook + tags[t].instagram)) * 100).toFixed(0) : 0}%
            </div>

            {process.env.REACT_APP_FUNCTION_FLUX_PROXY === 'true' ?
              <>
                <div className="analytics-tags__network">
                  <IconInstagram />{tags[t].instagram ? ((tags[t].instagram / (tags[t].twitter + tags[t].linkedin + tags[t].facebook + tags[t].instagram)) * 100).toFixed(0) : 0}%
                </div>
                <div className="analytics-tags__network">
                  <IconFacebook />{tags[t].facebook ? ((tags[t].facebook / (tags[t].twitter + tags[t].linkedin + tags[t].facebook + tags[t].instagram)) * 100).toFixed(0) : 0}%
                </div>
              </>
              : ''}
          </div>
          <div className="analytics-tags__col">
            <div className="analytics-tags__status">
              <div className="analytics-tags__status-bloc">
                <div className="analytics-tags__status-icon wait"><IconSchedule /></div>
                {tags[t].suggested ? ((tags[t].suggested / (tags[t].suggested + tags[t].published + tags[t].removed)) * 100).toFixed(0) : 0}%
              </div>
              <div className="analytics-tags__status-bloc">
                <div className="analytics-tags__status-icon accept"><IconCheck /></div>
                {tags[t].published ? ((tags[t].published / (tags[t].suggested + tags[t].published + tags[t].removed)) * 100).toFixed(0) : 0}%
              </div>
              <div className="analytics-tags__status-bloc">
                <div className="analytics-tags__status-icon refuse"><IconClose /></div>
                {tags[t].removed ? ((tags[t].removed / (tags[t].suggested + tags[t].published + tags[t].removed)) * 100).toFixed(0) : 0}%
              </div>
            </div>
          </div>
        </div>
      )
      i++
    }

    return rows
  }

  render() {
    if (!this.state.data) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.loading_data}</p> <CircularProgress /></div>
    if (this.state.data && this.state.data.tags && !Object.keys(this.state.data.tags).length) return <div className="analytics__loading"> <p>Pas de donnée à afficher</p></div>

    return (
      <div className="analytics-tags">
        <div className="analytics-tags__container">
          {/* HEAD */}
          <div className="analytics-tags__head">
            {wording.title}
          </div>
          <Filter filters={['group', 'network']} groups={this.props.groups} applyFilter={this.applyFilter} />
          {/* TABLE */}
          <div className="analytics-tags__table">
            <div className="analytics-tags__row head">
              <div className="analytics-tags__col">
                {wording.label_thematic}
              </div>
              <div className="analytics-tags__col">
                {wording.label_suggest}
              </div>
              <div className="analytics-tags__col">
                {wording.label_network}
              </div>
              <div className="analytics-tags__col">
                {wording.label_accept}
              </div>
            </div>
            {this.buildRow()}
          </div>
        </div>
      </div>
    )
  }
}

export default analyticsTags
