import React from 'react';

// Init component.
function IconArrowKeyboard(props) {
  return (
    <svg className="icon__arrow-keyboard fill-primary" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.59 7.70496L6 3.12496L1.41 7.70496L1.23266e-07 6.29496L6 0.294956L12 6.29496L10.59 7.70496Z" />
    </svg>
  );
}

export default IconArrowKeyboard;