import React from 'react';
import { NavLink } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import moment from 'moment'
import i18n from '../../i18n'

// Components
import Filter from './../misc/filter/filter'
import DatePicker from '../misc/datePicker/datePicker'

// Assets
import IconPhone from '../../assets/icons/phone'
import IconMouse from '../../assets/icons/mouse'
import IconCheck from '../../assets/icons/check'
import IconSchedule from "../../assets/icons/schedule.js";
import IconClose from "../../assets/icons/close.js";
import IconInfo from '../../assets/icons/info'
import IconChevron from '../../assets/icons/chevron'
import IconMail from '../../assets/icons/mail'
import IconArrowRounded from '../../assets/icons/arrow_rounded'
import IconUser from '../../assets/icons/user'
import IconTwitterBg from '../../assets/icons/twitterBg'
import IconLinkedinBg from '../../assets/icons/linkedinBg'
import IconFacebookBg from '../../assets/icons/facebookBg'
import IconInstaBg from '../../assets/icons/instagramBg'
import IconStar from "../../assets/icons/star"
import defaultUserIcon from "../../assets/images/default_profile_normal.png";

// utils
import global from "../../utils/global";
import EventEmitter from '../../utils/EventEmitter';
import params from '../../utils/params';

const wording = i18n.t('manager', { returnObjects: true }).analytics.global
moment.locale(i18n.language)

const COLORS_NET = {
  linkedin: '#0071A1',
  twitter: '#1CA1F2',
  facebook: '#4267B2',
  instagram: '#9258BD'
}

const COLORS_NOTI = {
  sub: '#525252',
  unsub: '#C3D0D7',
  sms: '#86A1B2',
  newsletter: '#697E8B'
}
class AnalyticsGlobal extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      data_r: null,
      filters: null,
      date: null,
      loading: false
    }

    this.applyFilter = this.applyFilter.bind(this)
    this.applyDate = this.applyDate.bind(this)
  }

  async componentDidMount() {
    let data = await this.props.getAnalyticsGlobalAction()
    let data_r = await this.props.getUsersRankAction()

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = null
    }
    if (data_r && data_r.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data_r.error, style: "error" })
      data_r = null
    }

    this.setState({ data, data_r })
  }

  async applyFilter(filters) {
    await this.setState({ filters, loading: true })
    let data = await this.props.getAnalyticsGlobalAction({ filters, date: this.state.date })
    let data_r = await this.props.getUsersRankAction({ filters, date: this.state.date })

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = this.state.data
    }
    if (data_r && data_r.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data_r.error, style: "error" })
      data_r = this.state.data_r
    }

    this.setState({ data, data_r, loading: false })
  }

  async applyDate(date) {
    await this.setState({ date, loading: true })
    let data = await this.props.getAnalyticsGlobalAction({ filters: this.state.filters, date })
    let data_r = await this.props.getUsersRankAction({ filters: this.state.filters, date })

    if (data && data.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data.error, style: "error" })
      data = this.state.data
    }
    if (data_r && data_r.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: data_r.error, style: "error" })
      data_r = this.state.data_r
    }

    this.setState({ data, data_r, loading: false })
  }

  getPercent(n, d) {
    let value = 0
    if (d) value = n / d
    return Math.round(value * 100)
  }

  render() {
    let data = this.state.data
    let data_r = this.state.data_r

    if (!data || !data_r) return <div className="analytics__loading"> <p>{i18n.t('manager', { returnObjects: true }).analytics.loading_data}</p> <CircularProgress /></div>

    const formatXAxis = (tickItem) => {

      const d = new Date(tickItem);
      return data.date_range === 'day' ? moment(d).lang(i18n.language).format('D/MM') : moment(d).lang(i18n.language).format('MMMM');
    };

    let total_newsletter = 0, total_sms = 0, total_sub = 0, total_unsub = 0

    let array_notif = [{ value: 0, label: 'sub' }, { value: 0, label: 'unsub' }, { value: 0, label: 'newsletter' }, { value: 0, label: 'sms' }]

    if (data && data.evolution_users.length) {
      let len = data.evolution_users.length
      total_newsletter = data.evolution_users[len - 1].total_newsletter
      total_sms = data.evolution_users[len - 1].total_sms
      total_sub = data.evolution_users[len - 1].total_sub
      total_unsub = data.evolution_users[len - 1].total_unsub

      array_notif = [{ value: total_sub, label: 'sub' }, { value: total_unsub, label: 'unsub' }, { value: total_newsletter, label: 'newsletter' }, { value: total_sms, label: 'sms' }]
    }

    // PERFORMANCE
    let total_suggested = 0, total_published = 0, total_removed = 0, total_suggested_manager = 0

    if (data && data.evolution_performance && data.evolution_performance.total && data.evolution_performance.total.length) {
      let len = data.evolution_performance.total.length
      let evp = data.evolution_performance.total

      total_suggested_manager = evp[len - 1].total_suggested_manager
      total_suggested = evp[len - 1].total_suggested
      total_published = evp[len - 1].total_published
      total_removed = evp[len - 1].total_removed
    }

    return (
      <div className="analytics-global">
        {/* FILTER + DAYPICKER */}
        <div className="analytics-global__container">
          <Filter filters={['group', 'tag', 'network']} groups={this.props.groups} tags={this.props.tags} applyFilter={this.applyFilter} />
          <DatePicker applyDate={this.applyDate} />
        </div>

        {/* FUNNEL */}
        <div className={`analytics-global__funnel ${this.state.loading ? 'loading' : ''}`}>
          {/* users */}
          <div className="analytics-global__funnel-users color-primary">
            <span className="analytics-global__funnel-users-number">
              {(this.state.data && this.state.data.total_users) || 0}
            </span>
            <br />
            {wording.bloc1.users}
          </div>

          {/* stat connect */}
          <div className="analytics-global__funnel-stat background-color-secondary">
            <IconInfo />
            <div className="analytics-global__funnel-stat-number color-primary">
              <IconPhone /> {data && data.percent_users_connect}%
            </div>
            <div className="analytics-global__funnel-stat-title">
              {wording.bloc1.subbloc1.txt}
            </div>
            <div className="analytics-global__funnel-stat-text">
              {wording.bloc1.subbloc1.txt2}
            </div>
          </div>

          <IconChevron />

          {/* stat interface */}
          <div className="analytics-global__funnel-stat background-color-secondary">
            <IconInfo />
            <div className="analytics-global__funnel-stat-number color-primary">
              <IconMouse /> {data && data.percent_users_use}%
            </div>
            <div className="analytics-global__funnel-stat-title">
              {wording.bloc1.subbloc2.txt}
            </div>
            <div className="analytics-global__funnel-stat-text">
              {wording.bloc1.subbloc2.txt2}
            </div>
          </div>

          <IconChevron />

          {/* stat share */}
          <div className="analytics-global__funnel-stat">
            <IconInfo />
            <div className="analytics-global__funnel-stat-number center">
              <div className="analytics-global__bloc-icon-check">
                <IconCheck />
              </div>
              {data && data.percent_users_share}%
            </div>
            <div className="analytics-global__funnel-stat-title">
              {wording.bloc1.subbloc3.txt}
            </div>
            <div className="analytics-global__funnel-stat-text">
              {wording.bloc1.subbloc3.txt2}
            </div>
          </div>

        </div>

        {/* EVOLUTION USERS */}
        <div className={`analytics-global__users ${this.state.loading ? 'loading' : ''}`}>
          {/* LEFT PART */}
          <div className="analytics-global__users-left">
            <div className="analytics-global__users-title">
              {wording.evo_users.title}
            </div>

            <div className="analytics-global__users-stat">
              <div className="analytics-global__users-stat-number color-primary">
                <div className="analytics-global__users-stat-icon background-color-secondary">
                  <IconUser className="fill-primary" />
                </div>
                {(this.state.data && this.state.data.total_users) || 0}
              </div>
              {wording.evo_users.label_users}
            </div>
            <div className="analytics-global__users-stat">
              <div className="analytics-global__users-stat-number sub">
                <div className="analytics-global__users-stat-icon">
                  <IconMail />
                </div>
                {(this.state.data && this.state.data.total_users_sub) || 0}
              </div>
              {wording.evo_users.label_users_sub}
            </div>
          </div>

          {/* RIGHT PART */}
          <div className="analytics-global__users-right">
            <LineChart
              width={763}
              height={361}
              data={(this.state.data && this.state.data.evolution_users) || []}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis />
              <Tooltip cursor={this.state.data && this.state.data.evolution_users.length ? { stroke: '#86A1B2', strokeWidth: 3 } : false} labelFormatter={(d) => { return `${data.date_range === 'day' ? `${moment(d).lang(i18n.language).format('DD')} ` : ''}${moment(d).lang(i18n.language).format('MMMM').charAt(0).toUpperCase() + moment(d).lang(i18n.language).format('MMMM').slice(1)} ${moment(d).lang(i18n.language).format('YYYY')}` }} />
              <Legend />
              <Line type="monotone" dataKey="total_users" name={wording.evo_users.label_users} stroke={process.env.REACT_APP_PRIMARY_COLOR} dot={false} />
              <Line type="monotone" dataKey="total_sub" name={wording.evo_users.label_users_sub} stroke="#86A1B2" dot={false} />
            </LineChart>
          </div>
        </div>

        {/* EVOLUTION PERFORMANCES */}
        <div className={`analytics-global__performances ${this.state.loading ? 'loading' : ''}`}>
          {/* LEFT PART */}
          <div className="analytics-global__performances-left">
            <div className="analytics-global__performances-title">
              {wording.evo_performances.title}
            </div>

            <div className="analytics-global__performances-stat">
              <div className="analytics-global__performances-stat-number post">
                <div className="analytics-global__performances-stat-icon">
                  <IconArrowRounded />
                </div>
                {data ? total_suggested_manager : 0}
              </div>
              {wording.evo_performances.label_suggest}
            </div>

            <div className="analytics-global__performances-stat">
              <div className="analytics-global__performances-stat-number accept">
                <div className="analytics-global__performances-stat-icon accept">
                  <IconCheck />
                </div>
                {data ? this.getPercent(total_published, (total_published + total_suggested + total_removed)) : 0}%
              </div>
              {wording.evo_performances.label_accept}
            </div>
            <div className="analytics-global__performances-stat">
              <div className="analytics-global__performances-stat-number wait">
                <div className="analytics-global__performances-stat-icon wait">
                  <IconSchedule />
                </div>
                {data ? this.getPercent(total_suggested, (total_published + total_suggested + total_removed)) : 0}%
              </div>
              {wording.evo_performances.label_wait}
            </div>
            <div className="analytics-global__performances-stat">
              <div className="analytics-global__performances-stat-number refuse">
                <div className="analytics-global__performances-stat-icon refuse">
                  <IconClose />
                </div>
                {data ? this.getPercent(total_removed, (total_published + total_suggested + total_removed)) : 0}%
              </div>
              {wording.evo_performances.label_refuse}
            </div>
          </div>
          {/* RIGHT PART */}
          <div className="analytics-global__performances-right">
            <LineChart
              width={763}
              height={451}
              data={(this.state.data && this.state.data.evolution_performance.total) || []}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis allowDecimals={false} />
              <Tooltip cursor={this.state.data && this.state.data.evolution_performance.total && this.state.data.evolution_performance.total.length ? { stroke: '#86A1B2', strokeWidth: 3 } : false} labelFormatter={(d) => { return `${data.date_range === 'day' ? `${moment(d).lang(i18n.language).format('DD')} ` : ''}${moment(d).lang(i18n.language).format('MMMM').charAt(0).toUpperCase() + moment(d).lang(i18n.language).format('MMMM').slice(1)} ${moment(d).lang(i18n.language).format('YYYY')}` }} />
              <Legend />
              <Line type="monotone" dataKey="total_published_day" name={wording.evo_performances.legend_accept} stroke="#23E27F" dot={false} />
              <Line type="monotone" dataKey="total_suggested_day" name={wording.evo_performances.legend_wait} stroke="#FFA447" dot={false} />
              <Line type="monotone" dataKey="total_removed_day" name={wording.evo_performances.legend_refuse} stroke="#F2615E" dot={false} />
              <Line type="monotone" dataKey="total_suggested_manager_day" name={wording.evo_performances.legend_suggest} stroke="#525252" dot={false} />
            </LineChart>
          </div>
        </div>

        <div className={`analytics-global__group ${this.state.loading ? 'loading' : ''}`}>
          {/* TOP NETWORK */}
          <div className="analytics-global__networks">
            {/* head */}
            <div className="analytics-global__networks-head">
              <span className="analytics-global__networks-head-title">
                {wording.top_networks.title}
              </span>
              <NavLink className="analytics-global__networks-head-all" to="/statistiques/reseaux" >
                {wording.top_networks.btn_all}
              </NavLink>
            </div>
            {/* Pie Chart */}
            <div className="analytics-global__networks-chart">
              {data_r ?
                <PieChart width={273} height={273} >
                  <Pie
                    data={data_r.network_pie} cx={132}
                    cy={132} innerRadius={60}
                    outerRadius={108} fill="unset" dataKey="value"
                  >
                    {
                      data_r.network_pie.map((entry, index) => <Cell stroke="unset" key={`cell-${index}`} fill={COLORS_NET[entry.network]} />)
                    }
                  </Pie>
                </PieChart>
                : ''}
            </div>

            {/* stat */}
            <div className="analytics-global__networks-stat">
              <div className="analytics-global__networks-network">
                <IconTwitterBg />
                Twitter
                <span className="analytics-global__networks-network-number">
                  {data_r && data_r.network ? this.getPercent(data_r.network.twitter, (data_r.network.twitter + data_r.network.linkedin + data_r.network.instagram + data_r.network.facebook)) : 0}%
                </span>
              </div>
              <div className="analytics-global__networks-network">
                <IconLinkedinBg />
                Linkedin
                <span className="analytics-global__networks-network-number">
                  {data_r && data_r.network ? this.getPercent(data_r.network.linkedin, (data_r.network.twitter + data_r.network.linkedin + data_r.network.instagram + data_r.network.facebook)) : 0}%
                </span>
              </div>
              <div className="analytics-global__networks-network">
                <IconFacebookBg />
                Facebook
                <span className="analytics-global__networks-network-number">
                  {data_r && data_r.network ? this.getPercent(data_r.network.facebook, (data_r.network.twitter + data_r.network.linkedin + data_r.network.instagram + data_r.network.facebook)) : 0}%
                </span>
              </div>
              <div className="analytics-global__networks-network">
                <IconInstaBg />
                Instagram
                <span className="analytics-global__networks-network-number">
                  {data_r && data_r.network ? this.getPercent(data_r.network.instagram, (data_r.network.twitter + data_r.network.linkedin + data_r.network.instagram + data_r.network.facebook)) : 0}%
                </span>
              </div>
            </div>
          </div>

          {/* NOTIFICATIONS */}
          <div className="analytics-global__notifications">
            {/* head */}
            <div className="analytics-global__notifications-head">
              <span className="analytics-global__notifications-head-title">
                {wording.notifications.title}
              </span>
            </div>

            {/* Pie Chart */}
            <div className="analytics-global__networks-chart">
              <PieChart width={273} height={273} onMouseEnter={this.onPieEnter}>
                <Pie
                  data={array_notif} cx={132}
                  cy={132} innerRadius={60}
                  outerRadius={108} fill="unset" dataKey="value"
                >
                  {
                    (array_notif).map((entry, index) => <Cell stroke="unset" key={`cell-${index}`} fill={COLORS_NOTI[entry.label]} />)
                  }
                </Pie>
              </PieChart>
            </div>

            {/* stat */}
            <div className="analytics-global__notifications-stat">
              <div className="analytics-global__notifications-source">
                <div className="analytics-global__notifications-source-circle twice"></div>
                {wording.notifications.both}
                <span className="analytics-global__notifications-source-number">
                  {this.getPercent(total_sub, (total_unsub + total_newsletter + total_sms + total_sub))}%
                </span>
              </div>
              <div className="analytics-global__notifications-source">
                <div className="analytics-global__notifications-source-circle sms"></div>
                SMS
                <span className="analytics-global__notifications-source-number">
                  {this.getPercent(total_sms, (total_unsub + total_newsletter + total_sms + total_sub))}%
                </span>
              </div>
              <div className="analytics-global__notifications-source">
                <div className="analytics-global__notifications-source-circle nl"></div>
                Newsletter
                <span className="analytics-global__notifications-source-number">
                  {this.getPercent(total_newsletter, (total_unsub + total_newsletter + total_sms + total_sub))}%
                </span>
              </div>
              <div className="analytics-global__notifications-source">
                <div className="analytics-global__notifications-source-circle none"></div>
                {wording.notifications.any}
                <span className="analytics-global__notifications-source-number">
                  {this.getPercent(total_unsub, (total_unsub + total_newsletter + total_sms + total_sub))}%
                </span>
              </div>
            </div>
          </div>

          {/* MEMBERS ACTIVITY */}
          <div className="analytics-global__activity">
            <div className="analytics-global__activity-head">
              <span className="analytics-global__activity-head-title">
                {wording.top_users.title}
              </span>
              <NavLink className="analytics-global__activity-head-all" to="/statistiques/utilisateurs" >
                {wording.top_users.btn_all}
              </NavLink>
            </div>

            {/* stat */}
            <div className="analytics-global__activity-stat">
              {
                data_r && data_r.all && data_r.all.slice(0, 6).map((r, index) => {
                  const { screenNameThatShouldBeDisplayed, profileImageThatShouldBeDisplayed } = global.getUserNameAndAvatar({ user: r.user })
                  return (
                    <div className="analytics-global__activity-member" key={index}>
                      <div className="analytics-global__activity-member-rank">
                        {index + 1}
                      </div>
                      <div className="analytics-global__activity-member-pic">
                        <img src={profileImageThatShouldBeDisplayed} onError={(e) => { e.target.src = defaultUserIcon }} alt="member pic" />
                      </div>

                      <div className="analytics-global__activity-member-bloc">
                        <div className="analytics-global__activity-member-name">
                          {screenNameThatShouldBeDisplayed}
                        </div>
                        <div className="analytics-global__activity-member-share">
                          {r.published ? r.published : 0} {wording.top_users.share}
                        </div>
                      </div>
                      <span className="analytics-global__activity-member-points">
                        {r.total}
                      </span>
                      <IconStar />
                    </div>

                  )
                })
              }
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default AnalyticsGlobal
