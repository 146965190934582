import React from 'react';

// Init component.
function IconCrossDelete(props) {
	return (
		<svg className="icon icon-cross-delete" viewBox="0 0 16 16">
			<circle id="delete-a" cx="8" cy="8" r="8" />
			<path fill="#FFF" d="M9,7 L11,7 C11.5522847,7 12,7.44771525 12,8 C12,8.55228475 11.5522847,9 11,9 L9,9 L9,11 C9,11.5522847 8.55228475,12 8,12 C7.44771525,12 7,11.5522847 7,11 L7,9 L5,9 C4.44771525,9 4,8.55228475 4,8 C4,7.44771525 4.44771525,7 5,7 L7,7 L7,5 C7,4.44771525 7.44771525,4 8,4 C8.55228475,4 9,4.44771525 9,5 L9,7 Z" transform="rotate(45 8 8)" />
		</svg>
	);
}

export default IconCrossDelete;