import React from 'react';

// Init component.
function IconPeople(props) {
  return (
    <svg width="17" height="11" viewBox="0 0 17 11" xmlns="http://www.w3.org/2000/svg" className="fill-primary">
      <path d="M11.5909 4.71429C12.8736 4.71429 13.9014 3.66143 13.9014 2.35714C13.9014 1.05286 12.8736 0 11.5909 0C10.3082 0 9.27273 1.05286 9.27273 2.35714C9.27273 3.66143 10.3082 4.71429 11.5909 4.71429ZM5.40909 4.71429C6.69182 4.71429 7.71954 3.66143 7.71954 2.35714C7.71954 1.05286 6.69182 0 5.40909 0C4.12636 0 3.09091 1.05286 3.09091 2.35714C3.09091 3.66143 4.12636 4.71429 5.40909 4.71429ZM5.40909 6.28571C3.60864 6.28571 0 7.205 0 9.03571V11H10.8182V9.03571C10.8182 7.205 7.20955 6.28571 5.40909 6.28571ZM11.5909 6.28571C11.3668 6.28571 11.1118 6.30143 10.8414 6.325C11.7377 6.985 12.3636 7.87286 12.3636 9.03571V11H17V9.03571C17 7.205 13.3914 6.28571 11.5909 6.28571Z" />
    </svg>
  );
}

export default IconPeople;
