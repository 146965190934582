// Libs
import React from "react"
import moment from 'moment';
import i18n from '../../../i18n';

// Assets
import IconTwitterVerified from '../../../assets/icons/twitter-verified';
import IconAccountVerified from "../../../assets/icons/account-verifed";
import avatarPlaceholder from "../../../assets/images/default_profile_normal.png"

// Config
moment.locale(i18n.language)

class FluxCardHeader extends React.Component {
  render() {
    const account = this.props.account
    const account_info = JSON.parse(account.account_info)
    const post = this.props.post

    return (
      <div className="flux-card__header">
        <div className="flux-card__header-account-picture">
          <img src={account_info.profile_image_url || avatarPlaceholder} onError={(e) => { e.target.src = avatarPlaceholder }} alt="account profil pic" />
        </div>
        <div className="flux-card__header-info">
          <div className="flux-card__header-bloc">
            <div className="flux-card__header-account-name">
              {account.display_name}
              {account_info.verified ? (
                account.network === "twitter" ? <IconTwitterVerified /> : <IconAccountVerified />
              ) : ""}
            </div>
            <div className="flux-card__header-date">
              {moment(new Date(post.created_at)).lang(i18n.language).format('Do MMMM YYYY')}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FluxCardHeader