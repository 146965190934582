import React from 'react';
import { connect } from "react-redux";

// Import components
import * as actionCreators from "../actions/actionCreators"
import Analytics from "../components/analytics/analytics.js";

class AnalyticsCon extends React.Component {
  render() {
    return (
      <Analytics {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return state
};

export default connect(mapStateToProps, actionCreators, null, { pure: false })(AnalyticsCon);
