const styles = theme => ({
  emptyText: {
    marginTop: 40,
    textAlign: "center",
    color: theme.greyBlue,
    fontSize: 15,
    fontWeight: 600
  },
  detail: { // verif
    fontSize: 15,
    color: theme.greyBlue,
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    fontWeight: 600
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 5,
    marginBottom: 10
  },
  avatarActivity: {
    marginBottom: 0
  },
  icon: { // verif
    marginRight: 10,
    marginBottom: -4
  },
  groupChip: Object.assign({}, theme.groupChip, {
    marginRight: 5,
    marginBottom: 10
  }),
  // Tooltip
  tooltipUser: {
    maxHeight: 400,
    overflow: "hidden auto",
    pointerEvents: "unset",
    opacity: 1
  },
  tooltip: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    lineHeight: 1.5,
  },
  tooltipTitle: {
    display: "inline-flex",
  },
  tooltipContainer: {
    width: "100%",
    top: -13,
    position: "absolute",
    paddingRight: 16,
    textAlign: "center"
  },
  filters: {
    margin: "10px -7.5px 0",
  },
  filterButton: {
    borderRadius: 12.5,
    minHeight: 25,
    minWidth: 50,
    padding: 0,
    "&:hover": {
      backgroundColor: "#f1f4f6"
    },
    color: theme.blueGrey
  },
  filterButtonActive: {
    "&.orange": {
      backgroundColor: "orange",
      color: "white"
    },
    "&.green": {
      backgroundColor: theme.mintyGreen,
      color: "white"
    },
    "&.grapefruit": {
      backgroundColor: theme.grapefruit,
      color: "white"
    }
  },
  filterButtonLabel: {
    fontSize: 13,
    fontWeight: 600
  },
  search: {
    marginTop: 5,
    marginBottom: 7,
    width: "100%"
  },
  userListItem: {
    padding: "5px 0",
    position: "relative"
  },
  userListItemTextRoot: {
    padding: "0px 10px",
    "&:hover": Object.assign({
      paddingLeft: [10, "!important"],
    }, theme.groupChip),
  },
  userListItemTextPrimary: {
    fontSize: 15,
    fontWeight: "normal",
    color: theme.greyBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "&:hover": {
      color: "inherit",
      fontWeight: 600
    }
  },
  refusalMessage: {
    borderRadius: 5,
    border: `solid 1px ${theme.grapefruit}`,
    padding: 15,
    color: theme.greyishBrown,
    fontSize: 13,
  },
  refusalTitle: {
    color: theme.grapefruit,
    textTransform: "uppercase",
    lineHeight: "20px",
    fontSize: 11,
    fontWeight: "bold",
  },
  badgeStatus: {
    padding: 0,
    transform: "translate(0, 0)",
    height: "auto",
    minWidth: "auto"
  }
});

export default styles;