import React from 'react';
import { connect } from "react-redux";
import TimerMixin from 'react-timer-mixin';
import reactMixin from 'react-mixin';
import hash from 'object-hash';

// Import components
import * as actionCreators from "../actions/actionCreators";
import Home from "../components/homeManager/homeManager.js";
import params from '../utils/params';
import EventEmitter from '../utils/EventEmitter';

class HomeCon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpen: false,
      mediaIsOpen: false,
      mediaIndex: 0,
      media: {},
      formData: {},
      lastDraftsTweetitsLength: 0,
      hasMoreDraft: true,
      lastSuggestedTweetitsLength: 0,
      hasMoreSuggested: true,
      loadingMore: true,
      scrollEnd: 0,
      loadingMoreDraft: false,
      loadingMoreSuggested: false,
      loadingMorePublished: false,
    };

    this.loadMoreTweetits = this.loadMoreTweetits.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
    this.minimizeMedia = this.minimizeMedia.bind(this);
    this.formatUploadUrl = this.formatUploadUrl.bind(this);
    this.fastSubmitTweetit = this.fastSubmitTweetit.bind(this);
    this.createPost = this.createPost.bind(this);
  }

  async componentDidMount() {
    this.props.getTweetitsAction(10, 0, ["draft"]).then(() => {
      this.props.getTweetitsAction(10, 0, ["suggested"]).then(() => {
        this.setState({ loadingMore: false })
      });
    });
    this.props.getUsersAction();
    this.props.getGroupsAction();
    this.props.getTagsAction();

    /* Auto refresh */
    this.timer = setInterval(async function () {

      /* Disable the auto fetch to the api on page members or groups
         and If the window to create or edit a tweet is not display
      */
      if (
        this.props.history.location.pathname.indexOf("/members") === -1 &&
        this.props.history.location.pathname.indexOf("/groups") === -1 &&
        this.props.history.location.pathname.indexOf("/statistiques") === -1 &&
        !this.state.dialogIsOpen
      ) {
        await this.props.refreshTweetitsAction(
          this.props.tweetits_draft.length,
          0,
          hash(this.props.tweetits_draft.filter(t => t.id !== this.state.formData.id)), // Ignorer le tweetit en cours d'édition
          this.state.formData.id,
          params.statuses.DRAFT
        );
        await this.props.refreshTweetitsAction(
          this.props.tweetits_suggested.length,
          0,
          hash(this.props.tweetits_suggested.filter(t => t.id !== this.state.formData.id)), // Ignorer le tweetit en cours d'édition
          this.state.formData.id,
          params.statuses.SUGGESTED
        );
        this.props.tweetits_suggested.forEach(tweetit => {
          if (tweetit.scheduled_at) {
            this.forceUpdate()
          }
        });
      }
    }.bind(this), 15000);

  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  openTweetitDialog = data => {
    if (data.media) {
      /* Il faut cloner sinon les modifications dans le state affectent les props par référence */
      let clonedMedia = [...data.media].map(m => ({ ...m }));
      this.setState({
        dialogIsOpen: true,
        formData: Object.assign(data, this.state.formData, { media: clonedMedia })
      });
    } else {
      this.setState({
        dialogIsOpen: true,
        formData: Object.assign(data, this.state.formData)
      });
    }
  };

  closeTweetitDialog = () => {
    this.setState({ dialogIsOpen: false, formData: {} });
  };

  async fastSubmitTweetit(post_id, data) {
    let res = await this.props.editTweetitAction(post_id, data)

    if (res && res.error) EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })

    return res
  }

  async createPost(post) {
    let res = await this.props.createTweetitAction(post)

    if (res && res.error) EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })

    return res
  }

  formatUploadUrl(media, originalSize) {
    if (!media || !media.uploadcare_id) {
      return null;
    }

    if (media.type === params.mediaTypes.VIDEO || media.type.includes('video')) {
      return `https://ucarecdn.com/${media.uploadcare_id}/video.mp4`;
    }

    return `https://ucarecdn.com/${media.uploadcare_id}/${originalSize ? '' : '-/resize/x200/'}`;
  }

  enlargeMedia = (data, index) => {
    this.setState({
      mediaIsOpen: true,
      mediaIndex: index,
      media: data,
    });
  };

  minimizeMedia() {
    this.setState({ mediaIsOpen: false });
  }

  scrollDown(e) {
    // var limit = Math.max( e.scrollHeight, e.offsetHeight,
    //     e.clientHeight, e.scrollHeight, e.offsetHeight );
    // //console.log(this.state.scrollEnd, e.scrollTop, limit)
    // this.setState({ scrollEnd: e.scrollTop });
  }

  loadMoreTweetits(e, length, nb, status, loader) {
    if (e.scrollTop > 0 && !this.state.loadingMore &&
      ((status[0] === "suggested" && this.state.hasMoreSuggested) ||
        (status[0] === "draft" && this.state.hasMoreDraft))
    ) {
      this.setState({ [loader]: true, loadingMore: true, scrollEnd: e.scrollTop }, () => {
        this.props.getTweetitsAction(2, (nb + length), status).then(res => {
          if (res) {
            let lastName = "";
            let hasMoreName = "";
            switch (status[0]) {
              case "draft":
                lastName = "lastDraftsTweetitsLength";
                hasMoreName = "hasMoreDraft";
                break;
              case "suggested":
                lastName = "lastSuggestedTweetitsLength";
                hasMoreName = "hasMoreSuggested";
                break;
              default:
                break;
            }

            if (!res.filter(t => t.status === status[0]).length) {
              this.setState({ [loader]: false, loadingMore: false, [hasMoreName]: false, [lastName]: res.filter(t => t.status === status[0]).length });
            } else {
              this.setState({ [loader]: false, loadingMore: false, [lastName]: res.filter(t => t.status === status[0]).length })
            }
          } else {
            this.setState({ [loader]: false, loadingMore: false })
          }
        })
          .catch(() => {
            this.setState({ [loader]: false, loadingMore: false })
          });

      })

    }
  }

  render() {
    return (
      <Home
        history={this.props.history}
        formData={this.state.formData}
        user={this.props.user}
        users={this.props.users}
        groups={this.props.groups}
        tags={this.props.tags}
        getMediaUrl={this.formatUploadUrl}
        tweetits={[...this.props.tweetits_draft, ...this.props.tweetits_suggested].sort((a, b) => { return new Date(b.edited_at) - new Date(a.edited_at); })}
        isLoadingDraft={this.props.isLoading_draft}
        isLoadingSuggested={this.props.isLoading_suggested}
        dialogIsOpen={this.state.dialogIsOpen}
        openTweetitDialog={this.openTweetitDialog}
        closeTweetitDialog={this.closeTweetitDialog}
        mediaIsOpen={this.state.mediaIsOpen}
        mediaIndex={this.state.mediaIndex}
        enlargeMedia={this.enlargeMedia}
        minimizeMedia={this.minimizeMedia}
        media={this.state.media}
        submitMedia={this.props.submitMedia}
        createTweetitAction={this.createPost}
        editTweetitAction={this.props.editTweetitAction}
        editPostActivityAction={this.props.editPostActivityAction}
        deleteTweetitAction={this.props.deleteTweetitAction}
        fastSubmitTweetit={this.fastSubmitTweetit}
        getTweetAction={this.props.getTweetAction}
        getInstagramPostAction={this.props.getInstagramPostAction}
        getFacebookPostsAction={this.props.getFacebookPostsAction}
        getProfileAction={this.props.getProfileAction}
        createTagAction={this.props.createTagAction}
        deleteTagAction={this.props.deleteTagAction}
        getFileInfoUploadCare={this.props.getFileInfoUploadCare}
        loadMoreTweetits={this.loadMoreTweetits}
        hasMoreDraft={this.state.hasMoreDraft}
        hasMoreSuggested={this.state.hasMoreSuggested}
        scrollDown={this.scrollDown}
        loadingMoreDraft={(this.state.loadingMoreDraft)}
        loadingMoreSuggested={(this.state.loadingMoreSuggested)}
        loadingMorePublished={(this.state.loadingMorePublished)}
        getLinkedinAccessToken={this.props.getLinkedinAccessToken}
        fetchOpenGraphAction={this.props.fetchOpenGraphAction}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return state
};
reactMixin(HomeCon.prototype, TimerMixin);
export default connect(mapStateToProps, actionCreators)(HomeCon);