
// Libs
import React from "react"
import moment from 'moment';
import { Translation } from 'react-i18next';
import i18n from '../../i18n'

// Assets
import IconPlus from '../../assets/icons/plus';
import emptyFace from "../../assets/images/empty-face.svg"
import TwitterVerified from "../../assets/icons/twitter-verified"
import IconTwitter from "../../assets/icons/twitter-icon"
import IconLinkedin from "../../assets/icons/linkedin-icon"
import IconFacebook from "../../assets/icons/facebook"
import IconInstagram from "../../assets/icons/instagram"

// Components
import SettingsFluxModal from './settingsFluxModal'

// Utils
import EventEmitter from '../../utils/EventEmitter';
import params from '../../utils/params';
import utils from "../../utils/global";

moment.locale(i18n.language);

class SettingsFlux extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      fluxToEdit: null,
      flux: [],
      submitting: false
    };

    this.setModal = this.setModal.bind(this);
    this.createFlux = this.createFlux.bind(this);
    this.editFlux = this.editFlux.bind(this);
    this.deleteFlux = this.deleteFlux.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData()
  }

  setModal(modalMode, fluxToEdit = null) {
    let state = this.state
    state.modal = modalMode
    state.fluxToEdit = fluxToEdit
    this.setState(state)
  }

  async createFlux(data) {

    await this.setState({ submitting: true })
    let res = await this.props.createFluxAction(data)
    await this.setState({ submitting: false })

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
    } else {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.flux.popin_message.flux_created })
      this.setModal(null)
      this.getData()
    }
  }

  async editFlux(data) {

    await this.setState({ submitting: true })
    let res = await this.props.editFluxAction(data)
    await this.setState({ submitting: false })

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
    } else {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.flux.popin_message.flux_edited })
      this.setModal(null)
      this.getData()
    }
  }

  async deleteFlux(data) {
    let res = await this.props.deleteFluxAction(data)

    if (res && res.error) {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
    } else {
      EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: i18n.t('manager', { returnObjects: true }).settings.flux.popin_message.flux_deleted })
      this.setModal(null)
      this.getData()
    }
  }

  async getData() {
    let res = await this.props.getAllFluxAction()
    if (res && res.error) EventEmitter.emit(params.EVENT_OPEN_SNACKBAR, { message: res.error, style: "error" })
    else this.setState({ flux: res })
  }

  getTotalUsersFlux(f) {
    // Get users having a Twitter or Linkedin account
    let users_array = f.users && f.users.length ? f.users.filter(u => u.user_twitter || u.user_linkedin) : []

    // Get users in group having a Twitter or Linkedin account
    users_array = f.groups && f.groups.length ? users_array.concat(...f.groups.map(g => { return g.users.filter(u => u.user_twitter || u.user_linkedin) })) : users_array

    // Get an array of users id
    users_array = users_array && users_array.length && users_array.map((u) => u.id)

    // Remove duplicate id
    users_array = users_array && users_array.length ? [...new Set(users_array)] : []

    return users_array.length
  }

  render() {
    const flux = this.state.flux

    return (
      <Translation>
        {
          (t, { i18n }) => {
            t = t('manager', { returnObjects: true }).settings.flux

            return (
              <div className="settings-flux">
                {/* MODAL */}
                {this.state.modal &&
                  <SettingsFluxModal
                    modal={this.state.modal}
                    fluxToEdit={this.state.fluxToEdit}
                    setModal={this.setModal}
                    groups={this.props.groups}
                    users={this.props.users}
                    user={this.props.user}
                    createFlux={this.createFlux}
                    editFlux={this.editFlux}
                    deleteFlux={this.deleteFlux}
                    searchAccountAction={this.props.searchAccountAction}
                    submitting={this.state.submitting}
                  />
                }

                {/* BUTTON TO ADD A FLUX */}
                <button className="settings-flux__add color-primary" onClick={() => this.setModal('add')}>
                  <div className="settings-flux__add-icon background-color-primary">
                    <IconPlus />
                  </div>
                  {t.create_flux}
                </button>

                {/* LIST FLUX */}
                <div className={`settings-flux__list`}>

                  {!flux.length ? (
                    <div className="settings-flux__list-empty">
                      <img src={emptyFace} alt="" />
                      <p className="settings-flux__list-empty-heading">{t.title_empty}</p>
                      <div className="settings-flux__list-empty-text">{t.text_empty}</div>
                    </div>
                  ) : (
                    <React.Fragment>
                      {/* TABLE HEADER */}
                      <div className="settings-flux__list-row is-header">
                        <div className="settings-flux__list-col">{t.label_author}</div>
                        <div className="settings-flux__list-col">{t.label_flux}</div>
                        <div className="settings-flux__list-col">{t.label_type}</div>
                        <div className="settings-flux__list-col">{t.label_sub}</div>
                        <div className="settings-flux__list-col">{t.label_created_at}</div>
                        <div className="settings-flux__list-col"></div>
                      </div>

                      {flux.map((f, index) => {
                        let account_info = JSON.parse(f.account_info)


                        let creator = this.props.user.id === f.manager_id ? this.props.user : this.props.users.find(user => user.id === f.manager_id)
                        let {
                          screenNameThatShouldBeDisplayed,
                          profileImageThatShouldBeDisplayed
                        } = utils.getUserNameAndAvatar({ user: creator })

                        return (
                          <div key={index} className="settings-flux__list-row">

                            {/* CREATOR NAME + PROFILE PIC */}
                            <div className="settings-flux__list-col">
                              <div className="settings-flux__list-creator">
                                <img src={profileImageThatShouldBeDisplayed} alt="" className="settings-flux__list-creator-avatar" />
                                {screenNameThatShouldBeDisplayed}
                              </div>
                            </div>

                            {/* ACCOUNT NAME + PROFILE PIC */}
                            <div className="settings-flux__list-col">
                              <div className="settings-flux__list-flux">
                                <div>
                                  <div className="settings-flux__list-flux-display">
                                    {f.display_name}
                                    {account_info.verified && <TwitterVerified />}
                                  </div>
                                  {f.network === 'twitter' && <div className="settings-flux__list-flux-account">@{account_info.screen_name}</div>}
                                </div>
                              </div>
                            </div>

                            {/* NETWORK ICON */}
                            <div className="settings-flux__list-col">
                              <div className="settings-flux__list-type">
                                {f.network === 'twitter' && <IconTwitter />}{f.network === 'linkedin' && <IconLinkedin />}
                                {f.network === 'facebook' && <IconFacebook />}{f.network === 'instagram' && <IconInstagram />}
                              </div>
                            </div>

                            {/* FOLLOWERS COUNT */}
                            <div className="settings-flux__list-col">
                              <div className="settings-flux__list-subscribers">
                                {this.getTotalUsersFlux(f)}
                              </div>
                            </div>

                            {/* CREATED DATE */}
                            <div className="settings-flux__list-col">
                              <div className="settings-flux__list-date">
                                {moment(f.created_at).lang(i18n.language).format('Do MMMM YYYY')}
                              </div>
                            </div>

                            {/* EDIT BUTTON */}
                            <div className="settings-flux__list-col">
                              <button className="settings-flux__btn settings-flux__btn-secondary" onClick={() => this.setModal('edit', this.state.flux[index])}>
                                {t.btn_edit}
                              </button>
                            </div>
                          </div>
                        )
                      })
                      }
                    </React.Fragment>
                  )}
                </div>
              </div>
            )
          }
        }
      </Translation>

    )
  }
}

export default SettingsFlux